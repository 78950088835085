import routes from "config/routes";
import i18next from "i18next";

export const getPageName = (location: string) => {
  switch (location) {
    case routes.HOME:
      return i18next.t("Home");
    case routes.POOLS:
      return i18next.t("Pools");
    case routes.MYPACKAGES:
      return i18next.t("Memberships");
    case routes.VIEWZONE:
      return i18next.t("ViewZone");
    case routes.ACCOUNTCENTER:
      return i18next.t("Account Center");
    case routes.ACADEMY:
      return i18next.t("The View academy");
    case routes.INCOMECALCULATOR:
      return i18next.t("Income Calculator");
    case routes.VIDEOS:
      return i18next.t("The View App");
    default:
      return "";
  }
};
