import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAxiosGet } from "api/useApi";
import Loader from "components/Loader/Loader";
import { PoolsName } from "interface/IpoolsName";
import GenericPoolsPage from "pages/pools/GenericPools/GenericPoolsPage";
import * as G from "styles/Grid.styles";

import * as S from "../videos/Videos.styles";

const CreditLedger = () => {
  const { t } = useTranslation();

  const [getUserCredit, getUserCreditResult, getUserCreditStatus] = useAxiosGet(
    `/credit-history?&limit=20&sortBy=createdAt&sortOrder=-1`
  );

  const stats = getUserCreditResult?.data?.data;

  useEffect(() => {
    getUserCredit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = getUserCreditStatus === "loading";

  if (isLoading) return <Loader />;

  return (
    <G.GridContainer>
      <G.Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
        <S.PageWrapper>
          <GenericPoolsPage
            poolsName={PoolsName.CREDIT}
            description={t(
              "Please find an overview of all your The View Credits below"
            )}
            creditStats={stats}
            stats={stats}
          />
        </S.PageWrapper>
      </G.Grid>
    </G.GridContainer>
  );
};

export default CreditLedger;
