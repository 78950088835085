import React, { useEffect, useState } from "react";
import i18n from "i18n";

import ApiLoaderView from "components/ApiLoaderView";
import InfinityPoolsGeneralPageInfo from "./InfinityGeneralPageInfo";
import useInfinityPoolsApi from "api/useInfinityPoolsApi";
import InfinityPoolsCard from "./InfinityPoolsCard";

import { Grid, GridContainer } from "styles/Grid.styles";
import { PoolsContainer } from "./InfinityPoolsPage.styles";

interface PoolsPageProps {}
const PoolsPage: React.FC<PoolsPageProps> = () => {
  const { infinityPools, getStatus } = useInfinityPoolsApi(true);
  const [infinityPoolsData, setInfinityPoolsData] = useState([]);

  const loadData = async () => {
    if (getStatus === "success") {
      setInfinityPoolsData(infinityPools);
    }
  };
  useEffect(() => {
    loadData();
  }, [getStatus]);
  const pageView = () => {
    return (
      <>
        <ApiLoaderView apiCall={loadData}>
          <GridContainer>
            <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
              <InfinityPoolsGeneralPageInfo
                image="/assets/infinity-pool/infinity-pool-page-icon.png"
                title={i18n.t("The Infinity Pool")}
                description={i18n.t(
                  "Infinity Pool will also be applied to everyone that has already made the application since the beginning including Theia Packages"
                )}
              ></InfinityPoolsGeneralPageInfo>
            </Grid>
            <Grid
              smCol="1/4"
              mdCol="1/8"
              lgCol="1/12"
              smRow="3/3"
              mdRow="3/3"
              lgRow="2/3"
            >
              <PoolsContainer>
                <InfinityPoolsCard
                  infinityPoolsData={infinityPoolsData}
                ></InfinityPoolsCard>
              </PoolsContainer>
            </Grid>
          </GridContainer>
        </ApiLoaderView>
      </>
    );
  };
  return <>{pageView()}</>;
};

export default PoolsPage;
