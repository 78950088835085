import axios from "axios";
import { useState } from "react";
import { LoaderStates } from "config/states";
import { baseUrl, getHeader } from "./useApi";

const useInfinityPoolsApi = (directStart: boolean = false) => {
  const [getStatus, setGetStatus] = useState(LoaderStates.IDLE);
  const [infinityPools, setInfinityPools] = useState([]);

  const getInfinityPools = async () => {
    if (getStatus === LoaderStates.LOADING) return;
    setGetStatus(LoaderStates.LOADING);
    try {
      const result = await axios.get(`${baseUrl}/infinity_pool`, getHeader());
      const infinityPoolsArray = result.data.data;
      setInfinityPools(infinityPoolsArray);
      setGetStatus(LoaderStates.SUCCESS);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetStatus(LoaderStates.ERROR);
    }
  };
  if (directStart && getStatus === LoaderStates.IDLE) getInfinityPools();
  return { infinityPools, getStatus, getInfinityPools };
};

export default useInfinityPoolsApi;
