export interface Link {
  url: string;
  text: string;
}

export interface LinkData {
  year: number;
  links: Link[];
}
export const linkData: LinkData[] = [
  {
    year: new Date().getFullYear(),
    links: [
      {
        url: "/assets/legal/The_View_Terms_of_Use.pdf",
        text: "Terms of Use",
      },
      { url: "/assets/legal/Disclaimer_The_View.pdf", text: "Disclaimer" },
      {
        url: "/assets/legal/The_View_Privacy_Policy.pdf",
        text: "Privacy Policy",
      },
      {
        url: "/assets/legal/Cookie_Policy_The_View.pdf",
        text: "Cookies Policy",
      },
    ],
  },
];
