import MobileSideBar from "components/Sidebar/Sidebar";
import sStorage from "util/sStorage";

import { useAppDispatch, useAppState } from "AppContext";

import * as S from "./Header.styles";

const Header = () => {
  const appDispatch: Function = useAppDispatch();
  const { isSidebarShown, theme } = useAppState();

  return (
    <div>
      <S.HeaderSet theme={theme}>
        <S.Container isSidebarShown={isSidebarShown}>
          <S.HeaderSetMenuButton
            closed={!isSidebarShown}
            onClick={() => {
              appDispatch({
                type: "sidebarShown",
                data: !isSidebarShown,
              });
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </S.HeaderSetMenuButton>
          <S.HeaderSideBarLogo closed={!isSidebarShown}>
            <img
              src={`/assets/re-branding/logos/new-view-logo-${
                sStorage.get("theme") === "TheiaThemeLight" ? "black" : "white"
              }.svg`}
              alt="viewlogo"
            />
          </S.HeaderSideBarLogo>
          <div></div>{" "}
        </S.Container>
      </S.HeaderSet>
      <S.HeaderSetMobile closed={!isSidebarShown}>
        <MobileSideBar />
      </S.HeaderSetMobile>
    </div>
  );
};

export default Header;
