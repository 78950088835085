import React, { useEffect } from "react";
import { useAppDispatch, useAppState } from "AppContext";

interface AppDirectionProps {}

const AppDirection: React.FC<AppDirectionProps> = ({ children }) => {
  const appDispatch: Function = useAppDispatch();
  const { isDirection, language } = useAppState();

  const detectDirection = (direction: string) => {
    appDispatch({
      type: "direction",
      data: direction,
    });
  };

  useEffect(() => {
    language === "ar" ? detectDirection("rtl") : detectDirection("ltr");
  }, [language, isDirection]);

  return <div dir={isDirection === "rtl" ? "rtl" : "ltr"}>{children}</div>;
};

export default AppDirection;
