import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";

import * as S from "./Videos.styles";

interface VideoProps {
  title: string;
  description: string;
  onVideoClick: () => void;
}

const Video: FC<VideoProps> = ({ title, description, onVideoClick }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onVideoClick();
  };

  return (
    <S.VideoWrapper onClick={handleClick}>
      <S.VideoStaticWrapper>
        <PlayIcon />
      </S.VideoStaticWrapper>
      <S.VideoInformationWrapper>
        <S.VideoTitle>{t(title)}</S.VideoTitle>
        <S.VideoDescription>{t(description)}</S.VideoDescription>
      </S.VideoInformationWrapper>
    </S.VideoWrapper>
  );
};

export default Video;
