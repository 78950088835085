import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useFeatureFlag from "api/useFeatureFlag";
import useStatsApi from "api/useStatsApi";
import CreditModal from "components/Credits/views/CreditModal";
import Header from "components/Header/Header";
import PageContent from "components/PageContent/PageContent";
import Sidebar from "components/Sidebar/Sidebar";

import { useAppState } from "AppContext";

const RenderPage = () => {
  const history = useHistory();
  const { user } = useAppState();

  const [showMaintenance, setShowMaintenance] = useState(false);
  const { stats, getStatsStatus } = useStatsApi({
    init: ["getStats"],
  });

  const isUserLandExist = user?.lands && Object.keys(user.lands).length > 0;

  const isCreditRequestsActive = useFeatureFlag("credit-requests");

  useEffect(() => {
    const shouldShowMaintenance =
      !stats?.user && !stats?.total && getStatsStatus === "success";
    setShowMaintenance(shouldShowMaintenance);
  }, [stats, getStatsStatus, history]);

  if (getStatsStatus === "loading") return null;

  const renderHeader = !showMaintenance && <Header />;
  const renderSidebar = !showMaintenance && <Sidebar />;

  const renderContent = (
    <div style={{ display: "flex" }}>
      {renderSidebar}
      <PageContent showMaintenance={showMaintenance} />
    </div>
  );

  return (
    <>
      {renderHeader}
      {isUserLandExist && isCreditRequestsActive && (
        <CreditModal modalType={user.lands?.status} userLands={user.lands} />
      )}
      {renderContent}
    </>
  );
};

export default RenderPage;
