import i18n from "i18n";

export const getMembershipName = (membership: any) => {
  if (membership?.size) {
    if (membership.size.toUpperCase() === "S") return i18n.t("Founders S");
    if (membership.size.toUpperCase() === "M") return i18n.t("Founders M");
    if (membership.size.toUpperCase() === "L") return i18n.t("Founders L");
    if (membership.size.toUpperCase() === "XL") return i18n.t("Founders XL");

    if (membership.size.toUpperCase() === "VIEW_BASIC") return i18n.t("Basic");
    if (membership.size.toUpperCase() === "VIEW_STANDARD")
      return i18n.t("Standard");
    if (membership.size.toUpperCase() === "VIEW_SUPER") return i18n.t("Super");
    if (membership.size.toUpperCase() === "VIEW_ULTRA") return i18n.t("Ultra");
    if (membership.size.toUpperCase() === "VIEW_ULTRA_PLUS")
      return i18n.t("Ultra+");
  } else {
    if (membership.value === 125) return i18n.t("Basic");
    if (membership.value === 250) return i18n.t("Standard");
    if (membership.value === 500) return i18n.t("Super");
    if (membership.value === 1000) return i18n.t("Ultra");
    if (membership.value === 2000) return i18n.t("Ultra+");
  }

  return "Invalid Membership!";
};
