import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const ErrorWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 148px 0px 0px 0px;
  @media ${breakpoints.tablet} {
    flex-direction: column;
    margin: 82px 0px 0px 38px;
  }
  @media ${breakpoints.mobile} {
    margin: 74px 12px 0px 0px;
  }
`;
export const ErrorImageBlock = styled.div`
  width: 100%;
  margin: -71px 0px 0px 0px;

  @media ${breakpoints.tablet} {
    width: 45%;
    margin: 0px 0px 0px 263px;
  }
  @media ${breakpoints.mobile} {
    width: 60%;
    margin: 38px 0px 0px 160px;
  }
`;

export const ErrorImage = styled.img`
  max-width: 514px;
  max-height: 508px;
  @media ${breakpoints.tablet} {
    max-width: 406px;
    max-height: 401px;
  }
  @media ${breakpoints.mobile} {
    max-width: 221px;
    max-height: 218px;
  }
`;

export const ErrorContentWrapper = styled.div`
  width: 100%;
  margin-left: 167px;
  button {
    height: 48px;
    width: 201px;
    border-radius: 14px;
    font-size: 16px;
    line-height: 14px;
  }
  @media ${breakpoints.tablet} {
    width: 78%;
    margin: -135px 0px 0px 7px;
    button {
      margin-left: -4px;
    }
  }
  @media ${breakpoints.mobile} {
    width: 100%;
    margin: -73px 0px 0px 12px;
    button {
      margin-left: 0px;
    }
  }
`;

export const ErrorContentBlock = styled.div`
  margin-bottom: 79px;
  @media ${breakpoints.tablet} {
    width: 75%;
    margin-left: 0px;
    margin-bottom: 73px;
  }
  @media ${breakpoints.mobile} {
    width: 95%;
    margin-bottom: 73px;
    margin-left: 0px;
  }
`;

export const ErrorHeader = styled.div`
  font-size: 80px;
  line-height: 75px;
  font-weight: 600;
  color: ${(p: any) => p.theme.colors.textColors.textVariant};

  @media ${breakpoints.tablet} {
    line-height: 100px;
  }
  @media ${breakpoints.mobile} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const ErrorHighlightText = styled.span`
  font-size: 80px;
  line-height: 75px;
  font-weight: 600;
  color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
  @media ${breakpoints.tablet} {
    line-height: 100px;
  }
  @media ${breakpoints.mobile} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const ErrorContext = styled.div`
  margin: 45px 40px 0px 7px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  @media ${breakpoints.tablet} {
    margin: 39px 17px 0px 0px;
  }
  @media ${breakpoints.mobile} {
    margin: 9px 0px 0px 3px;
    color: ${(p: any) => p.theme.colors.brandColors.brandVariantLight};
  }
`;
