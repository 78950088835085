import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const Description = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  span {
    color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
  }
  div {
    font-size: 18px;
    color: #808191;
    font-weight: 400;
    margin-top: 8px;
  }
`;
export const RegionCardWrapper = styled.div<{
  maxSelected: boolean;
  alreadySelected: boolean;
}>`
  margin-bottom: 30px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? " margin-left: 20px"
      : " margin-right: 20px;margin-left:5px"};

  &:hover {
    .fingerprint {
      background-color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
    }
  }
  div {
    @media ${breakpoints.mobile} {
      max-width: 163px;
    }
  }

  div {
    cursor: ${({ maxSelected, alreadySelected }) =>
      maxSelected && !alreadySelected ? "default" : "pointer"};
  }

  @media ${breakpoints.mobile} {
    margin: auto;
    margin-bottom: 24px;
  }
`;

export const FingerprintContainer = styled.div<{
  maxSelected?: boolean;
  selected?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    height: 80%;
    margin-top: 5%;
  }
`;

export const Opacity = styled.div<{
  maxSelected: boolean;
  selected: boolean;
}>`
  opacity: ${({ maxSelected, selected }) =>
    maxSelected && !selected ? 0.5 : 1};
  cursor: ${({ maxSelected, selected }) =>
    maxSelected && !selected ? "not-allowed" : "pointer"};
  div {
    pointer-events: ${({ maxSelected, selected }) =>
      maxSelected && !selected ? "none" : "auto"};
  }
`;

export const RegionVote = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 40px;
`;
