import { useState } from "react";
import { useTranslation } from "react-i18next";

import { IRegionAndCity } from "interface/ILocationData";

export interface IStepCollectionData {
  locationId?: string;
  walletAddress?: string;
  saveWalletId?: boolean;
  saveEmail?: boolean;
  isBlockchainAddressFound: boolean;
  contact: string;
  email: string;
  contactType: string;
  contactLanguage: string;
  requestedAllocations: {
    value: string;
    count: number;
  }[];
}

export const useMintingModalStepper = () => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<{
    shown: boolean;
    type: string;
  }>({
    shown: false,
    type: "",
  });

  const [results, setResults] = useState<any[]>([]);

  const [hasTriedAndFailed, setHasTriedAndFailed] = useState<boolean>(false);

  const [selectedRegion, setSelectedRegion] =
    useState<IRegionAndCity | null>(null);

  const setModalShown = (shown: boolean, type: string = "") => {
    setModalState({ shown, type });
  };

  const handleModalClose = () => {
    setModalShown(false);
    setResults([]);
    setSelectedRegion(null);
  };

  const handleButtonClick = (location: IRegionAndCity) => {
    setModalShown(true);
    setSelectedRegion(location);
  };

  const handleMintIconClick = (location: IRegionAndCity) => {
    setSelectedRegion(location);
    setModalShown(true, "mintingDetails");
  };

  const handleNext = (stepData: []) => {
    setResults([...results, stepData]);
  };

  const handleFinish = (stepData?: any) => {
    const newHasTriedAndFailed =
      stepData.step === "WalletMint" && stepData.error === true;
    newHasTriedAndFailed && setHasTriedAndFailed(true);
  };

  const isMintDataEmpty = (mintData?: number): boolean =>
    !mintData || mintData === 0;

  const getMintingStatus = (mintedData?: number, mintingProgress?: number) => {
    if (isMintDataEmpty(mintedData) && isMintDataEmpty(mintingProgress)) {
      return null;
    }

    const progress = mintingProgress
      ? `${mintingProgress} - ${t("Progress")}`
      : "";
    const minted = mintedData ? `${mintedData} - ${t("Minted")}` : "";

    return {
      text: progress,
      subtext: minted,
    };
  };

  return {
    modalType: modalState.type,
    modalShown: modalState.shown,
    handleModalClose,
    handleButtonClick,
    getMintingStatus,
    selectedRegion,
    isMintDataEmpty,
    activeStep: results.length,
    handleNext,
    handleFinish,
    hasTriedAndFailed,
    handleMintIconClick,
    results: results,
    onTryAgain: (stepName?: string) => {
      const stepIndex = results.findIndex((result) => result.step === stepName);
      setResults(
        results.slice(0, stepIndex !== -1 ? stepIndex : results.length)
      );
    },
  };
};
