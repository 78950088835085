import { Controls, Player } from "@lottiefiles/react-lottie-player";

import loaderjson from "./loader.json";

const ButtonLoader = () => {
  return (
    <Player
      autoplay
      loop
      src={loaderjson}
      style={{ height: "20px", width: "20px" }}
    >
      <Controls
        visible={false}
        buttons={["play", "repeat", "frame", "debug"]}
      />
    </Player>
  );
};

export default ButtonLoader;
