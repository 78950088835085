import styled, { keyframes } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";
import { ToggleDropdown } from "./AllocatePackageForm";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  @media ${breakpoints.mobile} {
    width: 100%;
  }
  button {
    margin-left: 1px;
    height: 40px;
  }
`;

export const PackageTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.44px;
  line-height: 40px;
  padding-bottom: 10px;
  color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
`;

export const PackageDate = styled.div`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.34px;
  line-height: 40px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "  text-align: left"
      : "  text-align: right"};

  color: ${(p: any) => p.theme.colors.textColors.textVariant};
`;

export const AllocatePackageColor = styled.span``;

export const AllocatePackageOption = styled.div<{ value: any }>`
  padding: 10px;
  font-size: 14px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "  text-align: right"
      : "  text-align: left"};
  border-radius: 14px;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background: ${(p: any) => p.theme.colors.brandColors.brandPrimaryVariant};
      cursor: pointer;
    }
  }

  > :nth-child(2) {
    color: ${(p: any) => p.theme.colors.surfaces.surfaceVariantTwo};
  }
  ${AllocatePackageColor} {
    color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
  }
`;

export const PackageHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "  flex-direction: row-reverse;"
      : "  flex-direction: inherit;"};
`;

export const PackageHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "  text-align: right"
      : "  text-align: left"};
`;

export const PackageTitleSmall = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  margin-bottom: -2px;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  color: #7d7e8e;
`;

export const FormSelectBorder = styled.div`
  border-radius: 24px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid ${(p: any) => p.theme.colors.surfaces.buttonPrimary};
`;

export const DropdownBody = styled.div`
  padding: 10px 0;
  width: 100%;
  border-radius: 24px;
  height: 150px;
  position: absolute;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
  border: 1px solid ${(p: any) => p.theme.colors.surfaces.buttonPrimary};
  @media ${breakpoints.mobile} {
    top: auto;
    bottom: 82%;
  }
`;

export const DropdownArrow = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  ${(props) =>
    props.theme.direction === "rtl" ? "  left:10px" : "  right:10px"};

  mask: url("/assets/re-branding/icons/arrow.svg") no-repeat center;
  transition: 0.4s all ease-in-out;
  background: ${(p: any) => p.theme.colors.textColors.textVariant};
`;

export const Dropdown = styled.div<ToggleDropdown>`
  border-radius: 16px;
  background-color: white;
  transition: 0.5s;
  ${DropdownBody} {
    display: ${(props) => {
      return props.toggleDropdown ? "block" : "none";
    }};
  }
  ${DropdownArrow} {
    transform: ${(props) =>
      props.toggleDropdown ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const DropdownHeaderColor = styled.div`
  span {
    color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
  }
`;

export const DropdownHeader = styled.div<{ onClick: any }>`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? `  flex-direction: row-reverse ; div{
          left:10px
      }`
      : `  flex-direction: initial;  `};

  border-radius: 23px;
  animation: ${fadeIn} 0.8s;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
  color: ${(p: any) => p.theme.colors.textColors.textVariant};
  @media (hover: none) and (pointer: coarse) {
    cursor: none;
  }
  @media ${breakpoints.mobile} {
    padding: 10px 15px;
  }
`;

export const DropdownBodyContent = styled.div`
  height: 90%;
  width: 100%;
  overflow: auto;
  border-radius: 20px;
  padding: 5px;
  position: absolute;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
`;

export const Button = styled.button`
  position: relative;
`;
