import React from "react";
import { useTranslation } from "react-i18next";
import {
  ProfilePool,
  ProfilePoolContent,
  ProfilePoolContentWrapper,
  ProfilePoolImage,
  ProfilePoolTitle,
  EuroSpan,
} from "./IncomeCalculatorPageBanner.styles";

interface IncomeCalculatorPoolItemsProps {
  state: any;
  totalPotentialRev: number;
}

const IncomeCalculatorPoolItems: React.FC<IncomeCalculatorPoolItemsProps> = (
  state
) => {
  const { t } = useTranslation();
  const onePercentPotentialRev = state?.totalPotentialRev / 100;
  const data = state?.state?.stats;
  const ownedSharesFounders =
    data?.user?.founders * (onePercentPotentialRev / data?.total?.founders) ||
    0;

  const ownedSharesGlobal =
    data?.user?.global * (onePercentPotentialRev / data?.total?.global) || 0;

  const poolBoxItems = [
    {
      key: "founders",
      title: t("Founders Circle"),
      icon: (
        <img
          src="/assets/re-branding/pool-icons/founders.png"
          alt={t("founders")}
          width="48px"
          height="48px"
        />
      ),
      ownedShares: `${ownedSharesFounders.toFixed(2)} `,
      totalShares: `${(
        (data?.user?.founders / data?.total?.founders) *
        100
      ).toFixed(1)} %`,
    },
    {
      key: "global",
      title: t("Global Pool"),
      icon: (
        <img
          src="/assets/re-branding/pool-icons/globe.png"
          alt={t("founders")}
          width="48px"
          height="48px"
        />
      ),
      ownedShares: `${ownedSharesGlobal.toFixed(2)} `,
      totalShares: ` ${(
        data?.user?.global / data?.total?.global || 0 * 100
      ).toFixed(1)} %`,
    },
  ];

  return (
    <ProfilePool>
      {poolBoxItems.map((item: any, index: number) => (
        <ProfilePoolContentWrapper key={index}>
          <ProfilePoolImage>{item.icon}</ProfilePoolImage>
          <ProfilePoolTitle>{t(item.title)}:</ProfilePoolTitle>
          <ProfilePoolContent key={index}>
            {item.ownedShares && (
              <>
                <EuroSpan> € {item.ownedShares} </EuroSpan> {t("you own")}
              </>
            )}{" "}
            <span> {item.totalShares} </span>
          </ProfilePoolContent>
        </ProfilePoolContentWrapper>
      ))}
    </ProfilePool>
  );
};

export default IncomeCalculatorPoolItems;
