import axios from "axios";
import { useState } from "react";

import { LoaderStates } from "config/states";
import { baseUrl, getHeader } from "./useApi";
import { IDashboardLocation } from "interface/ILocationData";

const useLocationsApi = (
  directStart: boolean = false,
  apiUrl: string,
  locationType: string
) => {
  const [getLocationsStatus, setGetLocationsStatus] = useState(
    LoaderStates.IDLE
  );
  const [locations, setLocations] = useState([] || {});
  const [dashboardLocations, setDashboardLocations] =
    useState<IDashboardLocation | undefined>();

  const getLocations = async () => {
    if (getLocationsStatus === LoaderStates.LOADING) return;
    setGetLocationsStatus(LoaderStates.LOADING);
    try {
      const result = await axios.get(`${baseUrl}/${apiUrl}`, getHeader());
      setLocations(result.data.data);
      setDashboardLocations(result.data.locationsGroupedByType);
      setGetLocationsStatus(LoaderStates.SUCCESS);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetLocationsStatus(LoaderStates.ERROR);
    }
  };
  if (directStart && getLocationsStatus === LoaderStates.IDLE) getLocations();
  return { locations, getLocationsStatus, getLocations, dashboardLocations };
};

export default useLocationsApi;
