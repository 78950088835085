import React, { useReducer } from "react";
import {
  theiaUnitsReducer,
  IncomeCalculatorState,
  initialIncomeCalculatorState,
} from "./TheiaUnitsReducer";

const TheiaUnitsStateContext = React.createContext(
  initialIncomeCalculatorState
);
const TheiaUnitsDispatchContext = React.createContext({});

export const useTheiaUnitsState = () => {
  //TODO: ADD ANWERED AND ERROR

  const context = React.useContext(TheiaUnitsStateContext);
  if (context === undefined)
    throw new Error(
      "useTheiaUnitsState must be used within a TheiaUnitsProvider"
    );
  return context;
};

export const useTheiaUnitsDispatch = (): Function => {
  const context = React.useContext(TheiaUnitsDispatchContext);
  if (context === undefined)
    throw new Error(
      "useTheiaUnitsDispatch must be used within a TheiaUnitsProvider"
    );
  return context as Function;
};

interface TheiaUnitsProviderProps {
  initialState: IncomeCalculatorState;
}

const TheiaUnitsProvider: React.FC<TheiaUnitsProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(theiaUnitsReducer, initialState);
  return (
    <TheiaUnitsStateContext.Provider value={state}>
      <TheiaUnitsDispatchContext.Provider value={dispatch}>
        {children}
      </TheiaUnitsDispatchContext.Provider>
    </TheiaUnitsStateContext.Provider>
  );
};

export default TheiaUnitsProvider;
