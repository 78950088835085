import { useAppState } from "AppContext";

function useFeatureFlag(flagName: string): boolean {
  const { user } = useAppState();
  const flag = user?.featureFlags?.find((flag) => {
    return flag.name === flagName;
  });
  const isActive = flag ? flag.isActive : false;

  return isActive;
}

export default useFeatureFlag;
