import React from "react";

import { PublicTemplate } from "pages/login/PublicTemplate";
import PublicRoutes from "routes/PublicRoutes";

import { useAppState } from "AppContext";

interface AppAuthProps {
  children?: any;
}

const AppAuth = ({ children }: AppAuthProps) => {
  const { isLoggedIn } = useAppState();
  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <PublicTemplate>
      <PublicRoutes />
    </PublicTemplate>
  );
};

export default AppAuth;
