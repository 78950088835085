import styled, { keyframes } from "styled-components";
import { zIndex } from "styles/zIndex.styles";
import { breakpoints } from "styles/themes/breakPoint";

const scale = keyframes`
  from {
    transform: scaleY(0);
  
  }

  to {
    transform: scaleY(1);

  }
`;

export const DropdownBody = styled.div`
  margin-top: 14px;
  padding: 0;
  width: 115px;
  ${(props) => (props.theme.direction === "rtl" ? "left:-6px" : "right:-6px")};
  z-index: ${zIndex.languageDropdown};
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  position: absolute;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
  animation: ${scale} 0.2s;
  transform-origin: top;
  @media ${breakpoints.mobile} {
    right: 0;
  }
`;
export const Dropdown = styled.div<{
  isOpen: Boolean;
}>`
  border-radius: 16px;
  margin-right: 8px;
  margin-top: 2px;
  cursor: pointer;
  transition: 0.5s;
  ${DropdownBody} {
    display: ${(props) => (props.isOpen ? "block" : "none")};

    @media ${breakpoints.mobile} {
      width: 100%;
      bottom: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 80px;
      display: ${(props) => (props.isOpen ? "" : "none")};
    }
  }
  svg {
    @media ${breakpoints.mobile} {
      margin-left: 16px;
    }
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    transition: 0.4s all ease-in-out;
    #chevron {
      fill: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    }
  }
`;

export const InnerMenuLabel = styled.div`
  display: none;
  @media ${breakpoints.mobile} {
    color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    font-weight: 500;
    margin-top: 19px;
    margin-bottom: 30px;
    svg {
      margin-right: 12px;
      transform: ${(p: any) =>
        p.theme.direction === "ltr" ? "rotate(265deg)" : "rotate(450deg)"};
    }
  }
`;

export const DropdownLabel = styled.div`
  display: none;
  @media ${breakpoints.mobile} {
    display: block;
    color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    font-weight: 500;
  }
`;

export const DropdownBox = styled.div<{ isOpen: Boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    transform: rotate(180deg);
    ${({ isOpen }) => (isOpen ? "transform: rotate(360deg);" : "")}
    margin-bottom: 2px;
  }
  @media ${breakpoints.mobile} {
    svg {
      margin-bottom: 0;
      transform: ${(p: any) =>
        p.theme.direction === "ltr" ? "rotate(90deg)" : "rotate(270deg)"};
    }
  }
`;

export const DropdownBodyContent = styled.div<{
  isSelected: Boolean;
  index: Number;
}>`
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background-color: rgba(255, 243, 166, 0.4);
    color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    border-top-left-radius: ${({ index }) => (index === 0 ? "8px" : 0)};
    border-top-right-radius: ${({ index }) => (index === 0 ? "8px" : 0)};

    :first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    :last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  @media ${breakpoints.mobile} {
    span {
      margin-left: 12px;
    }
    padding: 20px 15px;
    background-color: ${({ isSelected }) =>
      isSelected ? "rgba(255, 243, 166, 0.4)" : ""};
    color: ${({ isSelected }) =>
      isSelected ? (p: any) => p.theme.colors.brandColors.brandSecondary : ""};
    border-radius: 12px;
  }
`;
export const DropdownItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
  font-weight: 500;
  white-space: nowrap;
  ${(props) =>
    props.theme.direction === "rtl" ? "padding-left:8px" : "padding-right:8px"};
  max-width: 100%;
  font-size: 14px;
`;
