import { useTranslation } from "react-i18next";

import * as S from "pages/accountCenterPage/AccountCenterPage.styles";

import { ReactComponent as Briefcase } from "../../assets/icons/briefcase-tick.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";

interface ItemPops {
  label: string;
  href: string;
}

export interface AccountCenterContentProps {
  title: string;
  description: string;
  items: ItemPops[];
  isFeatureEnabled?: boolean;
}

const AccountCenterContent = ({
  title,
  description,
  items,
  isFeatureEnabled = true,
}: AccountCenterContentProps) => {
  const { t } = useTranslation();

  if (!isFeatureEnabled) {
    return null;
  }
  return (
    <S.AccountCenterBox>
      <S.AccountCenterTitle>
        <S.AccountCenterIcon>
          <Briefcase />
        </S.AccountCenterIcon>
        {t(title)}
      </S.AccountCenterTitle>
      <S.Description>{t(description)}</S.Description>
      <S.ItemContainer>
        {items.map((item, key: number) => (
          <S.AccountCenterItem key={key}>
            <S.ItemTitle href={item.href} target="_blank">
              {t(item.label)}
            </S.ItemTitle>
            <S.Download href={item.href} title={item.label} download>
              <DownloadIcon />
            </S.Download>
          </S.AccountCenterItem>
        ))}
      </S.ItemContainer>
    </S.AccountCenterBox>
  );
};

export default AccountCenterContent;
