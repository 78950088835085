import ILocationData from "interface/ILocationData";
import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState,
} from "react";
import Loader from "./Loader/Loader";
import SoldOut from "./MyPackages/AllocatedPackageSoldOut";

const iLoaderState: ILoaderState = {
  loading: false,
  error: "",
  finished: false,
  data: {},
};

interface ILoaderState {
  loading: boolean;
  error: string;
  finished: boolean;
  data: any;
}

interface LoaderActionType {
  type: string;
  message?: string;
  data?: any; //F; //-> generic ?
}

const loaderReducer = <
  FD extends { [key: string]: any },
  FA extends LoaderActionType
>(
  state: FD,
  action: FA
) => {
  switch (action.type) {
    case "error": {
      return {
        ...state,
        error: action.data.message,
        loading: false,
      };
    }
    case "loading": {
      return {
        ...state,
        error: "",
        loading: true,
        finished: false,
      };
    }
    case "success": {
      return {
        ...state,
        error: "",
        loading: false,
        finished: true,
        data: action.data,
      };
    }
    default:
      throw new Error();
  }
};

const initialState: ILoaderState = { ...iLoaderState };
interface ApiLoaderViewProps {
  apiCall: Function;
  reload?: number;
  data?: { [name: string]: any };
  onLoaded?: Function;
  selectedLocation?: ILocationData;
  onClose?: any;
}

const ApiLoaderView: FunctionComponent<ApiLoaderViewProps> = ({
  apiCall,
  data,
  reload,
  children,
  onLoaded,
  selectedLocation,
  onClose,
}) => {
  const [state, dispatch] = useReducer(loaderReducer, initialState);
  const [finished, setFinished] = useState(false);
  const apiReload = reload || 0;

  const callApi = React.useCallback(() => {
    if (finished) return;
    setFinished(true);
    dispatch({ type: "loading" });
    (async () => {
      try {
        const response = await apiCall();
        dispatch({ type: "success", message: "success", data: response });
        if (onLoaded) onLoaded(response);
      } catch (error) {
        dispatch({ type: "error", message: "error", data: error });
      }
    })();
  }, [apiCall, onLoaded, finished]);

  useEffect(() => {
    callApi();
  }, [callApi, finished]);

  useEffect(() => {
    if (apiReload > 0) setFinished(false);
  }, [apiReload]);

  return (
    <>
      {state.finished ? (
        children
      ) : state.error ? (
        state.error.response.data.error === "location total allocated" ? (
          <SoldOut selectedLocation={selectedLocation} onClose={onClose} />
        ) : (
          <div>Error</div>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ApiLoaderView;
