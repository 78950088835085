import styled from "styled-components";
import { breakpointMin } from "@successfactory/sf-libraries";

export const ViewCreditParentContainer = styled.div`
  margin-top: 12px;
  background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 18px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(
      180deg,
      #b2ddf0 0%,
      rgba(155, 218, 239, 0) 100%
    );
  }
  ${breakpointMin.xl} {
    margin: auto;
  }
`;

export const ViewCreditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  ${breakpointMin.xl} {
    display: contents;
    text-align: center;
  }
`;
