import { AccountCenterContentProps } from "components/AccountCenter/AccountCenterContent";
import i18n from "i18next";

const accountCenterData: AccountCenterContentProps[] = [
  {
    title: i18n.t("Compensation Plan"),
    description: i18n.t(
      "Please find the current The View Compensation Plan to download below."
    ),
    items: [
      {
        label: i18n.t("English"),
        href: "/assets/compensation-plans/compensation-plan-en.pdf",
      },
      {
        label: i18n.t("Russian"),
        href: "/assets/compensation-plans/compensation-plan-ru.pdf",
      },
      {
        label: i18n.t("Spanish"),
        href: "/assets/compensation-plans/compensation-plan-es.pdf",
      },
      {
        label: i18n.t("German"),
        href: "/assets/compensation-plans/compensation-plan-de.pdf",
      },
      {
        label: i18n.t("Arabic"),
        href: "/assets/compensation-plans/compensation-plan-ar.pdf",
      },
    ],
  },
  {
    title: i18n.t("Legal"),
    description: i18n.t(
      "These documents form the legal basis of our services. Please read them carefully before using our platform. You may click on the link to download the documents."
    ),
    items: [
      {
        label: i18n.t("Privacy Policy"),
        href: "/assets/legal/The_View_Privacy_Policy.pdf",
      },
      {
        label: i18n.t("Cookie Policy"),
        href: "/assets/legal/Cookie_Policy_The_View.pdf",
      },
      {
        label: i18n.t("Terms of Use"),
        href: "/assets/legal/The_View_Terms_of_Use.pdf",
      },
      {
        label: i18n.t("Disclaimer"),
        href: "/assets/legal/Disclaimer_The_View.pdf",
      },
    ],
  },
  {
    title: i18n.t("The View Metaverse"),
    description: i18n.t(
      "Please find the 'The View Metaverse Launcher' for Windows and additional documentation below."
    ),
    items: [
      {
        label: i18n.t("The View Metaverse Launcher for Windows"),
        href: "https://assets.jointheview.com/metaverse/TheView.zip",
      },
      {
        label: i18n.t("System Requirements"),
        href: "/assets/metaverse/T_V-PC_Specs.pdf",
      },
      {
        label: i18n.t("MetaMask Setup Guide"),
        href: "/assets/metaverse/The_View_Guide_-_BSC_MetaMask.pdf",
      },
      {
        label: i18n.t("Trust Wallet Setup Guide"),
        href: "/assets/metaverse/The_View_Guide_-_BSC_Trust_Wallet.pdf",
      },
    ],
  },
];

export default accountCenterData;
