import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { forgotPasswordSchema as schema } from "components/Login/schemas/login.schema";
import * as S from "components/Login/styles/Shared.styles";
import { ButtonStyles } from "styles/Button.styles";

import routes from "config/routes";

import FormInput from "../Components/Common/FormInput";

interface ForgotPasswordFormProps {
  onSubmit: (data: any) => void;
}

const ForgotPasswordForm = ({ ...props }: ForgotPasswordFormProps) => {
  const { t } = useTranslation();
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { onSubmit } = props;
  const { handleSubmit, register, formState } = methods;

  return (
    <FormProvider {...methods}>
      <S.ButtonGroup>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            {...register("email", { required: true })}
            name="email"
            type="email"
            title="Email"
            placeHolder={t("Please enter your email address")}
          />

          <ButtonStyles
            type="submit"
            $twoTone={true}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            {t("Request Password")}
          </ButtonStyles>
        </S.Form>

        <S.ButtonText as="a" href={routes.LOGIN}>
          {t("Back to Login")}
        </S.ButtonText>
      </S.ButtonGroup>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
