import { breakpointMax } from "@successfactory/sf-libraries";
import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const TabsMenuWrapper = styled.div<{ page?: string }>`
  margin-top: ${({ page }) => (page === "incomeCalculator" ? "20px" : "0px")};
  @media ${breakpoints.mobile} {
    margin-top: 85px;
  }

  .tabs-menu {
    ${({ page }) => (page === "dashboard" ? "margin-top: 40px;" : "")}
  }

  .dropdown-wrapper {
    ${breakpointMax.xl} {
      ${({ page }) =>
        page === "dashboard" ? "width: 100%; margin-top: 60px;" : ""}
    }
    .dropdown-container {
      > div div {
        ${({ page }) => (page === "dashboard" ? "top: 12px;" : "")}
      }
    }
  }

  .universal.pool.icon {
    margin: 100px;
  }
  .dropdown-container {
    ul {
      background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
      border-radius: 8px;
      ${({ page }) => (page !== "incomeCalculator" ? "position: inherit;" : "")}
      border: 1px solid ${({ theme }) => theme.colors.brandColors.brandPrimary};
      margin-top: 4px;
      li {
        color: ${({ theme }) => theme.colors.textColors.textVariant};
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.textColors.textVariant};
        border-radius: 0;
        :last-child {
          border-bottom: 1px solid transparent;
        }
        margin: 0;
        padding: 14px;
        &:hover {
          color: black;
        }
      }
    }
  }
`;
