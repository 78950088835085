/*
 ** In russian there are a lot of rules about plural.
 ** This function is to get all the number covered.
 */
export const getRussianTranslations = (
  getCurrentLang: any,
  totalUnits: number,
  t: any
) => {
  if (getCurrentLang !== "ru") {
    return totalUnits === 1 ? t("Unit") : t("Units");
  }
  const lastDigit = totalUnits % 10;
  const lastTwoDigits = totalUnits % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return t("Units_after_five");
  }

  if (lastDigit === 1) {
    return t("Units_1");
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return t("Units_2_4");
  }
  return t("Units_after_five");
};
