import styled from "styled-components";

export const Container = styled.div<{ region: string; activeCard: boolean }>`
  cursor: pointer;
  position: relative;
  width: 184px;
  height: 200px;
  border-radius: 18px;
  box-shadow: 0.5px 0.5px 8px
    ${({ activeCard }) =>
      activeCard
        ? (p: any) => p.theme.colors.brandColors.brandSecondary
        : "transparent"};
  background: linear-gradient(
    to bottom,
    ${({ activeCard }) =>
      activeCard
        ? (p: any) => p.theme.colors.brandColors.brandSecondary
        : (p: any) => p.theme.colors.textColors.textGrey},
    transparent 75%
  );
  z-index: 1;
  &.map {
    &:hover {
      & div {
        background: ${({ activeCard }) =>
          activeCard
            ? (p: any) => p.theme.colors.brandColors.brandSecondary
            : (p: any) => p.theme.colors.brandColors.brandPrimary};
      }
    }
  }
  &:before {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 98%;
    height: 100%;
    border-radius: 18px;
    background: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
    z-index: -1;
  }
  &:hover {
    background: linear-gradient(
      to bottom,
      ${({ activeCard }) =>
        activeCard
          ? (p: any) => p.theme.colors.brandColors.brandSecondary
          : (p: any) => p.theme.colors.brandColors.brandPrimary},
      transparent 75%
    );
    color: ${({ activeCard }) =>
      activeCard
        ? (p: any) => p.theme.colors.brandColors.brandSecondary
        : (p: any) => p.theme.colors.brandColors.brandPrimary};
  }
  &:active {
    background: linear-gradient(
      to bottom,
      ${(p: any) => p.theme.colors.brandColors.brandSecondary},
      transparent 75%
    );
  }
  &.no-hover {
    pointer-events: none;
    box-shadow: 0.5px 0.5px 8px
      ${(p: any) => p.theme.colors.brandColors.brandSecondary};
  }
`;

export const CardHeader = styled.div<{ region: string }>`
  font-family: ${(p: any) => p.theme.typography.family};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 33%;
  // padding-top: ${({ region }) => (region.length > 20 ? "8px" : "22px")};
`;

export const CardTitle = styled.div<{}>`
  color: ${(p: any) => p.theme.colors.textColors.textVariant};
  font-weight: 500;
  font-size: 14px;
  /* margin-left: 15px;
  margin-right: 15px; */
`;

export const CardHeaderLine = styled.hr`
  position: absolute;
  left: 1px;
  right: 1px;
  top: 28%;
  bottom: 75%;
  border: 1px solid ${(p: any) => p.theme.colors.textColors.textGrey};
`;

export const CardHeaderLineBottom = styled.hr<{ activeCard: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 29%;
  bottom: 75%;
  opacity: ${({ activeCard }) => (activeCard ? 0.5 : 0.2)};
  border: 1px solid
    ${({ activeCard }) =>
      activeCard
        ? (p: any) => p.theme.colors.brandColors.brandSecondary
        : "#8e8e8e"};
`;

export const CardBody = styled.div<{ activeCard: boolean }>`
  display: flex;
  height: 66%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${({ activeCard }) =>
    activeCard ? (p: any) => p.theme.colors.brandColors.brandSecondary : null};
`;
