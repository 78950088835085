import IRegionVoteData from "interface/IRegionVoteData";
import React from "react";
import { CardRegion } from "./CardRegion";
import { FingerprintContainer } from "./RegionSelect.styles";
import { useTranslation } from "react-i18next";
interface RegionSelectProps {
  region: IRegionVoteData;
  onChange: Function;
  selectedRegions: Array<IRegionVoteData>;
}

const RegionSelect: React.FC<RegionSelectProps> = ({
  region,
  onChange,
  selectedRegions,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(false);
  const maxSelected = selectedRegions.length === 5;

  const map = `${process.env.REACT_APP_AWS_ASSETS_URL}/${region.map}`;
  const mapDisable = `${process.env.REACT_APP_AWS_ASSETS_URL}/${region.map_disabled}`;
  const mapHover = `${process.env.REACT_APP_AWS_ASSETS_URL}/${region.map_hover}`;
  const onItemClick = () => {
    if (maxSelected && !selectedRegions.includes(region)) return;
    setSelected(!selected);
    onChange(region, !selected);
  };

  return (
    <CardRegion
      region={region.region}
      onClick={onItemClick}
      activeCard={selected}
    >
      <FingerprintContainer>
        {selected ? (
          <img src={map} alt={t("country")} />
        ) : (
          <img
            src={mapDisable}
            onMouseOver={(e) => (e.currentTarget.src = mapHover)}
            onMouseOut={(e) => (e.currentTarget.src = mapDisable)}
            alt={t("country")}
          />
        )}
      </FingerprintContainer>
    </CardRegion>
  );
};

export default RegionSelect;
