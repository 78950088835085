export type ModalContent = {
  name: string;
  text: string[];
  ctas: string[];
  type: ModalType | string;
};

export enum ModalType {
  CONFIRMATION = "CONFIRMATION",
  REJECTION = "REJECTION",
  INSUFFICIENT_CREDITS_FOR_FULL_REQUEST = "INSUFFICIENT_CREDITS_FOR_FULL_REQUEST",
  CONGRATULATIONS = "CONGRATULATIONS",
  FAILED_PURCHASE_CUTOFF = "FAILED_PURCHASE_CUTOFF",
  LAND_PURCHASE_NOT_POSSIBLE = "LAND_PURCHASE_NOT_POSSIBLE",
  CONFIRM_PARTIAL_PURCHASE = "CONFIRM_PARTIAL_PURCHASE",
  CONFIRMATION_PHASE_2 = "CONFIRMATION_PHASE_2",
  CONFIRMATION_PHASE_2_CONGRATULATIONS = "CONFIRMATION_PHASE_2_CONGRATULATIONS",
  CONFIRMATION_PHASE_2_REJECTION = "CONFIRMATION_PHASE_2_REJECTION",
  CONFIRMATION_PHASE_2_WALLETNOTFOUND = "CONFIRMATION_PHASE_2_WALLETNOTFOUND",
  ERROR_UNPROCESSABLE_REQUEST = "ERROR_UNPROCESSABLE_REQUEST",
}
