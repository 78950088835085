import styled from 'styled-components';

import { breakpoints } from 'styles/themes/breakPoint';
import { zIndex } from 'styles/zIndex.styles';

export const SidebarContainer = styled.div<{ closed: any }>`
  background-color: ${(p: any) => {
    if (p.theme.name === 'TheiaThemeLight') return 'none';
    return p.theme.colors.surfaces.surfaceTertiary;
  }};
  padding: 40px 10px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const displayBrandLogo = (p: any) => {
  if (p.closed) {
    return `
    width: 200px;
    img{
      :first-child {
        display:none
      }
      :last-child {
        display:block
      }
    } `;
  }
  return `
  width: 100px;
  position: relative;
  top: 10px;
  ${p.direction === 'rtl' ? `  right:60px` : `  left:60px;`}
`;
};

const DisplayLogoutButton = (p: any) => {
  if (p.closed) {
    return `
   display:none;
     ${p.direction === 'rtl' ? `  right:15px;` : `     left: 15px;`}

    `;
  }
  return ` cursor: pointer;
   `;
};
const SidebarFlexStatus = (p: any) => {
  if (p.space === 'space-around') {
    return `
    justify-content: space-between;
    position: relative;
    height: 100px;
    `;
  }
};

const Closed = (p: any) => {
  if (p.closed) {
    return `
    width: 96px;
    ${
      p.direction === 'rtl'
        ? `   border-left: 1px solid #6b6c7a;`
        : `   border-right: 1px solid #6b6c7a;`
    }
 
    @media ${breakpoints.mobile} {
      top:-1000px;
    }
    .sidebar--brand-logo {
        ${p.direction === 'rtl' ? `  margin-right:32px;` : ` margin-left:32px`}
    }
    .all-content {
      position: relative;
      @media ($breakpointMobile) {
        padding: 0;
        top: 50px;
      }
      .animation-container {
        .logout-button {
          cursor: pointer;
            ${p.direction === 'rtl' ? `  right:32px;` : ` left:32px`}
          &:hover {
            color: ${(p: any) => p.theme.colors.brandColors.brandVariantLight};
            svg {
              g {
                #HoverStroke1 {
                  stroke: ${(p: any) =>
                    p.theme.colors.brandColors.brandVariantLight};
                    fill: ${(p: any) =>
                      p.theme.colors.brandColors.brandVariantLight};
                }
                #HoverStroke2 {
                  stroke: ${(p: any) =>
                    p.theme.colors.brandColors.brandVariantLight};
                    fill: ${(p: any) =>
                      p.theme.colors.brandColors.brandVariantLight};
                }
                #HoverStroke4 {
                  stroke: ${(p: any) =>
                    p.theme.colors.brandColors.brandVariantLight};
                    fill: ${(p: any) =>
                      p.theme.colors.brandColors.brandVariantLight};
                }
                #HoverStroke5 {
                  stroke: ${(p: any) =>
                    p.theme.colors.brandColors.brandVariantLight};
                    fill: ${(p: any) =>
                      p.theme.colors.brandColors.brandVariantLight};
                }
              }
            }
          }
        }
      }
    }
  }
  }`;
  } else {
    return ` ${
      p.direction === 'rtl'
        ? `   border-left: 1px solid #6b6c7a;`
        : `   border-right: 1px solid #6b6c7a;`
    }`;
  }
};

export const SidebarSet = styled.div<{
  closed: Boolean;
  direction: string;
  isLanguageOpen: Boolean;
}>`
  color: ${(p: any) => p.theme.colors.textColors.textVariant} !important;
  height: 100%;
  position: fixed;
  width: 256px;
  overflow: hidden;
  z-index: ${zIndex.sideBar};
  transition: 0.5s;
  background: ${(p: any) => p.theme.colors.brandColors.backgroundTone};

  @media ${breakpoints.mobile} {
    background-color: ${(p: any) =>
      p.isLanguageOpen
        ? p.theme.colors.surfaces.surfaceVariant
        : p.theme.colors.surfaces.surfaceTertiary};
    transition: 0.2s;
    position: fixed;
    width: 100%;
    left: 0;
    justify-content: center;
    height: 100vh;
    align-items: center;
    display: flex;
    z-index: ${zIndex.sideBar};
    border: unset;
  }
  ${Closed}
`;

export const SidebarFlex = styled.div<{ space: string; closed: Boolean }>`
  display: flex;
  flex-direction: row;
  ${SidebarFlexStatus}
  @media ${breakpoints.mobile} {
    height: 60px;
  }
`;

export const AllContent = styled.div`
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @media ${breakpoints.mobile} {
    ::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  @media only screen and (max-height: 820px) {
    .animation-container {
      .logout-button {
        position: relative;
      }
    }
  }
`;

export const BrandLogo = styled.div<{
  closed: boolean;
  direction: string;
}>`
  display: flex;
  height: 50px;
  align-items: center;

  ${displayBrandLogo}
`;

export const ViewLogo = styled.img<{
  closed: boolean;
  isMobile: boolean;
}>`
  width: ${({ closed }) => (closed ? '55px' : '100%')};
  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
`;

export const SidebarNavlivkContainer = styled.div<{
  closed: boolean;
  direction: string;
}>`
  cursor: not-allowed;
  transition: 0.5s;
  a {
    text-decoration: none;
  }

  @media ${breakpoints.mobile} {
    padding-top: 20px;
  }
`;

export const SidebarAnimationContainer = styled.div<{
  closed: boolean;
  direction: 'rtl' | 'ltr';
  bottom?: boolean;
}>`
  margin-bottom: 20px;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  ${(closed) => (closed ? 'width:50px' : '')}

  svg {
    ${(p: any) => (p.theme.direction === 'rtl' ? 'transform: scaleX(-1);' : '')}
  }

  @media ${breakpoints.mobile} {
    ${({ bottom }) =>
      bottom ? 'margin-bottom: 200px;' : 'margin-bottom: 0px;'}
  }
`;
export const LogoutButton = styled.div<{ closed: any }>`
  position: relative;

  color: ${(p: any) => p.theme.colors.textColors.textVariant};
  ${DisplayLogoutButton};
  &.rtl-logout-btn {
    padding-right: 10px;
  }
`;
export const SidebarBottomGroup = styled.div<{
  closed: boolean;
  direction: string;
}>`
  bottom: 20px;
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${DisplayLogoutButton};
  display: flex;
  @media ${breakpoints.mobile} {
    padding: 0;
    bottom: -34%;
    position: relative;
    margin-top: unset;
  }

  svg {
    #logoutIcon {
      stroke: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    }
  }
`;

export const MobileToggleWrapper = styled.div`
  display: none;
  @media ${breakpoints.mobile} {
    display: block;
    margin-top: 68px;
    position: relative;
    padding-left: ${(p: any) => (p.theme.direction === 'ltr' ? '48px' : null)};
  }
`;

export const LanguageDropdownWrapper = styled.div`
  margin-top: 80px;
  div {
    font-size: 14px;
    display: none;
    @media ${breakpoints.mobile} {
      display: unset;
      text-align: start;
    }
  }
`;

export const SidebarFlexWrapper = styled.div``;
