import React, { ChangeEvent } from "react";

import InputField from "components/Table/Cell/InputField";

import * as S from "./SharedStyles.styles";

export interface MintingListProps {
  available?: number;
  title: string;
  subTitle: string;
  amount: number;
  isJustOneOrLastItem?: boolean;
  value: number;
  onChange?: (value: number) => void;
  isPurchase?: boolean;
}

const MintingList = ({
  available,
  title,
  subTitle,
  amount,
  onChange,
  isJustOneOrLastItem,
  isPurchase = false,
}: MintingListProps) => {
  const handleAmountChange = (newAmount: number) => {
    if (onChange) {
      const checkedValue = isNaN(newAmount)
        ? amount
        : Math.min(newAmount, Number(available || 0));

      onChange(checkedValue);
    }
  };

  const renderMintingList = () => (
    <>
      <S.MintingListItemName>
        {`${title}`} <br /> {`${subTitle}`}
      </S.MintingListItemName>
      <S.MintingListInputGroup>
        <S.MintingListInputButton
          onClick={() => handleAmountChange(amount - 1)}
          disabled={amount === 0}
        >
          -
        </S.MintingListInputButton>
        <InputField
          amount={amount}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleAmountChange(parseFloat(e.target.value))
          }
        />
        <S.MintingListInputButton
          onClick={() => handleAmountChange(amount + 1)}
          disabled={amount >= Number(available || 0)}
        >
          +
        </S.MintingListInputButton>
      </S.MintingListInputGroup>
    </>
  );

  return isPurchase ? (
    <S.MintingList isJustOneOrLastItem={isJustOneOrLastItem}>
      {renderMintingList()}
    </S.MintingList>
  ) : (
    <S.MintingContainer>{renderMintingList()}</S.MintingContainer>
  );
};

export default MintingList;
