import { useTranslation } from "react-i18next";

import { Description } from "components/CreatorsCorner/RegionSelect.styles";
import CustomCard from "components/CustomCard/CustomCard";
import * as G from "styles/Grid.styles";
import { stringToHTML } from "util/stringToHTML";

import { useAppState } from "AppContext";

import TheViewAppInfo from "./TheViewAppInfo";
import Videos from "./Videos";
import * as S from "./Videos.styles";
import { APP_INFO_DATA, VideosPageData } from "./VideosPageData";

const VideosPage = () => {
  const { t } = useTranslation();

  const { language } = useAppState();

  const videoLinks = VideosPageData.videoData.map((video) => ({
    ...video,
    videoUrl: video.videoUrl.replace(
      "[id]",
      video.ids?.[language || "en"] || ""
    ),
  }));

  let lang = language || "en";
  const appInfo = APP_INFO_DATA.map((info) => ({ ...info, lang }));

  //This needs to be static because they would like to hide for now
  const staticeEnabledAppInfo = false;

  return (
    <G.GridContainer>
      <G.Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
        <S.PageWrapper>
          <Description>
            {stringToHTML(
              t(`Introduction to the <span>The View</span> application`)
            )}
          </Description>
          <Videos videos={videoLinks} />
          {staticeEnabledAppInfo && (
            <S.CustomCardContainer>
              <CustomCard>
                <TheViewAppInfo appInfo={appInfo} />
              </CustomCard>
            </S.CustomCardContainer>
          )}
        </S.PageWrapper>
      </G.Grid>
    </G.GridContainer>
  );
};

export default VideosPage;
