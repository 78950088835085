import IPackageData from "interface/IPackageData";
import React from "react";
import { getMembershipName } from "util/getMembershipName";
import { SfButton } from "@successfactory/sf-libraries";
import {
  CardWrapper,
  NotAllocatedCardContent,
  NotAllocatedCaption,
  Button,
} from "./MembershipCards.styles";
import { useTranslation } from "react-i18next";
import { SfCardMembership } from "../CardGroups/SfCardMembership/SfCardMembership";
interface NotAllocatedCardProps {
  onCardSelected?: Function;
  card: IPackageData;
}
const NotAllocatedCard: React.FC<NotAllocatedCardProps> = ({
  onCardSelected,
  card,
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    if (onCardSelected) onCardSelected(card);
  };

  const initialCardContent = {
    header: {
      captionLeft: t("Membership"),
      captionRight: new Date(card.purchasedAt).toLocaleDateString("de-CH"),
      titleLeft: getMembershipName(card),
      titleRight: `${card.value} €`,
    },
  };

  const mediaMobile = window.matchMedia("(max-width: 414px)");
  const mediaMobileSmall = window.matchMedia("(max-width: 320px)");

  return (
    <CardWrapper>
      <SfCardMembership
        type="initial"
        cardContent={initialCardContent}
        width={
          mediaMobileSmall.matches
            ? "295px"
            : mediaMobile.matches
            ? "295px"
            : undefined
        }
      >
        <NotAllocatedCardContent>
          <div>
            <img
              src="assets/re-branding/cards/not-allocated.png"
              alt={t("Not Located")}
              width="140px"
            />
          </div>
          <NotAllocatedCaption>{t("Not Allocated")}</NotAllocatedCaption>
          <Button>
            <SfButton
              label={t("Allocate")}
              twoTone={true}
              onClick={() => handleClick()}
            />
          </Button>
        </NotAllocatedCardContent>
      </SfCardMembership>
    </CardWrapper>
  );
};

export default NotAllocatedCard;
