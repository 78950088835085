import { useTranslation } from "react-i18next";

import TheiaSvgIcons from "components/TheiaSvgIcons/TheiaSvgIcons";
import moment from "moment";

import { Request } from "./IWallet";
import * as S from "./SharedStyles.styles";

interface MintingDetailsDateItemsProps {
  requestStatuses?: Request[];
}

const MintingDetailsDateItems = ({
  requestStatuses,
}: MintingDetailsDateItemsProps) => {
  const { t } = useTranslation();

  return (
    <S.DateItemsTable>
      <thead>
        <S.DateItemsTableTr>
          <S.DateItemsTableTd>{t("Date")}</S.DateItemsTableTd>
          <S.DateItemsTableTd>{t("Status")}</S.DateItemsTableTd>
        </S.DateItemsTableTr>
      </thead>
      <tbody>
        {requestStatuses?.map((status, index) => {
          const { actionAt: requestStatus, action } = status;
          return (
            <S.DateItemsTableTr key={index}>
              <S.DateItemsTableTd>
                {moment(requestStatus)
                  .tz("Europe/Amsterdam")
                  .format("DD.MM.YYYY HH:mm:59, z")}
              </S.DateItemsTableTd>
              <S.DateItemsTableTd>
                <S.MintingDetailLabelStatus status={action}>
                  <TheiaSvgIcons name={action.toLowerCase()} />
                  {t(action)}
                </S.MintingDetailLabelStatus>
              </S.DateItemsTableTd>
            </S.DateItemsTableTr>
          );
        })}
      </tbody>
    </S.DateItemsTable>
  );
};

export default MintingDetailsDateItems;
