import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SfModal, SfTableGlobalSearch } from "@successfactory/sf-libraries";
import { baseUrl, getHeader } from "Api";
import axios from "axios";

import useDashboardSearchApi from "api/useDashboardSearchApi";
import useLocationsApi from "api/useLocationsApi";
import { useAppState } from "AppContext";

import Loader from "components/Loader/Loader";
import { Minting, Wallet, WalletMint } from "components/MintingComponents";
import MintingDetails from "components/MintingComponents/MintingDetails";
import Stepper from "components/Stepper/Stepper";
import * as S from "components/TheiaTable/TheiaTable.styles";

import { IDashboardLocation, IRegionAndCity } from "interface/ILocationData";
import IPackageData from "interface/IPackageData";
import { LocationTabs, LocationType } from "interface/IpoolsName";
import { IStats } from "interface/IStats";
import IUser from "interface/IUser";

import { useFeatureEnableForUser } from "util/isFeatureEnableForUser";
import { useMintingModalStepper } from "util/useMintingEvent";

import { LocationDistributionTable } from "./LocationDistributionTable";
import { LocationTableMobile } from "./Table.styles";

import { ReactComponent as InfoCircle } from "../../assets/table/info-circle.svg";

const LocationDistributionCard = React.lazy(
  () => import("./LocationDistributionCard")
);

export interface ITableUser {
  newPackages: [];
  packages: Array<IPackageData>;
  user: IUser;
  stats: IStats;
}
export interface PaginationProps {
  paginatedData: Array<IRegionAndCity>;
  pageCount: number;
  pageSize: number;
  isNextPage: boolean | undefined;
}

export const DashboardTable = (props: {
  tabsName: LocationTabs;
  tabsType: LocationType;
}) => {
  const { t } = useTranslation();

  //Updated userProfile with AppState
  const { language } = useAppState();

  const tabsName = props.tabsName;
  const tabsType = props.tabsType;
  const [tableData, setTableData] = useState<Array<IRegionAndCity> | []>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [searchResult, setSearchResult] = useState<any | null | undefined>({});
  const [noSearchFound, setNoSearchFound] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { dashboardLocations, getLocationsStatus } = useLocationsApi(
    true,
    `dashboard_table?locationType=${tabsType}&page=1`,
    "dashboardTable"
  );

  const { isFeatureEnabled } = useFeatureEnableForUser("nft-minting");

  const { searchData, getSearchStatus } = useDashboardSearchApi(
    searchString.length > 2,
    tabsType,
    searchString,
    language
  );

  const {
    modalShown,
    handleNext,
    handleFinish,
    handleModalClose,
    handleButtonClick,
    handleMintIconClick,
    selectedRegion,
    activeStep,
    results,
    onTryAgain,
    modalType,
    hasTriedAndFailed,
    getMintingStatus,
    isMintDataEmpty,
  } = useMintingModalStepper();

  const searchIsStarted =
    searchString.length > 2 && getSearchStatus === "success";

  useEffect(() => {
    if (searchIsStarted) {
      setSearchResult(searchData);
    } else setSearchResult(null);
  }, [getSearchStatus, searchString, searchIsStarted, searchData]);

  const [dashBoardpagination, setDashboardPagination] =
    useState<PaginationProps>({
      paginatedData: [],
      pageCount: 1,
      pageSize: 50,
      isNextPage: false,
    });

  const loadTableData = useCallback(
    async (tabsName: LocationTabs) => {
      if (getLocationsStatus === "success") {
        switch (tabsName) {
          case LocationTabs.REGIONS:
            if (searchIsStarted && searchResult?.region?.data.length === 0) {
              setNoSearchFound(true);
            } else setNoSearchFound(false);
            setTableData(
              searchResult?.region
                ? searchResult.region?.data
                : dashboardLocations?.region?.data
            );
            setDashboardPagination({
              ...dashBoardpagination,
              isNextPage: searchResult?.region
                ? searchResult.region?.canGoToNextPage
                : dashboardLocations?.region?.canGoToNextPage,
            });
            break;
          case LocationTabs.CONTINENTS:
            if (searchIsStarted && searchResult?.continent?.data.length === 0) {
              setNoSearchFound(true);
            } else setNoSearchFound(false);
            setTableData(
              searchResult?.continent
                ? searchResult.continent?.data
                : dashboardLocations?.continent?.data
            );
            setDashboardPagination({
              ...dashBoardpagination,
              isNextPage: searchResult?.continent
                ? searchResult.continent?.canGoToNextPage
                : dashboardLocations?.continent?.canGoToNextPage,
            });
            break;
          case LocationTabs.VERTICALS:
            if (searchIsStarted && searchResult?.vertical?.data.length === 0) {
              setNoSearchFound(true);
            } else setNoSearchFound(false);
            setTableData(
              searchResult?.vertical
                ? searchResult.vertical?.data
                : dashboardLocations?.vertical?.data
            );

            setDashboardPagination({
              ...dashBoardpagination,
              isNextPage: searchResult?.vertical
                ? searchResult?.vertical?.canGoToNextPage
                : dashboardLocations?.vertical?.canGoToNextPage,
            });
            break;
          default:
            if (searchIsStarted && searchResult?.city?.data.length === 0) {
              setNoSearchFound(true);
            } else setNoSearchFound(false);
            setTableData(
              searchResult?.city
                ? searchResult.city?.data
                : dashboardLocations?.city?.data
            );
            setDashboardPagination({
              ...dashBoardpagination,
              isNextPage: searchResult?.city
                ? searchResult?.city?.canGoToNextPage
                : dashboardLocations?.city?.canGoToNextPage,
            });
        }
      }
    },
    [
      dashBoardpagination,
      dashboardLocations,
      getLocationsStatus,
      searchIsStarted,
      searchResult,
    ]
  );

  const loadDashboardData = (
    tabsName: LocationTabs,
    result: IDashboardLocation
  ) => {
    switch (tabsName) {
      case LocationTabs.CITIES:
        return result.city.data;
      case LocationTabs.REGIONS:
        return result.region.data;
      case LocationTabs.CONTINENTS:
        return result.continent.data;
      default:
        return result.vertical.data;
    }
  };

  const loadMoreData = useCallback(
    async (tabsName: LocationTabs, pageCount: number) => {
      const pageNum = pageCount + 1;

      const result = await axios.get(
        `${baseUrl}/dashboard_table/?locationType=${tabsType}&page=${pageNum}&search=${searchString}`,
        getHeader()
      );
      const isNextPage =
        result?.data?.locationsGroupedByType?.[tabsType]?.canGoToNextPage;

      setDashboardPagination({
        ...dashBoardpagination,
        pageCount: pageNum,
        isNextPage,
      });
      let moreData = loadDashboardData(
        tabsName,
        result?.data?.locationsGroupedByType
      );
      let loadedData = [...tableData, ...moreData];

      setTableData(loadedData);
    },
    [dashBoardpagination, tableData, searchString, tabsType]
  );

  useEffect(() => {
    loadTableData(tabsName);
  }, [tabsName, searchResult, getLocationsStatus]);

  useEffect(() => {
    setIsDisabled(
      !dashBoardpagination.isNextPage ||
        noSearchFound ||
        tableData?.length === 0
    );
  }, [dashBoardpagination.isNextPage, noSearchFound, tableData?.length]);

  const onDashboardSearch = (e: any) => {
    setSearchString(e);
  };

  const getSearchBoxAndFilterContainer = () => {
    return (
      <S.SearchContainer page="dashboard">
        <SfTableGlobalSearch
          onChange={(e: any) => onDashboardSearch(e)}
          placeholder={t("Search")}
        ></SfTableGlobalSearch>
      </S.SearchContainer>
    );
  };

  const getNoResultPage = (text: string) => {
    return (
      <S.NoResultWrapper page="dashboard">
        <InfoCircle />
        <S.NoResultText>{text}</S.NoResultText>
      </S.NoResultWrapper>
    );
  };

  const commonProps = {
    data: tableData,
    handleButtonClick: handleButtonClick,
    isFeatureEnabled: isFeatureEnabled,
    hasTriedAndFailed,
    isMintDataEmpty,
    getMintingStatus,
  };

  const commonHandleModalClose = {
    handleModalClose: handleModalClose,
  };

  const commonHandleNext = {
    onSubmit: handleNext,
    ...commonHandleModalClose,
  };

  const commonHandleMintIconClick = {
    handleMintIconClick: handleMintIconClick,
  };

  const commonLastStepProps = {
    onSubmit: handleFinish,
    onTryAgain: onTryAgain,
    results,
    ...commonHandleModalClose,
  };

  const isMintingDetail = modalType === "mintingDetails";

  return (
    <>
      {getSearchBoxAndFilterContainer()}
      {noSearchFound && tableData?.length === 0 ? (
        <S.SearchResultContainer>
          {getNoResultPage(t("No units found."))}
        </S.SearchResultContainer>
      ) : !noSearchFound && tableData?.length === 0 ? (
        <S.SearchResultContainer>
          {getNoResultPage(
            t("Sorry, it seems that there are no available units")
          )}
        </S.SearchResultContainer>
      ) : (
        tableData?.length !== 0 && (
          <>
            <>
              <LocationDistributionTable
                tabsName={tabsName}
                pageCount={dashBoardpagination.pageCount}
                setTableData={setTableData}
                {...commonHandleMintIconClick}
                {...commonProps}
              />
            </>
            <LocationTableMobile>
              <Suspense fallback={<Loader />}>
                <LocationDistributionCard
                  {...commonProps}
                  {...commonHandleMintIconClick}
                />
              </Suspense>
            </LocationTableMobile>
            {tableData?.length >= 30 && (
              <S.LoadMoreContainer>
                <S.LoadMoreButton
                  onClick={() => {
                    loadMoreData(tabsName, dashBoardpagination.pageCount);
                  }}
                  disabled={isDisabled}
                  isDisabled={isDisabled}
                >
                  {t("Load more")}
                </S.LoadMoreButton>
              </S.LoadMoreContainer>
            )}
          </>
        )
      )}

      <SfModal shown={modalShown} onClose={handleModalClose} type="theia">
        {isMintingDetail ? (
          <MintingDetails {...selectedRegion} {...commonHandleModalClose} />
        ) : (
          <Stepper activeStep={activeStep}>
            <Minting {...selectedRegion} {...commonHandleNext} />
            <Wallet
              informationText={t(
                "We will keep you updated about the minting process. Please confirm your Email address!"
              )}
              results={results}
              onTryAgain={onTryAgain}
              {...selectedRegion}
              {...commonHandleNext}
            />
            <WalletMint {...commonLastStepProps} />
          </Stepper>
        )}
      </SfModal>
    </>
  );
};
