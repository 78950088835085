import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Column, DataItem, Sorting } from "./CreditTable";
import { filterAndSortData } from "./util/sorting";

import * as S from "../LocationDistributionCard.styles";

const CreditCard = ({
  columns = [],
  data,
  sorting,
}: {
  columns?: Column[];
  data: DataItem[];
  sorting: Sorting;
}) => {
  const { t } = useTranslation();
  const isCreditCard = true;
  const columnStyle = { width: "100%" };
  const [sortedData, setSortedData] = useState<DataItem[]>([]);

  useEffect(() => {
    const sorted = filterAndSortData(data, columns, sorting);
    setSortedData(sorted);
  }, [columns, data, sorting]);

  const renderColumn = (column: Column, item: DataItem, index: number) => {
    const isDevide = index === 2;

    return (
      <div key={column.header + index}>
        {isDevide && <S.DevideLine />}
        <S.TableCardBody>
          <S.BodyColumn style={columnStyle}>
            <S.StyledHeader>{t(column.header)}</S.StyledHeader>
            <S.Text brandColor={true}>
              {column.cell
                ? column.cell({ value: t(item[column.accessor]) })
                : t(item[column.accessor])}
            </S.Text>
          </S.BodyColumn>
        </S.TableCardBody>
      </div>
    );
  };

  const renderTableCard = (item: DataItem, index: number) => (
    <S.TableCard key={`credit-table-card-${index}`} isCreditCard={isCreditCard}>
      {columns.map((column, columnIndex) =>
        renderColumn(column, item, columnIndex)
      )}
    </S.TableCard>
  );

  return <>{sortedData.map(renderTableCard)}</>;
};

export default CreditCard;
