import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader/Loader";
import { useAppState } from "AppContext";
import useUserApi from "api/useUserApi";

import {
  BannerHeader,
  BadgesHeader,
  CoursesHeader,
  BadgesSection,
  MobileCardsSection,
} from "./AcademyPage.styles";
import { Grid, GridContainer } from "styles/Grid.styles";

const AcademyPageBanner = React.lazy(
  () => import("components/Banners/AcademyPageBanner/AcademyPageBanner")
);
const AcademyBadges = React.lazy(
  () => import("components/AcademyBadges/AcademyBadges")
);
const CourseCards = React.lazy(
  () => import("components/CourseCards/CourseCards")
);
const AcademyPage: React.FC = () => {
  const { t } = useTranslation();
  const { isSidebarShown } = useAppState();

  const { getUser, getUserStatus, userProfile } = useUserApi(true);

  const isLoading = getUserStatus === "loading";

  useEffect(() => {
    if (getUserStatus === "idle") getUser();
  }, [getUserStatus]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Suspense fallback={<Loader />}>
        <GridContainer>
          <Grid
            smCol="1/4"
            mdCol="1/8"
            lgCol="1/9"
            smRow="2/2"
            mdRow="2/2"
            lgRow="2/2"
          >
            {" "}
            <BannerHeader>{t("My Status")}</BannerHeader>
            <AcademyPageBanner userProfile={userProfile} />
          </Grid>
          <Grid
            smCol="1/4"
            mdCol="1/8"
            lgCol="1/12"
            xlCol="10/12"
            smRow="4/4"
            mdRow="4/4"
            lgRow="4/4"
            xlRow="1/4"
          >
            <BadgesSection>
              <BadgesHeader>{t("My Badges")}</BadgesHeader>
              <AcademyBadges
                userProfile={userProfile}
                isSidebarShown={isSidebarShown}
              />
            </BadgesSection>
          </Grid>
          <Grid
            smCol="1/4"
            mdCol="1/8"
            lgCol="1/9"
            smRow="3/3"
            mdRow="3/3"
            lgRow="3/3"
          >
            <MobileCardsSection>
              <Grid smCol="1/4" mdCol="1/8" lgCol="1/9">
                <CoursesHeader>{t("The Academy Courses")}</CoursesHeader>
              </Grid>
              <CourseCards
                isSidebarShown={isSidebarShown}
                userProfile={userProfile}
              />
            </MobileCardsSection>
          </Grid>
        </GridContainer>
      </Suspense>
    </>
  );
};

export default AcademyPage;
