import React from "react";
import Api from "Api";
import { SfButton } from "@successfactory/sf-libraries";
import { useTranslation } from "react-i18next";

import { LocationIcon } from "components/LocationIcon/LocationIcon";
import ApiLoaderView from "components/ApiLoaderView";
import ILocationData from "interface/ILocationData";
import IPackageData from "interface/IPackageData";
import { calculateShares } from "util/calculateShares";

import {
  ContentHead,
  AllocatedCaption,
  ButtonBox,
  AllocatePackageModal,
  AllocatePackageModalWrapper,
} from "./AllocatePackage.styles";

interface TwoAllocatePackageProps {
  selectedPackage: IPackageData;
  selectedLocation: ILocationData;
  onPackageAllocated?: Function;
  onClose: Function;
}

const TwoAllocatePackage: React.FC<TwoAllocatePackageProps> = ({
  selectedPackage,
  selectedLocation,
  onPackageAllocated,
  onClose,
}) => {
  const { t } = useTranslation();
  const allocateShares = async () => {
    let allocateInfo = {
      package_id: selectedPackage!._id,
      location_id: selectedLocation._id,
    };

    const result = await Api.allocate(allocateInfo);

    return result;
  };

  const onAllocation = () => {
    if (onPackageAllocated)
      onPackageAllocated(selectedPackage, selectedLocation);
  };
  const calculateSharesDisplay = () => {
    let amount = calculateShares(selectedPackage, selectedLocation);
    let unitTypeAmount = amount === 1 ? t("unit is") : t("units are");

    return (
      <>
        {amount} {unitTypeAmount}
      </>
    );
  };

  return (
    <ApiLoaderView
      apiCall={allocateShares}
      onLoaded={onAllocation}
      selectedLocation={selectedLocation}
      onClose={onClose}
    >
      <AllocatePackageModal>
        <AllocatePackageModalWrapper>
          <LocationIcon
            height="160px"
            image={selectedLocation.map || selectedLocation.logo}
            darkImage={selectedLocation.dark_logo}
            fitLogo={false}
            type={selectedLocation.type}
            updatedAt={selectedLocation.updated_at}
          />
        </AllocatePackageModalWrapper>
        <ContentHead>
          <p>
            {calculateSharesDisplay()} {t("succesfully allocated to")}{" "}
            <AllocatedCaption>{t(selectedLocation!.region)}</AllocatedCaption>
          </p>
        </ContentHead>
        <ButtonBox>
          <SfButton
            twoTone={true}
            onClick={() => {
              onClose();
            }}
            label={t("Close")}
          ></SfButton>
        </ButtonBox>
      </AllocatePackageModal>
    </ApiLoaderView>
  );
};

export default TwoAllocatePackage;
