import { breakpointMax, breakpointMin } from "@successfactory/sf-libraries";
import styled from "styled-components";
import { getFontStyle } from "styles/mixin/getFontStyles";
import { zIndex } from "styles/zIndex.styles";

export const TableContainer = styled.div`
  ${breakpointMax.xl} {
    display: none;
  }
`;

export const Table = styled.table<{
  hasIcon: boolean;
}>`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 1px;
  thead tr {
    background: ${({ theme }) => theme.colors.brandColors.brandTableHeadTone};
  }
  thead tr th {
    width: calc(100% / 4);
    @media not all and (min-resolution: 0.001dpcm) {
      width: 26%;
    }
    padding: 8px 14px;
    text-align: ${({ theme }) =>
      theme.direction === "rtl" ? "right" : "left"};
  }

  tbody {
    tr {
      height: auto;
      width: 100%;
    }
    td {
      @media not all and (min-resolution: 0.001dpcm) {
        width: 26%;
      }
      width: calc(100% / 4);
      align-items: left;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding: 0px 10px;

      :first-child {
        padding: 14px 12px;
      }

      span {
        color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
      }
    }
  }

  ${({ theme }) =>
    theme.direction === "rtl" ? `th:last-child` : `th:first-child`} {
    border-radius: 8px 0px 0px 8px;
  }

  ${({ theme }) =>
    theme.direction === "rtl" ? `th:first-child` : `th:last-child`} {
    border-radius: 0 8px 8px 0;
  }
`;

export const SortingIcons = styled.span<{
  isDesc?: boolean;
  index: number;
  isActive: boolean;
}>`
  ${({ index }) => (index === 0 ? "display: none;" : "")}
  position: absolute;
  ${({ theme }) =>
    theme.direction === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;"}

  svg:first-child {
    display: block;
    margin-top: 6px;
    ${({ isDesc, theme, isActive }) =>
      !isDesc && isDesc !== undefined && isActive
        ? `path { fill: ${theme.colors.brandColors.brandSecondary}; }`
        : ""}
  }

  svg:last-child {
    margin-bottom: 8px;
    ${({ isDesc, theme, isActive }) =>
      isDesc && isActive
        ? `path { fill: ${theme.colors.brandColors.brandSecondary}; }`
        : ""}
  }
`;

export const RowWrapper = styled.tr`
  width: 100%;
  border-radius: 12px 0px 0px 12px;
  &:nth-child(even) {
    background: ${(p: any) => p.theme.colors.brandColors.brandTableCellTone};
  }
`;

export const RowTd = styled.td<{ noData?: boolean }>`
  width: 100%;
  ${({ noData }) =>
    noData
      ? null
      : `:hover {
    border-top: 1px solid #fff3a6;
    border-bottom: 1px solid #fff3a6;

    &:last-child {
      border-left: 1px solid #fff3a6;
      border-right: 1px solid #fff3a6;
    }
  }`}
`;

export const TableRow = styled.tr``;

export const PoolImg = styled.span`
  img {
    height: 32px;
  }
`;

export const FlagAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img,
  svg {
    height: 32px;
    ${({ theme }) =>
      theme.direction === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;"}
  }
`;

export const ExpandBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: ${({ theme }) =>
    theme.colors.brandColors.tableAccordionContainer};
  border-radius: 8px;
  cursor: pointer;
`;

export const ExpandEmpty = styled.div`
  height: 32px;
  width: 32px;
`;

export const Open = styled.span``;

export const Close = styled.span`
  img {
    transform: rotate(180deg);
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  background: ${({ theme }) => theme.colors.brandColors.darkBackgroundTone};
  border-radius: 8px;
  color: #ffffff;
`;

export const DetailsTitle = styled.h4`
  ${({ theme }) => getFontStyle("captionCaps", theme)}
`;

export const DetailsContent = styled.p`
  ${({ theme }) => getFontStyle("captionMedium", theme)}
`;

export const SfTablePagination = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: rgba(152, 152, 152, 0.5);
  border-radius: 8px;
  padding: 12px 15px;
  ${({ theme }) => getFontStyle("caption", theme)}
  select {
    background-color: unset;
    border: none;
    cursor: pointer;
    margin: 0px 16px;
  }
  button {
    background-color: unset;
    border: none;
    cursor: pointer;
  }

  ${breakpointMin.md} {
    margin-top: 0;
  }
`;

export const PaginationIndex = styled.span`
  margin: 0px 32px 0px 42px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const ChevronLeft = styled.span`
  img {
    transform: rotate(-90deg);
  }
`;

export const ChevronRight = styled.span`
  img {
    transform: rotate(90deg);
  }
`;

export const CellMainContainer = styled.div``;

export const PoolsTableIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const ToolTipTd = styled.td`
  div {
    width: fit-content;
  }
`;
export const EmptyTable = styled.td`
  background: rgba(152, 152, 152, 0.5);
`;

export const PoolCardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${breakpointMax.md} {
    height: 50vh;
  }
  ${breakpointMin.xl} {
    display: none;
  }
`;

export const PoolDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const PoolCardRegionImage = styled.div`
  img {
    width: 32px;
    height: 32px;
  }
`;

export const PoolRegionText = styled.div`
  ${({ theme }) =>
    theme.direction === "rtl" ? "margin-right: 12px;" : "margin-left: 12px;"}
`;

export const DateTimeUnitRow = styled.div<{ sidebar: boolean }>`
  display: block;
  max-width: 35%;
  ${breakpointMin.md} {
    ${({ theme, sidebar }) =>
      !sidebar && theme.direction === "rtl"
        ? "margin-right: 20px;"
        : !sidebar && theme.direction === "ltr"
        ? "margin-left: 20px;"
        : ""}
  }
`;
export const Unit = styled.div`
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
`;
export const DateAndTime = styled.div`
  display: block;
  max-width: 45%;
  ${breakpointMin.md} {
    max-width: 50%;
    margin: 0 6px;
  }
`;

export const Date = styled.div`
  color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
`;
export const Time = styled.div`
  color: ${(p: any) => p.theme.colors.textColors.textWhite};
`;

export const Title = styled.div<{ isExpanded: boolean }>`
  color: ${({ theme, isExpanded }) =>
    isExpanded
      ? theme.colors.textColors.textWhite
      : theme.colors.textColors.textGrey};
`;

export const CardBody = styled.div<{ sidebar?: Boolean }>`
  width: 100%;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  width: 100%;
  margin-bottom: 24px;
  justify-content: space-evenly;
  display: flex;
  line-height: 2;
  ${breakpointMin.md} {
    ${({ sidebar }) =>
      sidebar ? "margin: 16px; max-width: 90%;" : "margin: 0;"}
    margin-bottom: 24px;
  }
`;

export const HeaderTitlePoolsCard = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const RegionPoolsDesc = styled.div`
  display: block;
`;

export const HeaderTitleContainerPoolsCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto !important;
  margin: 16px 16px 0px 16px;
`;

export const SubRowTitle = styled.span`
  font-size: 10px;
`;

export const SortAndSearchWrapper = styled.div``;

export const SortContainer = styled.div<{ type?: string }>`
  display: flex;
  ${({ type }) =>
    type === "dashboard" ? "justify-content: space-between;" : ""}
  width: auto;
  ${({ type, theme }) =>
    type !== "dashboard"
      ? `float:
          ${theme.direction} === "rtl" ? "left" : "right";`
      : ""}
  margin-right: 20px;
  ${breakpointMin.md} {
    ${({ type }) => (type === "dashboard" ? "justify-content: unset;" : "")}
    float: unset;
    ${({ theme }) =>
      theme.direction === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;"}
  }
  ${breakpointMin.xl} {
    display: none;
  }
`;

export const SortLabel = styled.span<{ type?: string }>`
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 30px;
  ${({ theme }) =>
    theme.direction === "rtl" ? "margin-left: 30px;" : "margin-right: 30px;"}
  ${breakpointMin.md} {
    ${({ theme }) =>
      theme.direction === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;"}
  }
`;

export const DropdownContainer = styled.div`
  .dropdown-container {
    margin-top: 20px;
    svg {
      position: absolute;
      ${({ theme }) =>
        theme.direction === "rtl" ? "left: 20px;" : "left: 20px;"}
      height: 8px;
    }
    div {
      font-weight: 600;
      font-size: 10px;
      box-shadow: unset;
      text-transform: uppercase;
    }
    ul {
      overflow: scroll !important;
      max-height: calc(100% + 180px);
      min-width: 260px;
      background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
      border: 1px solid ${({ theme }) => theme.colors.brandColors.brandPrimary};
      ${({ theme }) =>
        theme.direction === "rtl" ? "" : `right: -20px; left: unset;`}
      ${breakpointMin.md} {
        max-height: calc(100% + 215px);
        ${({ theme }) =>
          theme.direction === "rtl" ? "right: -20px;" : "left: 0;"}
      }
    }

    li {
      margin: 0;
      border-radius: 0;
      :first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      :last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export const SearchContainer = styled.div<{ page?: string }>`
  display: block;
  ${({ page }) => (page === "credit" ? "width:100%;" : "")}

  ${breakpointMin.md} {
    display: flex;
    justify-content: space-between;
    float: ${({ theme }) => (theme.direction === "rtl" ? "left" : "right")};
    ${breakpointMin.xl} {
      ${({ page }) => (page === "dashboard" ? "margin-top: -60px;" : "")}
    }
  }

  .table-searchbar {
    background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
    border-radius: 14px;
    border-color: ${({ theme }) => theme.colors.brandColors.brandPrimary};
    font-size: 14px;
    height: 40px;
    ${({ page }) => (page === "dashboard" ? "" : "margin-bottom: 28px;")}
    z-index: ${zIndex.dropDown};
    width: 100%;

    ${breakpointMin.md} {
      margin-bottom: 34px;
    }

    ${breakpointMin.xl} {
      width: 300px;
    }

    svg {
      margin-inline-end: 5px;
    }

    input {
      background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      width: 100%;
    }
  }
`;

export const NoResultWrapper = styled.div<{ page?: "dashboard" | "pools" }>`
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const NoResultText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: initial;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 80px;
`;

export const PoolAccordionBodyContent = styled.div`
  margin-top: 12px;
  font-weight: 300;
`;

export const LoadMoreButton = styled.button<{ isDisabled: boolean }>`
  width: 168px;
  height: 42px;
  font-size: 12px;
  background: #7c7758;
  border-radius: 12px;
  border-color: transparent;
  text-align: center;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.2 : 1)};
`;

export const LoadMoreContainer = styled.div`
  padding-bottom: 12px;
  margin-top: 20px;
  text-align: center;
`;

export const SearchResultContainer = styled.div`
  width: 100%;
`;
