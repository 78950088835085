import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

import { GlobalStyle } from "App.styles";
import AppAuth from "AppAuth";
import AppDirection from "AppDirection";
import Loader from "components/Loader/Loader";
import RenderPage from "RenderPage";

import AppProvider from "AppContext";

function App() {
  if (process.env.REACT_APP_ENV === "production") {
    console.log = function () {};
  }

  return (
    <AppProvider>
      <HelmetProvider>
        <Router>
          <GlobalStyle />
          <AppDirection>
            <AppAuth>
              <div className="App">
                <Suspense fallback={<Loader />}>
                  <RenderPage />
                </Suspense>
              </div>
            </AppAuth>
          </AppDirection>
        </Router>
      </HelmetProvider>
    </AppProvider>
  );
}

export default App;
