import { FC, ReactNode } from "react";

import Error from "assets/SvgIcons/Error";
import Minted from "assets/SvgIcons/Minted";
import Notified from "assets/SvgIcons/Notified";
import Progress from "assets/SvgIcons/Progress";
import Success from "assets/SvgIcons/Success";
import Unable from "assets/SvgIcons/Unable";
import Warning from "assets/SvgIcons/Warning";

type IconNames =
  | "success"
  | "error"
  | "progress"
  | "notified"
  | "minted"
  | "warning"
  | "unable";

interface TheiaSvgIconsProps {
  name: IconNames | string;
  width?: number | string;
  height?: number | string;
  fill?: string;
  viewBox?: string;
  stroke?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const icons: {
  [key: string | IconNames]: FC<Omit<TheiaSvgIconsProps, "name">>;
} = {
  success: Success,
  error: Error,
  progress: Progress,
  notified: Notified,
  minted: Minted,
  unable: Unable,
  warning: Warning,
};

const TheiaSvgIcons: FC<TheiaSvgIconsProps> = ({ name, ...props }) => {
  const Icon = icons[name];
  return <Icon {...props} />;
};

export default TheiaSvgIcons;
