import i18n from "i18n";

export const ShopifyButtonProperties = [
  {
    labelText: i18n.t("Shop Merchandise"),
    imageSrc: "/assets/icons/shopify.svg",
    redirectUrl: "https://sf-merchandise.com/collections/the-view",
    newTab: true,
    isSidebar: true,
    isSidebarShown: true,
    key: i18n.t("Shop Merchandise"),
  },
];

export default ShopifyButtonProperties;
