import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const ErrorWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 192px 0px 0px 72px;
  @media ${breakpoints.tablet} {
    flex-direction: column;
    margin: 32px 32px 0px 44px;
  }
  @media ${breakpoints.mobile} {
    margin: 114px 12px 0px 12px;
  }
`;
export const ErrorImageBlock = styled.div`
  width: 46%;
  margin: -219px 0px 0px 0px;

  @media ${breakpoints.tablet} {
    width: 87%;
    margin: 0px -31px 0px 50px;
  }
  @media ${breakpoints.mobile} {
    margin: 10px 0px 0px 0px;
  }
`;

export const ErrorImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ErrorContentWrapper = styled.div`
  width: 35%;
  margin-right: 0px;
  button {
    height: 48px;
    width: 201px;
    border-radius: 14px;
    font-size: 16px;
    line-height: 14px;
  }
  @media ${breakpoints.tablet} {
    width: 78%;
    margin: 5px -2px 0px 88px;
    button {
      margin-left: -4px;
    }
  }
  @media ${breakpoints.mobile} {
    width: 84%;
    margin: 63px 0px 0px 0px;
    button {
      margin-left: 0px;
    }
  }
`;

export const ErrorContentBlock = styled.div`
  margin-bottom: 79px;
  @media ${breakpoints.tablet} {
    margin-left: 0px;
    margin-bottom: 100px;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 71px;
    margin-left: 0px;
  }
`;

export const ErrorHeader = styled.div`
  margin: 0px 0px 0px 0px;
  font-size: 80px;
  line-height: 90px;
  font-weight: 600;
  color: ${(p: any) => p.theme.colors.textColors.textVariant};

  @media ${breakpoints.tablet} {
    margin: 2px 0px 0px 0px;
    line-height: 100px;
  }
  @media ${breakpoints.mobile} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const ErrorHighlightText = styled.span`
  font-size: 80px;
  line-height: 90px;
  font-weight: 600;
  color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
  @media ${breakpoints.tablet} {
    line-height: 85px;
  }
  @media ${breakpoints.mobile} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const ErrorContext = styled.div`
  margin: 45px 17px 0px 5px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  @media ${breakpoints.tablet} {
    margin: 16px 12px 0px 3px;
  }
  @media ${breakpoints.mobile} {
    margin: 20px 0px 0px 3px;
    color: ${(p: any) => p.theme.colors.brandColors.brandVariantLight};
  }
`;
