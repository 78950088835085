import IUser, { FeatureFlag, IUserLands } from "interface/IUser";
import sStorage from "util/sStorage";

import { TheiaThemes } from "./styles/themes/Theia";

export interface IAppUser {
  wallets: [];
  nftEmails: [];
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  lands: IUserLands;
  featureFlags: FeatureFlag[];
}

export interface AppState {
  isLoggedIn: boolean;
  isSidebarShown: boolean;
  theme: any;
  user: IUser | IAppUser;
  token: string;
  language: string;
  isDirection: "ltr" | "rtl";
}

export const initialAppState: AppState = {
  isLoggedIn:
    !window.location.pathname.includes("login") && sStorage.get("token")
      ? true
      : false,
  isSidebarShown: true,
  theme:
    // !window.location.pathname.includes("login") &&
    sStorage.get("theme") === "TheiaThemeLight"
      ? TheiaThemes.TheiaThemeLight
      : TheiaThemes.TheiaThemeDark,
  user:
    (!window.location.pathname.includes("login") && sStorage.get("user")) ||
    ({} as IUser | IAppUser),
  token:
    (!window.location.pathname.includes("login") && sStorage.get("token")) ||
    "",
  language:
    (!window.location.pathname.includes("login") && sStorage.get("language")) ||
    localStorage.getItem("language") ||
    "en",
  isDirection: localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
};

export type AppAction =
  | { type: "login"; message: string; data: any }
  | { type: "logout" }
  | { type: "theme"; data: any }
  | { type: "user"; data: IUser | IAppUser }
  | { type: "sidebarShown"; data: boolean }
  | { type: "direction"; data: any }
  | { type: "lng"; data: string };

export const appReducer = (state: AppState, action: AppAction) => {
  switch (action.type) {
    case "theme":
      const newTheme = {
        ...state,
        theme: action.data.theme,
      };
      sStorage.set("theme", newTheme.theme.name);
      return newTheme;
    case "logout":
      sessionStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        user: {} as IUser | IAppUser,
        token: "",
        courses: [],
      };

    case "login":
      const newState = {
        ...state,
        isLoggedIn: true,
        token: action.data.token,
        courses: action.data.courses,
        user: action.data.user,
      };
      sStorage.set("token", newState.token);
      return newState;
    case "sidebarShown":
      return { ...state, isSidebarShown: action.data };
    case "user":
      return {
        ...state,
        user: action.data,
      };

    case "lng":
      return { ...state, language: action.data };
    case "direction":
      return { ...state, isDirection: action.data };
    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(state)}`);
  }
};
