import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { SfModalContent, SfModalHeader } from "@successfactory/sf-libraries";

import useSelectedLocationsApi from "api/useSelectedLocationApi";

import ILocationData from "interface/ILocationData";
import IPackageData from "interface/IPackageData";

import { getMembershipName } from "util/getMembershipName";

import * as S from "./AllocatePackageComponent.styles";
import NullAllocatePackage from "./AllocatePackageNull";
import OneAllocatePackage from "./AllocatePackageOne";
import TwoAllocatePackage from "./AllocatePackageTwo";

interface AllocatePackageComponentProps {
  selectedPackage: IPackageData;
  locations: Array<ILocationData>;
  onPackageAllocated: Function;
  onClose: Function;
}

const AllocatePackageComponent: React.FC<AllocatePackageComponentProps> = ({
  selectedPackage,
  onPackageAllocated,
  locations,
  onClose,
}) => {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = React.useState(
    {} as ILocationData
  );
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isSoldOut, setIsSoldOut] = React.useState<boolean>(false);
  const { getSelectedLocations, selectedLocations } = useSelectedLocationsApi(
    true,
    "location",
    selectedLocation._id
  );
  const onAllocation = (sPackage: IPackageData, sLocation: ILocationData) => {
    if (onPackageAllocated) onPackageAllocated(sPackage, sLocation);
  };

  const getSelectedLocationDetails = () => {
    getSelectedLocations(selectedLocation._id);
  };

  useEffect(() => {
    if (selectedLocation && selectedLocation._id) {
      getSelectedLocationDetails();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (
      selectedLocations?.continent &&
      selectedLocations?.continent?.max_available <=
        selectedLocations.continent.total_allocated
    ) {
      setIsSoldOut(true);
    }
  }, [selectedLocations]);

  const displayModalContent = () => {
    if (currentStep === 0)
      return (
        <div>
          <NullAllocatePackage
            locations={locations}
            selectedPackage={selectedPackage}
            setCurrentStep={setCurrentStep}
            setSelectedLocation={setSelectedLocation}
          />
        </div>
      );
    if (currentStep === 1)
      return (
        <div>
          <OneAllocatePackage
            selectedPackage={selectedPackage}
            selectedLocation={selectedLocation}
            setCurrentStep={setCurrentStep}
            isSoldOut={isSoldOut}
          />
        </div>
      );
    if (currentStep === 2 && selectedLocation)
      return (
        <div>
          <TwoAllocatePackage
            selectedPackage={selectedPackage}
            selectedLocation={selectedLocation || ({} as ILocationData)}
            onPackageAllocated={onAllocation}
            onClose={onClose}
          />
        </div>
      );

    return <div></div>;
  };
  return (
    <>
      {currentStep !== 2 ? (
        <SfModalHeader>
          <S.PackageHeader>
            <S.PackageHeaderLeft>
              <S.PackageTitleSmall>{t("Membership")}</S.PackageTitleSmall>
              <S.PackageTitle>
                {getMembershipName(selectedPackage)}
              </S.PackageTitle>
            </S.PackageHeaderLeft>
            <div>
              <S.PackageTitleSmall>
                {new Date(selectedPackage!.purchasedAt).toLocaleDateString(
                  "de-CH",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )}
              </S.PackageTitleSmall>
              <S.PackageDate>{selectedPackage!.value}€</S.PackageDate>
            </div>
          </S.PackageHeader>
        </SfModalHeader>
      ) : (
        <SfModalHeader>
          <S.PackageTitle>{t("Successfully Allocated")}</S.PackageTitle>
        </SfModalHeader>
      )}
      <SfModalContent>{displayModalContent()}</SfModalContent>
    </>
  );
};

export default AllocatePackageComponent;
