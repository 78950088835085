import styled, { css } from "styled-components";
import { breakpointMin } from "@successfactory/sf-libraries";
import { breakpoints } from "styles/themes/breakPoint";

const stylesMargin = css`
  margin-top: 30px;
`;

export const PageWrapper = styled.div`
  ${stylesMargin};
`;

export const VideosContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 30px;
  ${stylesMargin};
  ${breakpointMin.xl} {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;

export const VideoWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: ${({ theme }) =>
    `${theme.colors.surfaces.surfaceTransparencyPrimary}`};
  border-radius: 8px;
  flex-grow: 1;
`;

export const Video = styled.div``;

export const VideoStaticWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.cardsMembership.cardPrimary};
  width: 100%;
  min-height: 243px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpointMin.xxl} {
    min-height: 342px;
  }
`;
export const VideoInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: ${({ theme }) => theme.colors.brandColors.darkBackgroundTone};
  border-radius: 0px 0px 8px 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
`;

export const VideoTitle = styled.h3`
  margin-top: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  ${breakpointMin.xl} {
    min-height: 64px;
  }
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
`;

export const VideoDescription = styled.p`
  font-weight: 600;
  margin: 0px;
  font-size: 16px;
  line-height: 30px;
`;

export const VideoPlayerContainer = styled.iframe`
  border: 1px solid ${({ theme }) => theme.colors.brandColors.brandSecondary};
  object-fit: cover;
  width: 100%;
`;

export const PlayerContainer = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.surfaces.surfaceSecondary}; ;
`;

export const InfoAppContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 30px;
  ${breakpointMin.xl} {
    flex-direction: row;
    margin-bottom: 0;
    align-items: start;
    justify-content: space-between;
  }
`;

export const InfoWrapper = styled.div`
  max-width: 100%;
  margin-top: 32px;

  :first-child {
    margin-top: 0;
  }

  ${breakpoints.tablet} {
    margin-top: 100px;
  }
  ${breakpointMin.xl} {
    max-width: calc(50% - 53px);
    margin-top: 0;
    min-height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const InfoAppDescription = styled.div`
  display: flex;
  text-align: start;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  span {
    display: contents;
    color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
  }
`;

export const InfoAppLink = styled.a``;

export const InfoAppImage = styled.img`
  display: flex;
  gap: 32px;
`;

export const CustomCardContainer = styled.div`
  ${stylesMargin};
  margin-bottom: 30px;
`;

export const InfoAppLinkWrapper = styled.div`
  display: flex;
  gap: 12px;
  ${stylesMargin};

  @media ${breakpoints.tablet} {
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  @media ${breakpoints.desktop} {
    gap: 32px;
  }
`;
