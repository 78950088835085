import { useMemo } from "react";

import { useApiHelpers, useApiOptionsProps, useAxiosGet } from "./useApi";

const useStatsApi = ({ init = [] }: useApiOptionsProps) => {
  const { shouldInitApi } = useApiHelpers();

  const [getStats, getStatsResult, getStatsStatus] = useAxiosGet("/stats");

  if (shouldInitApi("getStats", init, getStatsStatus)) getStats();

  const { stats, timestamp, banners, unitRules } = useMemo(
    () => ({
      stats:
        getStatsStatus === "success" &&
        getStatsResult &&
        getStatsResult.data &&
        getStatsResult.data.success
          ? getStatsResult.data.data
          : {},
      timestamp:
        getStatsStatus === "success" &&
        getStatsResult &&
        getStatsResult.data &&
        getStatsResult.data.success
          ? new Date(getStatsResult.data.timestamp)
          : new Date(),
      banners:
        getStatsStatus === "success" &&
        getStatsResult &&
        getStatsResult.data &&
        getStatsResult.data.success
          ? getStatsResult.data.banners
          : [],
      unitRules:
        getStatsStatus === "success" &&
        getStatsResult &&
        getStatsResult.data &&
        getStatsResult.data.success
          ? getStatsResult.data.unitRules
          : [],
    }),
    [getStatsResult, getStatsStatus]
  );

  return {
    stats,
    timestamp,
    banners,
    unitRules,
    getStatsStatus,
    getStats,
  };
};

export default useStatsApi;
