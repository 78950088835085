import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";
import { zIndex } from "styles/zIndex.styles";

const Flexbox = css`
  display: flex;
`;

export const HeaderSet = styled.div`
  background-color: ${(p) => {
    return p.theme.colors.surfaces.surfaceTertiary;
  }};
  position: fixed;
  z-index: ${zIndex.header};
  width: 100%;
  display: none;
  box-shadow: 0px 0px 6px 4px rgb(0 0 0 / 10%);
  @media ${breakpoints.mobile} {
    box-shadow: unset;
    display: block;
  }
`;

export const Container = styled.div<{ isSidebarShown: boolean }>`
  padding: 20px 0 20px 20px;
  ${Flexbox}
  align-items: center;
  justify-content: space-between;
  ${({ isSidebarShown }) =>
    !isSidebarShown ? "border-bottom: 1px solid #6b6c7a;" : ""}
`;

export const HeaderSetMenuButton = styled.div<{ closed: any }>`
  width: 40px;
  position: relative;
  height: 20px;
  padding-right: 20px;
  cursor: pointer;
  span {
    display: block;
    width: calc(50% + 5px);
    position: absolute;
    height: 2px;
    background: ${(props) => props.theme.colors.brandColors.brandVariantLight};
    border-radius: 20px;
    opacity: 1;
    transform: ${({ closed }) => (closed ? "rotate(0deg)" : "rotate(45deg)")};
    transition: 0.25s ease-in-out;
    :nth-child(1) {
      top: ${({ closed }) => (closed ? "0" : "8px")};
    }
    :nth-child(2) {
      top: ${({ closed }) => (closed ? "7px" : "-8px")};
      width: ${({ closed }) => (closed ? "" : "0%")};
      opacity: ${({ closed }) => (closed ? "1" : "0")};
      transform-origin: ${({ closed }) => (closed ? "initial" : "left center")};
    }
    :nth-child(3) {
      top: ${({ closed }) => (closed ? "15px" : "8px")};
      transform: ${({ closed }) =>
        closed ? "rotate(0deg)" : "rotate(-45deg)"};
    }
  }
`;

export const HeaderSetMobile = styled.div<{ closed: any }>`
  display: none;
  @media ${breakpoints.mobile} {
    display: block;
  }
`;

export const HeaderSideBarLogo = styled.div<{ closed: any }>`
  height: 30px;
  position: relative;
  ${(props) => (props.theme.direction === "rtl" ? "right: 0" : "left: -30px")};
  bottom: 14px;
`;
