import React from "react";

import sStorage from "util/sStorage";

import { IconImage, IconMapWrapper } from "./LocationIcon.styles";

interface LocationIconProps {
  height?: string;
  margin?: string;
  darkImage?: string;
  image?: string;
  fitLogo?: boolean;
  type?: string;
  className?: string;
  updatedAt?: string;
}

export const LocationIcon: React.FC<LocationIconProps> = ({
  margin,
  height,
  image,
  darkImage,
  type,
  fitLogo = true,
  className,
  updatedAt = "",
}) => {
  const theme = sStorage.get("theme");
  const showDarkImage = theme === "TheiaThemeLight" && type !== "region";
  const imageToShow = showDarkImage ? darkImage : image;

  const imageSource = `${process.env.REACT_APP_AWS_ASSETS_URL}/${imageToShow}?${updatedAt}`;

  return fitLogo ? (
    <IconImage
      className={`table-image ${className || ""}`}
      height={height}
      margin={margin}
      src={imageSource}
    />
  ) : (
    <IconMapWrapper height={height} className={`iconImage  ${className || ""}`}>
      <img src={imageSource} alt={`${imageToShow}`} />
    </IconMapWrapper>
  );
};
