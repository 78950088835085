import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;    
    color: ${(p: any) => p.theme.colors.textColors.textVariant};
    } 

  body {
    padding: 0;   
    margin: 0;  
    min-height: 100vh;
    background-image: ${({ theme }) => `linear-gradient(
      ${theme.colors.surfaces.surfaceTransparencyBgPrimary}, 
         ${theme.colors.surfaces.surfaceTransparencyBgPrimary}),
      url("/assets/background.png");`}; 
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    font-family: "Poppins", sans-serif;
    background-color:${({ theme }) => theme.colors.surfaces.surfaceSecondary}
   } 

   
  #root {
    min-height: 100vh;
    width: 100%;
    flex: 1;
  }

  html {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }
  `;
