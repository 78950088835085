import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAxiosPost } from "api/useApi";
import sStorage from "util/sStorage";
import { IStepCollectionData } from "util/useMintingEvent";

import FeedbackDialog from "./FeedbackDialog";
import FeedbackDialogSwitch from "./FeedbackDialogSwitch";
import { PropsByStatusType, WalletProps } from "./IWallet";
import * as S from "./SharedStyles.styles";

const WalletMint = ({
  onSubmit,
  handleModalClose,
  onTryAgain,
  results,
}: WalletProps) => {
  const { t } = useTranslation();
  const [postMinting, , postMintingStatus] = useAxiosPost(
    `/nfts/request-to-mint`
  );

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const { ctaProps, stepData } = useMemo(() => {
    const onConfirm = () => {
      setFormSubmitted(true);
      postMinting(stepData);
    };

    const steps = new Map(results.map((obj) => [obj["step"], obj]));
    const mintingStepData = steps.get("Minting");
    const walletStepData = steps.get("Wallet");

    const confirmationText = t(
      "Your Wallet <span>{{walletAddress}}</span> has been validated. Your units are now being minted!",
      {
        walletAddress: walletStepData.address,
      }
    );

    const data: IStepCollectionData = {
      locationId: mintingStepData.location_id,
      walletAddress: walletStepData.address,
      saveWalletId: walletStepData.saveWalletId,
      saveEmail: walletStepData.saveEmail,
      isBlockchainAddressFound: walletStepData.isBlockchainAddressFound,
      contact: walletStepData.contact,
      email: walletStepData.contact,
      contactType: "EMAIL",
      contactLanguage: sStorage.get("language") || "en",
      requestedAllocations: mintingStepData.requestedAllocations.map(
        (allocation: { unit_type: number; count: number }) => ({
          value: allocation.unit_type,
          count: allocation.count,
        })
      ),
    };

    return {
      stepData: data,
      ctaProps: !walletStepData.error
        ? {
            icon: "success",
            message: {
              title: t("Well done!"),
              description: confirmationText,
            },
            buttons: [
              {
                label: t("Continue"),
                onClick: onConfirm,
              },
            ],
          }
        : walletStepData.error && walletStepData?.errors?.success === false
        ? {
            icon: "error",
            message: {
              title: t("Warning!"),
              description: t(
                `Your Wallet ID was not found in the blockchain, are you sure you want to mint units to this Wallet? By doing so, your NFTs might get lost.`
              ),
            },
            buttons: [
              {
                label: t("Cancel"),
                onClick: handleModalClose,
              },
              {
                label: t("Continue"),
                onClick: onConfirm,
              },
            ],
          }
        : {
            icon: "error",
            message: {
              title: t("Sorry!"),
              description: t(
                `We could not connect to your Wallet. Please check your Wallet ID or try again later,`
              ),
            },
            buttons: [
              {
                label: t("Close"),
                onClick: handleModalClose,
              },
              {
                label: t("Try Again"),
                onClick: () => onTryAgain && onTryAgain("Wallet"),
              },
            ],
          },
    };
  }, [results, t, handleModalClose, onTryAgain, postMinting]);

  useEffect(() => {
    postMintingStatus === "success" && onSubmit(stepData);
  }, [postMintingStatus, onSubmit, stepData]);

  const config: PropsByStatusType = {
    success: {
      icon: "success",
      message: {
        title: t("Congratulations!"),
        description: t(
          `Your NFTs are queued for minting! Please note that the minting process itself will take some time. We will inform you by Email once the process is completed!`
        ),
      },
      buttons: [
        {
          label: t("Close"),
          onClick: () => {
            handleModalClose();
            window.location.reload();
          },
        },
      ],
    },
    error: {
      icon: "error",
      message: {
        title: t("Oops!"),
        description: t(`There are no NFTs to mint!`),
      },
      buttons: [
        {
          label: t("Close"),
          onClick: handleModalClose,
        },
      ],
    },
    serverErrors: {
      icon: "error",
      message: {
        title: t("Sorry!"),
        description: t(
          `There was an error with the request. Please try again!`
        ),
      },
      buttons: [
        {
          label: t("Cancel"),
          onClick: handleModalClose,
        },
        {
          label: t("Try Again"),
          onClick: () => onTryAgain && onTryAgain("Wallet"),
        },
      ],
    },
  };

  return (
    <S.MintWrapper>
      {!formSubmitted ? (
        <FeedbackDialog {...ctaProps} />
      ) : (
        <FeedbackDialogSwitch status={postMintingStatus} config={config} />
      )}
    </S.MintWrapper>
  );
};

export default WalletMint;
