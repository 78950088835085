import { useTranslation } from "react-i18next";

import { useSfModal } from "@successfactory/sf-libraries";
import { useAxiosPost } from "api/useApi";
import Loader from "components/Loader/Loader";
import Content from "components/Login/Components/Common/Content";
import TheiaSvgIcons from "components/TheiaSvgIcons/TheiaSvgIcons";
import { FormsContainer } from "pages/login/PublicTemplate.styles";

import { useAppState } from "AppContext";

import Feedback from "./Common/Feedback";
import CommonModal from "./Common/Modal";

import { APIEndpoints } from "../constants/APIEndpoints";
import { useAxiosResult } from "../constants/useAxiosResult";
import TemporaryPasswordForm from "../Forms/TemporaryPasswordForm";

const TemporaryPasswordComponent = () => {
  const { t } = useTranslation();
  const { language, theme } = useAppState();

  const [modalProps, setShown] = useSfModal("theia");

  const [temporaryPassword, temporaryPasswordResult, temporaryPasswordStatus] =
    useAxiosPost(APIEndpoints.REQUEST_PASSWORD);

  const onSubmit = (data: any) => {
    const updatedData = { ...data, language };
    setShown(true);
    temporaryPassword(updatedData);
  };

  const { error, success, serverError } = useAxiosResult(
    temporaryPasswordResult
  );

  const isError = Boolean(error) || Boolean(serverError);
  const isServerError = Boolean(serverError);

  const isSuccess = Boolean(success);

  const isStatusLoading = temporaryPasswordStatus === "loading";

  const modalCurrentProps = {
    showCrossmark: false,
    closeOnOutsideClick: true,
    ...modalProps,
  };
  if (isStatusLoading) return <Loader />;

  return (
    <FormsContainer>
      <Content
        title={t("Request Temporary Password")}
        description={t(
          "Please provide your previously used Email address to request a new password!"
        )}
      />
      {isError && (
        <Feedback
          message={t(error)}
          icon={
            <TheiaSvgIcons
              name={"warning"}
              fill={theme.colors.helpers.negativePrimary}
              width={20}
              height={20}
            />
          }
        />
      )}
      <TemporaryPasswordForm onSubmit={onSubmit} />

      {(isSuccess || isServerError) && (
        <CommonModal
          modalProps={modalCurrentProps}
          isError={isError || isServerError}
          dynamicModalContent={{
            icon: (
              <TheiaSvgIcons
                name={isSuccess ? "success" : "error"}
                fill={
                  !isError || !serverError
                    ? theme.colors.brandColors.brandSecondary
                    : theme.colors.textColors.textLightGrey
                }
                width={40}
                height={40}
              />
            ),
            title: isServerError ? t("Ooops!") : t("Congratulations!"),
            description: isServerError ? t(serverError) : t(success),
            buttons: [{ label: t("Back to Login"), href: "/login" }],
          }}
        />
      )}
    </FormsContainer>
  );
};

export default TemporaryPasswordComponent;
