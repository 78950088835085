import useUserApi from "api/useUserApi";
import AccountCenterContent from "components/AccountCenter/AccountCenterContent";
import Loader from "components/Loader/Loader";
import MyProfileForm from "components/MyProfile/MyProfileForm";
import { Grid, GridContainer } from "styles/Grid.styles";
// import { useFeatureEnableForUser } from "util/isFeatureEnableForUser";

import { MyProfileBlock, MyProfileContainer } from "./AccountCenterPage.styles";
import accountCenterData from "./data/AccountCenterContentData";

const AccountCenterPage = () => {
  const {
    getUserStatus,
    userProfile,
    verticals,
    updateUserProfile,
    updateUserLanguage,
    getUser,
  } = useUserApi(true);

  // const { isFeatureEnabled } = useFeatureEnableForUser("nft-minting");

  const isLoading = getUserStatus === "loading";

  // const isFeatureEnabledForLastItem =
  //   accountCenterData.length > 0
  //     ? accountCenterData.length - 1
  //       ? isFeatureEnabled
  //       : true
  //     : false;

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    const myProfileFormProps = {
      userProfile: userProfile!,
      verticals,
      updateUserProfile,
      updateUserLanguage,
      getUser,
    };

    return (
      <GridContainer>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12"></Grid>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
          <MyProfileContainer>
            <MyProfileBlock>
              <MyProfileForm {...myProfileFormProps} />
            </MyProfileBlock>
            <MyProfileBlock>
              {accountCenterData.map((section, index) => (
                <AccountCenterContent key={index} {...section} />
              ))}
            </MyProfileBlock>
          </MyProfileContainer>
        </Grid>
      </GridContainer>
    );
  };

  return renderContent();
};

export default AccountCenterPage;
