export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeEachWordLetter = (string: string) => {
  const stringSplitted = string.split(" ");
  return stringSplitted
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
};
