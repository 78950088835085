import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { availableLanguages } from "config/availableLanguages";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";

import {
  DropdownBody,
  DropdownBodyContent,
  Dropdown,
  DropdownItem,
  DropdownBox,
  Checkmark,
  DropdownListItem,
} from "./MyProfileLanguageDropdown.styles";

interface MyProfileLanguageDropdownProps {
  setSelectedLanguage: Function;
  selectedLanguage: any;
}

const MyProfileLanguageDropdown: React.FC<MyProfileLanguageDropdownProps> = ({
  setSelectedLanguage,
  selectedLanguage,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  const OutsideSelectOption = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: { target: any }) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  OutsideSelectOption(wrapperRef);

  const checkValue = (item: any) => {
    setSelectedLanguage({
      language: item.language,
      display: item.display,
      code: item.code,
      changed: true,
    });
    setOpen(false);
  };

  return (
    <Dropdown
      ref={wrapperRef}
      isOpen={isOpen}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      <DropdownBox>
        <DropdownItem>{selectedLanguage.display}</DropdownItem>
        <Chevron />
      </DropdownBox>
      <DropdownBody>
        {availableLanguages.map((item: any, index: number) => (
          <DropdownBodyContent
            key={index}
            onClick={() => {
              checkValue(item);
            }}
          >
            <div style={{ display: "flex" }}>
              <Checkmark
                selected={selectedLanguage.language === item.language}
              />{" "}
              <DropdownListItem
                selected={selectedLanguage.language === item.language}
              >
                {t(item.display)}
              </DropdownListItem>
            </div>
          </DropdownBodyContent>
        ))}
      </DropdownBody>
    </Dropdown>
  );
};

export default MyProfileLanguageDropdown;
