export const stringToHTML = function (string: string) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(string, "text/html");
  const contentNodes = doc.childNodes[0].childNodes[1].childNodes;

  const content = Array.from(contentNodes).map((item: any, index: number) => {
    if (item.tagName === "SPAN")
      return <span key={index}>{item.textContent}</span>;
    if (item.tagName === "B") return <b key={index}>{item.textContent}</b>;
    if (item.tagName === "I") return <i key={index}>{item.textContent}</i>;
    if (item.tagName === "BR")
      return (
        <>
          <br key={index} />
          {item.textContent}
        </>
      );
    return item.textContent;
  });
  return content;
};
