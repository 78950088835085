import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

const direction = (p: any) => {
  if (p.direction === "rtl") {
    return `
      right: ${p.sidebar ? "256px" : "96px"};
      left:0;
       @media ${breakpoints.mobile} {
         right:0;
      }
       }
    `;
  }
  return `
    left: ${p.sidebar ? "256px" : "96px"};
    right:0;
    @media ${breakpoints.mobile} {
         left:0
       }
   `;
};
export const ContentWrapper = styled.div<{
  sidebar: boolean;
  direction: string;
}>`
  display: flex;
  margin-top: 80px;
  min-height: 100%;
  width: ${({ sidebar }) =>
    sidebar ? "calc(100% - 256px)" : "calc(100% - 96px)"};
  position: relative;
  transition: 0.5s;
  @media ${breakpoints.mobile} {
    margin-top: 60px;
    padding: 0px;
    width: 100%;
    height: 100%;
    left: unset;
  }
  ${direction}
`;

export const Page = styled.div`
  display: flex;
  padding: 30px 0px;
`;
