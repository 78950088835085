import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Container,
  CardHeaderLine,
  CardHeader,
  CardHeaderContent,
  CardHeaderEmptyContent,
  HeaderCaptionLeft,
  HeaderCaptionRight,
  HeaderTitleLeft,
  HeaderTitleRight,
  CardBody,
  CardFooter,
  CardFooterLine,
  FooterCaption,
  FooterTitle,
} from "./SfCardMembership.styles";
import SfLoadingContentIcon from "./SfLoadingContentIcon";

type CardContent = {
  header: {
    captionLeft?: string;
    captionRight?: string;
    titleLeft?: string;
    titleRight?: string;
  };
  footer?: {
    caption: string;
    title: string;
  };
};
export interface SfCardMembershipProps {
  type: "initial" | "allocated" | "empty";
  cardContent?: CardContent | null;
  buttonClick?: Function;
  width?: string;
  height?: string;
}

export const SfCardMembership: React.FC<SfCardMembershipProps> = ({
  type = "initial",
  cardContent,
  children,
  width = "282px",
  height = "400px",
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Container width={width} height={height}>
      <CardHeaderLine />
      <CardHeader>
        {type === "empty" ? (
          <CardHeaderEmptyContent>
            <SfLoadingContentIcon color={theme.colors.textColors.textGrey} />
          </CardHeaderEmptyContent>
        ) : (
          <CardHeaderContent>
            <HeaderCaptionLeft>
              {cardContent?.header.captionLeft}
            </HeaderCaptionLeft>
            <HeaderCaptionRight>
              {cardContent?.header.captionRight}
            </HeaderCaptionRight>
            <HeaderTitleLeft>{cardContent?.header.titleLeft}</HeaderTitleLeft>
            <HeaderTitleRight>
              {cardContent?.header?.titleRight}
            </HeaderTitleRight>
          </CardHeaderContent>
        )}
      </CardHeader>
      <CardBody>{children}</CardBody>
      {cardContent && cardContent.footer ? (
        <>
          <CardFooterLine />
          <CardFooter>
            <FooterCaption>{cardContent.footer.caption}</FooterCaption>
            <FooterTitle>{cardContent.footer.title}</FooterTitle>
          </CardFooter>
        </>
      ) : null}
    </Container>
  );
};
