import { useTranslation } from "react-i18next";
import ILocationData from "interface/ILocationData";
import IPackageData from "interface/IPackageData";
import React, { useCallback, useState } from "react";
import {
  AllocatePackageModalWrapper,
  ContentHead,
  PackageOptionsWrapper,
  RegionLink,
  AllocationDescription,
} from "./AllocatePackage.styles";
import AllocatePackageForm from "./AllocatePackageForm";
import IRegionOptions from "interface/IRegionOptions";
import { sortedLocationsByValue } from "util/sortLocationValue";

import { ReactComponent as RegionPoolsIcon } from "../../assets/pools/region-pools/region-pools.svg";
import { ReactComponent as CityPoolsIcon } from "../../assets/pools/city-pools/city-pools.svg";
import { ReactComponent as VerticalPoolsIcon } from "../../assets/pools/vertical-pools/vertical-pools.svg";
import { ReactComponent as ContinentPoolsIcon } from "../../assets/pools/continent-pools/continent-pool.svg";

interface NullAllocatePackageProps {
  locations: Array<ILocationData>;
  selectedPackage: IPackageData;
  setCurrentStep: Function;
  setSelectedLocation: Function;
}
export interface LocationsPerType {
  city: Array<ILocationData>;
  region: Array<ILocationData>;
  continent: Array<ILocationData>;
  [key: string]: Array<ILocationData>;
}

const NullAllocatePackage: React.FC<NullAllocatePackageProps> = ({
  locations,
  selectedPackage,
  setCurrentStep,
  setSelectedLocation,
}) => {
  const { t } = useTranslation();
  const filteredSoldOutLocations = locations.filter(
    (location) => location.total_allocated < location.max_available
  );

  const [optionId, setOptionId] = useState<IRegionOptions["id"]>("");
  const [active, setActive] = useState<number | boolean | null>(null);
  const [selectedType, setSelectedType] = useState<string>("");

  const regionsOptions: Array<IRegionOptions> = [
    {
      labelPlural: t("Regions"),
      labelSingular: t("Region"),
      type: "region",
      id: "regionPool",
      icon: <RegionPoolsIcon />,
    },
    {
      labelPlural: t("Cities"),
      labelSingular: t("City"),
      type: "city",
      id: "cityPool",
      icon: <CityPoolsIcon />,
    },
    {
      labelPlural: t("Verticals"),
      labelSingular: t("Vertical"),
      type: "vertical",
      id: "verticalPool",
      icon: <VerticalPoolsIcon />,
    },
    {
      labelPlural: t("World Areas"),
      labelSingular: t("World Area"),
      type: "continent",
      id: "worldAreaPool",
      icon: <ContinentPoolsIcon />,
    },
  ];
  const getLocationsAccordingToType = useCallback(
    (): LocationsPerType =>
      filteredSoldOutLocations.reduce(
        (locationAcc, location: ILocationData) => {
          const filteredLocations = filteredSoldOutLocations.filter(
            (loc: ILocationData) =>
              loc.type === location.type &&
              loc.value <= selectedPackage.value &&
              (loc.restricted_to === null ||
                new Date(loc.restricted_to) >
                  new Date(selectedPackage.purchasedAt))
          );
          return {
            ...locationAcc,
            [location.type]: sortedLocationsByValue(filteredLocations),
          };
        },
        { city: [], region: [], vertical: [], continent: [] }
      ),
    [filteredSoldOutLocations, selectedPackage.value]
  );

  return (
    <>
      <AllocatePackageModalWrapper>
        <ContentHead>{t("Allocate your membership")}</ContentHead>
        <AllocationDescription>
          {t(
            "Choose the region, city, vertical, or world area to which you want to allocate this Membership. Higher-value Memberships will give you more units and more regions, cities, verticals, or world areas to choose from."
          )}
        </AllocationDescription>
      </AllocatePackageModalWrapper>
      <PackageOptionsWrapper>
        {regionsOptions.map((option, index: number) => {
          let isDisabled = true;

          if (getLocationsAccordingToType()[option.type]) {
            isDisabled =
              getLocationsAccordingToType()[option.type].length === 0;
          }

          return (
            <RegionLink
              onClick={() => {
                setOptionId(option.id);
                setActive(index);
                setSelectedType(option.type);
              }}
              key={index}
              active={active === index ? true : false}
              isDefault={!isDisabled}
              isDisabled={isDisabled}
            >
              {option.icon ? option.icon : null}
              {option.labelPlural}
            </RegionLink>
          );
        })}
      </PackageOptionsWrapper>
      <AllocatePackageForm
        selectedPackage={selectedPackage}
        locations={filteredSoldOutLocations}
        setCurrentStep={setCurrentStep}
        setSelectedLocation={setSelectedLocation}
        regionOptions={regionsOptions}
        locationsAccordingToType={getLocationsAccordingToType()}
        optionId={optionId}
        selectedType={selectedType}
      />
    </>
  );
};

export default NullAllocatePackage;
