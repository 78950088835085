import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const CardWrapper = styled.div`
  ${(props) =>
    props.theme.direction === "rtl"
      ? " margin: 0 0px 39px 32px"
      : " margin: 0 32px 36px 0"};
  @media ${breakpoints.mobile} {
    margin-right: 0;
  }
`;

export const CardContent = styled.div<{
  direction: any;
  locationType: string | undefined;
  continentShares: any;
}>`
  position: absolute;
  top: 25%;
  width: 100%;
  height: 55%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const NotAllocatedCardContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  margin-top: 70px;
  width: 100%;
`;

export const AllocatedCaption = styled.div`
  font-size: 14px;
  color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
`;

export const AllocatedRegion = styled.span`
  padding-inline-start: 5px;
`;

export const AllocatedCountry = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;
export const AllocatedDate = styled.span`
  display: inline-block;
  margin-top: -5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #7d7e8e;
`;
export const NotAllocatedCaption = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #808191;
  margin: 12px 0 25px;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 80px;
  margin-right: 80px;
`;
