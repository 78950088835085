import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IButton } from "interface/IButton";
import { ButtonStyles } from "styles/Button.styles";

const BannerButton = ({
  labelText,
  imageSrc = "",
  redirectUrl,
  $twoTone = false,
  disabled = false,
  newTab = false,
  isSidebarShown = false,
  isSidebar = false,
  secondary = false,
  onClick,
}: IButton) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (redirectUrl && typeof redirectUrl === "string") {
      if (newTab) {
        window.open(redirectUrl, "_blank");
      } else {
        window.location.replace(redirectUrl);
      }
    }
  };

  const buttonLabel = useMemo(
    () => (
      <>
        {imageSrc !== "" && <img src={imageSrc} alt={labelText} />}
        {t(labelText)}
      </>
    ),
    [imageSrc, labelText, t]
  );

  const buttonContent = useMemo(() => {
    if (isSidebar && !isSidebarShown && imageSrc !== "") {
      return <img src={imageSrc} alt="" />;
    } else {
      return buttonLabel;
    }
  }, [isSidebar, isSidebarShown, imageSrc, buttonLabel]);

  return (
    <ButtonStyles
      onClick={handleClick}
      $twoTone={$twoTone}
      disabled={disabled}
      $secondary={secondary}
    >
      {buttonContent}
    </ButtonStyles>
  );
};

export default memo(BannerButton);
