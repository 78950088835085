import React, { ReactElement, SVGProps } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  CardHeader,
  CardTitle,
  CardHeaderLineBottom,
  CardBody,
} from "./CardRegion.styles";

export interface CardRegionProps {
  type?: "default" | "answered";
  region: string;
  icon?: SVGProps<SVGElement> | HTMLImageElement | ReactElement;
  onClick?: Function;
  activeCard: boolean;
}

export const CardRegion: React.FC<CardRegionProps> = ({
  type = "default",
  region,
  onClick,
  activeCard,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      region={region}
      onClick={() => (onClick ? onClick() : null)}
      activeCard={activeCard}
      className={type === "answered" ? "no-hover" : ""}
    >
      <CardHeader region={region}>
        <CardTitle>{t(region)}</CardTitle>
        <CardHeaderLineBottom activeCard={activeCard} />
      </CardHeader>
      <CardBody className="map" activeCard={activeCard}>
        {children}
      </CardBody>
    </Container>
  );
};
