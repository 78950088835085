import React from "react";
import { useTranslation } from "react-i18next";

import { SfTabsMenu } from "@successfactory/sf-libraries";

import IPackageData from "interface/IPackageData";

import CardGroups from "./CardGroups/CardGroups";

interface MyPackageProps {
  onPackageSelected?: Function;
  packages: Array<IPackageData>;
}

const MyPackages: React.FC<MyPackageProps> = ({
  onPackageSelected,
  packages,
}) => {
  const { t } = useTranslation();
  const allocatedPackages = () => {
    return packages
      ? packages.filter(
          (card: IPackageData) => card.location && !card.minting?.requested
        )
      : null;
  };

  const notUsedPackages = () => {
    return packages
      ? packages.filter(
          (card: IPackageData) => !card.location && !card.minting?.requested
        )
      : null;
  };

  const mintedPackages = () => {
    const minted = packages
      ? packages.filter((card: IPackageData) => card.minting?.requested)
      : null;
    return minted;
  };

  const items = [
    {
      label: t("All"),
      content: (
        <CardGroups packages={packages} onCardSelected={onPackageSelected} />
      ),
    },
    {
      label: t("Not Used"),
      content: (
        <CardGroups
          packages={notUsedPackages()}
          onCardSelected={onPackageSelected}
        />
      ),
    },
    {
      label: t("Allocated"),
      content: (
        <CardGroups
          packages={allocatedPackages()}
          onCardSelected={onPackageSelected}
        />
      ),
    },
    {
      label: t("Minted"),
      content: (
        <CardGroups
          packages={mintedPackages()}
          onCardSelected={onPackageSelected}
        />
      ),
    },
  ];

  return (
    <>
      <SfTabsMenu items={items} design="Round" align="Start" />
    </>
  );
};

export default MyPackages;
