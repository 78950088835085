import { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./SharedStyles.styles";

import { ReactComponent as Check } from "../../assets/icons/check.svg";

interface WalletFormCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  isPrivacyPolicy?: boolean;
  isTermsAndConditions?: boolean;
  isTermsOfUse?: boolean;
  privacyPolicyUrl?: string;
  termsAndConditionsUrl?: string;
  termsOfUseUrl?: string;
  isCookiePolicy?: boolean;
  cookiePolicyUrl?: string;
  isDisclaimer?: boolean;
  disclaimerUrl?: string;
  isLabelHasHtml?: boolean;
}

const WalletFormCheckbox = ({
  label,
  checked,
  onChange,
  isPrivacyPolicy = false,
  isTermsOfUse = false,
  isCookiePolicy = false,
  isDisclaimer = false,
  privacyPolicyUrl = "",
  termsAndConditionsUrl = "",
  termsOfUseUrl = "",
  disclaimerUrl = "",
  cookiePolicyUrl = "",
  isLabelHasHtml = false,
}: WalletFormCheckboxProps) => {
  const { t } = useTranslation();
  const getLanguage = localStorage.getItem("language");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange(checked);
  };

  const getUrlAndText = () => {
    if (isPrivacyPolicy) {
      return {
        url: privacyPolicyUrl,
        text: t("Privacy Policy"),
      };
    } else if (isTermsOfUse) {
      return {
        url: termsOfUseUrl,
        text: t("Terms of Use"),
      };
    } else if (isCookiePolicy) {
      return {
        url: cookiePolicyUrl,
        text: t("Cookie Policy"),
      };
    } else if (isDisclaimer) {
      return {
        url: disclaimerUrl,
        text: t("Disclaimer"),
      };
    } else {
      return {
        url: termsAndConditionsUrl,
        text: t("Terms and Conditions"),
      };
    }
  };

  const { url, text } = getUrlAndText();

  const displayLabel = useMemo(() => {
    if (url !== "") {
      return (
        <S.CheckboxPrivacyWrapper>
          <S.CheckboxDisplay>{label}</S.CheckboxDisplay>
          <S.PrivacyPolicyLink href={url} target="_blank">
            {t(text)}
          </S.PrivacyPolicyLink>
        </S.CheckboxPrivacyWrapper>
      );
    } else if (isLabelHasHtml) {
      return (
        <S.CheckboxDisplay
          language={getLanguage}
          dangerouslySetInnerHTML={{ __html: t(label) }}
          isLabelHasHtml
        />
      );
    } else {
      return (
        <S.CheckboxDisplay language={getLanguage}>{t(label)}</S.CheckboxDisplay>
      );
    }
  }, [url, isLabelHasHtml, label, t, text, getLanguage]);

  return (
    <S.CheckboxLabel>
      <S.CheckboxInput
        type="checkbox"
        checked={checked}
        onChange={handleInputChange}
        isLabelHasHtml={isLabelHasHtml}
      />
      <S.CheckboxDisplay>{checked && <Check />}</S.CheckboxDisplay>

      {displayLabel}
    </S.CheckboxLabel>
  );
};

export default WalletFormCheckbox;
