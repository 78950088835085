export enum PoolsName {
  UNIVERSAL = "universal",
  FOUNDERS = "founders",
  GLOBAL = "global",
  LEADERSHIP = "leadership",
  INFINITY = "infinity",

  CREDIT = "credit",
}

export enum LocationType {
  CITY = "city",
  REGION = "region",
  VERTICAL = "vertical",
  CONTINENT = "continent",
}

export enum LocationTabs {
  CITIES = "cities",
  REGIONS = "regions",
  VERTICALS = "verticals",
  CONTINENTS = "continents",
}
