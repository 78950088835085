import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { SfModal } from "@successfactory/sf-libraries";
import BannerButton from "components/BannerButton/BannerButton";
import * as St from "components/Credits/styles/CreditsCommon.styles";
import { BannerGroup } from "components/MintingComponents/SharedStyles.styles";

interface ModalProps {
  showCrossmark: boolean;
  shown: boolean;
  contentShadow: boolean;
  type: string;
}

export interface Button {
  label: string;
  href: string;
}

interface CommonModalProps {
  modalProps: ModalProps;
  isError?: boolean;
  dynamicModalContent: {
    title: string;
    description: string;
    buttons: Button[];
    icon: ReactNode;
  };
}

const CommonModal = ({ ...props }: CommonModalProps) => {
  const { t } = useTranslation();

  const { modalProps, isError, dynamicModalContent } = props;

  return (
    <SfModal {...modalProps}>
      <St.ModalWrapper>
        {Boolean(dynamicModalContent.icon) ? (
          <St.ModalIcon>{dynamicModalContent.icon}</St.ModalIcon>
        ) : null}
        <St.ModalHeader isError={isError}>
          {t(dynamicModalContent.title)}
        </St.ModalHeader>
        <St.ModalContentWrapper>
          <St.ModalContent>
            {t(dynamicModalContent.description)}
          </St.ModalContent>
          <BannerButtonGroup buttons={dynamicModalContent.buttons} />
        </St.ModalContentWrapper>
      </St.ModalWrapper>
    </SfModal>
  );
};

interface BannerButtonGroupProps {
  buttons: Button[];
}

const BannerButtonGroup: React.FC<BannerButtonGroupProps> = ({ buttons }) => {
  const { t } = useTranslation();
  const isJustOneButton = buttons?.length === 1;
  return (
    <BannerGroup isJustOneButton={isJustOneButton}>
      {buttons.map((button, index) => (
        <BannerButton
          $twoTone={true}
          key={`common-modal-cta-${index}`}
          labelText={t(button.label)}
          redirectUrl={button.href}
        />
      ))}
    </BannerGroup>
  );
};

export default CommonModal;
