import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const ProfileForm = styled.div`
  padding: 0 20px 48px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px -2px 3px #6baabf;
  @media ${breakpoints.mobile} {
    width: 90%;
  }
`;

export const FormButton = styled.div<{ color: string; icon: Boolean }>`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    width: 194px;
    padding: 10px 20px;
    background-color: ${({ color }) => color};
    div {
      margin-right: 8px;
      margin-left: 0;
    }
  }
  img {
    height: 20px;

    ${(props) =>
      props.theme.direction === "rtl"
        ? "margin-left: 8px;"
        : "margin-right: 8px;"};
    svg {
      #Rectangle {
        fill: red;
      }
    }
  }
`;
export const Line = styled.div`
  mix-blend-mode: normal;
  opacity: 0.2;
  width: calc(100% + 40px);
  border: 1px solid
    ${(p: any) => p.theme.colors.brandColors.brandPrimaryVariant};
  margin-bottom: 20px;
`;

export const ProfileBlock = styled.div`
  width: 100%;
  label {
    padding-top: 2px;
    position: relative;
    display: block;
    letter-spacing: 1px;
    ${(props) =>
      props.theme.direction === "rtl" ? " font-size:14px" : " font-size:12px"};
  }

  @media ${breakpoints.tablet} {
    width: 90%;
  }
`;

export const ProfileHeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

export const ProfileHeader = styled.h4`
  font-size: 24px;
  font-weight: 600;
  padding: 0;
`;

export const ProfileRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  > :nth-child(1) {
    ${(props) =>
      props.theme.direction === "rtl"
        ? " padding-left: 10px"
        : " padding-right: 10px"};
  }
  @media ${breakpoints.tablet} {
    display: block;
    > :nth-child(1) {
      ${(props) =>
        props.theme.direction === "rtl"
          ? " padding-left: 0"
          : " padding-right: 0"};
    }
  }
`;

export const ProfileRowBlock = styled.div`
  min-height: 100px;
  font-size: 16px;
  ul span {
    margin-top: 16px;
  }
  ul {
    padding-right: 0px !important;
    max-width: 100% !important;
    @media (min-width: 768px) {
      bottom: -304px !important;
    }
  }
  div div {
    font-family: unset;
    font-size: 16px;
    align-items: center;

    @media ${breakpoints.mobile} {
      font-size: 14px;
    }
  }
  input {
    font-family: unset;
    font-size: 16px;
    @media ${breakpoints.mobile} {
      font-size: 14px;
    }
  }
  img {
    ${(props) =>
      props.theme.direction === "rtl"
        ? " padding-left: 8px"
        : " padding-right: 8px"};
  }
`;

export const ErrorText = styled.div`
  color: red;
  font-size: 12px;
`;

export const NoErrorText = styled.div``;

export const ProfileCaptionCaps = styled.label`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`;

export const MyViewCodeWrapper = styled.div`
  margin: 8px 0px;
  position: relative;
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid;
  background: none;
  border-color: #9bdaef;
  width: 100%;
  display: flex;
  cursor: pointer;
`;

export const MyViewCodeInput = styled.input`
  outline: none;
  background: none;
  width: 100%;
  text-overflow: ellipsis;
  border: none;
`;

export const ProfileCaption = styled.label`
  font-size: 10px;
  font-weight: 500;
`;

export const ProfileDropdown = styled.div``;

export const ProfileDropdownBlock = styled.div`
  position: relative;
  width: 90%;
`;

export const MyProfileDropdownWrapper = styled.div`
  svg {
    width: 12px;
  }
`;

export const CountryFieldWrapper = styled.div`
  input::placeholder {
    color: white;
  }
`;
