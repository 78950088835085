import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { useSfModal } from "@successfactory/sf-libraries";
import { useAxiosGet, useAxiosPost } from "api/useApi";
import Loader from "components/Loader/Loader";
import Content from "components/Login/Components/Common/Content";
import TheiaSvgIcons from "components/TheiaSvgIcons/TheiaSvgIcons";
import { FormsContainer } from "pages/login/PublicTemplate.styles";

import { useAppState } from "AppContext";

import CommonModal from "./Common/Modal";

import { APIEndpoints } from "../constants/APIEndpoints";
import { useAxiosResult } from "../constants/useAxiosResult";
import SetupPasswordForm from "../Forms/SetupPasswordForm";

const SetupPasswordComponent: React.FC = () => {
  const { t } = useTranslation();
  const { language, theme } = useAppState();

  const params: any = useParams();
  const [userMail, setUserMail] = useState<string>("");
  const [modalProps, setShown] = useSfModal("theia");

  const isResetPasswordPage =
    window.location.pathname.includes("reset-password");

  const [validate, validateResult, validateStatus] = useAxiosGet(
    APIEndpoints.VALIDATE_TOKEN(`token=${params.token}`)
  );

  const [setNewPassword, setNewPasswordResult, setNewPasswordStatus] =
    useAxiosPost(APIEndpoints.SET_NEW_PASSWORD);

  const isLoading =
    validateStatus === "loading" || setNewPasswordStatus === "loading";

  useEffect(() => {
    validate();
  }, []);

  useEffect(() => {
    if (
      validateStatus === "success" &&
      Object.keys(validateResult).length > 0
    ) {
      setUserMail(validateResult?.data?.data?.user?.email);
    }
  }, [validateStatus, validateResult]);

  const onSubmit = (data: any) => {
    const updatedData = { ...data, email: userMail, language: language };
    setNewPassword(updatedData);
  };

  const { error: validateError } = useAxiosResult(validateResult);

  const {
    error: setNewPasswordError,
    success: setNewPasswordSuccess,
    serverError,
  } = useAxiosResult(setNewPasswordResult);

  const isResult =
    Boolean(validateError) ||
    Boolean(setNewPasswordError) ||
    Boolean(serverError) ||
    Boolean(setNewPasswordSuccess);

  const isError = Boolean(validateError) || Boolean(setNewPasswordError);

  useEffect(() => {
    if (isResult) {
      setShown(true);
    }
  }, [isResult, setShown]);

  if (isLoading) return <Loader />;

  const modalCurrentProps = {
    showCrossmark: false,
    closeOnOutsideClick: true,
    ...modalProps,
  };

  return (
    <FormsContainer pageName={"set-up-password"}>
      <Content
        title={isResetPasswordPage ? t("Reset Password") : t("Set Up Password")}
        description={
          isResetPasswordPage
            ? t("Please reset your password!")
            : t("Please set up your new password!")
        }
      />
      <SetupPasswordForm onSubmit={onSubmit} userMail={userMail} />
      {isResult && (
        <CommonModal
          modalProps={modalCurrentProps}
          isError={isError || Boolean(serverError)}
          dynamicModalContent={{
            title: isError || serverError ? t("Oops!") : t("Congratulations!"),
            icon: (
              <TheiaSvgIcons
                name={!isError || !serverError ? "success" : "error"}
                fill={
                  !isError || !serverError
                    ? theme.colors.brandColors.brandSecondary
                    : theme.colors.textColors.textLightGrey
                }
                width={40}
                height={40}
              />
            ),
            description:
              isError || serverError
                ? t(validateError) || t(serverError) || t(setNewPasswordError)
                : t(setNewPasswordSuccess),
            buttons: [{ label: t("Back to Login"), href: "/login" }],
          }}
        />
      )}
    </FormsContainer>
  );
};

export default SetupPasswordComponent;
