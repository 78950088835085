import styled from "styled-components";

export const IconImage = styled.img<{ height?: string; margin?: string }>`
  ${({ height }) => (height ? `height: ${height};` : "")}
  ${({ margin }) => (margin ? `margin: ${margin};` : "")}
`;

export const IconMapWrapper = styled.div<{
  width?: string;
  margin?: string;
  height?: string;
}>`
  width: 100%;
  img {
    height: ${({ height }) => (height ? height : "130px")};
    object-fit: cover;
  }
`;
