import React, { useCallback } from "react";
import { useTheiaUnitsDispatch } from "stores/TheiaUnitsProvider";

import InputField from "./InputField";

import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { CellInputWrapper, CellInputButton } from "./UnitCell.styles";

interface UnitsCellProps {
  cellInfo: any;
  mutationFieldName: "potentialRevOperations" | "unitsOperations";
  amount: number;
  poolsType: string;
}

const UnitsCell: React.FC<UnitsCellProps> = ({
  cellInfo,
  mutationFieldName,
  amount,
  poolsType,
}) => {
  const dispatch = useTheiaUnitsDispatch();
  const cellData = cellInfo.row.original;
  const maxValue =
    mutationFieldName === "potentialRevOperations"
      ? 999999999
      : cellData.max_available;

  const isValidValue = useCallback(
    (value: number) => value >= 0 && value <= maxValue,
    [maxValue]
  );

  const onUnitUpdate = useCallback(
    (amount: number) => {
      dispatch({
        type: mutationFieldName,
        region_code: cellData.region_code,
        poolsType: poolsType,
        data: {
          units: amount,
        },
      });
    },
    [dispatch, mutationFieldName, cellData.region_code, poolsType]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = Number(e.target.value);
      if (!isValidValue(value)) {
        value = Number(e.target.defaultValue);
      }
      onUnitUpdate(value);
    },
    [isValidValue, onUnitUpdate]
  );

  const onClick = useCallback(
    (operation: string) => {
      let newAmount = amount;
      const isIncrease = operation === "increase";
      if (mutationFieldName === "potentialRevOperations") {
        if (isIncrease && newAmount < 999999949) {
          newAmount += 50;
        } else if (!isIncrease && newAmount >= 50) {
          newAmount -= 50;
        }
      } else if (mutationFieldName === "unitsOperations") {
        if (isIncrease && newAmount < cellData.max_available) {
          newAmount += 1;
        } else if (!isIncrease && newAmount > 0) {
          newAmount -= 1;
        }
      }
      return onUnitUpdate(newAmount);
    },
    [amount, cellData.max_available, mutationFieldName, onUnitUpdate]
  );

  return (
    <CellInputWrapper>
      <CellInputButton onClick={() => onClick("decrease")}>
        <Minus />
      </CellInputButton>
      {mutationFieldName === "potentialRevOperations" ? " €" : null}
      <InputField amount={amount} onChange={onChange} />
      <CellInputButton onClick={() => onClick("increase")}>
        <Plus />
      </CellInputButton>
    </CellInputWrapper>
  );
};

export default UnitsCell;
