import React from "react";
import { useTranslation } from "react-i18next";
import { SfButton, SfModalHeader } from "@successfactory/sf-libraries";

import { LocationIcon } from "components/LocationIcon/LocationIcon";

import {
  ContentHead,
  AllocatedCaption,
  ButtonBox,
  AllocatePackageModalWrapper,
} from "./AllocatePackage.styles";
import { PackageTitle } from "./AllocatePackageComponent.styles";

interface SoldOutProps {
  selectedLocation: any;
  onClose: Function;
}

const SoldOut: React.FC<SoldOutProps> = ({ selectedLocation, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <SfModalHeader>
        <PackageTitle>{t("Sold Out")}</PackageTitle>
      </SfModalHeader>
      <AllocatePackageModalWrapper>
        <LocationIcon
          height={"200px"}
          image={selectedLocation.map || selectedLocation.logo}
          darkImage={selectedLocation.dark_logo}
          fitLogo={false}
          type={selectedLocation.type}
          updatedAt={selectedLocation.updated_at}
        />
        <ContentHead>
          <p>
            {t("Sorry! All units have been sold out for")}{" "}
            <AllocatedCaption>{selectedLocation!.region}</AllocatedCaption>
          </p>
        </ContentHead>

        <ButtonBox>
          <SfButton
            twoTone={true}
            onClick={() => {
              onClose();
            }}
            label={t("Understood")}
          ></SfButton>
        </ButtonBox>
      </AllocatePackageModalWrapper>
      <LocationIcon
        height={"200px"}
        image={selectedLocation.map || selectedLocation.logo}
        darkImage={selectedLocation.dark_logo}
        fitLogo={false}
        type={selectedLocation.type}
        updatedAt={selectedLocation.updated_at}
      />
      <ContentHead>
        <p>
          {t("Sorry! All units have been sold out for")}{" "}
          <AllocatedCaption>{selectedLocation!.region}</AllocatedCaption>
        </p>
      </ContentHead>

      <ButtonBox>
        <SfButton
          twoTone={true}
          onClick={() => {
            onClose();
          }}
          label={t("Understood")}
        ></SfButton>
      </ButtonBox>
    </>
  );
};

export default SoldOut;
