import React from "react";

interface LoadingContentIconProps {
  color: string;
}

const LoadingContentIcon: React.FC<LoadingContentIconProps> = ({ color }) => {
  return (
    <svg
      width="235"
      height="25"
      viewBox="0 0 235 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.101018" width="64" height="8" rx="4" fill={color} />
      <rect
        opacity="0.101018"
        x="171"
        width="64"
        height="8"
        rx="4"
        fill={color}
      />
      <rect
        opacity="0.101018"
        x="191"
        y="13"
        width="44"
        height="12"
        rx="6"
        fill={color}
      />
      <rect
        opacity="0.101018"
        x="1"
        y="13"
        width="84"
        height="12"
        rx="6"
        fill={color}
      />
    </svg>
  );
};

export default LoadingContentIcon;
