export const zIndex = {
  header: 3,
  sideBar: 2,
  topHeader: 2,
  courseCard: 1,
  languageDropdown: 1,
  dropDown: 1,
  input: 0,
  searchbar: 1,
};
