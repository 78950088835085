import IRegionVoteData from "interface/IRegionVoteData";
import { useMemo } from "react";
import {
  useApiHelpers,
  useApiOptionsProps,
  useAxiosGet,
  useAxiosPost,
} from "./useApi";

const usePollsApi = ({
  refresh = true,
  init = [],
  id = "",
}: useApiOptionsProps) => {
  const { shouldInitApi } = useApiHelpers();
  const [getPoll, getPollResult, getPollStatus] = useAxiosGet("/get-poll");
  const [voteForPoll, votePollResult, votePollStatus] =
    useAxiosPost("/vote-poll");

  const votePoll = async (poll_slug: string, locations: string[]) =>
    (await voteForPoll({ locations: locations }, poll_slug)) &&
    refresh &&
    getPoll();

  const poll = useMemo(() => getPollResult?.data?.data || {}, [getPollResult]);
  const alreadyVoted = useMemo(
    () => poll?.placedVotes && poll?.placedVotes?.length >= 5,
    [poll]
  );

  const votedRegions = useMemo(() => {
    if (!alreadyVoted) return [];
    if (!poll.placedVotes) return [];

    const placedVotesIds = poll.placedVotes.map(
      (placedVote: any) => placedVote.poll_location_id
    );
    const regions = poll.locations.filter((location: IRegionVoteData) =>
      placedVotesIds.includes(location._id)
    );
    return regions;
  }, [poll, alreadyVoted]);

  if (shouldInitApi("getPoll", init, getPollStatus)) getPoll();

  return {
    poll,
    getPoll,
    getPollStatus,
    alreadyVoted,
    votedRegions,
    votePoll,
    votePollResult,
    votePollStatus,
  };
};

export default usePollsApi;
