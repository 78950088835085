import { Column, DataItem } from "../CreditTable";

export const sortData = (
  data: DataItem[],
  sorting: { column: string | number; order: string }
) => {
  return [...data].sort((a, b) => {
    if (a[sorting.column] < b[sorting.column]) {
      return sorting.order === "asc" ? -1 : 1;
    }
    if (a[sorting.column] > b[sorting.column]) {
      return sorting.order === "asc" ? 1 : -1;
    }
    return 0;
  });
};
export const filterAndSortData = (
  data: DataItem[],
  columns: Column[],
  sorting: { column: string | number; order: string }
) => {
  const filteredData = data.filter((item) =>
    columns.some((column) => {
      const value = item[column.accessor];

      if (!isNaN(value) && value === parseFloat(value)) {
        return true;
      }

      return false;
    })
  );

  return sortData(filteredData, sorting);
};
