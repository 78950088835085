import { useTranslation } from "react-i18next";

import * as S from "../../styles/Shared.styles";

interface ContentProps {
  title: string;
  description: string;
}

const Content = ({ ...props }: ContentProps) => {
  const { title, description } = props;

  const { t } = useTranslation();

  return (
    <S.ContentContainer>
      <S.ContentTitle>{t(title)}</S.ContentTitle>
      <S.ContentDescription>{t(description)}</S.ContentDescription>
    </S.ContentContainer>
  );
};

export default Content;
