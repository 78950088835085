import { FC } from "react";
import { useHistory } from "react-router-dom";
import Routes from "config/routes";
import { SfButton } from "@successfactory/sf-libraries";
import {
  ErrorWrapper,
  ErrorImageBlock,
  ErrorImage,
  ErrorContentWrapper,
  ErrorContentBlock,
  ErrorHighlightText,
  ErrorHeader,
  ErrorContext,
} from "./Error404Page.styles";
import { useTranslation } from "react-i18next";

export const Error404Page: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ErrorWrapper>
      <ErrorImageBlock>
        <ErrorImage src="/assets/error/error-404.svg" alt="error-404-image" />
      </ErrorImageBlock>
      <ErrorContentWrapper>
        <ErrorContentBlock>
          <ErrorHeader>Oops,</ErrorHeader>
          <ErrorHighlightText>{t("something is missing!")}</ErrorHighlightText>
          <ErrorContext>
            {t("We can’t seem to find the page you’re looking for.")}
          </ErrorContext>
        </ErrorContentBlock>
        <SfButton
          label={t("Go back to Dashboard")}
          onClick={() => history.push(Routes.HOME)}
        />
      </ErrorContentWrapper>
    </ErrorWrapper>
  );
};
