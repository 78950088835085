import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

import { getFontStyle } from "../../styles/mixin/getFontStyles";

const borderbottom = css`
  border-bottom: 1px solid rgba(228, 228, 228, 0.1);
`;
const bodyColumn = css`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const TableCard = styled.div<{ isCreditCard?: boolean }>`
  ${({ isCreditCard }) =>
    isCreditCard
      ? `
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex: 0 49%;
        gap: 10px;

        @media ${breakpoints.mobile} {
          flex: 0 100%;
        };
      `
      : ""};
  background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
  border: ${({ theme }) =>
    `1px solid ${theme.colors.brandColors.brandSecondary}`};
  border-radius: 8px;
  margin-bottom: 8px;
`;

export const TableCardHeader = styled.div`
  ${borderbottom}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;

export const BodyColumnBorder = styled.div`
  ${borderbottom}
  ${bodyColumn}
`;

export const BodyColumnWithoutBorder = styled.div`
  ${bodyColumn}
`;

export const TableCardBody = styled.div`
  display: flex;

  div:nth-child(2) {
    align-items: flex-end;
  }
`;

export const BodyColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-start: 8px;
`;

export const LocationName = styled.span`
  ${({ theme }) => getFontStyle("bodyS", theme)};
`;

export const Text = styled.span<{
  brandColor: boolean;
  upperCase?: boolean;
  isSoldout?: boolean;
}>`
  color: ${({ theme, brandColor, isSoldout }) =>
    isSoldout
      ? theme.colors.textColors.textGrey
      : brandColor
      ? theme.colors.brandColors.brandSecondary
      : theme.colors.textColors.textVariant};
  ${({ theme }) => getFontStyle("captionMedium", theme)};
  text-transform: ${({ upperCase }) => (upperCase ? `uppercase` : "none")};
  text-decoration: ${({ isSoldout }) => (isSoldout ? `line-through` : null)};
`;

export const StyledCoins = styled.div`
  height: 19px;
  text-align: end;
`;

export const StyledHeader = styled.span`
  ${({ theme }) => getFontStyle("caption", theme)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textColors.textGrey};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    cursor: pointer;
    fill: ${(p: any) => p.theme.colors.textColors.textVariant};
  }
`;

export const SoldOut = styled.span`
  color: ${({ theme }) => theme.colors.textColors.textGrey};
  text-transform: uppercase;
`;

export const DevideLine = styled.div`
  flex-basis: 100%;
  height: 0;
`;
