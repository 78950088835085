import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const Wrapper = styled.div<{ isSidebarShown: boolean }>`
  position: relative;
  overflow-x: visible;
  margin-top: 90px;

  @media ${breakpoints.tablet} {
    justify-content: flex-start;
  }
  @media ${breakpoints.mobile} {
    margin-top: 80px;
  }
`;

export const BannerHeader = styled.div`
  width: 100%;
  height: 32px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
  font-size: 24px;
`;
export const BadgesHeader = styled.div`
  width: 100%;
  height: 32px;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
  font-size: 24px;
  @media ${breakpoints.tablet} {
    margin-bottom: 30px;
    padding-bottom: 40px;
  }
  @media ${breakpoints.mobile} {
    margin-top: 20px;
  }
`;
export const BadgesSection = styled.div`
  ${({ theme }) =>
    theme.direction === "rtl" ? "padding-right: 20px;" : "padding-left: 20px;"}
  margin-bottom: 30px;
  overflow-y: hidden;
  margin-top: 40px;

  @media ${breakpoints.tablet} {
    padding-left: 0;
    border-left: none;
    border-right: none;
    margin-top: 400px;
  }
`;

export const CoursesHeader = styled.div`
  width: 629px;
  height: 32px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
  font-size: 24px;
  @media ${breakpoints.mobile} {
    margin-bottom: 28px;
  }
`;

export const MobileCardsSection = styled.div``;
