import { FC } from "react";
import { useHistory } from "react-router-dom";
import Routes from "config/routes";
import { SfButton } from "@successfactory/sf-libraries";
import {
  ErrorWrapper,
  ErrorImageBlock,
  ErrorImage,
  ErrorContentWrapper,
  ErrorContentBlock,
  ErrorHighlightText,
  ErrorHeader,
  ErrorContext,
} from "./GeneralErrorPage.styles";
import { useTranslation } from "react-i18next";

export const GeneralErrorPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ErrorWrapper>
      <ErrorImageBlock>
        <ErrorImage
          src="/assets/error/general-error.svg"
          alt="error-cross-image"
        />
      </ErrorImageBlock>
      <ErrorContentWrapper>
        <ErrorContentBlock>
          <ErrorHeader>Oops!</ErrorHeader>
          <ErrorHighlightText>{t("Something went wrong!")}</ErrorHighlightText>
          <ErrorContext>
            {t(
              "An error appeared. Please refresh the page or try again later."
            )}
          </ErrorContext>
        </ErrorContentBlock>

        <SfButton
          label={t("Go back to Dashboard")}
          onClick={() => history.push(Routes.HOME)}
        />
      </ErrorContentWrapper>
    </ErrorWrapper>
  );
};
