import { ILanguage } from "interface/ILanguage";
import styled from "styled-components";

export const Container = styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
  height: 445px;
  border-radius: 13px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px -2px 0px ${(p: any) => p.theme.colors.brandColors.brandPrimaryVariant};
  background: linear-gradient(
    to bottom,
    ${(p: any) => p.theme.colors.brandColors.brandPrimaryVariant},
    transparent 75%
  );
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    left: 1px;
    top: 0;
    width: 99%;
    height: 100%;
    border-radius: 13px;
    background: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
    z-index: -1;
  }
`;

export const CardHeaderLine = styled.span`
  position: absolute;
  left: 1px;
  right: 3px;
  top: 24%;
  border-top: 1px solid
    ${(p: any) => p.theme.colors.cardsMembership.cardPrimary};
  border-bottom: 2px solid
    ${(p: any) => p.theme.colors.cardsMembership.cardSecondary};
`;

export const CardHeader = styled.div`
  z-index: 4;
`;

export const CardHeaderContent = styled.div`
  padding: 26px 28px;
`;

export const CardHeaderEmptyContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 38px;
`;

export const HeaderCaptionLeft = styled.div`
  text-align: left;
  position: absolute;
  height: 16px;
  left: 28px;
  right: 38px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7d7e8e;
`;

export const HeaderTitleLeft = styled.div`
  text-align: left;
  position: absolute;
  height: 32px;
  left: 28px;
  right: 38px;
  padding-top: 17px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
`;

export const HeaderCaptionRight = styled.div`
  text-align: right;
  position: absolute;
  height: 16px;
  right: 28px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7d7e8e;
`;

export const HeaderTitleRight = styled.div`
  text-align: right;
  position: absolute;
  height: 32px;
  right: 28px;
  padding-top: 17px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
`;

export const HeaderMintingStatus = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  height: 32px;
  line-height: 32px;
  right: 0px;
  padding-top: 48px;
`;

export const HeaderMintingStatusText = styled.span`
  padding: 0px 16px;
  background: rgba(246, 219, 118, 1);
  color: black;
  border-radius: 12px;
`;

export const HeaderMintedStatusText = styled.span`
  padding: 0px 16px;
  background: radial-gradient(
    144.31% 506.23% at -25.61% -117.71%,
    #f6db76 0%,
    #b67f11 100%
  );
  color: black;
  border-radius: 12px;
`;

export const CardBody = styled.div``;

export const CardFooterLine = styled.span`
  position: absolute;
  left: 1px;
  right: 3px;
  top: 80%;
  border-top: 1px solid
    ${(p: any) => p.theme.colors.cardsMembership.cardPrimary};
  border-bottom: 2px solid
    ${(p: any) => p.theme.colors.cardsMembership.cardSecondary};
`;

export const CardFooter = styled.div`
  position: relative;
  padding: 65px 4px;
  text-align: center;
  display: flex;
  justify-content: space-around;
`;

export const FooterBottom = styled.div`
  > :last-child > :last-child {
    display: none;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 256px;
`;

export const FooterWrapper = styled.div`
  margin: 0 2px;
`;

export const BorderDiv = styled.span`
  margin-top: 256px;
  border-left: 2px solid
    ${(p: any) => p.theme.colors.cardsMembership.cardSecondary};
  border-right: 1px solid
    ${(p: any) => p.theme.colors.cardsMembership.cardPrimary};
  width: 3px;
`;

export const FooterCaption = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  height: 20px;
  margin-bottom: 6px;
  color: ${(p: any) => p.theme.colors.textColors.textVariant};
`;

export const FooterTitle = styled.div<{ lang?: ILanguage["code"] }>`
  color: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
  height: 24px;
  font-weight: 500;
  ${({ lang }) =>
    lang !== "es" && lang !== "de" ? "font-size: 14px;" : "font-size: 10px;"}
`;
