import { breakpointMin } from "@successfactory/sf-libraries";
import styled, { css } from "styled-components";

export const CardPoolTitle = styled.div<{ type?: string }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #f6db76;
  margin-bottom: 3px;
  ${breakpointMin.xl} {
    ${({ type }) => (type === "pools-page" ? "margin-top: 14px;" : "")}
  }
`;

const getCardPoolContent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  img {
    height: 110%;
    width: auto;
    margin-top: -50px;
  }
  ${breakpointMin.xl} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CardPoolContent = styled.div<{
  isPoolsPage?: Boolean;
}>`
  ${({ isPoolsPage }) =>
    isPoolsPage ? getCardPoolContent : "position: relative;"}
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  span {
    color: #fff;
  }
`;

export const Percentage = styled.span`
  color: ${({ theme }) => theme.colors.surfaces.buttonSecondary} !important;
`;

export const CardPoolIcon = styled.div`
  ${({ theme }) =>
    theme.direction === "rtl" ? "left:-126px" : "right:-126px"};
  position: absolute;
  top: -70px;
`;

const getPoolsPageTestStyles = (isPoolsPage: Boolean) => {
  return `
${isPoolsPage ? "" : "max-width: 70%;"}
${breakpointMin.md} {
max-width: 170px;
}
${breakpointMin.xl} {
  max-width: 100%;
}
`;
};

export const TextWrapper = styled.div<{
  isPoolsPage?: Boolean;
}>`
  text-transform: uppercase;
  ${({ isPoolsPage }) =>
    isPoolsPage ? getPoolsPageTestStyles(isPoolsPage) : ""}
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  color: ${(p: any) => p.theme.colors.textColors.textWhite};
  a {
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #f6db76;
    text-transform: initial;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Container = styled.div`
  div {
    img {
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      pointer-events: none;
    }
  }

  :last-child {
    img {
      margin-right: 20px;
      ${({ theme }) =>
        theme.direction === "rtl" ? "margin-left: 20px" : "margin-right: 20px"}
    }
  }
`;

export const CardPoolIconPoolSection = styled.div`
  img {
    width: 240px;
    ${breakpointMin.md} {
      width: 250px;
    }
    ${breakpointMin.xl} {
      width: 700px;
    }
  }
  position: absolute;
  top: 10px;
  /* right: -60px; */

  ${({ theme }) =>
    theme.direction === "rtl" ? "left: -122px;" : "right: -122px;"}

  ${breakpointMin.md} {
    top: 5px;
    ${({ theme }) =>
      theme.direction === "rtl" ? "left: -20px;" : "right: -20px;"}
  }
  ${breakpointMin.xl} {
    top: -175px;
    ${({ theme }) =>
      theme.direction === "rtl" ? "left: 26px;" : " right: 26px;"}
  }
`;

export const ViewDetailsButton = styled.a`
  font-size: 12px;
  color: #f6db76;
  cursor: pointer;
  text-decoration: underline;
  text-transform: capitalize;
`;
