import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SfToggleSwitcher } from "@successfactory/sf-libraries";
import LanguageDropdown from "components/TopHeader/LanguageDropdown";
import { IButton } from "interface/IButton";
import { TheiaThemeDark, TheiaThemeLight } from "styles/themes/Theia";
import { useFeatureEnableForUser } from "util/isFeatureEnableForUser";
import sStorage from "util/sStorage";

import { useAppDispatch, useAppState } from "AppContext";

import routes from "config/routes";
import ShopifyButtonProperties from "config/shopifyButtonProperties";

import { ReactComponent as Logout } from "assets/sidebar-icons/logout-gold.svg";

import * as S from "./Sidebar.styles";
import SidebarLinkItems from "./SidebarItems";
import { SidebarLink } from "./SidebarLink";

import BannerButton from "../BannerButton/BannerButton";

const Sidebar: React.FC = () => {
  const appDispatch: Function = useAppDispatch();
  const { t } = useTranslation();
  const { isSidebarShown, isDirection } = useAppState();

  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 704);

  const { isFeatureFlagLoaded, isFeatureEnabled } =
    useFeatureEnableForUser("shopify-banner");

  const {
    isFeatureFlagLoaded: isVideoFeatureLoaded,
    isFeatureEnabled: isFeatureVideoEnabled,
  } = useFeatureEnableForUser("the-view-video");

  const ButtonConfigs: IButton[] = [];

  if (isFeatureFlagLoaded && isFeatureEnabled) {
    ButtonConfigs.push({
      ...ShopifyButtonProperties[0],
      isSidebarShown,
    });
  }

  const sidebarLinkItemsGroup = useMemo(
    () =>
      SidebarLinkItems.map((linkItem: any, index: number) => {
        const indexToRemove = SidebarLinkItems.findIndex(
          (el) => el.linkTo === routes.VIDEOS
        );
        if (
          isVideoFeatureLoaded &&
          !isFeatureVideoEnabled &&
          index === indexToRemove
        ) {
          return <></>;
        }
        return <SidebarLink linkItem={linkItem} key={index} />;
      }),
    [isFeatureVideoEnabled, isVideoFeatureLoaded]
  );

  const toggle = useCallback(
    (checked: boolean) => {
      const theme = checked ? "TheiaThemeDark" : "TheiaThemeLight";
      sStorage.set("theme", theme);
      appDispatch({
        type: "theme",
        data: { theme: checked ? TheiaThemeLight : TheiaThemeDark },
      });
    },
    [appDispatch]
  );

  const handleLogout = () => {
    appDispatch({
      type: "logout",
      message: "logout",
    });
  };

  useEffect(() => {
    const handleWindowResize = () => setIsMobile(window.innerWidth <= 704);

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      appDispatch({
        type: "sidebarShown",
        data: false,
      });
    }
  }, [isMobile]);

  const theme = sStorage.get("theme");
  const logoColor = theme === "TheiaThemeLight" ? "black" : "white";

  const sidebarStateProps = {
    closed: !isSidebarShown,
    direction: isDirection,
  };

  const sideBarShownProps = {
    closed: sidebarStateProps.closed,
  };

  return (
    <S.SidebarSet isLanguageOpen={isLanguageOpen} {...sidebarStateProps}>
      <S.SidebarContainer {...sideBarShownProps}>
        <S.SidebarFlex space={"space-around"} {...sideBarShownProps}>
          <S.BrandLogo {...sidebarStateProps}>
            <S.ViewLogo
              src={`/assets/re-branding/logos/new-view-logo-${logoColor}.svg`}
              alt="viewlogo"
              isMobile={isMobile}
              {...sideBarShownProps}
            />
          </S.BrandLogo>
        </S.SidebarFlex>
        <S.AllContent>
          <>
            <S.SidebarNavlivkContainer {...sidebarStateProps}>
              {sidebarLinkItemsGroup}
            </S.SidebarNavlivkContainer>
            <S.SidebarAnimationContainer {...sidebarStateProps}>
              {ButtonConfigs.map((buttonConfig, index: number) => (
                <BannerButton {...buttonConfig} key={index.toString()} />
              ))}
            </S.SidebarAnimationContainer>
          </>
          {window.innerWidth < 960 && (
            <S.LanguageDropdownWrapper>
              <LanguageDropdown setIsLanguageOpen={setIsLanguageOpen} />
            </S.LanguageDropdownWrapper>
          )}

          <S.SidebarAnimationContainer {...sidebarStateProps} bottom={true}>
            <S.SidebarBottomGroup
              className={`bottom-group logout-button`}
              onClick={handleLogout}
              {...sidebarStateProps}
            >
              <Logout />
              <S.LogoutButton {...sideBarShownProps}>
                {t("Log out")}
              </S.LogoutButton>
            </S.SidebarBottomGroup>
            <S.MobileToggleWrapper>
              <SfToggleSwitcher
                onChange={toggle}
                check={
                  sStorage.get("theme") === "TheiaThemeLight" ? true : false
                }
              />
            </S.MobileToggleWrapper>
          </S.SidebarAnimationContainer>
        </S.AllContent>
      </S.SidebarContainer>
    </S.SidebarSet>
  );
};

export default Sidebar;
