import Joi from "joi";

interface SF_Object_Props {
  [key: string]: any; //{[key:string]:any}
}

const getBasicErrorMessage = (name: string) => {
  return {
    "string.base": `"${name}" should be a type of 'text'`,
    "string.empty": `Please fill in your ${name}`,
    "string.min": `"${name}" should have a minimum length of {#limit}`,
    "any.required": `Please fill in your ${name}`,
  };
};

// TODO: add custom messages:
const SF_Form_ErrorMessages: SF_Object_Props = {
  email: {
    "string.base": `"Email" should be a type of 'text'`,
    "string.empty": `Please fill in your email`,
    "string.min": `"Email" should have a minimum length of {#limit}`,
    "string.email": `Please enter a valid email`,
    "any.required": `Please fill in your email`,
  },
  password: {
    "string.base": `"Password" should be a type of 'text'`,
    "string.empty": `Please fill in your password`,
    "string.min": `"Password" should have a minimum length of {#limit}`,
    "pattern.base": `"Password" should be a valid password`,
    "pattern.match": "effef",
    // pattern.: "hii",
    "any.required": `Please fill in your password`,
  },

  sfusername: {
    "string.empty": `Please fill in your SF username`,
  },

  search: {
    "string.empty": `"Search Field" cannot be an empty field`,
  },
  textarea: {
    "string.empty": `"Text area" cannot be an empty field`,
  },

  goal: {
    "any.require": `"Goal" is a required field`,
  },

  taskname: {
    "any.require": `"Task name" is a required field`,
  },

  lastname: getBasicErrorMessage("Last name"),

  firstname: getBasicErrorMessage("First name"),

  phone: getBasicErrorMessage("Phone number"),

  referral: getBasicErrorMessage("Referral code"),
};

export const SF_FormAddErrorMessages = (
  messages: SF_Object_Props,
  validators: SF_Object_Props
) => {
  for (let name in validators) {
    if (messages.hasOwnProperty(name))
      validators[name] = validators[name].messages(messages[name]);
  }
  return validators;
};

const formValidators: SF_Object_Props = SF_FormAddErrorMessages(
  SF_Form_ErrorMessages,
  {
    email: Joi.string().email({ tlds: { allow: false } }),
    password: Joi.string().pattern(
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*\d]{8,}$/)
    ),

    confirmpassword: Joi.string(),

    search: Joi.string(),
    textarea: Joi.string(),
    dropdown: Joi.string(),
    dropdownwithicon: Joi.string(),

    sfusername: Joi.string(),
    firstname: Joi.string(),
    phone: Joi.string(),
    lastname: Joi.string(),
    referral: Joi.string(),

    language: Joi.string(),
    country: Joi.string(),

    goal: Joi.number(),

    title: Joi.string(),
    description: Joi.string(),
    date: Joi.string(),
    time: Joi.string(),
  }
);

export default formValidators;
