import { FC, Fragment } from "react";
import {
  PageGeneralDescription,
  PageGeneralImage,
  PageGeneralTitle,
  PageGeneralTitleContainer,
  PageGeneralTitleWrapper,
} from "./InfinityPoolsPage.styles";
import { useTranslation } from "react-i18next";

interface InfinityPoolsGeneralPageInfoProps {
  image: string;
  title: string;
  description: string;
}
const InfinityPoolsGeneralPageInfo: FC<InfinityPoolsGeneralPageInfoProps> = ({
  image,
  title,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <PageGeneralTitleContainer>
        <PageGeneralImage src={image} alt=""></PageGeneralImage>
        <PageGeneralTitleWrapper>
          <PageGeneralTitle>{t(title)}</PageGeneralTitle>
          <PageGeneralDescription>{t(description)}</PageGeneralDescription>
        </PageGeneralTitleWrapper>
      </PageGeneralTitleContainer>
    </>
  );
};

export default InfinityPoolsGeneralPageInfo;
