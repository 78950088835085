import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  breakpointsMax,
  matchesBreakpoint,
  SfToggleSwitcher,
} from "@successfactory/sf-libraries";

import useUserApi from "api/useUserApi";
import { useAppDispatch, useAppState } from "AppContext";

import { getPageName } from "util/getPageName";
import sStorage from "util/sStorage";

import { TheiaThemeDark, TheiaThemeLight } from "styles/themes/Theia";

import { ReactComponent as Arrow } from "assets/sidebar-icons/left-square.svg";

import LanguageDropdown from "./LanguageDropdown";
import * as S from "./TopHeader.styles";

const TopHeader: React.FC = () => {
  const appDispatch: Function = useAppDispatch();
  const { isSidebarShown, isDirection } = useAppState();
  const { updateUserTheme, userProfile } = useUserApi(true);
  const location = useLocation();
  const { t } = useTranslation();

  const pageName = getPageName(location.pathname);
  const isMobile = matchesBreakpoint(breakpointsMax.md, "max-width");

  const toggle = (checked: boolean) => {
    checked
      ? sStorage.set("theme", "TheiaThemeLight")
      : sStorage.set("theme", "TheiaThemeDark");
    appDispatch({
      type: "theme",
      data: { theme: checked ? TheiaThemeLight : TheiaThemeDark },
    });
    updateUserTheme(checked ? "TheiaThemeLight" : "TheiaThemeDark");
  };

  useEffect(() => {
    if (userProfile?.backgroundMode) {
      const theme =
        userProfile?.backgroundMode === "TheiaThemeLight"
          ? TheiaThemeLight
          : TheiaThemeDark;
      appDispatch({
        type: "theme",
        data: { theme },
      });
      appDispatch({
        type: "user",
        data: userProfile,
      });
    }
  }, [appDispatch, userProfile]);

  return (
    <S.TopHeaderContainer sidebar={isSidebarShown} direction={isDirection}>
      <S.TopHeaderWrapper>
        <S.RtlSidebar
          isSidebarShown={isSidebarShown}
          isRtl={isDirection === "rtl"}
        >
          <button
            onClick={() => {
              appDispatch({
                type: "sidebarShown",
                data: !isSidebarShown,
              });
            }}
          >
            <S.TopHeaderImage isSidebarShown={isSidebarShown}>
              <Arrow />
            </S.TopHeaderImage>
            <S.PageName>{t(pageName)}</S.PageName>
          </button>
        </S.RtlSidebar>
        <S.TopHeaderMenu>
          <S.ToggleWrapper>
            <SfToggleSwitcher
              onChange={toggle}
              checked={sStorage.get("theme") === "TheiaThemeLight"}
            />
          </S.ToggleWrapper>
          {!isMobile && <LanguageDropdown />}
        </S.TopHeaderMenu>
      </S.TopHeaderWrapper>
    </S.TopHeaderContainer>
  );
};

export default TopHeader;
