import { useState } from "react";
import { useTranslation } from "react-i18next";

import { baseUrl, getHeader } from "api/useApi";
import axios from "axios";
import TheiaSvgIcons from "components/TheiaSvgIcons/TheiaSvgIcons";
import { FormsContainer } from "pages/login/PublicTemplate.styles";

import { useAppDispatch, useAppState } from "AppContext";

import Content from "./Common/Content";
import Feedback from "./Common/Feedback";

import LoginForm from "../Forms/LoginForm";

const LoginComponent = () => {
  const { theme } = useAppState();
  const appDispatch: Function = useAppDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState({
    hasError: false,
    message: "",
  });

  const onSubmit = async (data: any) => {
    setError((prevError) => ({ ...prevError, hasError: false }));
    try {
      const result = await axios.post(baseUrl + "/login", data, getHeader());

      if (result.data && result.data.success) {
        const token = result.data.token;
        appDispatch({
          type: "login",
          message: "login",
          data: { token: token },
        });
      }
    } catch (error: any) {
      setError({ hasError: true, message: error.response.data.error });
      console.error("Response data:", error.response.data);
    }
  };

  return (
    <FormsContainer>
      <Content
        title={t("Login")}
        description={t("Welcome back! Let’s access your account.")}
      />

      {error.hasError && (
        <Feedback
          message={t(error.message)}
          icon={
            <TheiaSvgIcons
              name={"warning"}
              fill={theme.colors.helpers.negativePrimary}
              width={20}
              height={20}
            />
          }
        />
      )}

      <LoginForm onSubmit={onSubmit} />
    </FormsContainer>
  );
};

export default LoginComponent;
