import React from "react";

import RegionSelect from "./RegionSelect";
import IRegionVoteData from "interface/IRegionVoteData";
import {
  Description,
  RegionCardWrapper,
  RegionVote,
} from "./RegionSelect.styles";
import { useTranslation } from "react-i18next";
import { stringToHTML } from "util/stringToHTML";

interface CountryVoteProps {
  countries: Array<IRegionVoteData>;
  setSelectedRegions: Function;
  selectedRegions: Array<IRegionVoteData>;
}

const RegionSelectVote: React.FC<CountryVoteProps> = ({
  setSelectedRegions,
  selectedRegions,
  countries,
}) => {
  const { t } = useTranslation();
  const onLocationChanged = (region: IRegionVoteData, selected: boolean) => {
    if (!region) return;
    const newRegions = [...selectedRegions];
    if (!selected) {
      const i = newRegions.indexOf(region);
      if (i > -1) newRegions.splice(i, 1);
    } else {
      newRegions.push(region);
    }
    setSelectedRegions(newRegions);
  };

  return (
    <>
      <Description>
        {stringToHTML(
          t("What are the next <span>5 regions</span> that we should add?")
        )}
        <div>{t("You can select 5 regions from the 10 listed below:")}</div>
      </Description>
      <RegionVote>
        {countries.map((region: IRegionVoteData, key: number) => {
          return (
            <RegionCardWrapper
              key={key}
              maxSelected={selectedRegions.length === 5}
              alreadySelected={selectedRegions.includes(region)}
            >
              <RegionSelect
                region={region}
                onChange={onLocationChanged}
                selectedRegions={selectedRegions}
              />
            </RegionCardWrapper>
          );
        })}
      </RegionVote>
    </>
  );
};

export default RegionSelectVote;
