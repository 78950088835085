import { ModalType } from "../types/modalScenarioType";

interface ICheckboxes {
  label: string;
  checked: boolean;
  isRequired?: boolean;
}

export interface ModalContent {
  type: ModalType;
  title: string;
  text: string[];
  ctas?: string[];
  checkboxes?: ICheckboxes[];
}

export const modalContents: ModalContent[] = [
  {
    type: ModalType.CONFIRMATION,
    title: "modal.confirmLandPurchase", // Confirm your land purchase
    text: [
      "modal.confirmPurchaseDescription1", // Please confirm that you still want to purchase the land. If you reject it, your reservation will be discarded.
      "modal.confirmPurchaseDescription2",
      "modal.commonReservation", // Reservation made for <span>%plotsRequested%</span> plots.
      "modal.requiredCredits", // Required The View Credits: <span>%requiredCredits%</span>
      "modal.availableCredits", // Available The View Credits: <span>%currentCredits%</span>
    ],
    checkboxes: [
      {
        label: "modal.confirmPurchaseCheckbox", // I hereby confirm that I want to purchase <span>%plotsRequested%</span> plot/s of The View land.
        checked: false,
        isRequired: true,
      },
    ],
    ctas: ["modal.reject", "modal.confirm"],
  },
  {
    type: ModalType.REJECTION,
    title: "modal.landPurchaseRejected", // Land purchase rejected
    text: [
      "modal.landPurchaseRejectedDescription1", // We hereby confirm that no The View land will be purchased with your The View Credits.
      "modal.landPurchaseRejectedDescription2", // Your amount of The View Credits will remain unchanged.
      "modal.landPurchaseRejectedDescription3", // If you still plan to buy The View land, please go to <a href="https://land.theview.world/" target="_blank">https://land.theview.world/</a> and wait for updates from our side.
    ],
    ctas: ["modal.close"],
  },
  {
    type: ModalType.CONGRATULATIONS,
    title: "modal.congratulations", // Congratulations!
    text: [
      "modal.congratulationsDescription1", // We hereby confirm that we have received your purchase request for <span>%plotsRequested%</span> plot/s.
      "modal.congratulationsDescription2", // Please note that the process itself will take some time.
      "modal.congratulationsDescription3", // We will inform you by Email once the process is completed!
    ],
    ctas: ["modal.close"],
  },
  {
    type: ModalType.LAND_PURCHASE_NOT_POSSIBLE,
    title: "modal.landPurchaseNotPossible", // Land purchase not possible
    text: [
      "modal.landPurchaseNotPossibleDescription1", // You made a reservation to buy The View land at <a href="https://land.theview.world/" target="_blank">https://land.theview.world/</a>
      "modal.landPurchaseNotPossibleDescription2", // Unfortunately, you do not owe sufficient The View Credits to do a purchase. Therefore your reservation has been discarded.
      "modal.commonReservation", // Reservation made for <span>%plotsRequested%</span> plots.
      "modal.requiredCredits", // Required The View Credits: <span>%requiredCredits%</span>
      "modal.availableCredits", // Available The View Credits: <span>%currentCredits%</span>
    ],
    ctas: ["modal.close"],
  },
  {
    type: ModalType.CONFIRM_PARTIAL_PURCHASE,
    title: "modal.confirmPartialLandPurchase", // Confirm your partial land purchase
    text: [
      "modal.confirmPartialLandPurchaseDescription1", // You made a reservation to buy The View land at https://land.theview.world/.
      "modal.confirmPartialLandPurchaseDescription2", // Unfortunately, you reserved more land plots than are available. Please confirm if you want to make a partial purchase. If you reject it, your reservation will be discarded.
      "modal.commonReservation", // Reservation made for <span>%plotsRequested%</span> plots.
      "modal.plotsRemaining", // Plots still available: <span>%plotsAssigned%</span>
      "modal.requiredCredits", // Required The View Credits: <span>%requiredCredits%</span>
      "modal.availableCredits", // Available The View Credits: <span>%currentCredits%</span>
    ],
    ctas: ["modal.reject", "modal.confirm"],
    checkboxes: [
      {
        label: "modal.confirmPurchaseCheckbox", // I hereby confirm that I want to purchase <span>%plotsRequested%</span> plot/s of The View land.
        checked: false,
        isRequired: true,
      },
    ],
  },
  {
    type: ModalType.FAILED_PURCHASE_CUTOFF,
    title: "modal.failedPurchaseCutoff", // Confirm your partial land purchase
    text: [
      "modal.failedPurchaseCutoffDescription1", // Unfortunately, all land plots were reserved already!
      "modal.failedPurchaseCutoffDescription2", // Please wait until the next land gets released and try it again!
      "modal.commonReservation", // Reservation made for <span>%plotsRequested%</span> plots.
      "modal.availablePlots", // Available plots: <span>0</span>
    ],
    ctas: ["modal.close"],
  },
  {
    type: ModalType.INSUFFICIENT_CREDITS_FOR_FULL_REQUEST,
    title: "modal.insufficientCreditsForFullRequest", // Confirm your partial land purchase
    text: [
      "modal.insufficientCreditsForFullRequestDescription1", // Unfortunately, we recognized that you reserved more plots than you have available The View Credits.
      "modal.insufficientCreditsForFullRequestDescription2", // Please confirm if you want to make a partial purchase. If you reject it, your reservation will be discarded.
      "modal.insufficientCreditsForFullRequestDescription3", // Please confirm if you want to make a partial purchase. If you reject it, your reservation will be discarded.
      "modal.commonReservation", // Reservation made for <span>%plotsRequested%</span> plots.
      "modal.requiredCredits", // Required The View Credits: <span>%requiredCredits%</span>
      "modal.availableCredits", // Available The View Credits: <span>%currentCredits%</span>
    ],
    checkboxes: [
      {
        label: "modal.confirmPurchaseCheckbox", // I hereby confirm that I want to purchase <span>%plotsRequested%</span> plot/s of The View land.
        checked: false,
        isRequired: true,
      },
    ],
    ctas: ["modal.reject", "modal.confirm"],
  },
  {
    type: ModalType.CONFIRMATION_PHASE_2,
    title: "modal.confirmationPhaseTwo", //"Confirm your land purchase - Phase 2"
    text: [
      "modal.confirmationPhaseTwoDescription1", //"You made a request to purchase The View land plots in Phase 1 which is already sold out. Now you have the option to be part of Phase 2! ",
      "modal.confirmationPhaseTwoDescription2", //"Land plots will be placed randomly.",
      "modal.availableCredits", //"Available The View Credits: <span>%currentCredits%</span>",
      "modal.confirmationPhaseTwoDescription3", //"Amount of plots that can be purchased: <span>%maxPurchases%</span>",
    ],
    checkboxes: [
      {
        label: "modal.confirmPurchaseCheckbox", // I hereby confirm that I want to purchase <span>%plotsRequested%</span> plot/s of The View land.
        checked: false,
        isRequired: true,
      },
    ],
    ctas: ["modal.reject", "modal.confirm"],
  },
  {
    type: ModalType.CONFIRMATION_PHASE_2_WALLETNOTFOUND,
    title: "modal.congratulations", // Congratulations!
    text: [
      "modal.landPurchase2WalletNotFoundDescription1", // Your Wallet ID was not found in the blockchain, are you sure you want to purchase land plots to this Wallet? By doing so, your NFTs might get lost.
    ],
    ctas: ["modal.reject", "modal.continue"],
  },
  {
    type: ModalType.CONFIRMATION_PHASE_2_REJECTION,
    title: "modal.landPurchaseRejected", // Land purchase rejected
    text: [
      "modal.landPurchase2RejectedDescription1", // We hereby confirm that no The View land will be purchased with your The View Credits. Your amount of The View Credits will remain unchanged.
    ],
    ctas: ["modal.close"],
  },
  {
    type: ModalType.CONFIRMATION_PHASE_2_CONGRATULATIONS,
    title: "modal.congratulations", // Congratulations!
    text: [
      "modal.landPurchase2congratulationsDescription1", // We hereby confirm that we have received your purchase request for <span>%plotsRequested%</span> plot/s.
      "modal.landPurchase2congratulationsDescription2", // There will be a time frame of 2 weeks for processing, please have patience until you see the land plots in your wallet..
    ],
    ctas: ["modal.close"],
  },
  {
    type: ModalType.ERROR_UNPROCESSABLE_REQUEST,
    title: "modal.error.unprocessable", // Sorry!
    text: [
      "modal.error.Description1", // We were not able to handle your request.
      "modal.error.Description2", // If you have any questions, please contact customer support!
    ],
    ctas: ["modal.close"],
  },
];
