import { iTheme } from "./iTheme";

export const TheiaText = {
  h1: {
    weight: 600,
    size: "48px",
    lineHeight: "56px",
  },
  h2: {
    weight: 600,
    size: "36px",
    lineHeight: "44px",
  },
  h3: {
    weight: 600,
    size: "30px",
    lineHeight: "40px",
  },
  h4: {
    weight: 600,
    size: "24px",
    lineHeight: "32px",
  },
  h5: {
    weight: 600,
    size: "20px",
    lineHeight: "30px",
  },
  captionCaps: {
    weight: 700,
    size: "10px",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  captionCapsMedium: {
    weight: 700,
    size: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
  },
  caption: {
    weight: 400,
    size: "12px",
    lineHeight: "16px",
    font: "'Open Sans', sans-serif",
  },
  captionMedium: {
    weight: 600,
    size: "14px",
    lineHeight: "20px",
    font: "'Open Sans', sans-serif",
  },
  bodyL: {
    weight: 600,
    size: "20px",
    lineHeight: "28px",
    font: "'Open Sans', sans-serif",
  },
  bodyM: {
    weight: 600,
    size: "16px",
    lineHeight: "24px",
    font: "'Open Sans', sans-serif",
  },
  bodyS: {
    weight: 400,
    size: "14px",
    lineHeight: "24px",
    font: "'Open Sans', sans-serif",
  },
  bodyA: {
    weight: 400,
    size: "16px",
    lineHeight: "24px",
    font: "'Open Sans', sans-serif",
  },
};

export const TheiaThemeDark: iTheme = {
  name: "TheiaThemeDark",
  colors: {
    brandColors: {
      brandPrimary: "#9BDAEF",
      brandSecondary: "#F6DB76",
      brandPrimaryVariant: "#6BAABF",
      brandSecondaryTone: "#7C7758",
      brandSecondaryVariant: "#784E00",
      brandVariantDark: "#989898",
      brandVariantLight: "#FFFFFF",
      backgroundTone: "#262626",
      backgroundDark: "#FFFFFF",
      darkBackgroundTone: "rgba(23, 29, 41, 0.72)",
      mediumBackgroundTone: "rgba(228, 228, 228, 0.1)",
      lighterBackgroundTone: "#7D7C7A",
      brandTableCellTone: "rgba(228, 228, 228, 0.05)",
      brandTableHeadTone: "rgba(152, 152, 152, 0.5)",
      tableAccordionContainer: "#789096",
    },
    textColors: {
      textBlack: "#141414",
      textLightGrey: "#EBEBEB",
      textGrey: "#989898",
      textWhite: "#FFFFFF",
      textVariant: "#EBEBEB",
      EmptyCardTextVariant: "#FFFFFF",
    },
    helpers: {
      positivePrimary: "#2EAA79",
      positiveSecondary: "#DBF6EC",
      warningPrimary: "#FDB100",
      warningSecondary: "#FFF1D1",
      negativePrimary: "#F84C26",
      negativeSecondary: "#FEDFD8",
      informationPrimary: "#6F6BF8",
      informationSecondary: "#E5E4FE",
      promoPrimary: "#00C2EE",
      promoSecondary: "#CEF6FF",
      positivePrimaryShadow: "#fff",
    },
    surfaces: {
      surfaceMain: "#141414",
      buttonPrimary: "#6BAABF",
      buttonSecondary: "#9BDAEF",
      surfacePrimary: "#989898",
      surfaceSecondary: "#191B21",
      surfaceTertiary: "#242424",
      surfaceBackground: "#262626",
      surfaceVariant: "#141414",
      surfaceVariantTwo: "#F6DB76",
      surfaceTransparencyBgPrimary: "rgba(20, 20, 20, 0.8)",
      surfaceTransparencyPrimary: "rgba(152, 152, 152, 0.3)",
      surfaceTransparencySecondary: "rgba(152, 152, 152, 0.15)",
      surfaceTransparencyBlur: "rgba(20, 20, 20, 0.5)",
      surfacePrimaryShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      surfaceSecondaryShadow: "0px 30px 50px rgba(0, 0, 0, 0.25)",
      surfaceHighlight: "rgba(107, 170, 191, 0.4)",
      cardBorderColor: "#F6DB76",

      cocMemberBackground: "#B57E10",
    },
    cardsMembership: {
      cardPrimary: "#000000",
      cardSecondary: "#2c2c2c",
    },
  },

  typography: {
    text: TheiaText,
    family: "'Poppins', sans-serif",
  },
};

export const TheiaThemeLight: iTheme = {
  name: "TheiaThemeLight",
  colors: {
    brandColors: {
      brandPrimary: "#6BAABF",
      brandSecondary: "#784E00",
      brandPrimaryVariant: "#9BDAEF",
      brandSecondaryTone: "#7C7758",
      brandSecondaryVariant: "#F6DB76",
      brandVariantDark: "#989898",
      brandVariantLight: "#FFFFFF",
      backgroundTone: "#E4E4E4",
      backgroundDark: "#000000",

      darkBackgroundTone: "rgba(152, 152, 152, 0.3)",
      mediumBackgroundTone: "#EBEBEB",
      lighterBackgroundTone: "#7D7C7A",
      brandTableCellTone: "rgba(248, 248, 248, 0.3)",
      brandTableHeadTone: "#EBEBEB",
      tableAccordionContainer: "#784E00",
    },
    textColors: {
      textBlack: "#141414",
      textLightGrey: "#EBEBEB",
      textGrey: "#989898",
      textWhite: "#FFFFFF",
      textVariant: "#141414",
      EmptyCardTextVariant: "#784E00",
    },
    helpers: {
      positivePrimary: "#2EAA79",
      positiveSecondary: "#DBF6EC",
      warningPrimary: "#FDB100",
      warningSecondary: "#FFF1D1",
      negativePrimary: "#F84C26",
      negativeSecondary: "#FEDFD8",
      informationPrimary: "#6F6BF8",
      informationSecondary: "#E5E4FE",
      promoPrimary: "#00C2EE",
      promoSecondary: "#CEF6FF",
      positivePrimaryShadow: "#000",
    },
    surfaces: {
      surfaceMain: "#141414",
      buttonPrimary: "#6BAABF",
      buttonSecondary: "#9BDAEF",
      surfacePrimary: "#EBEBEB",
      surfaceSecondary: "#F8F8F8",
      surfaceTertiary: "#DBDBDB",
      surfaceBackground: "rgba(245, 245, 245, 0.8)",
      surfaceVariant: "#EBEBEB",
      surfaceVariantTwo: "#F6DB76",
      surfaceTransparencyPrimary: "rgba(152, 152, 152, 0.3)",
      surfaceTransparencyBgPrimary: "rgba(248, 248, 248, 0.8)",
      surfaceTransparencySecondary: "rgba(248, 248, 248, 0.3)",
      surfaceTransparencyBlur: "rgba(228, 225, 225, 0.5)",
      surfacePrimaryShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      surfaceSecondaryShadow: "0px 30px 50px rgba(0, 0, 0, 0.25)",
      surfaceHighlight: "rgba(107, 170, 191, 0.4)",
      cardBorderColor: "#EBEBEB",

      cocMemberBackground: "#B57E10",
    },
    cardsMembership: {
      cardPrimary: "#cfcfcf",
      cardSecondary: "#DBDBDB",
    },
  },
  typography: {
    text: TheiaText,
    family: "'Poppins', sans-serif",
  },
};

export const TheiaThemes = {
  TheiaThemeLight: TheiaThemeLight,
  TheiaThemeDark: TheiaThemeDark,
};
