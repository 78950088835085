import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAxiosGet } from "api/useApi";
import BannerButton from "components/BannerButton/BannerButton";
import web3 from "web3";

import { useAppState } from "AppContext";

import FeedbackDialogSwitch from "./FeedbackDialogSwitch";
import {
  FieldValidator,
  InitialState,
  PropsByStatusType,
  WalletProps,
} from "./IWallet";
import * as S from "./SharedStyles.styles";
import WalletFormCheckbox from "./WalletFormCheckbox";
import WalletFormItems from "./WalletFormItems";

const validateEmail: FieldValidator = (email) =>
  email !== "" &&
  email.length <= 50 &&
  /^[^\s@]{1,50}@[^\s@]+\.[^\s@]{1,50}$/.test(email);
export const validateWallet: FieldValidator = (wallet) => {
  try {
    web3.utils.toChecksumAddress(wallet);
    return true;
  } catch (error) {
    return false;
  }
};

const commonInitialState = {
  valid: true,
  errors: [],
  dirty: 0,
  filty: 15,
};
const getInitialState = (email: string, wallet: string): InitialState => ({
  wallet: {
    value: wallet,
    validator: validateWallet,
    ...commonInitialState,
    name: "Wallet ID!",
  },
  email: {
    value: email,
    validator: validateEmail,
    ...commonInitialState,
    name: "Email Address!",
  },
});

const Wallet = ({
  informationText,
  onSubmit,
  handleModalClose,
  region,
  value,
  results,
}: WalletProps) => {
  const { t } = useTranslation();
  const { user } = useAppState();

  const [getWalletsData, getWalletsDataResult, getWalletsDataStatus] =
    useAxiosGet("/nfts/blockchain-check");

  const getLastUsed = (collection: any[], defaultValue: string) =>
    collection.find((item) => item.lastUsed)?.address || defaultValue;

  const userWallet = getLastUsed(
    user?.wallets,
    user?.wallets[0]?.address || ""
  );
  const userEmail = getLastUsed(user?.nftEmails, user?.email);

  const [walletData, setWalletData] = useState<InitialState>(
    getInitialState(userEmail, userWallet)
  );

  const calculateUnits = useCallback(() => {
    const totalUnits = results.reduce((total, result) => {
      const unit = result.requestedAllocations.reduce(
        (sum: number, item: { unit_type: number }) => {
          const { unit_type } = item;
          return sum + unit_type;
        },
        0
      );
      return total + unit;
    }, 0);

    const unitText = totalUnits / (value || 0);
    const confirmationText = t(
      `By clicking 'Connect' I confirm, that I want to mint {{unitText}} units of {{region}}.`,
      { unitText, region }
    );

    return (
      <S.InformationTextWrapper>
        <S.InformationText>{confirmationText}</S.InformationText>
      </S.InformationTextWrapper>
    );
  }, [region, results, t, value]);

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [emailChanged, setEmailChanged] = useState<boolean>(false);

  const [checkboxes, setCheckboxes] = useState({
    saveWalletId: false,
    saveEmail: false,
    privacyPolicy: false,
    termsAndConditions: false,
    termsOfUse: false,
  });

  const handleCheckboxChange = (checkboxName: string, checked: boolean) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: checked,
    }));
  };
  const onChange = useCallback(
    (fieldName: keyof InitialState, value: string) => {
      const fieldState = { ...walletData[fieldName] };

      fieldState.valid = fieldState.validator(value);
      fieldState.value = value;
      fieldState.dirty = fieldState.dirty + 1;
      fieldState.errors =
        !fieldState.valid && fieldState.dirty >= fieldState.filty
          ? [t(`Please enter a valid ${fieldState.name}`)]
          : [];

      const newState = { ...walletData, [fieldName]: fieldState };

      setWalletData(newState);
      setEmailChanged(
        fieldState.name === "Email Address!" &&
          fieldState.value !== userEmail &&
          fieldState.value !== ""
      );
    },
    [walletData, t, userEmail]
  );

  const hasExistingWalletOrEmails =
    (user?.wallets?.length || user?.nftEmails?.length) > 0;

  const labelTextNext = hasExistingWalletOrEmails
    ? t("Start Minting")
    : t("Connect");

  const walletIdProps = {
    data: walletData.wallet,
    onChange,
    title: t("Enter your Wallet ID"),
    group: "wallet",
    placeHolder: t("Enter your Wallet ID"),
    user: user,
    type: "wallets",
  };

  const userEmailProps = {
    data: walletData.email,
    onChange,
    title: t("Email address"),
    group: "email",
    placeHolder: t("Enter your Email Address"),
    user: user,
    type: "nftEmails",
  };

  const saveWalletProps = {
    label: t("Save Wallet ID for next use"),
    checked: checkboxes.saveWalletId,
    onChange: (checked: boolean) =>
      handleCheckboxChange("saveWalletId", checked),
  };

  const saveEmailProps = {
    label: t("Save Email Address for next use"),
    checked: checkboxes.saveEmail,
    onChange: (checked: boolean) => handleCheckboxChange("saveEmail", checked),
  };

  const savePolicyProps = {
    label: t("I have read and agree to the"),
    isPrivacyPolicy: true,
    checked: checkboxes.privacyPolicy,
    privacyPolicyUrl: "assets/compensation-plans/compensation-plan-en.pdf",
    onChange: (checked: boolean) =>
      handleCheckboxChange("privacyPolicy", checked),
  };

  const saveTermsOfUseProps = {
    label: t("I have read and agree to the "),
    checked: checkboxes.termsOfUse,
    isTermsOfUse: true,
    termsOfUseUrl: "assets/compensation-plans/compensation-plan-en.pdf",
    onChange: (checked: boolean) => handleCheckboxChange("termsOfUse", checked),
  };

  const saveTermsProps = {
    label: t("I have read and agree to the"),
    checked: checkboxes.termsAndConditions,
    isTermsAndConditions: true,
    termsAndConditionsUrl: "assets/compensation-plans/compensation-plan-en.pdf",
    onChange: (checked: boolean) =>
      handleCheckboxChange("termsAndConditions", checked),
  };

  const isDisabled = useMemo(() => {
    const emailValid = validateEmail(walletData.email.value);
    const walletValid = validateWallet(walletData.wallet.value);

    const isDisabled =
      !emailValid ||
      !walletValid ||
      !checkboxes.termsAndConditions ||
      !checkboxes.privacyPolicy ||
      !checkboxes.termsOfUse;

    return isDisabled;
  }, [
    checkboxes.privacyPolicy,
    checkboxes.termsAndConditions,
    walletData.email.value,
    walletData.wallet.value,
    checkboxes.termsOfUse,
  ]);

  const handleSubmit = useCallback(() => {
    const walletAddress = walletData.wallet.value;
    setFormSubmitted(true);
    getWalletsData(walletAddress);
  }, [walletData.wallet.value, getWalletsData]);

  useEffect(() => {
    if (
      getWalletsDataStatus === "success" ||
      getWalletsDataStatus === "error" ||
      getWalletsDataStatus === "serverErrors"
    ) {
      const stepData = {
        step: "Wallet",
        error: getWalletsDataStatus === "error",
        address: walletData.wallet.value,
        contact: walletData.email.value,
        saveWalletId: checkboxes.saveWalletId,
        saveEmail: checkboxes.saveEmail,
        isBlockchainAddressFound:
          getWalletsDataStatus === "success" ? true : false,
        errors:
          getWalletsDataStatus === "error" ? getWalletsDataResult.data : false,
      };
      onSubmit(stepData);
    }
  }, [
    checkboxes.saveEmail,
    checkboxes.saveWalletId,
    getWalletsDataResult.data,
    getWalletsDataStatus,
    onSubmit,
    walletData.email.value,
    walletData.wallet.value,
  ]);

  const config: PropsByStatusType = {
    serverErrors: {
      icon: "error",
      message: {
        title: t("Sorry!"),
        description: t(
          `We could not connect to your Wallet. Please check your Wallet ID or try again later`
        ),
      },
      buttons: [
        {
          label: t("Cancel"),
          onClick: handleModalClose,
        },
        {
          label: t("Try Again"),
          onClick: () => {
            setFormSubmitted(false);
          },
        },
      ],
    },
  };

  return (
    <S.MintWrapper>
      {!formSubmitted ? (
        <>
          <S.ModalHeader>
            {!hasExistingWalletOrEmails
              ? t("Wallet connection")
              : t("Personal Details")}
          </S.ModalHeader>

          <S.MintingScrollWrapper>
            <S.MintingListWrapper>
              <S.FormGroup>
                <WalletFormItems {...walletIdProps} />
                <WalletFormCheckbox {...saveWalletProps} />
              </S.FormGroup>
              <S.FormGroup>
                <S.InformationText>{informationText}</S.InformationText>
              </S.FormGroup>
              <S.FormGroup>
                <WalletFormItems {...userEmailProps} />
                {walletData.email.valid && emailChanged ? (
                  <WalletFormCheckbox {...saveEmailProps} />
                ) : null}
              </S.FormGroup>
              <S.FormGroup>
                <WalletFormCheckbox {...savePolicyProps} />
                <WalletFormCheckbox {...saveTermsProps} />
                <WalletFormCheckbox {...saveTermsOfUseProps} />
              </S.FormGroup>
            </S.MintingListWrapper>
          </S.MintingScrollWrapper>
          {calculateUnits()}
          <S.BannerGroup isMintingStep>
            <BannerButton
              labelText={t("Cancel")}
              onClick={handleModalClose}
              secondary
            />
            <BannerButton
              labelText={labelTextNext}
              onClick={handleSubmit}
              disabled={isDisabled}
              $twoTone
            />
          </S.BannerGroup>
        </>
      ) : (
        <FeedbackDialogSwitch config={config} status={getWalletsDataStatus} />
      )}
    </S.MintWrapper>
  );
};

export default Wallet;
