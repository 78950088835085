import React from "react";
import IRegionVoteData from "interface/IRegionVoteData";
import { CardRegion } from "./CardRegion";
import {
  Description,
  FingerprintContainer,
  RegionVote,
} from "./RegionSelect.styles";
import { Fingerprint, VotedRegionWrapper } from "./VotedRegions.styles";
import { useTranslation } from "react-i18next";

interface PollResultsProps {
  regions: Array<IRegionVoteData>;
  name: string;
}

const PollResults: React.FC<PollResultsProps> = ({ regions, name }) => {
  const { t } = useTranslation();

  const displayPollResults = () => {
    if (regions.length === 0 || regions === undefined) return;
    return regions.map((region: any, key: number) => {
      const map = `/assets/country-maps/${region.code}.svg`;
      return (
        <VotedRegionWrapper key={key}>
          <CardRegion
            region={region.region}
            type={"answered"}
            activeCard={true}
          >
            <FingerprintContainer>
              <Fingerprint>
                <img src={map} alt={t("country")} />
              </Fingerprint>
            </FingerprintContainer>
          </CardRegion>
        </VotedRegionWrapper>
      );
    });
  };

  return (
    <>
      <Description>
        {t("The new ")}
        <span>
          {t(" Regions from ")} {t(name)}
        </span>
      </Description>
      <RegionVote>{displayPollResults()}</RegionVote>
    </>
  );
};

export default PollResults;
