const Minted = ({ ...props }) => {
  const {
    width = 16,
    height = 16,
    fill = "#9BDAEF",
    viewBox = "0 0 16 16",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={fill} />
      <path
        d="M12.7676 4.63439C12.4582 4.32457 11.9558 4.32477 11.646 4.63439L6.59787 9.68272L4.35419 7.43906C4.04438 7.12924 3.54217 7.12924 3.23236 7.43906C2.92255 7.74887 2.92255 8.25107 3.23236 8.56089L6.03684 11.3654C6.19165 11.5202 6.39464 11.5978 6.59765 11.5978C6.80067 11.5978 7.00386 11.5204 7.15867 11.3654L12.7676 5.7562C13.0775 5.4466 13.0775 4.94418 12.7676 4.63439Z"
        fill="#141414"
      />
    </svg>
  );
};
export default Minted;
