import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AcademyPage from "pages/academy/AcademyPage";
import AccountCenterPage from "pages/accountCenterPage/AccountCenterPage";
import CreditLedgerPage from "pages/credit/CreditLedger";
import { Error404Page } from "pages/error/Error404Page";
import { GeneralErrorPage } from "pages/error/GeneralErrorPage";
import { HomePage } from "pages/home/HomePage";
import IncomeCalculatorPage from "pages/incomeCalculator/IncomeCalculatorPage";
import Maintenance from "pages/maintenance/Maintenance";
import MyPackagesPage from "pages/myPackages/MyPackagesPage";
import PoolsPageDashboard from "pages/pools/PoolsPageDashboard";
import VideosPage from "pages/videos/VideosPage";
import ViewZonePage from "pages/viewZone/ViewZonePage";

import routes from "config/routes";

interface UserRoutesProps {
  showMaintenance: boolean;
}

const UserRoutes: FC<UserRoutesProps> = ({ showMaintenance }) => {
  // const isProd = process.env.REACT_APP_ENV === "production";
  return (
    <Switch>
      <Route path={routes.ACCOUNTCENTER}>
        <AccountCenterPage />
      </Route>
      <Route path={routes.POOLS_UNIVERSAL}>
        <PoolsPageDashboard tab={0} />
      </Route>
      <Route path={routes.POOLS_GLOBAL}>
        <PoolsPageDashboard tab={1} />
      </Route>
      <Route path={routes.POOLS_FOUNDERS}>
        <PoolsPageDashboard tab={2} />
      </Route>
      <Route path={routes.POOLS_INFINITY}>
        <PoolsPageDashboard tab={3} />
      </Route>
      <Route path={routes.POOLS_LEADERSHIP}>
        <PoolsPageDashboard tab={4} />
      </Route>
      <Route path={routes.MYPACKAGES}>
        <MyPackagesPage />
      </Route>
      <Route path={routes.INCOMECALCULATOR}>
        <IncomeCalculatorPage />
      </Route>
      <Route path={routes.VIEWZONE}>
        <ViewZonePage />
      </Route>
      <Route path={routes.HOME}>
        <HomePage showMaintenance={showMaintenance} />
      </Route>
      <Route path={routes.ACADEMY}>
        <AcademyPage />
      </Route>
      <Route path={routes.ERROR}>
        <GeneralErrorPage />
      </Route>
      <Route path={routes.ERROR404}>
        <Error404Page />
      </Route>
      <Route path={routes.ERROR404}>
        <Error404Page />
      </Route>
      <Route path={routes.VIDEOS}>
        <VideosPage />
      </Route>
      <Route path={routes.CREDITLEDGER}>
        <CreditLedgerPage />
      </Route>
      <Route path={routes.MAINTENANCE}>
        <Maintenance showMaintenance={showMaintenance} />
      </Route>
      <Route>
        <Redirect to={routes.MAINTENANCE} />
      </Route>

      {showMaintenance ? (
        <Route>
          <Redirect to={routes.MAINTENANCE} />
        </Route>
      ) : (
        <Route>
          <Redirect to={routes.HOME} />
        </Route>
      )}
    </Switch>
  );
};

export default UserRoutes;
