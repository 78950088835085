const Progress = ({ ...props }) => {
  const {
    width = 16,
    height = 16,
    fill = "#FFF1D1",
    viewBox = "0 0 16 16",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9474 3C12.2381 3 12.4737 3.23564 12.4737 3.52632V6.15793C12.4737 6.44861 12.2381 6.68425 11.9474 6.68425H9.3158C9.02512 6.68425 8.78948 6.44861 8.78948 6.15793C8.78948 5.86726 9.02512 5.63161 9.3158 5.63161H10.8417C10.4399 5.23788 9.95097 4.93929 9.41086 4.76223C8.66216 4.5168 7.85442 4.51797 7.10626 4.76539C6.35814 5.01281 5.70907 5.49344 5.25434 6.13686C4.79964 6.78025 4.56331 7.55253 4.57982 8.34033C4.59632 9.12813 4.86489 9.88987 5.34617 10.5137C5.82745 11.1376 6.49592 11.5905 7.25373 11.8064C8.01152 12.0223 8.81859 11.9895 9.55638 11.7129C10.2942 11.4364 10.9239 10.9305 11.353 10.2697C11.5113 10.0259 11.8373 9.95666 12.081 10.115C12.3248 10.2733 12.3941 10.5992 12.2358 10.843C11.684 11.6926 10.8745 12.343 9.92586 12.6986C8.97727 13.0542 7.93963 13.0963 6.96533 12.8187C5.99105 12.5412 5.13154 11.9588 4.51272 11.1567C3.8939 10.3546 3.54862 9.37518 3.52741 8.36238C3.50619 7.34963 3.80998 6.35669 4.39471 5.52933C4.97941 4.70199 5.81396 4.08407 6.77574 3.76599C7.73747 3.44792 8.77606 3.44637 9.73876 3.76196C10.3641 3.96697 10.9361 4.29872 11.4211 4.73243V3.52632C11.4211 3.23564 11.6567 3 11.9474 3Z"
        fill="black"
      />
    </svg>
  );
};
export default Progress;
