import { breakpointMin } from "@successfactory/sf-libraries";
import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

import background from "../../../../assets/dashboard/academy/pattern.svg";

export const Refresh = styled.button``;
const display = (type: any) => `
  display: ${type || "block"};
`;
const flexDirection = (direction: any) => `
  justify-content: ${direction || "center"};
  align-items: ${direction || "center"};
`;
export const ProfitBannerWrapper = styled.div<{
  isSticky: boolean | null;
  isSidebarShown: boolean;
  isActive: boolean;
  scrollY: number;
  isOpen: boolean;
}>`
  margin-top: 30px;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 12px;
  padding: 24px 30px;
  min-height: 310px;
  background-size: 1900px 45vw;
  background-image: url(${background}),
    linear-gradient(129.38deg, #b67f11 0.88%, #e2bf56 57.49%, #b67f11 117.2%);
  position: relative;
  @media ${breakpoints.tablet} {
    height: auto !important;
    background-size: 1400px 80vw;
  }
  @media ${breakpoints.mobile} {
    padding: 24px 10px;
    background-size: 1900px 900px;
  }
  .profit-collapse {
    display: none;
    position: relative;
  }
  &.sticky-income-calculator {
    border-radius: 0;
    position: fixed;
    transition: all 1.3s ease-in-out 0s;
    z-index: 1;
    @media ${breakpoints.mobile} {
      left: 0;
      width: 100%;
      .mobile-view {
        display: none;
      }
      .profit-collapse {
        display: block;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: -10px;
        button {
          border: 0;
          background: transparent;
          position: absolute;
          left: 50%;
          padding: 0;
          top: 27px;
          transform: translate(-50%, -50%);
          img {
            transition: transform 0.4s ease-out 0s;
            transform: ${({ isActive }) => {
              return !isActive ? "rotate(180deg) translateZ(0px)" : "none";
            }};
          }
        }
      }
    }

    @media ${breakpoints.tablet} {
      padding: 15px;
      ${({ isSticky, scrollY, isSidebarShown }) =>
        isSticky && scrollY > 0 && !isSidebarShown
          ? "bottom: 0px;"
          : isSticky && scrollY > 0 && isSidebarShown
          ? "bottom: 0px;"
          : "bottom: -600px;"}
    }
    @media (min-width: 912px) and (max-width: 912px) {
      ${({ isSticky, scrollY }) =>
        isSticky && scrollY > 0 ? "bottom: 355px;" : "bottom: -600px;"}
    }
    @media ${breakpoints.tabletSmall} {
      ${({ isSticky, scrollY, isSidebarShown }) =>
        isSticky && scrollY > 0 && isSidebarShown
          ? "bottom: -2px;"
          : isSticky && scrollY > 0 && !isSidebarShown
          ? "bottom: 10px;"
          : "bottom: -600px;"}
    }
    @media ${breakpoints.mobile} {
      padding: 15px;
      bottom: ${({ isActive, scrollY }) => {
        return isActive
          ? scrollY > 0
            ? "-245px"
            : "-600px"
          : scrollY > 0
          ? "-22px"
          : "-600px";
      }};
      small {
        display: none;
      }
    }

    bottom: ${({ isSticky }) => {
      return isSticky ? "-90px" : "-600px";
    }};
    ${(props) => {
      return props.theme.direction === "rtl"
        ? ` right: ${props.isSidebarShown ? "255px" : "96px"};`
        : ` left: ${props.isSidebarShown ? "255px" : "96px"};`;
    }}
    width: ${({ isSidebarShown }) => {
      return isSidebarShown ? "calc(100% - 255px)" : "calc(100% - 96px)";
    }};
  }
  tbody tr:nth-child(even) {
    background: transparent;
  }
`;
export const ProfitBannerTitle = styled.h2`
  color: ${(p: any) => p.theme.colors.textColors.textWhite};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  width: auto;
  padding-bottom: 30px;
  @media ${breakpoints.mobile} {
    padding-bottom: 18px;
  }
`;

export const DropdownWrapper = styled.div<{}>`
  position: relative;
  width: 100%;
  div div {
    color: transparent !important;
    height: 42px;
  }

  span {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
  }
`;

export const ProfileBannerContent = styled.div<{
  isOpen?: Boolean;
  isSticky?: Boolean;
}>`
  ${display("flex")};
  ${flexDirection("baseline")};
  flex-direction: column;
  width: 25%;
  h4 {
    height: 35px;
    @media ${breakpoints.mobileSmall} {
      height: 50px;
    }
  }
  .dropdown-h4 {
    ${({ theme }) => (theme.direction === "rtl" ? "" : "height: 10px;")}
  }

  ${breakpointMin.md} {
    h4 {
      height: unset;
    }
    .dropdown-h4 {
      height: unset;
    }
  }

  &:last-child {
    ${flexDirection("flex-end")};

    button {
      padding: 11px;
      font-weight: 700;
      width: 80%;
      height: 40px;
      margin-bottom: 3px;
      position: relative;
      svg {
        transition: 0.3s all ease-in-out;
        transform: rotate(180deg);
        ${({ isOpen }) => (isOpen ? "transform: rotate(360deg);" : "")}
        position: absolute;
        top: 16px;
        ${({ theme }) =>
          theme.direction === "rtl" ? "left: 20px;" : "right: 20px;"}
        ${({ theme }) =>
          theme.direction === "rtl"
            ? "margin-right: 22px;"
            : "margin-left: 22px;"}
        path {
          fill: #fff;
        }
      }

      @media ${breakpoints.tablet} {
        width: 100%;
        margin-bottom: 0;
      }
      @media ${breakpoints.mobile} {
        width: 60%;
        margin: 20px 0 0;
      }
      @media ${breakpoints.mobileSmall} {
        width: 75%;
      }
    }
  }
  @media ${breakpoints.tablet} {
    margin-bottom: 5px;
    width: 50%;

    ${(props) =>
      props.theme.direction === "rtl"
        ? " padding-left: 15px"
        : " padding-right: 15px"};
  }

  @media ${breakpoints.mobile} {
    justify-content: center !important;
    align-items: center !important;
    &:last-child,
    &:nth-child(3) {
      width: 100%;
    }
    &:nth-child(2),
    &:nth-child(3) {
      ${(props) =>
        props.theme.direction === "rtl"
          ? " padding-left: 0"
          : " padding-right: 0"};
    }
  }
`;
export const ProfileBannerContentTitle = styled.h4<{
  isSidebarShown?: Boolean;
}>`
  color: ${(p: any) => p.theme.colors.textColors.textWhite};
  font-weight: 700;
  ${(props) =>
    props.theme.direction === "rtl" ? "font-size: 16px" : "font-size: 11px"};
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;

  span {
    text-align: left;
    text-transform: none;
    padding: 8px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
    }
    ::-webkit-scrollbar-track {
      margin: 10px;
    }
  }

  @media ${breakpoints.mobile} {
    ${({ theme }) => (theme.direction === "rtl" ? "" : "font-size: 10px;")}
  }
  @media ${breakpoints.tabletSmall} {
    ${({ theme }) => (theme.direction === "rtl" ? "" : "font-size: 10px;")}
  }
`;
export const ProfileBannerContentItem = styled.div`
  width: 80%;
  background-color: #784e00;
  border-radius: 12px;
  padding: 7px 10px;
  text-align: center;
  position: relative;
  span {
    color: ${(p: any) => p.theme.colors.textColors.textWhite};
    @media ${breakpoints.mobile} {
      font-size: 14px;
    }
  }

  @media ${breakpoints.tablet} {
    width: 100%;
  }
`;
export const MargueeWrapper = styled.div`
  width: calc(100% + 60px);
  position: relative;
  padding: 2px 0;

  .marquee-container {
    ${(props) => {
      return props.theme.direction === "rtl"
        ? ` flex-direction: row-reverse!important`
        : ` `;
    }};
  }
  ${(props) => {
    return props.theme.direction === "rtl" ? ` right: -30px` : ` left: -30px`;
  }};
  background: rgba(120, 78, 0, 0.6);
  @media ${breakpoints.mobile} {
    margin-top: 0;
  }
`;
export const ProfileBannerInfo = styled.small`
  padding-top: 20px;
  color: ${(p: any) => p.theme.colors.textColors.textWhite};
  display: inline-block;
  ${(props) => {
    return props.theme.direction === "rtl"
      ? ` font-size:14px`
      : ` font-size:12px`;
  }};
  line-height: 18px;
`;
export const ProfilBannerTableWrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  background: rgba(120, 78, 0, 0.6);
  border-radius: 12px;
  margin: 20px 0;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 100%;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 48px;
    background-color: ${({ theme }) => theme.colors.brandColors.brandPrimary};
  }
  ::-webkit-scrollbar-track {
    margin: 10px;
  }
  table {
    width: 33.3333%;
    padding: 20px 18px;
    @media ${breakpoints.mobile} {
      width: 100%;
    }
    @media ${breakpoints.tablet} {
      padding: 20px 10px;
    }
    tr {
      ${display("flex")};
      justify-content: space-between;
      align-items: center;
      td {
        ${display("flex")};
        ${flexDirection("center")};
        padding: 5px 0;
        &:first-child {
          justify-content: flex-start;
        }
        span {
          font-size: 13px;
          line-height: 24px;
          padding-left: 10px;
          color: ${(p: any) => p.theme.colors.textColors.textWhite};
          &.user-shares {
            color: #f6db76;
          }
          &.table-title {
            text-transform: uppercase;
            padding-left: 0;
          }
        }
      }
    }
  }
`;
export const ProfileTableFlex = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointMin.md} {
    flex-direction: row;
  }

  .table-image,
  img {
    width: 32px;
    ${(props) =>
      props.theme.direction === "rtl"
        ? " padding-left: 8px"
        : " padding-right: 8px"};
  }
  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const ProfilePool = styled.div`
  ${display("flex")};
  ${flexDirection("center")};
`;
export const ProfilePoolContentWrapper = styled.div`
  ${(props) =>
    props.theme.direction === "rtl"
      ? " padding-left: 80px"
      : " padding-right: 80px"};
  ${display("flex")};
  ${flexDirection("center")};
`;
export const ProfilePoolImage = styled.div`
  ${(props) =>
    props.theme.direction === "rtl"
      ? " padding-left: 7px"
      : " padding-right: 7px"};
`;
export const ProfilePoolTitle = styled.div`
  ${(props) =>
    props.theme.direction === "rtl"
      ? " padding-left: 10px"
      : " padding-right: 10px"};
  color: #f6db76;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;
export const ProfilePoolContent = styled.div`
  text-transform: uppercase;
  color: ${(p: any) => p.theme.colors.textColors.textWhite};
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    ${display("inline-block")};
    color: ${({ theme }) => theme.colors.surfaces.buttonSecondary};
    padding: 0 10px;
  }
`;
export const EuroSpan = styled.span`
  direction: ltr;
`;
export const SfTooltipImage = styled.div`
  width: 15px;
  height: 15px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? " margin-right: 7px"
      : " margin-left: 7px"};
  position: relative;
  top: 4px;
`;
export const ProfileBannerContentWrapper = styled.div`
  width: 100%;
  ${display("flex")};
  @media ${breakpoints.tablet} {
    flex-flow: wrap;
    flex: 1 1 180px;
  }
`;
export const RefresfContainer = styled.div<{
  isChanged: boolean;
}>`
  opacity: ${({ isChanged }) => (isChanged ? 1 : 0)};
  transition: visibility 0s, opacity 0.5s linear;
  visibility: ${({ isChanged }) => (isChanged ? "visible" : "hidden")};
  position: absolute;
  right: 5px;
  top: 4px;
  cursor: pointer;

  #HoverStroke2 {
    stroke: #fff;
  }
`;
