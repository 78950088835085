import React from "react";
import RegionSelectVote from "components/CreatorsCorner/RegionSelectVote";
import VotedRegions from "components/CreatorsCorner/VotedRegions";

import IRegionVoteData from "interface/IRegionVoteData";
import PollResults from "components/CreatorsCorner/PollResults";
import usePollsApi from "api/usePollsApi";
import { CreatorsCornerMetaTags } from "config/metaTags";
import Loader from "components/Loader/Loader";
import { SfButton } from "@successfactory/sf-libraries";
import { useTranslation } from "react-i18next";
import { Grid, GridContainer } from "styles/Grid.styles";

interface FounderCornersProps {}

const ViewZonePage: React.FC<FounderCornersProps> = () => {
  const { t } = useTranslation();
  const {
    poll,
    getPollStatus,
    alreadyVoted,
    votedRegions,
    votePoll,
    votePollStatus,
  } = usePollsApi({ init: "getPoll" });

  const [selectedRegions, setSelectedRegions] = React.useState(
    [] as Array<IRegionVoteData>
  );

  const submitSelectedLocations = async () =>
    votePoll(
      poll.poll.slug,
      selectedRegions.map((region: IRegionVoteData) => region._id)
    );

  const getView = () => {
    if (getPollStatus === "loading" || votePollStatus === "loading")
      return <Loader />;
    if (poll.active === false)
      return (
        <PollResults
          name={poll.poll.name}
          regions={poll.results.map((region: any) => region.location)}
        />
      );
    if (alreadyVoted) return <VotedRegions regions={votedRegions} />;

    return (
      <>
        <RegionSelectVote
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
          countries={poll.locations || []}
        />
        <div className="country__vote-submit">
          <SfButton
            isDisabled={selectedRegions.length === 5 ? false : true}
            onClick={submitSelectedLocations}
            label={t("Submit selected regions")}
            twoTone={true}
            size={"small"}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <CreatorsCornerMetaTags />
      <GridContainer>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12"></Grid>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
          {getView()}
        </Grid>
      </GridContainer>
    </>
  );
};

export default ViewZonePage;
