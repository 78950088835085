import { css } from "styled-components";
import { iTheme } from "styles/themes/iTheme";

export interface fontStyleProps {
  types:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "captionCaps"
    | "captionCapsMedium"
    | "caption"
    | "captionMedium"
    | "bodyL"
    | "bodyM"
    | "bodyS"
    | "bodyA";
}

export const getFontStyle = (type: fontStyleProps["types"], theme: iTheme) => {
  return css`
    font-weight: ${theme.typography.text[type].weight};
    font-size: ${theme.typography.text[type].size};
    line-height: ${theme.typography.text[type].lineHeight};
    text-transform: ${theme.typography.text[type].textTransform};
  `;
};
