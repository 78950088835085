import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const activeClassName = "nav-item-active";
const svgStyle = css`
  svg {
    #HoverStroke1 {
      stroke: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
    }
    #HoverStroke2 {
      stroke: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    }
    #HoverStroke3 {
      fill: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    }
    #HoverStroke4 {
      fill: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
    }
  }
`;

export const NavLinkStyle = styled(NavLink).attrs({ activeClassName })<{
  $closed: boolean;
}>`
  &.${activeClassName} {
    > div:first-child {
      background-color: rgba(255, 243, 166, 0.4);
      transition: ${(p: any) => (p.$closed ? "" : "0.3s ease")};
      border-radius: 12px;
      width: ${(p: any) => (p.$closed ? "55px" : "auto")};
    }

    ${svgStyle}
  }
  :hover {
    ${svgStyle}
  }
`;
export const SidebarNavlinkLabel = styled.div<{ closed: boolean }>`
  color: ${(p: any) => p.theme.colors.textColors.textVariant};
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  height: 56px;
  text-decoration: none;
  margin: 4px 0 10px 0;
  border-radius: 12px;
  padding: 8px 10px;
  width: ${(p: any) => (p.closed ? "56px" : "")};
  span {
    display: ${(p: any) => (p.closed ? "none" : "block")};
  }
  :hover {
    span {
      color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
    }
  }
`;

export const SidebarNavlinkSvg = styled.div`
  vertical-align: sub;
  display: flex;
  align-items: center;
  :hover {
    #HoverStroke1 {
      stroke: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
    }
    #HoverStroke2 {
      stroke: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    }
    #HoverStroke3 {
      fill: ${(p: any) => p.theme.colors.brandColors.brandSecondary};
    }
    #HoverStroke4 {
      fill: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
    }
  }
  svg {
    margin-right: ${(p: any) => (p.theme.direction === "ltr" ? "4px" : "0")};
    margin-left: ${(p: any) => (p.theme.direction === "rtl" ? "4px" : "0")};
    #HoverStroke1 {
      stroke: ${(p: any) => p.theme.colors.textColors.textVariant};
    }
    #HoverStroke2 {
      stroke: ${(p: any) => p.theme.colors.textColors.textVariant};
    }
    #HoverStroke3 {
      fill: ${(p: any) => p.theme.colors.textColors.textVariant};
    }
    #HoverStroke4 {
      fill: ${(p: any) => p.theme.colors.textColors.textVariant};
    }
  }
`;

export const NavLinkWrapper = styled.div<{ closed: boolean }>`
  :last-child {
    border-top: 1px solid ${(p: any) => p.theme.colors.textColors.textGrey};
    margin-top: 20px;
    padding-top: 20px;
    max-width: ${({ closed }) => (closed ? "56px" : "auto")};
  }
`;
