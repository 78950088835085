export interface RoutesProps {
  [ROUTE_NAME: string]: string;
}

const Routes: RoutesProps = {
  LOGIN: "/login",
  // LOGIN_TEST: "/login_test/:id",
  LANDING: "/landing",
  TEMPORARY: "/temporary-password",
  SETUPPASSWORD: "/setup-password/:token",
  FORGOTPASSWORD: "/forgot-password",
  RESETPASSWORD: "/reset-password/:token",
  HOME: "/home",
  MYPACKAGES: "/my-memberships",
  CREATORSCORNER: "/creators-corner",
  INCOMECALCULATOR: "/income-calculator",
  VIEWZONE: "/viewzone",
  POOLS_UNIVERSAL: "/pools-universal",
  POOLS_INFINITY: "/pools-infinity",
  POOLS_FOUNDERS: "/pools-founders",
  POOLS_GLOBAL: "/pools-global",
  POOLS_LEADERSHIP: "/pools-leadership",
  ACCOUNTCENTER: "/account-center",
  ACADEMY: "/my-academy",
  ERROR404: "/404",
  ERROR: "/error",
  VIDEOS: "/videos",
  MAINTENANCE: "/maintanance",
  CREDITLEDGER: "/credit-ledger",
};

export default Routes;
