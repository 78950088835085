import { useState } from "react";

import axios, { AxiosResponse } from "axios";
import sStorage from "util/sStorage";

export const baseUrl = process.env.REACT_APP_API_URL;

export const getHeader = () => {
  const token = sStorage.get("token");
  return {
    headers: {
      Accept: "application/json",
      Authorization: "bearer " + token,
    },
  };
};

type LoaderStates = "idle" | "loading" | "success" | "error" | "serverErrors";

const useAxiosMethod = (
  method: "get" | "post" | "patch",
  endpoint: string
): [
  (data?: any, params?: string[] | string) => Promise<boolean>,
  any,
  string
] => {
  const [status, setStatus] = useState<LoaderStates>("idle");
  const [response, setResponse] = useState<AxiosResponse | {}>({});

  const request = async (data: any = {}, params?: string[] | string) => {
    if (status === "loading") return false;
    setStatus("loading");
    try {
      const endpointBaseUrl = `${baseUrl}${endpoint}`;
      const endpointUrl =
        params && typeof params === "string"
          ? `${endpointBaseUrl}/${params}`
          : endpointBaseUrl;

      const result = await axios[method](endpointUrl, data, getHeader());

      if (result.data.success) {
        setResponse(result);
        setStatus("success");
        return true;
      } else {
        setResponse(result);
        setStatus("error");
      }
    } catch (error: any) {
      console.log(`🚀 ~ file: useApi.ts ~ line 23 ~ ${method} ~ error`, error);
      setResponse(error.response);
      setStatus("serverErrors");
    }
    return false;
  };

  return [request, response, status];
};

export const useAxiosGet = (endpoint: string) =>
  useAxiosMethod("get", endpoint);

export const useAxiosPost = (endpoint: string) =>
  useAxiosMethod("post", endpoint);

export const useAxiosPatch = (endpoint: string) =>
  useAxiosMethod("patch", endpoint);

export const useApiHelpers = () => {
  return {
    shouldInitApi: (subject: string, init: string[] | string, status: string) =>
      (typeof init === "string" ? subject === init : init.includes(subject)) &&
      status === "idle",
  };
};

export interface useApiOptionsProps {
  id?: string;
  refresh?: boolean;
  init?: string[] | string;
}
