import { stringToHTML } from "@successfactory/sf-libraries";
import BannerButton from "components/BannerButton/BannerButton";
import TheiaSvgIcons from "components/TheiaSvgIcons/TheiaSvgIcons";

import { useAppState } from "AppContext";

import { Status } from "./IWallet";
import * as S from "./SharedStyles.styles";

interface FeedbackDialogProps extends Status {}

const FeedbackDialog = ({ icon, message, buttons }: FeedbackDialogProps) => {
  const { theme } = useAppState();
  return (
    <S.ApiErrorMessageWrapper>
      <TheiaSvgIcons
        name={icon}
        width={48}
        height={48}
        fill={theme?.colors.brandColors.brandSecondary}
      />
      <S.ApiErrorMessage>
        <S.ApiErrorMessageTitle>{message.title}</S.ApiErrorMessageTitle>
        <S.ApiErrorMessageDescription>
          {stringToHTML(message.description)}
        </S.ApiErrorMessageDescription>
      </S.ApiErrorMessage>
      <S.BannerGroup>
        {buttons.map((button, index) => (
          <BannerButton
            key={index.toString()}
            labelText={button.label}
            onClick={button.onClick}
            secondary={
              index === 0 &&
              (button.label === "Close" || button.label === "Cancel")
            }
            $twoTone={
              index === 1 ||
              button.label === "Understood" ||
              button.label === "Continue"
            }
          />
        ))}
      </S.BannerGroup>
    </S.ApiErrorMessageWrapper>
  );
};

export default FeedbackDialog;
