const Warning = ({ ...props }) => {
  const {
    width = 20,
    height = 20,
    fill = "#F6DB76",
    viewBox = `0 0 ${width} ${height}`,
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14.1667C10.1945 14.1667 10.3577 14.1008 10.4896 13.9688C10.6215 13.8369 10.6875 13.6737 10.6875 13.4792C10.6875 13.2848 10.6215 13.1216 10.4896 12.9897C10.3577 12.8577 10.1945 12.7917 10 12.7917C9.80557 12.7917 9.64238 12.8577 9.51043 12.9897C9.37849 13.1216 9.31252 13.2848 9.31252 13.4792C9.31252 13.6737 9.37849 13.8369 9.51043 13.9688C9.64238 14.1008 9.80557 14.1667 10 14.1667ZM9.43752 10.9792H10.6875V5.70841H9.43752V10.9792ZM10 18.3334C8.86113 18.3334 7.78474 18.1147 6.77085 17.6772C5.75696 17.2397 4.87155 16.6424 4.1146 15.8855C3.35766 15.1285 2.76044 14.2431 2.32294 13.2292C1.88544 12.2154 1.66669 11.132 1.66669 9.97925C1.66669 8.84036 1.88544 7.76397 2.32294 6.75008C2.76044 5.73619 3.35766 4.85425 4.1146 4.10425C4.87155 3.35425 5.75696 2.7605 6.77085 2.323C7.78474 1.8855 8.86807 1.66675 10.0209 1.66675C11.1597 1.66675 12.2361 1.8855 13.25 2.323C14.2639 2.7605 15.1458 3.35425 15.8958 4.10425C16.6458 4.85425 17.2396 5.73619 17.6771 6.75008C18.1146 7.76397 18.3333 8.8473 18.3333 10.0001C18.3333 11.139 18.1146 12.2154 17.6771 13.2292C17.2396 14.2431 16.6458 15.1285 15.8958 15.8855C15.1458 16.6424 14.2639 17.2397 13.25 17.6772C12.2361 18.1147 11.1528 18.3334 10 18.3334ZM10.0209 17.0834C11.9792 17.0834 13.6458 16.3924 15.0208 15.0105C16.3958 13.6285 17.0833 11.9515 17.0833 9.97925C17.0833 8.02091 16.3958 6.35425 15.0208 4.97925C13.6458 3.60425 11.9722 2.91675 10 2.91675C8.04168 2.91675 6.37155 3.60425 4.9896 4.97925C3.60766 6.35425 2.91669 8.02786 2.91669 10.0001C2.91669 11.9584 3.60766 13.6285 4.9896 15.0105C6.37155 16.3924 8.04863 17.0834 10.0209 17.0834Z"
        fill={fill}
      />
    </svg>
  );
};
export default Warning;
