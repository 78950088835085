import styled, { keyframes } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

const scale = keyframes`
  from {
    transform: scaleY(0);
  
  }

  to {
    transform: scaleY(1);

  }
`;
export const DropdownBody = styled.div`
  position: absolute;
  left: 0;
  margin: 8px 0px;
  line-height: 20px;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
  border-width: 1px;
  border-style: solid;
  border-color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
  width: 100%;
  animation: ${scale} 0.2s;
  transform-origin: top;
  z-index: 1;
`;
export const Dropdown = styled.div<{ isOpen: Boolean }>`
  margin: 8px 0px;
  position: relative;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: ${(p: any) => p.theme.colors.surfaces.surfaceVariant};
  border-width: 1px;
  border-style: solid;
  border-color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
  width: 100%;
  transition: 0.5s;
  ${DropdownBody} {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
  svg {
    transform: ${(props) =>
      props.isOpen ? "rotate(0deg)" : "rotate(-180deg)"};
    transition: 0.4s all ease-in-out;
    #chevron {
      fill: ${(p: any) => p.theme.colors.textColors.textVariant};
    }
  }
`;

export const DropdownBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownBodyContent = styled.div`
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
  color: ${(p: any) => p.theme.colors.textColors.textGrey};

  span:hover {
    color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
  }
`;
export const DropdownItem = styled.div`
  color: ${(p: any) => p.theme.colors.textColors.textVariant};
  ${(props) =>
    props.theme.direction === "rtl"
      ? " padding-left: 20px"
      : " padding-right: 20px"};
  max-width: 100%;
  font-size: 16px;
  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const Checkmark = styled.div<{ selected: boolean }>`
  width: 8px;
  height: 14px;
  border: solid
    ${({ selected }) =>
      selected
        ? (p: any) => p.theme.colors.brandColors.brandPrimary
        : "transparent"};

  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  ${(props) =>
    props.theme.direction === "rtl"
      ? " margin-left: 12px"
      : " margin-right: 12px"};
`;

export const DropdownListItem = styled.span<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected
      ? (p: any) => p.theme.colors.brandColors.brandPrimary
      : (p: any) => p.theme.colors.textColors.textGrey};
`;
