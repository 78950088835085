import ILocationData from "interface/ILocationData";

export interface IncomeCalculatorState {
  originalLocations: ILocationData[];
  locations: any;
  calculatedLocations: any;
  stats: {
    total: {
      founders: number;
      global: number;
      locations: number;
    };
    user: {
      founders: number;
      global: number;
      locations: number;
    };
  };
}
export const initialIncomeCalculatorState: IncomeCalculatorState = {
  originalLocations: [],
  locations: [],
  calculatedLocations: [],
  stats: {
    total: {
      founders: 0,
      global: 0,
      locations: 0,
    },
    user: {
      founders: 0,
      global: 0,
      locations: 0,
    },
  },
};
const calculateEstIncome = (
  userShares: number,
  maxAvaliable: number,
  potentialRev: number,
  multiplier: number = 0.01
) => {
  const percentage = userShares * multiplier;
  return percentage * (potentialRev / maxAvaliable);
};

const calculateDistribution = (userShares: number, maxAvaliable: number) => {
  const percentage = (userShares / maxAvaliable) * 100;
  return percentage;
};
export type TheiaUnitsAction =
  | {
      type: "potentialRevOperations";
      region_code: string;
      poolsType: string;
      data: any;
    }
  | {
      type: "unitsOperations";
      region_code: string;
      poolsType: string;
      data: any;
    }
  | { type: "resetRow"; region_code: string; data: any }
  | { type: "distribution"; region_code: string; data: any }
  | { type: "estIncome"; region_code: string; data: any }
  | { type: "reset"; data: any };
export const theiaUnitsReducer = (
  state: IncomeCalculatorState,
  action: TheiaUnitsAction
) => {
  const newState = { ...state };
  switch (action.type) {
    case "reset":
      newState.locations = state.originalLocations.map(
        (location: any, index: number) => {
          const units = location.userShares;
          const distribution = calculateDistribution(
            units,
            location.max_available
          );
          return {
            ...location,
            potentialRev: 0,
            userShares: units,
            estIncome: 0,
            distribution: distribution,
          };
        }
      );
      newState.calculatedLocations = newState.locations.filter(
        (location: any) => location.userShares > 0
      );
      return newState;
    case "potentialRevOperations":
      newState.locations = state.locations.map(
        (location: any, index: number) => {
          const potentialRev =
            location.region_code === action.region_code
              ? (location.potentialRev = action.data.units)
              : location.potentialRev;
          const estIncome = calculateEstIncome(
            location.userShares,
            location.max_available,
            potentialRev,
            action.poolsType === "Regions" ? 0.05 : 0.01
          );

          return {
            ...location,
            potentialRev: potentialRev,
            estIncome: estIncome,
          };
        }
      );
      newState.calculatedLocations = newState.locations.filter(
        (location: any) => location.userShares > 0
      );
      return newState;
    case "unitsOperations":
      newState.locations = state.locations.map(
        (location: any, index: number) => {
          const userShares =
            location.region_code === action.region_code
              ? action.data.units
              : location.userShares;

          const estIncome = calculateEstIncome(
            userShares,
            location.max_available,
            location.potentialRev,
            action.poolsType === "Regions" ? 0.05 : 0.01
          );
          const distribution = calculateDistribution(
            userShares,
            location.max_available
          );
          return {
            ...location,
            userShares: userShares,
            estIncome: estIncome,
            distribution: distribution,
          };
        }
      );
      newState.calculatedLocations = newState.locations.filter(
        (location: any) => location.userShares > 0
      );
      return newState;
    case "resetRow":
      newState.locations = state.locations.map(
        (location: any, index: number) => {
          const potentialRev =
            location.region_code === action.region_code
              ? (location.potentialRev = action.data.potentialRev)
              : location.potentialRev;

          const units =
            location.region_code === action.region_code
              ? (location.userShares = action.data.units[index])
              : location.userShares;

          const estIncome = calculateEstIncome(
            units,
            location.max_available,
            potentialRev,
            0.05
          );

          const distribution = calculateDistribution(
            units,
            location.max_available
          );
          return {
            ...location,
            potentialRev: potentialRev,
            userShares: units,
            estIncome: estIncome,
            distribution: distribution,
          };
        }
      );
      return newState;
    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(state)}`);
  }
};
