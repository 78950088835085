import React, { useCallback, useEffect, useMemo, useState } from "react";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

import {
  breakpointsMax,
  breakpointsMin,
  matchesBreakpoint,
  SfButton,
  SfDropDown,
  SfTooltip,
} from "@successfactory/sf-libraries";
import { LocationIcon } from "components/LocationIcon/LocationIcon";
import { motion } from "framer-motion";
import ILocationData from "interface/ILocationData";
import { LocationTabs } from "interface/IpoolsName";
import {
  useTheiaUnitsDispatch,
  useTheiaUnitsState,
} from "stores/TheiaUnitsProvider";

import { useAppState } from "AppContext";

import ArrowCircle from "assets/dashboard/academy/income-calculator/arrow-circle.svg";
import Rectangle from "assets/dashboard/academy/income-calculator/rectangle.svg";
import Tooltip from "assets/dashboard/academy/income-calculator/tooltip.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { ReactComponent as Refresh } from "assets/icons/refresh-square.svg";

import * as S from "./IncomeCalculatorPageBanner.styles";
import IncomeCalculatorPoolItems from "./IncomeCalculatorPoolItems";

interface IncomeCalculatorPageBannerProps {
  type: string;
  tabsName: LocationTabs;
  ShowRegionAndCityName: string;
}

const IncomeCalculatorPageBanner: React.FC<IncomeCalculatorPageBannerProps> = ({
  type,
  tabsName,
  ShowRegionAndCityName,
}) => {
  const { t } = useTranslation();
  const DropDownOptions = {
    options: [t("Year"), t("Month")],
    hover: {
      backgroundColor: " #F6DB76",
      textColor: "#784E00",
    },
    border: {
      borderRadius: "12px",
    },
    backgroundColor: "#784e00",
    textColor: "#fff",
  };

  const state = useTheiaUnitsState();
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isActive, setActive] = useState(true);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const { isSidebarShown, isDirection } = useAppState();
  const [onSelectedDropDown, setOnSelectedDropDown] = useState<any>(0);

  const isMobile = matchesBreakpoint(breakpointsMax.md, "max-width");
  const isBiggerThanTablet = matchesBreakpoint(breakpointsMin.xl, "min-width");

  const setStickyFooterBanner = () => {
    const element = window.document.getElementById("stickyBanner")!;
    const scrollElement = window.scrollY - element?.clientHeight + 30;

    setScrollY(scrollElement);
    setIsSticky(scrollElement > 40);
  };
  const getTabMapping = useCallback(
    (tab: LocationTabs) => {
      switch (tab) {
        case LocationTabs.CITIES:
          return { label: t("cities"), data: "city" };
        case LocationTabs.VERTICALS:
          return { label: t("verticals"), data: "vertical" };
        case LocationTabs.CONTINENTS:
          return { label: t("world areas"), data: "continent" };
        default:
          return { label: t("regions"), data: "region" };
      }
    },
    [t]
  );

  const loadIncomeCalData = useCallback(
    (tabsName: LocationTabs) => {
      const { data } = getTabMapping(tabsName);
      return data;
    },
    [getTabMapping]
  );

  const ShowRegionAndCityText = (tabsName: LocationTabs) => {
    const { label } = getTabMapping(tabsName);
    return label;
  };

  useEffect(() => {
    window.addEventListener("scroll", setStickyFooterBanner);

    return () => {
      window.removeEventListener("scroll", setStickyFooterBanner);
    };
  }, []);

  const getColumnsBySize = (locations: ILocationData[]) => {
    const columnAmount =
      window.innerWidth < 465 ? 1 : window.innerWidth < 768 ? 2 : 3;
    const initialResult = new Array(columnAmount).fill(1).map(() => []);
    const result = locations.reduce(
      (columns: any, location: any, index: number) => {
        const cp = [...columns];
        cp[index % columnAmount].push(location);
        return cp;
      },
      initialResult
    );
    return result;
  };

  const filterRegionAndCity = useCallback(
    (data: Array<ILocationData>) => {
      return data?.filter(
        (res: ILocationData) => res.type === loadIncomeCalData(tabsName)
      );
    },
    [loadIncomeCalData, tabsName]
  );

  const { totalPotentialRev, totalPotentialIncome, locationColumns } = useMemo(
    () => ({
      totalPotentialRev: filterRegionAndCity(state.locations).reduce(
        (sum: number, location: ILocationData) => sum + location.potentialRev,
        0
      ),
      totalPotentialIncome: filterRegionAndCity(state.locations).reduce(
        (sum: number, location: ILocationData) => sum + location?.estIncome,
        0
      ),
      locationColumns: getColumnsBySize(
        filterRegionAndCity(state.calculatedLocations)
      ),
    }),
    [filterRegionAndCity, state.calculatedLocations, state.locations]
  );

  const getData = (value: any) => {
    const index: any = DropDownOptions.options.indexOf(value);
    setOnSelectedDropDown(index);
  };

  const selectedValue = DropDownOptions.options[onSelectedDropDown];

  const dispatch = useTheiaUnitsDispatch();

  const resetState = (type: string) => {
    dispatch({
      type: "reset",
    });
  };
  const handleToggle = () => {
    setActive(!isActive);
  };

  const generateTooltip = (
    toolTipText: string,
    maxHeight: string,
    width: string,
    type: string
  ) => {
    const toolTipType = type === "Revenue";
    const toolTipPosition = isMobile
      ? toolTipType
        ? "bottom"
        : "left"
      : toolTipType
      ? "right"
      : "left";

    return (
      <SfTooltip
        toolTipPosition={toolTipPosition}
        toolTipBackgroundColor="#F6DB76"
        toolTipTextColor="#000"
        toolTipText={toolTipText}
        maxHeight={maxHeight}
        width={width}
      >
        <S.SfTooltipImage>
          <img src={Tooltip} alt="tooltip" />
        </S.SfTooltipImage>
      </SfTooltip>
    );
  };

  return (
    <div id="stickyBanner">
      <S.ProfitBannerWrapper
        className={type}
        isSticky={type === "sticky-income-calculator" ? isSticky : null}
        isActive={isActive}
        isSidebarShown={isSidebarShown}
        scrollY={scrollY}
        isOpen={isOpen}
      >
        <div className="profit-collapse">
          <img src={Rectangle} alt="" />
          <button onClick={handleToggle}>
            <img src={ArrowCircle} alt="expand" />
          </button>
        </div>
        <S.ProfitBannerTitle className="mobile-view">
          {t("What is my potential Income?")}
        </S.ProfitBannerTitle>
        <S.ProfileBannerContentWrapper>
          <S.ProfileBannerContent isSticky={isSticky}>
            <S.ProfileBannerContentTitle
              className={isDirection === "rtl" ? "rtl-tooltip-1" : "tooltip-1"}
              isSidebarShown={isSidebarShown}
            >
              {t(`${ShowRegionAndCityName} Total Revenue`)}
              {generateTooltip(
                t(
                  `The {{ShowRegionAndCityText}} Total Revenue is based on the estimated monthly or annual potential sales of The View products across all the {{ShowRegionAndCityText}} taken into consideration at the time of the estimation.`,
                  {
                    ShowRegionAndCityText: ShowRegionAndCityText(tabsName),
                  }
                ),
                "300px",
                !isSticky && isMobile ? "190px" : "",
                "Revenue"
              )}
            </S.ProfileBannerContentTitle>
            <S.ProfileBannerContentItem>
              <span>
                €
                {selectedValue === t("Month")
                  ? (totalPotentialRev / 12).toFixed(2)
                  : totalPotentialRev.toFixed(2)}
              </span>

              <S.RefresfContainer
                isChanged={totalPotentialRev > 0 ? true : false}
              >
                <Refresh
                  className="refresh"
                  onClick={() => resetState("resetRow")}
                />
              </S.RefresfContainer>
            </S.ProfileBannerContentItem>
          </S.ProfileBannerContent>
          <S.ProfileBannerContent isSticky={isSticky}>
            <S.ProfileBannerContentTitle
              className={isDirection === "rtl" ? "rtl-tooltip-2" : "tooltip-2"}
            >
              {t("Total Income")}

              {generateTooltip(
                t(
                  `The Total combined income is the estimated income you can have based on your total units - purchased and/or considering to purchase - along with the total estimated revenue of all estimated {{ShowRegionAndCityText}}
                  .`,
                  {
                    ShowRegionAndCityText: ShowRegionAndCityText(tabsName),
                  }
                ),
                isSticky && isBiggerThanTablet ? "120px" : "",
                !isSticky && isMobile ? "190px" : "",
                "Income"
              )}
            </S.ProfileBannerContentTitle>
            <S.ProfileBannerContentItem>
              <span>
                €
                {selectedValue === t("Month")
                  ? (totalPotentialIncome / 12).toFixed(2)
                  : totalPotentialIncome.toFixed(2)}
              </span>
            </S.ProfileBannerContentItem>
          </S.ProfileBannerContent>
          <S.ProfileBannerContent style={{ marginTop: "3px" }}>
            <S.ProfileBannerContentTitle className="dropdown-h4">
              {t("Per")}
            </S.ProfileBannerContentTitle>
            <S.DropdownWrapper>
              <SfDropDown
                className="SfDropDown"
                onChange={(e: any) => getData(e)}
                {...DropDownOptions}
              />
              <span>{selectedValue}</span>
            </S.DropdownWrapper>
          </S.ProfileBannerContent>
          <S.ProfileBannerContent isOpen={isOpen}>
            <SfButton
              icon={<Chevron />}
              twoTone
              label={isOpen ? t("Less details") : t("More details")}
              type="label+icon"
              onClick={() => setIsOpen(!isOpen)}
            ></SfButton>
          </S.ProfileBannerContent>
        </S.ProfileBannerContentWrapper>
        <S.ProfilBannerTableWrapper
          as={motion.div}
          style={{ overflowY: "scroll" }}
          initial={{ height: 0, padding: 0 }}
          animate={
            isOpen ? { height: "200px", opacity: 1 } : { height: 0, opacity: 0 }
          }
          transition={{ duration: 0.2 }}
          isOpen={isOpen}
        >
          <S.ProfileTableFlex>
            {locationColumns.map((column: [ILocationData], index: number) => {
              return (
                <table key={index}>
                  <thead>
                    <tr>
                      <td>
                        <span className="table-title">
                          {t(ShowRegionAndCityName)}
                        </span>
                      </td>
                      <td>
                        <span className="table-title">{t("Units")}</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {column.map(
                      (locationItem: ILocationData, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              <LocationIcon
                                type={locationItem.type}
                                image={locationItem.logo}
                                darkImage={locationItem.dark_logo}
                                updatedAt={locationItem.updated_at}
                              />
                              <span>{t(locationItem.region)}</span>
                            </td>
                            <td>
                              <span className="user-shares">
                                {locationItem.userShares}
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              );
            })}
          </S.ProfileTableFlex>
        </S.ProfilBannerTableWrapper>
        <S.MargueeWrapper>
          <Marquee
            pauseOnHover
            gradient={false}
            speed={50}
            loop={0}
            gradientWidth="100"
            direction={isDirection === "rtl" ? "right" : "left"}
          >
            <IncomeCalculatorPoolItems
              state={state}
              totalPotentialRev={totalPotentialRev}
            ></IncomeCalculatorPoolItems>
          </Marquee>
        </S.MargueeWrapper>

        <S.ProfileBannerInfo>
          {t(
            "* FOR GENERAL GUIDE AND ILLUSTRATIVE PURPOSES ONLY. No guarantees are made in the individual performance or revenue earnings as estimated. The presented results should not be taken as professional advice, or relied upon as the sole basis for making important decisions."
          )}
        </S.ProfileBannerInfo>
      </S.ProfitBannerWrapper>
    </div>
  );
};

export default IncomeCalculatorPageBanner;
