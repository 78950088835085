import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonStyles } from "styles/Button.styles";

import routes from "config/routes";

import FormInput from "../Components/Common/FormInput";
import { loginSchema as schema } from "../schemas/login.schema";
import * as S from "../styles/Shared.styles";

interface LoginFormProps {
  onSubmit: (data: any) => void;
}

const LoginForm = ({ ...props }: LoginFormProps) => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { onSubmit } = props;
  const { handleSubmit, register, formState } = methods;

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          {...register("email", { required: true })}
          name="email"
          type="email"
          title="Email"
          placeHolder={t("Please enter your email address")}
        />

        <FormInput
          {...register("password", { required: true, minLength: 6 })}
          name="password"
          type="password"
          title="Password"
          placeHolder={t("Please enter your password")}
        />

        <ButtonStyles
          type="submit"
          $twoTone={true}
          disabled={!formState.isValid || formState.isSubmitting}
        >
          {t("Login")}
        </ButtonStyles>
      </S.Form>

      <S.ButtonText as="a" href={routes.FORGOTPASSWORD}>
        {t("Forgot Password?")}
      </S.ButtonText>
      <S.ButtonText as="a" href={routes.TEMPORARY}>
        {t("First time Login? Click here to request a temporary Password")}
      </S.ButtonText>
    </FormProvider>
  );
};

export default LoginForm;
