import { FC } from "react";
import { useTranslation } from "react-i18next";

import { VideoDataProps } from "./VideosPageData";

import * as S from "./Videos.styles";

interface VideoModalPlayerProps {
  selectedVideo: VideoDataProps;
}

const VideoModalPlayer: FC<VideoModalPlayerProps> = ({ selectedVideo }) => {
  const { t } = useTranslation();

  const { title, videoUrl } = selectedVideo;

  return (
    <S.PlayerContainer>
      <S.VideoTitle>{t(`${title}`)}</S.VideoTitle>
      <S.VideoPlayerContainer 
        title={title} 
        src={videoUrl} 
        width="620" 
        height="311"  
        allowFullScreen
        sandbox="allow-scripts allow-same-origin"
        referrerPolicy="no-referrer"
      />

    </S.PlayerContainer>
  );
};

export default VideoModalPlayer;
