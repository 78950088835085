import { FC, useCallback, useEffect, useState } from "react";

import {
  breakpointsMax,
  matchesBreakpoint,
  SfModal,
} from "@successfactory/sf-libraries";
import Api from "Api";

import { MyPackagesMetaTags } from "config/metaTags";

import ApiLoaderView from "components/ApiLoaderView";
import AllocatePackageComponent from "components/MyPackages/AllocatePackageComponent";
import MyPackages from "components/MyPackages/MyPackages";

import ILocationData from "interface/ILocationData";
import IPackageData from "interface/IPackageData";

import { Grid, GridContainer } from "styles/Grid.styles";

const MyPackagesPage: FC = () => {
  const [modalShown, setModalShown] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({} as IPackageData);
  const [packageReload, setPackageReload] = useState(0);
  const [packages, setPackages] = useState([] as Array<IPackageData>);
  const [locations, setLocations] = useState([] as Array<ILocationData>);
  const isMobile = matchesBreakpoint(breakpointsMax.md, "max-width");

  const loadData = async () => {
    try {
      const user = await Api.getUser();
      setPackages(user?.data.data.packages);
      const locations = await Api.getLocations();
      setLocations(locations.data.data);
    } catch (error) {
      console.log("loadData", error);
    }
  };
  const getScrollPosition = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  };

  const disableScrolling = useCallback(() => {
    const value = getScrollPosition();
    window.onscroll = () => {
      window.scrollTo({ top: value });
    };
  }, []);

  const enableScrolling = () => {
    window.onscroll = () => {};
  };

  useEffect(() => {
    return modalShown ? disableScrolling() : enableScrolling();
  }, [disableScrolling, modalShown]);

  const onPackageSelected = (newSelectedPackage: IPackageData) => {
    setModalShown(true);
    setSelectedPackage(newSelectedPackage);
  };

  const onClose = () => {
    setModalShown(false);
  };
  function onPackageAllocated(
    selectedPackage: IPackageData,
    location: ILocationData
  ) {
    setPackageReload(packageReload + 1);
  }

  const getModalBackgroundHeight = () => {
    if (isMobile) return "calc(100% - 40px)";
    return "calc(100% - 40px)";
  };

  const pageView = () => {
    return (
      <>
        <ApiLoaderView apiCall={loadData} reload={packageReload}>
          <MyPackagesMetaTags />
          <GridContainer>
            <Grid
              smCol="1/4"
              mdCol="1/8"
              lgCol="1/12"
              smRow="3/3"
              mdRow="3/3"
              lgRow="2/3"
            >
              <MyPackages
                packages={packages}
                onPackageSelected={onPackageSelected}
              />
            </Grid>
          </GridContainer>
        </ApiLoaderView>
        <SfModal
          onClose={() => setModalShown(false)}
          shown={modalShown}
          backgroundHeight={getModalBackgroundHeight()}
          type="theia"
        >
          <AllocatePackageComponent
            selectedPackage={selectedPackage || ({} as IPackageData)}
            locations={locations}
            onPackageAllocated={onPackageAllocated}
            onClose={onClose}
          />
        </SfModal>
      </>
    );
  };
  return <>{pageView()}</>;
};

export default MyPackagesPage;
