import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";
import { zIndex } from "styles/zIndex.styles";

const Flexbox = css`
  display: flex;
`;
const FlexboxCenter = css`
  align-items: center;
  justify-content: center;
`;
const FlexboxSpaceAround = css`
  align-items: center;
  justify-content: space-between;
`;

const direction = (p: any) => {
  if (p.direction === "rtl") {
    return `
      right: ${p.sidebar ? "256px" : "96px"};
      left:0;
      padding-left: 40px;
      padding-right: 8px;
    `;
  }
  return `
    left: ${p.sidebar ? "256px" : "96px"};
    right:0;
    padding-right: 40px;
    padding-left: 8px;
   `;
};

export const TopHeaderContainer = styled.div<{
  sidebar: boolean;
  direction: string | any;
}>`
  backdrop-filter: saturate(180%) blur(10px);
  padding: 20px;
  border-bottom: 1px solid #6b6c7a;
  position: relative;
  z-index: ${zIndex.topHeader};
  display: flex;
  position: fixed;
  align-items: center;
  top: 0;
  ${direction}
  @media ${breakpoints.mobile} {
    display: none;
  }
  @media ${breakpoints.tablet} {
    padding-right: 20px;
  }
  transition: 0.5s;
  background: ${(p: any) => p.theme.colors.brandColors.backgroundTone};
`;
export const TopHeaderWrapper = styled.div`
  height: 42px;
  width: 100%;
  position: relative;
  ${Flexbox}
  ${FlexboxSpaceAround} 
  display: flex;
  justify-content: space-between;
  button {
    outline: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 10px;
    ${Flexbox}
    ${FlexboxCenter}
  }
`;
export const RtlSidebar = styled.div<{
  isSidebarShown: boolean;
  isRtl: boolean;
}>`
  ${({ isRtl, isSidebarShown }) =>
    isRtl && isSidebarShown ? `rotate(0)` : `rotate(-180deg)`};
  ${({ isRtl }) =>
    isRtl
      ? ` div {
      &:last-child {
        margin-right: 15px;
      }
    }`
      : ""}
`;
export const TopHeaderImage = styled.div<{ isSidebarShown: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  transform: ${({ isSidebarShown }) =>
    isSidebarShown ? `rotate(-180deg)` : `rotate(0deg)`};
  &.arrow__open {
    svg {
      #arrowFill {
        fill: ${(p: any) => p.theme.colors.textColors.textGrey};
      }
    }
  }
`;

export const PageName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(p: any) => p.theme.colors.textColors.textGrey};
`;

export const TopHeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ToggleWrapper = styled.div`
  ${(p: any) =>
    p.theme.direction === "rtl" ? "margin-left: 28px;" : "margin-right: 28px;"}
  label {
    ::after {
      height: 18.4px !important;
      margin-left: 3px;
    }
  }
  svg {
    height: 14px;
  }
  :nth-child(2) {
    width: 26px;
  }
`;
