import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useAxiosResult = (axiosResult: any) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const result = { error: "", success: "", serverError: "" };
    if (axiosResult?.data?.error) {
      result.error = t(axiosResult?.data?.error);
    }
    if (axiosResult?.data?.success) {
      result.success = axiosResult?.data?.success;
    }
    if (axiosResult?.data?.serverError) {
      result.serverError = axiosResult?.data?.serverError;
    }
    return result;
  }, [t, axiosResult]);
};
