import { useTranslation } from "react-i18next";

import { Request } from "./IWallet";
import MintingDetailsDateItems from "./MintingDetailsDateItems";
import * as S from "./SharedStyles.styles";

interface MintingDetailsItemProps {
  units?: number;
  walletId?: string;
  email?: string;
  requestStatuses?: Request[];
}

const MintingDetailsItem = ({
  units,
  walletId,
  email,
  requestStatuses,
}: MintingDetailsItemProps) => {
  const { t } = useTranslation();

  return (
    <S.MintDetailGroup>
      <S.MintDetailTitle>
        {t("units")}
        <S.MintingDetailLabel>
          <b>{units}</b>
        </S.MintingDetailLabel>
      </S.MintDetailTitle>
      <S.MintDetailTitle walletID>
        {t("Wallet Id")}
        <S.MintingDetailLabel>{walletId}</S.MintingDetailLabel>
      </S.MintDetailTitle>
      <S.MintDetailTitle>
        {t("email")}
        <S.MintingDetailLabel>{email}</S.MintingDetailLabel>
      </S.MintDetailTitle>
      <MintingDetailsDateItems requestStatuses={requestStatuses} />
    </S.MintDetailGroup>
  );
};

export default MintingDetailsItem;
