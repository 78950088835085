import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SfTable } from "@successfactory/sf-libraries";

import { useAppState } from "AppContext";
import {
  useTheiaUnitsDispatch,
  useTheiaUnitsState,
} from "stores/TheiaUnitsProvider";
import ILocationData from "interface/ILocationData";
import { LocationTabs } from "interface/IpoolsName";
import IncomeCalculatorPageBanner from "components/Banners/AcademyPageBanner/IncomeCalculatorPage/IncomeCalculatorPageBanner";
import { LocationIcon } from "components/LocationIcon/LocationIcon";
import UnitsCell from "./Cell/UnitsCell";

import { ReactComponent as Up } from "../../assets/icons/icon-up.svg";
import { ReactComponent as Down } from "../../assets/icons/icon-down.svg";
import { ReactComponent as Default } from "../../assets/icons/icon-default.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh-square.svg";

import { UnitCellWrapper } from "./Cell/UnitCell.styles";
import {
  TableRowCountry,
  TableRowColumn,
  TableRowColumnText,
  TableWrapper,
  RefreshContainer,
  EuroSign,
} from "./Table.styles";
import { Grid } from "styles/Grid.styles";

export const IncomeCalculatorTable = (props: {
  tabsName: LocationTabs;
  currentTab: string;
}) => {
  const tabsName = props.tabsName;
  const currentTab = props.currentTab;

  const { isDirection } = useAppState();
  const [incomeCalData, setIncomeCalData] = useState([]);
  const { t } = useTranslation();
  const state = useTheiaUnitsState();

  const filterRegionAndCityData = useCallback(
    (state) => {
      return state.locations?.filter(
        (res: ILocationData) => res.type === loadIncomeCaldData(tabsName)
      );
    },
    [tabsName]
  );

  useEffect(() => {
    setIncomeCalData(filterRegionAndCityData(state));
  }, [filterRegionAndCityData, state]);

  const tabMapping = {
    [LocationTabs.CITIES]: {
      type: "city",
      name: t("Cities"),
    },
    [LocationTabs.VERTICALS]: {
      type: "vertical",
      name: t("Verticals"),
    },
    [LocationTabs.REGIONS]: {
      type: "region",
      name: t("Regions"),
    },
    [LocationTabs.CONTINENTS]: {
      type: "continent",
      name: t("World Areas"),
    },
  };

  const loadIncomeCaldData = (tabsName: LocationTabs) =>
    tabMapping[tabsName]?.type;

  const ShowRegionAndCityName = (tabsName: LocationTabs) =>
    tabMapping[tabsName]?.name;

  const options = {
    sortable: true,
    pagination: {
      pageSize: 30,
    },
    buttonAtEnd: false,
    sortIcon: {
      up: <Up />,
      down: <Down />,
      default: <Default />,
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsD = useMemo(
    () => [
      {
        Header: "",
        id: "Empty",
        Cell: (cellInfo: any) => {
          return "";
        },
      },
      {
        Header: `${t(ShowRegionAndCityName(tabsName))}`,
        accessor: (d: ILocationData) => `${d.region}`,
        sortType: (rowA: any, rowB: any) => {
          if (rowA.original.country > rowB.original.country) return 1;
          if (rowB.original.country > rowA.original.country) return -1;
          return 0;
        },
        Cell: (cellInfo: any) => {
          const cellDetails = cellInfo.row.original;
          return (
            <TableRowCountry>
              <LocationIcon
                image={cellDetails.logo}
                type={cellDetails.type}
                darkImage={cellDetails.dark_logo}
                updatedAt={cellDetails.updated_at}
                className="income-calculator-location-icons"
                fitLogo
              />
              {t(cellDetails.region)}
            </TableRowCountry>
          );
        },
      },
      {
        Header: `${t("Potential Revenue")}`,
        accessor: "potentialRev",
        sortType: (rowA: any, rowB: any) => {
          if (rowA.original.potentialRev > rowB.original.potentialRev)
            return -1;
          if (rowB.original.potentialRev > rowA.original.potentialRev) return 1;
          return 0;
        },
        Cell: (cellInfo: any) => {
          return (
            <UnitCellWrapper columnName="potentialRevenue">
              <UnitsCell
                cellInfo={cellInfo}
                mutationFieldName="potentialRevOperations"
                amount={cellInfo.row.original.potentialRev}
                poolsType={currentTab}
              />
            </UnitCellWrapper>
          );
        },
      },
      {
        Header: `${t("Units")}`,
        accessor: "units",
        sortType: (rowA: any, rowB: any) => {
          if (rowA.original.userShares > rowB.original.userShares) return -1;
          if (rowB.original.userShares > rowA.original.userShares) return 1;
          return 0;
        },
        Cell: (cellInfo: any) => {
          return (
            <UnitCellWrapper columnName="units">
              <UnitsCell
                cellInfo={cellInfo}
                mutationFieldName="unitsOperations"
                amount={cellInfo.row.original.userShares}
                poolsType={currentTab}
              />
            </UnitCellWrapper>
          );
        },
      },
      {
        Header: `${t("Distribution %")}`,
        accessor: "distribution",
        sortType: (rowA: any, rowB: any) => {
          if (rowA.original.distribution > rowB.original.distribution)
            return -1;
          if (rowB.original.distribution > rowA.original.distribution) return 1;
          return 0;
        },
        Cell: (cellInfo: any) => {
          const cellData = cellInfo.row.original;
          return (
            <TableRowColumn>
              <TableRowColumnText>
                {cellData.distribution.toFixed(2)} %
              </TableRowColumnText>
            </TableRowColumn>
          );
        },
      },
      {
        Header: `${t("Potential Income")}`,
        accessor: "estIncome",
        sortType: (rowA: any, rowB: any) => {
          if (rowA.original.estIncome > rowB.original.estIncome) return -1;
          if (rowB.original.estIncome > rowA.original.estIncome) return 1;
          return 0;
        },
        Cell: (cellInfo: any) => {
          const cellData = cellInfo.row.original;
          const changed = cellData.estIncome === 0 ? false : true;
          return (
            <TableRowColumn>
              <TableRowColumnText changed={changed}>
                <EuroSign>€ {cellData.estIncome.toFixed(2)}</EuroSign>
              </TableRowColumnText>
            </TableRowColumn>
          );
        },
      },
      {
        Header: "",
        accessor: "refresh",
        Cell: (cellInfo: any) => {
          const dispatch = useTheiaUnitsDispatch();
          const state = useTheiaUnitsState();
          const cellData = cellInfo.row.original;
          const changed =
            cellData.estIncome === 0 && cellData.potentialRev === 0
              ? false
              : true;
          const rowResetHandler = (type: string) => {
            const cellData = cellInfo.row.original;
            dispatch({
              type: type,
              region_code: cellData.region_code,
              data: {
                potentialRev: 0,
                units: state.originalLocations.map(
                  (location: any, index: number) => {
                    return location.region_code === cellData.region_code
                      ? location.userShares
                      : null;
                  }
                ),
              },
            });
          };

          return (
            <RefreshContainer changed={changed}>
              <Refresh onClick={() => rowResetHandler("resetRow")} />
            </RefreshContainer>
          );
        },
      },
    ],
    []
  );

  const columns = useMemo(() => columnsD, [columnsD]);

  return (
    <>
      <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
        <IncomeCalculatorPageBanner
          type=""
          tabsName={tabsName}
          ShowRegionAndCityName={ShowRegionAndCityName(tabsName)}
        />
      </Grid>{" "}
      <TableWrapper
        tableName="incomeCalculator"
        style={{ paddingBottom: "300px" }}
      >
        <SfTable
          translator={t}
          dir={isDirection}
          columns={columns}
          data={incomeCalData}
          options={options}
          showBreakpointTablet={true}
          getRowProps={(row: any) => ({
            style: {
              backgroundColor:
                row.values.potentialRev > 0 ? "rgba(107, 170, 191, 0.4)" : null,
            },
          })}
        />
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
          <IncomeCalculatorPageBanner
            type="sticky-income-calculator"
            tabsName={tabsName}
            ShowRegionAndCityName={ShowRegionAndCityName(tabsName)}
          />
        </Grid>
      </TableWrapper>
    </>
  );
};
