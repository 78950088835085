import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonStyles } from "styles/Button.styles";

import FormInput from "../Components/Common/FormInput";
import { setupPasswordSchema as schema } from "../schemas/login.schema";
import * as S from "../styles/Shared.styles";

interface SetupPasswordFormProps {
  onSubmit: (data: any) => void;
  userMail: string;
}

const SetupPasswordForm = ({ ...props }: SetupPasswordFormProps) => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { onSubmit, userMail } = props;

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = methods;

  const {
    hasUppercase,
    hasLowercase,
    hasNumber,
    hasSpecialChar,
    hasMinCharacters,
    hasMaxCharacters,
  } = (errors?.password as any) || {};

  const checkRequirements = (rule: boolean) => (
    <span className={`${rule && isDirty ? "green" : "red"}`}>✓</span>
  );

  const PasswordRequirement = ({
    checkResult,
    label,
  }: {
    checkResult: boolean;
    label: string;
  }) => (
    <S.PasswordChecklistLi>
      {checkRequirements(checkResult)}

      {t(label)}
    </S.PasswordChecklistLi>
  );

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="email"
          type="email"
          title={t("Email")}
          disabled={true}
          placeHolder={userMail}
        />

        <FormInput
          {...register("password", { required: true, minLength: 6 })}
          name="password"
          type="password"
          title={t("Password")}
          placeHolder={t("Please enter your password")}
        />

        <FormInput
          {...register("confirmPassword", { required: true, minLength: 6 })}
          name="confirmPassword"
          type="password"
          title={t("Repeat Password")}
          placeHolder={t("Please re-enter your password")}
        />

        <S.PasswordChecklistWrapper>
          <S.PasswordChecklistTitle>
            {t("Password must contain:")}
          </S.PasswordChecklistTitle>

          <S.PasswordChecklistUl>
            {[
              {
                checkResult: hasMinCharacters?.type !== "custom",
                label: "Min 8 characters",
              },
              {
                checkResult: hasMaxCharacters?.type !== "custom",
                label: "Max 20 characters",
              },
              {
                checkResult: hasUppercase?.type !== "custom",
                label: "Min 1 uppercase letter (A-Z)",
              },
              {
                checkResult: hasLowercase?.type !== "custom",
                label: "Min 1 lowercase letter (a-z)",
              },
              {
                checkResult: hasNumber?.type !== "custom",
                label: "Min 1 number (0-9)",
              },
              {
                checkResult: hasSpecialChar?.type !== "custom",
                label: "Min 1 special character",
              },
            ].map(({ checkResult, label }) => (
              <PasswordRequirement
                key={label}
                checkResult={checkResult}
                label={label}
              />
            ))}
          </S.PasswordChecklistUl>
        </S.PasswordChecklistWrapper>

        <ButtonStyles
          type="submit"
          $twoTone={true}
          disabled={!isValid || !isDirty}
        >
          {t("Change Password")}
        </ButtonStyles>
      </S.Form>
    </FormProvider>
  );
};

export default SetupPasswordForm;
