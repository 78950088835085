import React, { FunctionComponent, useReducer } from "react";

import { enrichThemeWithDirection } from "@successfactory/sf-libraries";
import { appReducer, AppState, initialAppState } from "AppReducer";
import { ThemeProvider } from "styled-components";

const AppStateContext = React.createContext(initialAppState);
const AppDispatchContext = React.createContext({});

export const useAppState = () => {
  const context = React.useContext(AppStateContext);
  if (context === undefined)
    throw new Error("useAppState must be used within a AppProvider");
  return context;
};

export const useAppDispatch = (): Function => {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined)
    throw new Error("useAppDispatch must be used within a AppProvider");
  return context as Function;
};

const initialState: AppState = { ...initialAppState };

const AppProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const cookieTheme = `theme=${state.theme.name};path=/;domain=${
    window.location.hostname.split(".").length === 3
      ? window.location.hostname.split(".").slice(1).join(".")
      : window.location.hostname
  }`;
  document.cookie = cookieTheme;

  const cookieLanguage = `language=${state.language};path=/;domain=${
    window.location.hostname.split(".").length === 3
      ? window.location.hostname.split(".").slice(1).join(".")
      : window.location.hostname
  }`;
  document.cookie = cookieLanguage;

  const cookieString = `token=${state.token};path=/;domain=${
    window.location.hostname.split(".").length === 3
      ? window.location.hostname.split(".").slice(1).join(".")
      : window.location.hostname
  }`;
  document.cookie = cookieString;

  const themeWithDirection = enrichThemeWithDirection(
    state.isDirection,
    state.theme
  );

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={themeWithDirection}>{children}</ThemeProvider>
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export default AppProvider;
