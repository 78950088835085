import { Children, FC, ReactNode } from "react";

interface StepperProps {
  children: ReactNode;
  activeStep: number;
}

const Stepper: FC<StepperProps> = ({ children, activeStep }) => {
  return (
    <>
      {Children.map(children, (child, index) => index === activeStep && child)}
    </>
  );
};

export default Stepper;
