import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSfModal } from "@successfactory/sf-libraries";
import { useAxiosPost } from "api/useApi";
import Loader from "components/Loader/Loader";
import Content from "components/Login/Components/Common/Content";
import TheiaSvgIcons from "components/TheiaSvgIcons/TheiaSvgIcons";
import { FormsContainer } from "pages/login/PublicTemplate.styles";

import { useAppState } from "AppContext";

import CommonModal from "./Common/Modal";

import { APIEndpoints } from "../constants/APIEndpoints";
import { useAxiosResult } from "../constants/useAxiosResult";
import ForgotPasswordForm from "../Forms/ForgotPasswordForm";

const ForgotPasswordComponent = () => {
  const { t } = useTranslation();
  const { language, theme } = useAppState();

  const [forgatPassword, forgatPasswordResult, forgatPasswordStatus] =
    useAxiosPost(APIEndpoints.FORGOT_PASSWORD);

  const [modalProps, setShown] = useSfModal("theia");

  const onSubmit = (data: any) => {
    const updatedData = { ...data, language };
    setShown(true);
    forgatPassword(updatedData);
  };

  const { error, success, serverError } = useAxiosResult(forgatPasswordResult);

  const isError = Boolean(error) || Boolean(serverError);
  const isResult = Boolean(success) || isError;

  useEffect(() => {
    if (isResult) {
      setShown(true);
    }
  }, [isResult, setShown]);

  if (forgatPasswordStatus === "loading") return <Loader />;

  const modalCurrentProps = {
    showCrossmark: false,
    closeOnOutsideClick: true,
    ...modalProps,
  };

  return (
    <>
      <FormsContainer>
        <Content
          title={t("Forgot Password")}
          description={t("Please enter your email address")}
        />
        <ForgotPasswordForm onSubmit={onSubmit} />
      </FormsContainer>
      {isResult && (
        <CommonModal
          modalProps={modalCurrentProps}
          isError={isError}
          dynamicModalContent={{
            title: isError ? t("Oops!") : t("Congratulations!"),
            icon: (
              <TheiaSvgIcons
                name={isError ? "error" : "success"}
                fill={
                  isError
                    ? theme.colors.textColors.textLightGrey
                    : theme.colors.brandColors.brandSecondary
                }
                width={40}
                height={40}
              />
            ),
            description: isError
              ? t(error)
              : serverError
              ? t(serverError)
              : t(success),

            buttons: [{ label: t("Back to Login"), href: "/login" }],
          }}
        />
      )}
    </>
  );
};

export default ForgotPasswordComponent;
