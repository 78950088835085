import { ChangeEvent } from "react";

import InputField from "components/Table/Cell/InputField";

import { WalletFormItemsProps, WalletOptionProps } from "./IWallet";
import * as S from "./SharedStyles.styles";

const WalletFormItems = ({
  title,
  group,
  data,
  onChange,
  placeHolder,
  user,
  type,
}: WalletFormItemsProps) => {
  const isDataListExist = (user?.[type]?.length ?? 0) > 0;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(group, e.target.value);
  };

  return (
    <S.FormWrapper error={data.errors.length > 0}>
      <S.FormLabel>{title}</S.FormLabel>
      <InputField
        amount={data.value}
        onChange={handleInputChange}
        placeholder={placeHolder}
        list={type}
      />
      {isDataListExist && (
        <S.Datalist id={type}>
          {user?.[type]?.map((wallet: WalletOptionProps, index: number) => {
            return (
              <option key={wallet.address + index} value={wallet.address} />
            );
          })}
        </S.Datalist>
      )}
      {data.errors.length > 0 && <S.FormError>{data.errors[0]}</S.FormError>}
    </S.FormWrapper>
  );
};

export default WalletFormItems;
