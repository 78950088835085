import React from 'react';
import { calculateShares, getUnitsString } from 'util/calculateShares';
import IPackageData from 'interface/IPackageData';
import ILocationData from 'interface/ILocationData';
import {
  AllocatePackageModal,
  AllocatePackageModalWrapper,
  ButtonsBox,
  CancelButton,
  ContentDescription,
  ContentHead,
  CountryName,
} from './AllocatePackage.styles';
import { SfButton } from '@successfactory/sf-libraries';
import { useTranslation } from 'react-i18next';
import { LocationIcon } from 'components/LocationIcon/LocationIcon';
import {
  capitalizeEachWordLetter,
  capitalizeFirstLetter,
} from 'util/capitalizeFirstLetter';

interface OneAllocatePackageProps {
  selectedPackage: IPackageData;
  selectedLocation: ILocationData;
  setCurrentStep: Function;
  isSoldOut: boolean;
}

const OneAllocatePackage: React.FC<OneAllocatePackageProps> = ({
  selectedPackage,
  selectedLocation,
  setCurrentStep,
  isSoldOut,
}) => {
  const { t } = useTranslation();

  const displayPoolShares = (
    selectedPackage: IPackageData,
    selectedLocation: ILocationData
  ) => {
    const unitsString = getUnitsString(selectedPackage, selectedLocation, [
      'universal',
    ]);

    return unitsString;
  };

  const unitpoolsAllocatedDetails = () => {
    const { type: locationType, region, continent } = selectedLocation || {};
    let continentShares = 0;

    const calculateShare = calculateShares(selectedPackage, selectedLocation);

    if (
      selectedLocation?.type === 'region' &&
      selectedLocation?.continentValue
    ) {
      continentShares = isSoldOut
        ? 0
        : selectedPackage.value / selectedLocation.continentValue;
    }

    const poolShares = t(displayPoolShares(selectedPackage, selectedLocation));

    const unitTypes = calculateShare === 1 ? 'unit' : 'units';
    const locationTypeLabel =
      locationType === 'continent'
        ? capitalizeEachWordLetter('world area')
        : capitalizeFirstLetter(locationType);
    const regionText = `${t('in the')} ${t(locationTypeLabel)} ${t('Pool')}`;

    const getContinentText = () => {
      if (locationType === 'region') {
        const soldOutText = isSoldOut ? ` (${t('Sold Out')})` : '';
        return `${t(continent)} ${t('in the World Area Pool')}${soldOutText}`;
      }
      return '';
    };

    const contentDescription =
      locationType === 'region'
        ? t(
            'You’ll receive {{calculatedShare}} {{unitType}} to {{region}} {{regionText}}, {{continentShares}} units to {{continentText}} and {{poolShares}}',
            {
              calculatedShare: calculateShare,
              unitType: t(unitTypes),
              region: t(region),
              regionText: t(regionText),
              poolShares: t(poolShares),
              continentText: getContinentText(),
              continentShares,
            }
          )
        : t(
            'You’ll receive {{calculatedShare}} {{unitType}} to {{region}} {{regionText}} and {{poolShares}}',
            {
              calculatedShare: calculateShare,
              unitType: t(unitTypes),
              region: t(region),
              regionText,
              poolShares,
            }
          );

    return <ContentDescription>{t(contentDescription)}</ContentDescription>;
  };

  return (
    <AllocatePackageModal>
      <>
        <AllocatePackageModalWrapper>
          <LocationIcon
            image={selectedLocation.map || selectedLocation.logo}
            darkImage={selectedLocation.dark_logo}
            fitLogo={false}
            height="160px"
            type={selectedLocation.type}
            updatedAt={selectedLocation.updated_at}
          />
          <ContentHead>
            {t('Are you sure you want to allocate this membership to')}
            <CountryName>
              {t(selectedLocation.region)}
              {t('?')}
            </CountryName>
          </ContentHead>
        </AllocatePackageModalWrapper>
        {unitpoolsAllocatedDetails()}
      </>
      <ButtonsBox>
        <CancelButton>
          <SfButton
            onClick={() => {
              setCurrentStep(0);
            }}
            label={t('Cancel')}
          ></SfButton>
        </CancelButton>
        <SfButton
          twoTone
          className="button-green"
          onClick={() => {
            setCurrentStep(2);
          }}
          label={t('Confirm')}
        ></SfButton>
      </ButtonsBox>
    </AllocatePackageModal>
  );
};

export default OneAllocatePackage;
