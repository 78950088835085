import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SfDropDown, SfTable } from "@successfactory/sf-libraries";
import axios from "axios";

import { getHeader } from "api/useApi";
import { useAppState } from "AppContext";

import BannerButton from "components/BannerButton/BannerButton";
import BuyButton from "components/Button/BuyButton";
import { LocationIcon } from "components/LocationIcon/LocationIcon";

import ILocationData, {
  IDashboardLocation,
  IRegionAndCity,
} from "interface/ILocationData";
import { LocationTabs } from "interface/IpoolsName";

import { capitalizeFirstLetter } from "util/capitalizeFirstLetter";
import {
  getCoinImage,
  getMobileSortingParam,
  getMobileSortLabels,
  getSortingParam,
  isSoldOut,
} from "util/homePageTable";
import { getRussianTranslations } from "util/russianTranslations";

import * as S from "./Table.styles";

//TODO: improve tsconfig for import
import { ReactComponent as Default } from "../../assets/icons/icon-default.svg";
import { ReactComponent as Down } from "../../assets/icons/icon-down.svg";
import { ReactComponent as Up } from "../../assets/icons/icon-up.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import * as TT from "../TheiaTable/TheiaTable.styles";

export const LocationDistributionTable = (props: {
  data: Array<IRegionAndCity>;
  tabsName: LocationTabs;
  pageCount: number;
  handleButtonClick: Function;
  handleMintIconClick: Function;
  getMintingStatus: Function;
  hasTriedAndFailed: boolean;
  isFeatureEnabled: boolean;
  isMintDataEmpty: Function;

  setTableData: (data: Array<IRegionAndCity>) => void;
}) => {
  const { isDirection, theme } = useAppState();
  const { t } = useTranslation();
  const getCurrentLang = localStorage.getItem("language");
  const {
    data,
    tabsName,
    pageCount,
    setTableData,
    handleButtonClick,
    handleMintIconClick,
    isFeatureEnabled,
    hasTriedAndFailed,
    getMintingStatus,
  } = props;

  const [column, setColumn] = useState<any>();

  useEffect(() => {
    column && onCellSort();
  }, [column]);

  const getTableHeaderName = (tabsName: LocationTabs) => {
    switch (tabsName) {
      case LocationTabs.REGIONS:
        return t("Region");
      case LocationTabs.VERTICALS:
        return t("Vertical");
      case LocationTabs.CONTINENTS:
        return t("World Area");
      default:
        return t("City");
    }
  };

  const baseUrl =
    process.env.REACT_APP_API_URL ?? "https://api.stage.jointheview.com";

  const getColumn = () => {
    return [
      {
        Header: () => <S.TableRowPromo>{t("Promo")}</S.TableRowPromo>,
        accessor: (d: ILocationData) => `${d}`,
        id: "Coin",
        Cell: (cellInfo: any) => {
          return (
            <S.TableRowPromo>
              {getCoinImage(cellInfo.row?.original?.multiplier)}
            </S.TableRowPromo>
          );
        },
      },
      {
        Header: `${getTableHeaderName(tabsName)}`,
        accessor: (d: ILocationData) => `${d}`,
        Cell: (cellInfo: any) => {
          const cellDetails = cellInfo.row.original;

          return (
            <S.TableRowCountry>
              <S.LocationIconContainer>
                <LocationIcon
                  image={cellDetails.logo}
                  type={cellDetails.type}
                  darkImage={cellDetails.dark_logo}
                  updatedAt={cellDetails.updated_at}
                />
              </S.LocationIconContainer>
              <S.LocationText>{t(cellDetails.region)}</S.LocationText>
            </S.TableRowCountry>
          );
        },
      },
      {
        Header:
          getCurrentLang === "ru"
            ? `${t("Your Dashboard Units")}`
            : `${t("Your Units")}`,
        accessor: (d: { [key: string]: any }) => `${d.max_available}`,
        Cell: (cellInfo: any) => {
          const cellDetails = cellInfo.row.original;
          const translationLabel = getRussianTranslations(
            getCurrentLang,
            cellDetails.user_units,
            t
          );
          return (
            <S.TableRowUnits>
              <S.TableRowColumnText>
                {cellDetails.user_units} {translationLabel}
              </S.TableRowColumnText>
              <S.TableRowColumnSubtext upperCase>
                {cellDetails.total_units} {t("Total")}
              </S.TableRowColumnSubtext>
            </S.TableRowUnits>
          );
        },
      },

      {
        Header: `${t("Minted Units")}`,
        isVisible: isFeatureEnabled,
        accessor: (d: ILocationData) => `${d}`,
        Cell: (cellInfo: any) => {
          const cellDetails = cellInfo.row.original;

          const mintingStatus = getMintingStatus(
            cellDetails.user_minted,
            cellDetails.user_minting_pending
          );

          const shouldRenderIcon = cellDetails.mint_request_history.length > 0;

          return (
            mintingStatus &&
            (hasTriedAndFailed ? (
              <S.CustomTableButton>
                <BannerButton
                  labelText={t("Try Again")}
                  onClick={() => handleButtonClick(cellDetails)}
                  $twoTone
                />
              </S.CustomTableButton>
            ) : (
              <S.TableRowUnits>
                <S.TableRowColumnText>
                  {t(mintingStatus.text)}
                  {shouldRenderIcon && (
                    <div onClick={() => handleMintIconClick(cellDetails)}>
                      <Info />
                    </div>
                  )}
                </S.TableRowColumnText>
                <S.TableRowColumnSubtext>
                  {t(mintingStatus.subtext)}
                </S.TableRowColumnSubtext>
              </S.TableRowUnits>
            ))
          );
        },
      },
      {
        Header: `${t("Distribution")}`,
        accessor: "",
        Cell: (cellInfo: any) => {
          const cellDetails = cellInfo.row.original;
          return (
            <S.TableRowColumn>
              <S.TableRowColumnText>
                {cellDetails.distribution}%
              </S.TableRowColumnText>
              <S.TableRowColumnSubtext>
                {cellDetails.total_allocated_units} {t("SOLD")}
              </S.TableRowColumnSubtext>
            </S.TableRowColumn>
          );
        },
      },

      {
        Header: `${t("Total Minted")}`,
        isVisible: isFeatureEnabled,
        accessor: "",
        Cell: (cellInfo: any) => {
          const cellDetails = cellInfo.row.original;

          const allMinted = cellDetails.all_minted;
          const allMintingProgress = cellDetails.all_mints_pending;
          const mintingStatus = getMintingStatus(allMinted, allMintingProgress);

          return (
            mintingStatus && (
              <S.TableRowColumn>
                <S.TableRowColumnText>
                  {t(mintingStatus.text)}
                </S.TableRowColumnText>
                <S.TableRowColumnSubtext upperCase={false}>
                  {t(mintingStatus.subtext)}
                </S.TableRowColumnSubtext>
              </S.TableRowColumn>
            )
          );
        },
      },
      {
        Header: `${t("Current price")}`,
        accessor: "value",
        Cell: (cellInfo: any) => {
          const cellData = cellInfo.row.original;

          const isDisabled =
            !cellData.is_mintable || cellData.units_mintable.length === 0;

          const soldOut = isSoldOut(cellData);
          const unitsDisplay = isFeatureEnabled ? (
            <S.UnitsDisplay>{cellData.value} €</S.UnitsDisplay>
          ) : (
            <S.UnitsDisplay soldOut={soldOut}>
              {cellData.value} €
            </S.UnitsDisplay>
          );

          return (
            <S.TableRowColumn>
              {isFeatureEnabled && (
                <S.CustomTableButton>
                  {unitsDisplay}
                  <BannerButton
                    labelText={t("Mint Now")}
                    onClick={() => handleButtonClick(cellData)}
                    disabled={isDisabled}
                    $twoTone
                  />
                </S.CustomTableButton>
              )}
              {!isFeatureEnabled && (
                <>
                  {unitsDisplay}
                  {soldOut && <S.SoldOut>{t("Sold Out")}</S.SoldOut>}
                </>
              )}
            </S.TableRowColumn>
          );
        },
      },
    ];
  };

  const columns = useMemo(() => getColumn(), [getColumn()]);

  const filteredColumns = useMemo(() => {
    return columns.filter((column) => column.isVisible !== false);
  }, [columns]);

  const options = {
    sortable: true,
    pagination: {
      pageSize: data?.length,
    },
    buttonAtEnd: false,
    sortIcon: {
      up: <Up />,
      down: <Down />,
      default: <Default />,
    },
  };

  const tabHeaderName = capitalizeFirstLetter(tabsName);
  const currentTab = getTableHeaderName(tabsName);

  const getSortedResult = (
    isDesc: boolean,
    headerName: string | undefined,
    currentTab: string
  ) => {
    const tabValue = currentTab === "world area" ? "continent" : currentTab;
    const sortingPrefix = isDesc === false ? "-" : isDesc ? "" : "%2b";
    return `${baseUrl}/dashboard_table?locationType=${tabValue}&page=${pageCount}&sorting=${sortingPrefix}${headerName}`;
  };

  const getMobileSortedResult = (e: string, currentTab: string) => {
    const sortParam = getMobileSortingParam(e);
    const tabValue = currentTab === "world area" ? "continent" : currentTab;
    const sortingParam = sortParam ? `&sorting=${sortParam}` : "";
    return `${baseUrl}/dashboard_table?locationType=${tabValue}&page=${pageCount}${sortingParam}`;
  };

  const getTabsRelatedData = (currentTab: string, data: IDashboardLocation) => {
    switch (currentTab) {
      case t("Region"):
        return data?.region?.data;
      case t("City"):
        return data?.city?.data;
      case t("World Area"):
        return data?.continent?.data;
      default:
        return data?.vertical?.data;
    }
  };

  const onCellSort = async () => {
    const isDesc = column?.isSortedDesc;

    const headerName = getSortingParam(column?.id);
    const result = await axios.get(
      getSortedResult(isDesc, headerName, currentTab.toLowerCase()),
      getHeader()
    );

    let sortedData = getTabsRelatedData(
      currentTab,
      result?.data?.locationsGroupedByType
    );
    setTableData(sortedData);
  };

  const onMobileSort = async (e: string) => {
    const result = await axios.get(
      getMobileSortedResult(e, currentTab.toLowerCase()),
      getHeader()
    );
    const dataResult = getTabsRelatedData(
      currentTab,
      result?.data?.locationsGroupedByType
    );
    setTableData(dataResult);
  };

  const mobileSortLabels = getMobileSortLabels(tabHeaderName);

  const DropDownOptions = {
    options: mobileSortLabels,
    placeHolder: t("Please select"),
    hover: {
      backgroundColor: theme.colors.brandColors.brandPrimary,
      textColor: theme.colors.textColors.textBlack,
    },
    border: {
      borderRadius: "8px",
    },
    backgroundColor: "transparent",
    textColor: theme.colors.brandColors.brandSecondary,
  };

  return (
    <>
      {data?.length === 0 ? null : (
        <>
          <TT.SortContainer type="dashboard">
            <S.SortLabel>{t("Sort by")}</S.SortLabel>
            <TT.DropdownContainer>
              <SfDropDown
                {...DropDownOptions}
                onChange={(e: string) => onMobileSort(e)}
              />
            </TT.DropdownContainer>
          </TT.SortContainer>
        </>
      )}
      <S.TableWrapper tableName="dashboard">
        <SfTable
          translator={t}
          dir={isDirection}
          columns={filteredColumns}
          data={data}
          sorting={true}
          options={options}
          showBreakpointTablet={false}
          tableType="dashboard"
          setColumn={setColumn}
        />
      </S.TableWrapper>
    </>
  );
};
