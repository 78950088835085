import { useHistory } from "react-router-dom";

import {
  breakpointsMin,
  matchesBreakpoint,
} from "@successfactory/sf-libraries";
import i18n from "i18n";
import { IPoolCard } from "interface/IPool";
import { PoolsName } from "interface/IpoolsName";

import routes from "config/routes";

import { Percentage, TextWrapper, ViewDetailsButton } from "./Boxey.styles";

const GetPoolboxItemDescription = (
  userShares: string,
  totalShares: string,
  type: string,
  poolsName: PoolsName
) => {
  const isDesktop = matchesBreakpoint(breakpointsMin.xl, "min-width");
  const history = useHistory();
  const redirectToPoolsTab = (poolsName: PoolsName) => {
    switch (poolsName) {
      case "universal":
        history.push(routes.POOLS_UNIVERSAL);
        break;
      case "global":
        history.push(routes.POOLS_GLOBAL);
        break;
      case "founders":
        history.push(routes.POOLS_FOUNDERS);
        break;
      case "leadership":
        history.push(routes.POOLS_LEADERSHIP);
        break;
      default:
        history.push(routes.POOLS_INFINITY);
    }
  };

  return (
    <TextWrapper isPoolsPage={type === "pools-page"}>
      <span>
        <Percentage>
          {userShares} {i18n.t("units")}
        </Percentage>
        {type === "pools-page" && !isDesktop ? <br></br> : null}
      </span>
      {type === "home" ? <br></br> : ""}
      <span>
        &nbsp;
        {i18n.t("of ")}
        {totalShares} {i18n.t("total")}
      </span>
      <br></br>
      <span>
        {i18n.t("you own")} &nbsp;
        <Percentage>
          {(parseInt(userShares) / parseInt(totalShares)) * 100
            ? ((parseInt(userShares) / parseInt(totalShares)) * 100).toFixed(1)
            : "0"}
          %
        </Percentage>
      </span>
      {type === "home" && (
        <div>
          <ViewDetailsButton
            onClick={() => redirectToPoolsTab(poolsName)}
            className="view-details"
          >
            {i18n.t("View Details >")}
          </ViewDetailsButton>
        </div>
      )}
    </TextWrapper>
  );
};

const poolBoxItems: Array<IPoolCard> = [
  {
    key: "universal",
    title: i18n.t("Universal Pool"),
    icon: (
      <img
        src="/assets/re-branding/pool-icons/universal.png"
        alt="founders"
        width="200px"
      />
    ),
    ownedShares: "",
    totalShares: "",
    description: GetPoolboxItemDescription,
  },

  {
    key: "global",
    title: i18n.t("Global Pool"),
    icon: (
      <img
        src="/assets/re-branding/pool-icons/globe.png"
        alt="global"
        width="200px"
      />
    ),
    ownedShares: "",
    totalShares: "",
    description: GetPoolboxItemDescription,
  },

  {
    key: "founders",
    title: i18n.t("Founders Circle"),
    icon: (
      <img
        src="/assets/re-branding/pool-icons/founders.png"
        alt="founders"
        width="200px"
      />
    ),
    ownedShares: "",
    totalShares: "",
    description: GetPoolboxItemDescription,
  },
  {
    key: "infinity",
    title: i18n.t("Infinity Pool"),
    icon: (
      <img
        src="/assets/static-banners/infinity.png"
        alt="infinity"
        width="200px"
        style={{ marginRight: "35px" }}
      />
    ),
    ownedShares: "",
    totalShares: "",
    description: () => (
      <TextWrapper>
        <span>
          <Percentage>{i18n.t("1% Shared")}</Percentage>
        </span>
        <br></br>
        <span>{i18n.t("and Split")}</span>
        <br></br>
        <a href="/pools-infinity" className="view-details">
          {i18n.t("View Details >")}
        </a>
      </TextWrapper>
    ),
  },
];

export default poolBoxItems;
