import i18n from "i18n";

const getTitle = (units: number): string => {
  if (units > 1) return `${units} ${i18n.t("Units")}`;
  return `${units} ${i18n.t("Unit")}`;
};

export const getCardFooter = (
  perks: any
): Array<{ caption: string; title: string }> => {
  const pools = [
    { key: "founders", caption: i18n.t("Founders Circle") },
    { key: "global", caption: i18n.t("Global Pool") },
    { key: "universal", caption: i18n.t("Universal Pool") },
  ];
  const messages: { caption: string; title: string }[] = [];

  for (const pool of pools) {
    const poolKey = perks[pool.key];
    if (poolKey && !poolKey.shareData) {
      poolKey.shareData = { value: 125, shares: 0 };
    }

    if (poolKey?.shareData) {
      messages.push({
        caption: pool.caption,
        title: getTitle(poolKey.shareData.shares),
      });
    }
  }

  return messages || [{ caption: "", title: "" }];
};
