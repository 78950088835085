const size = {
  mobileSmall: "320px",
  mobile: "704px",
  tabletSmall: "768px",
  tabletMedium: "1044px",
  tablet: "1169px",
  desktop: "1170px",
};
export const breakpoints = {
  mobileSmall: `(max-width: ${size.mobileSmall})`,
  mobile: `(max-width: ${size.mobile})`,
  tabletSmall: `(max-width: ${size.tabletSmall})`,
  //tabletMedium: `(min-width: ${size.tabletMedium}) and (max-width: ${size.desktop})`,
  tablet: `(max-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
};
