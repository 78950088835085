import axios from "axios";
import IAllocateData from "interface/IAllocateData";
import { sStorage } from "util/sStorage";

export const baseUrl = process.env.REACT_APP_API_URL; // "http://localhost:5100";

export const getHeader = () => {
  const token = sStorage.get("token");
  return {
    headers: {
      Accept: "application/json",
      Authorization: "bearer " + token,
    },
  };
};
const Api = {
  retrieveFakeToken: async (fakeToken: string) => {
    try {
      const result = await axios.get(
        `${baseUrl}/fake_foreign_jwt/${fakeToken}`
      );

      if (result.status !== 200) {
        const err: any = new Error("getFakeToken response");
        err.message = result.statusText;
        throw err;
      }

      return result;
    } catch (error) {
      const err: any = new Error("getFakeToken request");
      err.message = error;
      throw err;
    }
  },

  verifyToken: async (token: any) => {
    console.log("test");
    try {
      const result = await axios.get(`${baseUrl}/verify/${token}`);
      console.log("result", result);
      if (result.status !== 200) {
        const err: any = new Error("getToken response");
        err.message = result.statusText;
        throw err;
      }

      return result;
    } catch (error) {
      const err: any = new Error("getToken request");
      err.message = error;
      throw err;
    }
  },

  getUser: async () => {
    try {
      const result = await axios.get(`${baseUrl}/user`, getHeader());
      if (result.status !== 200) {
        const err = new Error("getUser response");
        err.message = result.statusText;
        throw err;
      }
      return result;
    } catch (error) {}
  },
  getFeatureFlag: async (featureFlag: string) => {
    try {
      const result = await axios.get(
        `${baseUrl}/feature-flag/${featureFlag}`,
        getHeader()
      );
      if (result.status !== 200) {
        const err = new Error("getUser response");
        err.message = result.statusText;
        throw err;
      }
      return result.data;
    } catch (error) {}
  },
  getPoll: async () => {
    const pollName = "poll-two-april15";
    try {
      const result = await axios.get(
        `${baseUrl}/get-poll/${pollName}`,
        getHeader()
      );

      if (result.status !== 200) {
        const err: any = new Error("getPoll response");
        err.message = result.statusText;
        throw err;
      }
      return result;
    } catch (error) {
      const err: any = new Error("getPoll request");
      err.message = error;
      throw err;
    }
  },

  votePoll: async (pollName: string, locations: Array<string>) => {
    try {
      const result = await axios.post(
        `${baseUrl}/vote-poll/${pollName}`,
        { locations },
        getHeader()
      );

      if (result.status !== 200) {
        const err: any = new Error("votePoll response");
        err.message = result.statusText;
        throw err;
      }
      return result;
    } catch (error) {
      const err: any = new Error("votePoll request");

      err.message = error;
      throw err;
    }
  },

  getLocations: async () => {
    try {
      const result = await axios.get(`${baseUrl}/locations`, getHeader());

      if (result.status !== 200) {
        const err: any = new Error("getLoactions response");
        err.message = result.statusText;
        throw err;
      }
      return result;
    } catch (error) {
      const err: any = new Error("getLoactions request");
      err.message = error;
      throw err;
    }
  },
  getStats: async () => {
    try {
      const result = await axios.get(`${baseUrl}/stats`, getHeader());
      if (result.status !== 200) {
        const err: any = new Error("getStats response");
        err.message = result.statusText;
        throw err;
      }
      return result;
    } catch (error) {
      const err: any = new Error("getStats request");
      err.message = error;
      throw err;
    }
  },
  allocate: async (data: IAllocateData) => {
    try {
      const result = await axios.post(baseUrl + "/allocate", data, getHeader());

      if (result.status !== 200) {
        const err: any = new Error("allocate reponse");
        err.message = result.statusText;

        throw err;
      }

      return result;
    } catch (error) {
      const err: any = new Error("allocate request");
      err.message = error;

      console.log("ERROR?", error);

      throw err;
    }
  },
};
export default Api;
