import React from "react";
import { useTranslation } from "react-i18next";

import { CardsContainer, EmptyCardContent } from "./CardGroups.styles";
import { SfCardMembership } from "./SfCardMembership/SfCardMembership";

import { ReactComponent as Info } from "../../../assets/danger.svg";
import AllocatedCard from "../MembershipCards/CardAllocated";
import CardNotAllocated from "../MembershipCards/CardNotAllocated";

interface CardGroupProps {
  onCardSelected?: Function;
  packages: any;
}

const CardGroups: React.FC<CardGroupProps> = ({ onCardSelected, packages }) => {
  const { t } = useTranslation();
  if (!packages)
    return (
      <div style={{ marginTop: "34px" }}>
        <SfCardMembership type="empty" cardContent={null}>
          <EmptyCardContent>
            <Info />
            <p>{t("It seems that you don't have any allocated locations.")}</p>
          </EmptyCardContent>
        </SfCardMembership>
      </div>
    );
  else
    return packages.length < 1 || undefined ? (
      <div style={{ marginTop: "34px" }}>
        <SfCardMembership type="empty" cardContent={null}>
          <EmptyCardContent>
            <Info />

            <p>{t("It seems that you don't have any allocated locations.")}</p>
          </EmptyCardContent>
        </SfCardMembership>
      </div>
    ) : (
      <CardsContainer>
        {packages.map((card: any, key: number) =>
          card.location ? (
            <AllocatedCard key={key} card={card} />
          ) : (
            <CardNotAllocated
              onCardSelected={onCardSelected}
              key={key}
              card={card}
            />
          )
        )}
      </CardsContainer>
    );
};

export default CardGroups;
