import styled from "styled-components";
import { breakpointMin } from "@successfactory/sf-libraries";

const horizontalPadding = {
  sm: 8,
  md: 20,
  xl: 20,
};

export const GridContainer = styled.div<{ isSidebarOpen?: boolean }>`
  display: grid;
  gap: 18px 32px;
  grid-row: auto;
  grid-column: 1;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  padding: 0 ${horizontalPadding.sm}px 48px;
  width: 100vw;
  box-sizing: border-box;
  overflow: auto;

  ${breakpointMin.sm} {
    gap: 20px 20px;
    grid-row: auto;
    grid-template-columns: repeat(4, 1fr);
  }

  ${breakpointMin.md} {
    grid-row: auto;
    grid-template-columns: repeat(8, 1fr);
    padding: 0 ${horizontalPadding.md}px;
    width: 100%;
  }

  ${breakpointMin.xl} {
    gap: 40px 20px;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 ${horizontalPadding.xl}px;
    max-width: 1300px;
  }
`;

const getGridRow = (rowValue: `${number}/${number}` | undefined) => {
  if (rowValue === undefined) return "";
  const [rowValueStart, rowValueEnd] = rowValue.split("/");
  return `grid-row: ${rowValueStart} / ${rowValueEnd}`;
};

const getGridColumn = (columnValue: `${number}/${number}`) => {
  const [columnValueStart, columnValueEnd] = columnValue.split("/");
  return `grid-column: ${columnValueStart} / ${
    parseInt(columnValueEnd, 10) + 1
  };`;
};

export const Grid = styled.div<{
  smCol: `${1 | 2 | 3 | 4}/${1 | 2 | 3 | 4}`;
  mdCol: `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}/${
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9}`;
  lgCol: `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}/${
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12}`;
  xlCol?: `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}/${
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12}`;
  smRow?: `${number}/${number}`;
  mdRow?: `${number}/${number}`;
  lgRow?: `${number}/${number}`;
  xlRow?: `${number}/${number}`;
  isSidebarOpen?: boolean;
  width?: string;
  hidden?: boolean;
}>`
  ${({ width }) => (width ? `width: ${width};` : "")};
  ${({ hidden }) => (hidden ? `display: none;` : "")};
  ${({ smCol }) => getGridColumn(smCol)};
  ${({ smRow }) => getGridRow(smRow)};

  ${breakpointMin.md} {
    ${({ mdCol }) => getGridColumn(mdCol)};
    ${({ mdRow }) => getGridRow(mdRow)};
  }
  ${breakpointMin.xl} {
    ${({ lgCol }) => getGridColumn(lgCol)}
    ${({ lgRow }) => getGridRow(lgRow)};
  }
  ${breakpointMin.xxl} {
    ${({ xlCol }) => (xlCol ? getGridColumn(xlCol) : null)}
    ${({ xlRow }) => (xlRow ? getGridRow(xlRow) : null)};
  }
`;
