import { ITableUser } from "components/Table/DashboardTable";
import ILocationData, {
  ILocationTableData,
  IRegionAndCity,
} from "interface/ILocationData";
import { calculateLocationShares } from "util/calculateShares";
import { CoinImage } from "components/Table/Table.styles";
import i18next from "i18next";

export const yourDistribution = (data: IRegionAndCity): string => {
  return ((data.total_allocated_units / data.total_units) * 100).toFixed(2);
};

export const getTableData = (
  user: ITableUser,
  sortedLocations: Array<ILocationData>
): Array<ILocationTableData> => {
  const locationShares = calculateLocationShares(user.packages);
  return sortedLocations.map((location: ILocationData): ILocationTableData => {
    let shares = 0;
    if (locationShares.hasOwnProperty(location._id)) {
      shares = locationShares[location._id];
      return { ...location, userShares: shares };
    }
    return { ...location, userShares: shares };
  });
};

export const getCoinImage = (multiplier: number) => {
  if (multiplier === 0 || multiplier >= 4) return <> </>;
  else
    return (
      <CoinImage
        src={`/assets/icons/universal/unit-${multiplier}.png`}
        alt="coin"
      />
    );
};

export const isSoldOut = (location: IRegionAndCity): boolean =>
  location.total_units <= location.total_allocated_units;

export const getSortingParam = (id: string) => {
  switch (id) {
    case i18next.t("Region"):
      return "region";
    case i18next.t("City"):
      return "city";
    case i18next.t("Vertical"):
      return "vertical";
    case i18next.t("Your Units"):
      return "units";
    case i18next.t("Total Distribution"):
      return "distributions";
    case i18next.t("value"):
      return "price";
    case i18next.t("Coin"):
      return "promoType";
    case i18next.t("World Area"):
      return "continent";
  }
};

export const getMobileSortingParam = (label: string) => {
  switch (label) {
    case i18next.t("Promo type high-low"):
      return "-promoType";
    case i18next.t("Promo type low-high"):
      return "%2bpromoType";
    case i18next.t("Regions A-Z"):
      return "%2bregion";
    case i18next.t("Regions Z-A"):
      return "-region";
    case i18next.t("Cities A-Z"):
      return "%2bcity";
    case i18next.t("Cities Z-A"):
      return "-city";
    case i18next.t("Verticals A-Z"):
      return "%2bvertical";
    case i18next.t("Verticals Z-A"):
      return "-vertical";
    case i18next.t("Owned units high-low"):
      return "-units";
    case i18next.t("Owned units low-high"):
      return "%2bunits";
    case i18next.t("Total distribution high-low"):
      return "-distributions";
    case i18next.t("Total distribution low-high"):
      return "%2bdistributions";
    case i18next.t("Price high-low"):
      return "-price";
    case i18next.t("Price low-high"):
      return "%2bprice";
    case i18next.t("Promo type"):
      return "promoType";
    case i18next.t("Owned units"):
      return "units";
    case i18next.t("Total distribution"):
      return "distributions";
    case i18next.t("Price"):
      return "price";
    case i18next.t("Regions"):
      return "region";
    default:
      return "city";
  }
};

export const getMobileSortLabels = (tabHeaderName: string) => {
  return [
    i18next.t("Promo type high-low"),
    i18next.t("Promo type low-high"),
    i18next.t("Promo type"),
    i18next.t(`${tabHeaderName} A-Z`),
    i18next.t(`${tabHeaderName} Z-A`),
    i18next.t(tabHeaderName),
    i18next.t("Owned units high-low"),
    i18next.t("Owned units low-high"),
    i18next.t("Owned units"),
    i18next.t("Total distribution high-low"),
    i18next.t("Total distribution low-high"),
    i18next.t("Total distribution"),
    i18next.t("Price high-low"),
    i18next.t("Price low-high"),
    i18next.t("Price"),
  ];
};
