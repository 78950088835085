import { useEffect, useState } from "react";
import axios from "axios";
import { LoaderStates } from "config/states";
import { baseUrl, getHeader } from "Api";
import IPoolData from "../interface/IPoolData";
import { LocationType } from "interface/IpoolsName";

const useDashboardSearchApi = (
  directStart: boolean = false,
  locationType: LocationType,
  searchValue: string,
  language?: string
) => {
  const [getSearchStatus, setGetStatus] = useState(LoaderStates.IDLE);
  const [searchData, setSearchData] = useState<IPoolData | undefined>();

  const getSearchData = async () => {
    if (getSearchStatus === LoaderStates.LOADING) return;
    setGetStatus(LoaderStates.LOADING);
    try {
      const result = await axios.get(
        `${baseUrl}/dashboard_table/?locationType=${locationType}&page=1&search=${searchValue}&lng=${language}`,
        getHeader()
      );
      const searchData = result.data.locationsGroupedByType;
      setSearchData(searchData);
      setGetStatus(LoaderStates.SUCCESS);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetStatus(LoaderStates.ERROR);
    }
  };

  useEffect(() => {
    directStart && getSearchData();
  }, [searchValue, directStart]);

  if (directStart && getSearchStatus === LoaderStates.IDLE) getSearchData();
  return { searchData, getSearchStatus, getSearchData };
};

export default useDashboardSearchApi;
