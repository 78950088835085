import { breakpointMax } from "@successfactory/sf-libraries";
import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const CreditTableSelectWrapper = styled.div`
  position: relative;
  width: 80%;

  &:after {
    content: "^";
    position: absolute;
    transform: rotate(180deg);
    font: 20px "Consolas", monospace;
    top: 1px;

    color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
    display: none;
    ${breakpointMax.xl} {
      display: block;
    }
    z-index: 1;
    ${({ theme }) =>
      theme.direction === "rtl"
        ? `
       left:30px
      `
        : `
        right:30px
      `}
  }
`;
export const CreditTableSelect = styled.select`
  padding: 10px;
  border: 0;
  background-color: transparent;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  z-index: 2;
  &:focus {
    outline: none;
  }
`;

export const CreditTableContainer = styled.div`
  width: 100%;
  display: block;
  ${breakpointMax.xl} {
    display: none;
  }
`;

export const CreditCardContainer = styled.div`
  display: none;
  ${breakpointMax.xl} {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const TableRow = styled.tr`
  cursor: pointer;
  &:nth-child(even) {
    background-color: rgba(152, 152, 152, 0.3);
  }
`;

export const TableTd = styled.td`
  ${({ theme }) =>
    theme.direction === "rtl"
      ? `
        &:first-child {
          border-radius: 0 10px 10px 0;
        }
        &:last-child {
          border-radius: 10px 0 0 10px;
        }
      `
      : `
        &:first-child {
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
      `}
`;

export const ShortIconWrapper = styled.th`
  text-transform: uppercase;
  span {
    position: absolute;
    padding-inline-start: 10px;
    cursor: pointer;
  }
`;

export const SortingIcon = styled.span<{
  isDesc?: boolean;
  isActive: boolean;
}>`
  svg {
    path {
      fill: ${(p: any) => p.theme.colors.textColors.textVariant};
    }
  }
  svg:first-child {
    display: block;
    margin-top: 6px;
    ${({ isDesc, theme, isActive }) =>
      !isDesc && isDesc !== undefined && isActive
        ? `path { fill: ${theme.colors.brandColors.brandSecondary}; }`
        : ""}
  }

  svg:last-child {
    margin-bottom: 8px;
    ${({ isDesc, theme, isActive }) =>
      isDesc && isActive
        ? `path { fill: ${theme.colors.brandColors.brandSecondary}; }`
        : ""}
  }
`;

export const TableNoResultTd = styled.td`
  width: 100%;
  text-align: center;
  svg {
    vertical-align: middle;
    margin: 0 5px;
  }
`;
export const TableFormElementGroup = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpointMax.md} {
    flex-direction: column-reverse;
  }
`;

export const TableFormElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  select,
  label {
    display: none;
    ${breakpointMax.xl} {
      display: block;
      margin-bottom: 20px;
    }
  }
  select {
    ${breakpointMax.md} {
      width: 100%;
    }
  }
`;

export const FormLabel = styled.label`
  font-weight: 600;
  font-size: 10px;
`;
