import styled from "styled-components";

export const UnitCellWrapper = styled.div<{ columnName: string }>`
  ${({ columnName }) => {
    if (columnName === "potentialRevenue")
      return `
      div {
    width: 80%;
  }`;
  }}
  ${({ columnName }) => {
    if (columnName === "units")
      return `div {
    width: 60%;}`;
  }}
`;

export const CellInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 100px;
  padding: 6px;
  border-radius: 5px;
  justify-content: center;
  background: ${(props: any) => props.theme.colors.brandColors.backgroundTone};
`;

export const CellInputField = styled.input`
  background: ${(props: any) => props.theme.colors.brandColors.backgroundTone};
  color: ${(props: any) => props.theme.colors.textColors.textVariant};
  outline: none;
  text-align: center;
  width: 100%;
  border: none;
`;

export const CellInputButton = styled.button`
  color: ${(props: any) => props.theme.colors.brandColors.brandPrimary};
  border: none;
  border-radius: 4px;
  background: ${(props: any) => props.theme.colors.brandColors.backgroundTone};
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  svg {
    path {
      stroke: ${(props: any) => props.theme.colors.brandColors.brandPrimary};
    }
  }
  :hover {
    background: ${(props: any) => props.theme.colors.brandColors.brandPrimary};
    svg {
      path {
        stroke: #000000;
      }
    }
  }
`;
