import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SfButton,
  SfFormDropdown,
  SfFormInputField,
  SfFormWrapper,
  useSfForm,
} from "@successfactory/sf-libraries";
import ButtonLoader from "components/Loader/ButtonLoader";
import i18n from "i18n";
import { ILanguage } from "interface/ILanguage";
import IUser from "interface/IUser";
import IVertical from "interface/IVertical";
import Joi from "joi";
import { getCountryName } from "util/getCountryName";
import { useFeatureEnableForUser } from "util/isFeatureEnableForUser";
import sStorage from "util/sStorage";

import { useAppDispatch, useAppState } from "AppContext";

import { availableLanguages, defaultLang } from "config/availableLanguages";
import formValidators from "config/formValidators";

import * as S from "./MyProfileForm.styles";
import MyProfileLanguageDropdown from "./MyProfileLanguageDropdown";

interface MyProfileFormProps {
  userProfile: IUser;
  verticals: any;
  updateUserProfile: Function;
  updateUserLanguage: (language: string) => void;
  getUser: Function;
}
interface SelectedLanguage {
  language: ILanguage["language"];
  display: ILanguage["display"];
  code: ILanguage["code"];
  changed?: boolean;
}

const MyProfileForm: FC<MyProfileFormProps> = ({
  userProfile,
  verticals,
  updateUserProfile,
  updateUserLanguage,
  getUser,
}) => {
  const appDispatch: Function = useAppDispatch();
  const { t } = useTranslation();
  const { theme, isDirection } = useAppState();
  const { sfFormErrors, sfFormProps } = useSfForm();
  const storedLanguage = localStorage.getItem("language");
  const storedLangItem =
    availableLanguages.find(
      (lang: ILanguage) => lang.code === storedLanguage
    ) || defaultLang;
  const [buttonState, setButtonState] = useState<number>(0);
  const [updatedVerticals, setUpdatedVerticals] = useState<any>([]);

  const [deselectedVerticals] = useState<any>(
    userProfile?.deselected_verticals
  );

  const {
    isFeatureFlagLoaded: isCodeFeatureLoaded,
    isFeatureEnabled: isFeatureCodeEnabled,
  } = useFeatureEnableForUser("the-view-code");

  const userProfileLanguage =
    userProfile &&
    availableLanguages.find((item: ILanguage) => {
      return item.code === userProfile.language;
    });

  const [selectedLanguage, setSelectedLanguage] = useState<SelectedLanguage>({
    language: storedLangItem.language,
    display: storedLangItem.display,
    code: storedLangItem.code,
    changed: false,
  });

  useEffect(() => {
    if (userProfileLanguage) {
      sStorage.set("lng", userProfileLanguage.code);
      localStorage.setItem("language", userProfileLanguage.code);
      i18n.changeLanguage(userProfileLanguage.code);
      setSelectedLanguage({
        language: userProfileLanguage.language,
        display: userProfileLanguage.display,
        code: userProfileLanguage.code,
      });
    }
  }, [userProfileLanguage]);

  useEffect(() => {
    if (!storedLangItem) return;
    return setSelectedLanguage({
      language: storedLangItem.language,
      display: storedLangItem.display,
      code: storedLangItem.code,
    });
  }, [storedLangItem, storedLanguage]);

  useEffect(() => {
    const getVerticalsToDisplay = () => {
      const vert = verticals.map((vertical: any) => {
        return { ...vertical, label: t(vertical.label) };
      });
      let newVerticals = [...vert];

      for (let i = 0; i < newVerticals.length; i++) {
        for (let j = 0; j < deselectedVerticals.length; j++) {
          if (newVerticals[i] && deselectedVerticals[j]) {
            if (newVerticals[i]._id && deselectedVerticals[j]._id) {
              if (newVerticals[i]._id === deselectedVerticals[j]._id) {
                newVerticals[i].selected = false;
              }
            }
          }
        }
      }
      return newVerticals;
    };
    setUpdatedVerticals(getVerticalsToDisplay());
  }, [deselectedVerticals, t, verticals]);

  const getErrors = (errors: any) =>
    errors ? (
      errors.map((error: any, key: number) => (
        <S.ErrorText key={key}>{t(error.message)}</S.ErrorText>
      ))
    ) : (
      <S.NoErrorText />
    );

  const userDeselectedVerticals = updatedVerticals
    ? updatedVerticals.filter((vertical: IVertical) => {
        return !vertical.selected;
      })
    : null;

  const buttonDisabledState = () => {
    if (selectedLanguage.changed) return false;
    if (buttonState === 0 || buttonState === 1) {
      if (sfFormProps.state.dirties.verticals === 0) return true;
      if (sfFormProps.state.dirties.verticals) return false;
    }
  };

  const buttonColorState = () => {
    if (buttonState === 2) return theme.colors.helpers.positivePrimary;
    return null;
  };

  const buttonType = () => {
    if (buttonState === 2) return "icon+label";
    return null;
  };

  const buttonIcon = () => {
    if (buttonState === 2)
      return <img src="/assets/icons/saved.svg" alt={t("saved")} />;
    return "#9BDAEF";
  };

  const wait = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const buttonContent = () => {
    if (buttonState === 0) return t("Save");
    if (buttonState === 1) return <ButtonLoader />;
    if (buttonState === 2) return t("Saved");
  };

  const lockIcon =
    theme.name === "TheiaThemeDark"
      ? "/assets/icons/lockDark.svg"
      : "/assets/icons/lockLight.svg";

  const changeLanguage = (item: any) => {
    sStorage.set("lng", item.code);
    localStorage.setItem("language", item.code);
    i18n.changeLanguage(item.code);
    appDispatch({
      type: "lng",
      data: item.code,
    });
    updateUserLanguage(selectedLanguage.code);
  };

  return (
    <S.ProfileForm>
      <S.ProfileHeaderBlock>
        <S.ProfileHeader>{t("Account Settings")}</S.ProfileHeader>
      </S.ProfileHeaderBlock>
      <S.Line />
      <S.ProfileBlock>
        <SfFormWrapper
          onSubmit={async () => {
            setButtonState(1);
            await updateUserProfile(userDeselectedVerticals);
            await changeLanguage(selectedLanguage);
            setButtonState(2);
            await wait(4000);
            setButtonState(0);
            await getUser();
          }}
          {...sfFormProps}
        >
          <S.ProfileRow>
            <S.ProfileRowBlock>
              <S.ProfileCaptionCaps>{t("First Name")}</S.ProfileCaptionCaps>
              <SfFormInputField
                height={"auto"}
                isDisabled
                disableIconPath={lockIcon}
                name="firstname"
                type="text"
                value={userProfile?.firstname}
                validator={formValidators.firstname.required()}
                {...sfFormProps}
              />
              {getErrors(sfFormErrors.firstname)}
            </S.ProfileRowBlock>

            <S.ProfileRowBlock>
              <S.ProfileCaptionCaps>{t("Last Name")}</S.ProfileCaptionCaps>
              <SfFormInputField
                height={"auto"}
                isDisabled
                disableIconPath={lockIcon}
                name="lastname"
                type="text"
                value={userProfile?.lastname}
                validator={formValidators.lastname.required()}
                {...sfFormProps}
              />
              {getErrors(sfFormErrors.email)}
            </S.ProfileRowBlock>
          </S.ProfileRow>

          <S.ProfileRowBlock>
            <S.ProfileCaptionCaps>{t("Email")}</S.ProfileCaptionCaps>
            <SfFormInputField
              isDisabled
              disableIconPath={lockIcon}
              name="email"
              type="email"
              value={userProfile?.email}
              validator={formValidators.email.required()}
              {...sfFormProps}
            />
            {getErrors(sfFormErrors.email)}
          </S.ProfileRowBlock>

          <S.ProfileRowBlock>
            <S.ProfileCaptionCaps>
              {t("Country of Residence")}
            </S.ProfileCaptionCaps>
            <S.CountryFieldWrapper>
              <SfFormInputField
                isDisabled
                disableIconPath={lockIcon}
                name="country"
                type="text"
                placeholder={t(`${getCountryName(userProfile)}`)}
                validator={formValidators.firstname.required()}
                {...sfFormProps}
              />
            </S.CountryFieldWrapper>
            {getErrors(sfFormErrors.email)}
          </S.ProfileRowBlock>

          <S.ProfileRowBlock>
            <S.ProfileCaptionCaps style={{ marginBottom: "8px" }}>
              {t("Participate in")}
            </S.ProfileCaptionCaps>
            <S.MyProfileDropdownWrapper>
              <SfFormDropdown
                dir={isDirection}
                type={"checkItem"}
                displaySelectedCount={true}
                name={"verticals"}
                placeholder={t("Please select")}
                selectedCountPlaceholder={t("Selected items")}
                options={updatedVerticals}
                validator={Joi.array()}
                mobileLabels={{ cancel: t("Cancel"), set: t("Set") }}
                {...sfFormProps}
              />
            </S.MyProfileDropdownWrapper>
            {getErrors(sfFormErrors.verticals)}
          </S.ProfileRowBlock>
          <label className="caption_caps">{t("Preferred language")}</label>
          <MyProfileLanguageDropdown
            selectedLanguage={
              selectedLanguage.display ? selectedLanguage : defaultLang
            }
            setSelectedLanguage={setSelectedLanguage}
          />

          {isCodeFeatureLoaded && !isFeatureCodeEnabled && (
            <S.ProfileRowBlock style={{ marginTop: "24px" }}>
              <S.ProfileCaptionCaps>
                {t("User Account Code")}
              </S.ProfileCaptionCaps>
              <S.ProfileCaption>
                {t(
                  "Copy your unique user account code to link and use your The View Platform account."
                )}
              </S.ProfileCaption>
              <S.MyViewCodeWrapper
                onClick={() => {
                  navigator.clipboard.writeText(userProfile?.theViewCode?.code);
                }}
              >
                <S.MyViewCodeInput
                  disabled={true}
                  type="text"
                  name="viewcode"
                  value={userProfile?.theViewCode?.code}
                />
                <img
                  style={{ paddingRight: 0 }}
                  src="/assets/icons/copy.svg"
                  alt="Copy"
                />
              </S.MyViewCodeWrapper>
            </S.ProfileRowBlock>
          )}

          <S.FormButton color={buttonColorState()} icon={buttonState === 2}>
            <SfButton
              type={buttonType()}
              label={buttonContent()}
              size="medium"
              twoTone={true}
              isDisabled={buttonDisabledState()}
              icon={buttonIcon()}
            />
          </S.FormButton>
        </SfFormWrapper>
      </S.ProfileBlock>
    </S.ProfileForm>
  );
};

export default MyProfileForm;
