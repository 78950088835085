import axios from "axios";
import { useState } from "react";
import { LoaderStates } from "config/states";
import { baseUrl, getHeader } from "./useApi";

const useViewCreditApi = (directStart: boolean = false) => {
  const [getStatus, setGetStatus] = useState(LoaderStates.IDLE);
  const [creditData, setCreditData] = useState<number>(0);

  const getViewCredit = async () => {
    if (getStatus === LoaderStates.LOADING) return;
    setGetStatus(LoaderStates.LOADING);
    try {
      const result = await axios.get(`${baseUrl}/user/credits`, getHeader());
      const creditVal = result.data.data;
      setCreditData(creditVal);
      setGetStatus(LoaderStates.SUCCESS);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetStatus(LoaderStates.ERROR);
    }
  };
  if (directStart && getStatus === LoaderStates.IDLE) getViewCredit();
  return { creditData, getStatus, getViewCredit };
};

export default useViewCreditApi;
