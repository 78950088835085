import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentImg = styled.img`
  margin: 0 auto 8px auto;
  max-height: 120px;
  width: 200px;

  @media ${breakpoints.mobile} {
    width: 80px;
    bottom: -20px;
    margin-top: 10px;
  }
  &.city-maps {
    width: 150px;
    height: 150px;
  }
`;

export const AllocatePackageModal = styled.div`
  img {
    margin: 0 auto 8px auto;
    // max-height: 120px;
    @media screen and (max-width: 767px) {
      margin: 0 auto 8px auto;
    }
  }
`;

export const AllocatePackageModalWrapper = styled.div`
  padding-top: 20px;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.textColors.textVariant};
  img {
    margin: 24px;
    max-height: 80px;
  }
  @media ${breakpoints.mobile} {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

export const CountryName = styled.span`
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
  direction: ${({ theme }) => (theme.direction === "rtl" ? "rtl" : "ltr")};
`;

export const ContentDescription = styled.div`
  font-size: 13px;
  color: #7d7e8e;
`;

export const CancelButton = styled.div`
  button {
    background-color: ${({ theme }) => theme.colors.textColors.textGrey};
    @media ${breakpoints.mobile} {
      width: 100%;
      margin-top: 20px;
    }
  }
`;

export const Fingerprint = styled.div<{ selected: boolean }>`
  mask-size: 80px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  mask-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.textColors.textGrey};
  background-image: ${({ selected }) =>
    selected
      ? `linear-gradient(to right, #F6DB76
        , #B67F11)`
      : null};
  height: 134px;
  width: 100px;
  img {
    height: 100px;
  }
`;

export const PackageModelContent = styled.div`
  text-align: center;
  font-size: 30px;
  line-height: 24px;
  margin-top: 20px;
  button {
    height: 40px;
  }
`;

export const PackageModelContentDescription = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textColors.textVariant};
`;

export const AllocatedCaption = styled.span`
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
`;
export const PackageOptionsWrapper = styled.div`
  ${flexCenter}
  gap: 12px;
  padding: 20px 0;
  width: 100%;
  @media ${breakpoints.mobile} {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
  }
`;
export const RegionLink = styled.div<{
  active: boolean;
  isDefault: boolean;
  isDisabled?: boolean;
}>`
  text-decoration: none;
  ${flexCenter};
  border-radius: 15px;
  height: 104px;
  width: 112px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column;
  pointer-events: ${({ isDisabled }) =>
    isDisabled
      ? css`
          none;
        `
      : css`
           default;
        `};
  cursor: pointer;
  padding: 0 15px;
  opacity: ${({ isDefault, active }) =>
    isDefault || active
      ? css`
          1;
        `
      : css`
           0.5;
        `};

  border: ${({ active }) =>
    active
      ? css`2px solid ${(p: any) => p.theme.colors.textColors.textWhite}`
      : css`1px solid ${(p: any) => p.theme.colors.textColors.textGrey}`};

  svg {
    padding-bottom: 8px;
    path {
      fill: ${(p: any) => p.theme.colors.brandColors.backgroundDark};
    }
  }
  @media ${breakpoints.mobile} {
    padding: 0 7px;
    width: 92px;
  }
`;

export const ButtonsBox = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;

  button {
    height: 40px;
    width: 140px;
  }
  @media ${breakpoints.mobile} {
    justify-content: space-evenly;
    flex-direction: column-reverse;
    width: 100%;

    button {
      height: 48px;
      border-radius: 14px;
      width: 100%;
    }
  }
  margin-bottom: 30px;
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px auto 20px;
`;

export const AllocationDescription = styled.div`
  @media ${breakpoints.mobile} {
    height: 60px;
    overflow-y: scroll;
  }
`;
