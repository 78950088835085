import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SfButton } from "@successfactory/sf-libraries";

import ILocationData from "interface/ILocationData";
import IPackageData from "interface/IPackageData";
import IRegionOptions from "interface/IRegionOptions";

import { calculateShares } from "util/calculateShares";

import * as S from "./AllocatePackageComponent.styles";
import { LocationsPerType } from "./AllocatePackageNull";

interface AllocatePackageFormProps {
  setCurrentStep: Function;
  setSelectedLocation: Function;
  locations: Array<ILocationData>;
  selectedPackage: IPackageData;
  regionOptions: Array<IRegionOptions>;
  optionId: IRegionOptions["id"];
  locationsAccordingToType: LocationsPerType;
  selectedType: string;
}
export interface ToggleDropdown {
  toggleDropdown: boolean;
}
const AllocatePackageForm: React.FC<AllocatePackageFormProps> = ({
  setCurrentStep,
  setSelectedLocation,
  selectedPackage,
  regionOptions,
  optionId = "",
  selectedType,
  locationsAccordingToType,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const wrapperRef = useRef(null);
  const sortedLocations = useMemo(
    () => [
      ...locationsAccordingToType.city,
      ...locationsAccordingToType.region,
      ...locationsAccordingToType.vertical,
      ...locationsAccordingToType.continent,
    ],
    [locationsAccordingToType]
  );
  const toggleDropdown = () => setOpen(!isOpen);

  const OutsideSelectOption = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: { target: any }) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  OutsideSelectOption(wrapperRef);

  const clickDropdown = (value: string) => {
    setValue(value);
    toggleDropdown();
  };

  const countryValue = (
    countryValue: { region: string; code: string },
    shares: number
  ) => {
    passSelectedProps(countryValue.region, shares);
    setValue(countryValue.region);
    setOpen(false);
  };

  const passSelectedProps = (selectedRegion: string, selectedShares: number) =>
    setSelectedItem(
      "" +
        t(selectedRegion) +
        " | <span>" +
        selectedShares +
        " " +
        (selectedShares === 1 ? t("Unit") : t("Units")) +
        "</span>"
    );

  const createMarkUp = () => {
    return {
      __html: selectedItem,
    };
  };

  const getLocationShareOptions = (
    locations: Array<ILocationData>,
    selectedPackage: IPackageData
  ) => {
    locations.sort((a: ILocationData, b: ILocationData) => {
      if (a.region.toLowerCase() < b.region.toLowerCase()) {
        return -1;
      }
      if (a.region.toLowerCase() > b.region.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return locations.map((location: ILocationData, key: number) => {
      const shares = calculateShares(selectedPackage, location);
      return (
        <S.AllocatePackageOption
          key={key}
          value={location.region}
          onClick={() => countryValue(location, shares)}
        >
          {t(location.region)}
          &nbsp;| &nbsp;
          <S.AllocatePackageColor>{`${shares} ${
            shares > 1 ? t("Units") : t("Unit")
          }`}</S.AllocatePackageColor>
        </S.AllocatePackageOption>
      );
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value !== "") {
      const getChosenLocationDetails = (location: ILocationData) =>
        location.region === value;
      const chosenLocationDetails = sortedLocations.find(
        getChosenLocationDetails
      );
      setSelectedLocation(chosenLocationDetails);
      setCurrentStep(1);
      return;
    }
  };

  useEffect(() => {
    setSelectedItem("");
  }, [optionId]);

  return (
    <S.Form onSubmit={handleSubmit}>
      {optionId !== "" ? (
        <S.FormSelectBorder>
          <S.Dropdown toggleDropdown={isOpen} ref={wrapperRef}>
            <S.DropdownHeader
              onClick={(e: { currentTarget: { value: string } }) =>
                clickDropdown(e.currentTarget.value)
              }
            >
              {selectedItem ? (
                <S.DropdownHeaderColor
                  dangerouslySetInnerHTML={createMarkUp()}
                ></S.DropdownHeaderColor>
              ) : (
                regionOptions?.map((regionOption: IRegionOptions) => {
                  if (regionOption.id === optionId) {
                    return t("Please choose a ") + regionOption.labelSingular;
                  }
                  return null;
                })
              )}
              <S.DropdownArrow />
            </S.DropdownHeader>
            <S.DropdownBody>
              <S.DropdownBodyContent>
                {getLocationShareOptions(
                  locationsAccordingToType[selectedType],
                  selectedPackage
                )}
              </S.DropdownBodyContent>
            </S.DropdownBody>
          </S.Dropdown>
        </S.FormSelectBorder>
      ) : null}
      <SfButton label={t("Allocate")} twoTone isDisabled={!selectedItem}>
        <S.Button type="submit">{t("Allocate")}</S.Button>
      </SfButton>
    </S.Form>
  );
};

export default AllocatePackageForm;
