const Unable = ({ ...props }) => {
  const {
    width = 16,
    height = 16,
    fill = "#989898",
    viewBox = "0 0 16 16",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={fill} />
      <path
        d="M3.77725 3.68334C4.03614 3.43495 4.45121 3.43953 4.70435 3.69357L8.03848 7.03966L11.2957 3.7708C11.5488 3.51676 11.9639 3.51218 12.2227 3.76057C12.4816 4.00896 12.4863 4.41627 12.2332 4.67031L8.95538 7.95986L12.3132 11.3297C12.5663 11.5837 12.5616 11.991 12.3027 12.2394C12.0439 12.4878 11.6288 12.4832 11.3756 12.2292L8.03848 8.88006L4.62435 12.3064C4.37122 12.5605 3.95615 12.5651 3.69726 12.3167C3.43837 12.0683 3.43371 11.661 3.68684 11.4069L7.12157 7.95986L3.76683 4.59308C3.5137 4.33904 3.51837 3.93173 3.77725 3.68334Z"
        fill="#141414"
      />
    </svg>
  );
};
export default Unable;
