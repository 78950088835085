import { useTranslation } from "react-i18next";

import BannerButton from "components/BannerButton/BannerButton";

import { MintingHistory } from "./IWallet";
import MintingDetailsItem from "./MintingDetailsItem";
import * as S from "./SharedStyles.styles";

interface MintingDetailsProps {
  logo?: string;
  region?: string;
  mint_request_history?: MintingHistory[];
  handleModalClose?: () => void;
}

const MintingDetails = ({
  logo,
  region,
  handleModalClose,
  mint_request_history,
}: MintingDetailsProps) => {
  const { t } = useTranslation();

  const mintingRequestHistory: MintingHistory[] = mint_request_history || [];

  return (
    <S.MintWrapper>
      <S.ModalHeader>{t("Minting Details")}</S.ModalHeader>
      <S.MintLocationDetails>
        <S.MintLocationImage image={logo} darkImage={logo} />
        <S.MintLocationName>{t(region || "")}</S.MintLocationName>
      </S.MintLocationDetails>
      <S.MintingScrollWrapper>
        {mintingRequestHistory &&
          mintingRequestHistory.map((item, index) => {
            const { contact, nft_quantity, requestStatuses, walletAddress } =
              item;
            const detailsData = {
              units: nft_quantity,
              walletId: walletAddress,
              email: contact,
              requestStatuses: requestStatuses,
              key: `minting-details-item-${index}`,
            };

            return <MintingDetailsItem {...detailsData} />;
          })}
      </S.MintingScrollWrapper>
      <S.BannerGroup>
        <BannerButton
          labelText={t("Understood")}
          onClick={handleModalClose}
          $twoTone
        />
      </S.BannerGroup>
    </S.MintWrapper>
  );
};

export default MintingDetails;
