import { useState } from "react";

import { baseUrl, getHeader } from "Api";
import axios from "axios";
import { ITableUser } from "components/Table/DashboardTable";
import IPackageData from "interface/IPackageData";
import IUser from "interface/IUser";

import { useAppDispatch } from "AppContext";

import { LoaderStates } from "config/states";

const useUserApi = (directStart: boolean = false) => {
  const appDispatch: Function = useAppDispatch();
  const urlName = window.location.pathname;
  const [getUserStatus, setGetUserStatus] = useState(LoaderStates.IDLE);
  const [userPackages, setUserPackages] = useState<Array<IPackageData>>();
  const [userProfile, setUserProfile] = useState<IUser>();
  const [verticals, setVerticals] = useState<Array<string>>([]);

  const getUser = async () => {
    if (getUserStatus === LoaderStates.LOADING) return;
    setGetUserStatus(LoaderStates.LOADING);
    try {
      const { data } = await axios.get(`${baseUrl}/user`, getHeader());
      const { packages, user } = data.data;

      appDispatch({
        type: "user",
        data: user,
      });

      appDispatch({
        type: "lng",
        data: user.language,
      });

      setUserPackages(packages);
      setUserProfile(user);
      if (urlName === "/account-center") await getVertials();
      setGetUserStatus(LoaderStates.SUCCESS);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetUserStatus(LoaderStates.ERROR);
    }
  };

  const getVertials = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/verticals`, getHeader());
      if (!data) return;
      setVerticals(data.data);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetUserStatus(LoaderStates.ERROR);
    }
  };

  const updateUserProfileApiCall = (data: {
    deselected_verticals?: Array<string>;
    language?: string;
    backgroundMode?: string;
  }) => {
    if (!userProfile) return;
    axios
      .post(`${baseUrl}/user_profile/${userProfile._id}`, data, getHeader())
      .then((response) => {
        const updatedUser = response.data.data;
        appDispatch({
          type: "user",
          data: updatedUser,
        });
      })
      .catch((error) => {
        console.log("updateUserProfile -> error: ", error);
      });
  };

  const updateUserProfile = (deselected_verticals: Array<string>) => {
    updateUserProfileApiCall({ deselected_verticals });
  };

  const updateUserLanguage = (language: string) => {
    updateUserProfileApiCall({ language });
  };

  const updateUserTheme = (theme: string) => {
    updateUserProfileApiCall({ backgroundMode: theme });
  };

  if (directStart && getUserStatus === LoaderStates.IDLE) getUser();

  return {
    userPackages,
    userProfile,
    getUserStatus,
    tableUser: {
      user: userProfile,
      packages: userPackages,
      newPackages: [],
    } as ITableUser,
    getUser,
    getVertials,
    verticals,
    setGetUserStatus,
    updateUserProfile,
    updateUserLanguage,
    updateUserTheme,
  };
};

export default useUserApi;
