import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const PageGeneralTitleContainer = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  @media ${breakpoints.mobile} {
    display: block;
    width: 100%;
    padding: 0 7px;
  }
`;
export const PageGeneralImage = styled.img`
  width: 15%;
  @media ${breakpoints.tablet} {
    width: 25%;
  }
`;
export const PageGeneralTitleWrapper = styled.div`
  width: 80%;
  @media ${breakpoints.tablet} {
    width: 75%;
  }
  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;
export const PageGeneralTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  padding-bottom: 10px;
`;
export const PageGeneralDescription = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const CardBody = styled.div`
  display: flex;
  margin-top: 25%;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "padding: 0 20px 0 10px;"
      : "   padding: 0 10px 0 20px;"};
`;
export const CardBodyItemWrapper = styled.div`
  width: 50%;
`;
export const CardBodyItem = styled.div`
  padding-bottom: 15px;
`;
export const CardItemTitle = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 32px;
  color: #7d7e8e;
  letter-spacing: 1.29px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "text-align:right;"
      : "   text-align:left;"};
  text-transform: uppercase;
`;
export const CardItemValue = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? "text-align:right;"
      : "   text-align:left;"};
  &.you-own {
    color: ${(p: any) => p.theme.colors.brandColors.brandPrimary};
  }
`;
export const PoolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${breakpoints.mobile} {
    align-items: center;
    justify-content: center;
  }
`;
export const SfCardOneItem = styled.div`
  ${(props) =>
    props.theme.direction === "rtl"
      ? "margin: 0 0 36px 32px"
      : "   margin: 0 32px 36px 0"};
  @media ${breakpoints.mobile} {
    margin: 0 0 36px 0;
  }

  span {
    top: 32%;
  }
`;
