import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SfTabsMenu } from "@successfactory/sf-libraries";

import { LocationTabs } from "interface/IpoolsName";
import ILocationData from "interface/ILocationData";

import TheiaUnitsProvider from "stores/TheiaUnitsProvider";
import { IncomeCalculatorState } from "stores/TheiaUnitsReducer";

import useUserApi from "api/useUserApi";
import useLocationsApi from "api/useLocationsApi";
import useStatsApi from "api/useStatsApi";

import { calculateLocationShares } from "util/calculateShares";

import { IncomeCalculatorTable } from "components/Table/IncomeCalculatorTable";
import Loader from "components/Loader/Loader";

import { Grid, GridContainer } from "styles/Grid.styles";
import { TabsMenuWrapper } from "pages/pools/PoolsPageDashboard.styles";

interface IncomeCalculatorPageProps {}

interface TabContentProps {
  tabsName: LocationTabs;
  currentTab: string;
}

const getEnrichedLocations = (
  locations: Array<ILocationData>,
  locationShares: { [key: string]: number } = {}
) => {
  return locations.map((location: ILocationData) => ({
    ...location,
    potentialRev: 0,
    estIncome: 0,
    distribution: locationShares[location._id]
      ? (locationShares[location._id] / location.max_available) * 100
      : 0,
    userShares: locationShares.hasOwnProperty(location._id)
      ? locationShares[location._id]
      : 0,
  }));
};
const IncomeCalculatorPage: React.FC<IncomeCalculatorPageProps> = () => {
  const { t } = useTranslation();

  const { userPackages, getUserStatus } = useUserApi(true);
  const [currentTab, setCurrentTab] = useState("Regions");

  const handleTabChange = (label: string) => {
    setCurrentTab(label);
  };

  const { locations, getLocationsStatus } = useLocationsApi(
    true,
    "locations",
    "locations"
  );

  const { stats, getStatsStatus } = useStatsApi({
    init: ["getStats"],
  });

  const isLoading =
    getLocationsStatus === "loading" ||
    getUserStatus === "loading" ||
    getStatsStatus === "loading";

  if (isLoading) return <Loader />;

  //TODO: add empty / error state
  const locationShares = calculateLocationShares(userPackages);
  const enrichedLocations = getEnrichedLocations(locations, locationShares);

  const sortedLocations: ILocationData[] = enrichedLocations.sort(
    (a: any, b: any) => {
      return b.userShares - a.userShares;
    }
  );
  const calculatedLocations = sortedLocations.filter(
    (location: any) => location.userShares > 0
  );
  const initialState: IncomeCalculatorState = {
    originalLocations: sortedLocations,
    locations: sortedLocations,
    calculatedLocations: calculatedLocations,
    stats: stats,
  };

  const tabs = [
    { label: "Regions", tabsName: LocationTabs.REGIONS },
    { label: "Cities", tabsName: LocationTabs.CITIES },
    { label: "Verticals", tabsName: LocationTabs.VERTICALS },
    { label: "World Areas", tabsName: LocationTabs.CONTINENTS },
  ];

  const TabContent = ({ tabsName, currentTab }: TabContentProps) => (
    <IncomeCalculatorTable tabsName={tabsName} currentTab={currentTab} />
  );

  return (
    <TheiaUnitsProvider initialState={initialState}>
      <GridContainer>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
          <TabsMenuWrapper page="incomeCalculator">
            <SfTabsMenu
              items={tabs.map(({ label, tabsName }) => ({
                label: t(label),
                content: (
                  <TabContent tabsName={tabsName} currentTab={currentTab} />
                ),
              }))}
              design="Round"
              align="Start"
              withDropDown={true}
              borderRadius="20px"
              padding="10px 20px"
              onChange={(index: number) => {
                const { label } = tabs[index];
                handleTabChange(t(label));
              }}
            />
          </TabsMenuWrapper>
        </Grid>
      </GridContainer>
    </TheiaUnitsProvider>
  );
};
export default IncomeCalculatorPage;
