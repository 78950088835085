export const sStorage = {
  get: (name: string) => {
    const value = sessionStorage.getItem(name);
    if (!value) return false;
    if (value) {
      const stored = JSON.parse(value);
      return stored.data;
    }
  },
  set: (name: string, data: any) => {
    if (typeof data === "object")
      data = JSON.stringify({ type: "object", data: data });
    else if (typeof data === "string")
      data = JSON.stringify({ type: "string", data: data });
    const savedData = sessionStorage.setItem(name, data);
    return savedData;
  },
  del: (name: string) => sessionStorage.removeItem(name),
};
export default sStorage;
