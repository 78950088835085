import { useAppState } from "AppContext";
import { ISidebarLinkItem } from "./SidebarItems";
import {
  NavLinkStyle,
  NavLinkWrapper,
  SidebarNavlinkLabel,
  SidebarNavlinkSvg,
} from "./SidebarLink.styles";
import { useTranslation } from "react-i18next";

export interface SidebarLinkProps {
  linkItem: ISidebarLinkItem;
}

export const SidebarLink = ({ linkItem }: SidebarLinkProps) => {
  const { t } = useTranslation();
  const { isSidebarShown } = useAppState();

  return (
    <NavLinkWrapper closed={!isSidebarShown}>
      <NavLinkStyle
        activeClassName="nav-item-active"
        to={linkItem.linkTo}
        $closed={!isSidebarShown}
      >
        <SidebarNavlinkLabel closed={!isSidebarShown}>
          <SidebarNavlinkSvg>{linkItem.icon}</SidebarNavlinkSvg>
          <span>{t(linkItem.label)}</span>
        </SidebarNavlinkLabel>
      </NavLinkStyle>
    </NavLinkWrapper>
  );
};
