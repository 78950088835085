import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

const commonTextStyles = css`
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const LoginFormsLayoutContainer = styled.div`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      inset: 0;
      width: 100%;
      @media ${breakpoints.mobile} {
        overflow-y: auto;
        padding-bottom: 30px;
      }
      &:before {
        content: "";
        background-color: ${colors.surfaces.surfaceBackground};
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: -2;
      }
      &:after {
        content: "";
        background-image: url("/assets/re-branding/pool-icons/universal.png");
        background-size: cover;
        background-position: bottom left;
        position: fixed;
        bottom: 10px;
        left: -140px;
        width: 600px;
        height: 600px;
        z-index: -1;
        @media ${breakpoints.tablet} {
          bottom: -100px;
        }
        @media ${breakpoints.mobile} {
          display: none;
        }
      }
      a {
        text-decoration: none;
      }
    `;
  }}
`;

export const LoginFormsHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

export const LoginFormsLogo = styled.img``;
export const LoginFormsLanguage = styled.div`
  display: flex;
  align-items: center;
`;

export const FormsContainer = styled.div<{ pageName?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 457px;
  margin: 0 auto;
  padding: 0 15px;
  flex-direction: column;
  gap: 20px;
  @supports (-webkit-hyphens: none) {
    height: fit-content;
  }
`;

export const LoginFooterContainer = styled.div`
  width: 100%;
  padding: 20px 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 8;
  @media ${breakpoints.mobile} {
    padding: 20px 3px;
  }
`;

export const LoginFooterUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  @media ${breakpoints.mobile} {
    gap: 10px;
  }
`;

export const LoginFooterLink = styled.a`
  ${commonTextStyles};
  position: relative;
  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: "|";
    position: absolute;
    left: -10px;
    top: 0;
    @media ${breakpoints.mobile} {
      left: -6px;
    }
  }
`;

export const LoginFooterText = styled.span`
  ${commonTextStyles};
`;

export const LoginFooterLi = styled.li`
  list-style: none;
`;
