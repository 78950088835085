import { SfLottiePlayer } from "@successfactory/sf-libraries";

import LoaderJson from "assets/lottie/new-loader.json";

import { LoaderStyle } from "./Loader.styles";

const Loader = () => {
  return (
    <LoaderStyle>
      <SfLottiePlayer width="100px" src={LoaderJson} />
    </LoaderStyle>
  );
};

export default Loader;
