import { FC } from "react";
import { Redirect } from "react-router-dom";
import routes from "config/routes";
import { Container, TheViewLogoImage, Title } from "./Maintenance.styles";

import TheViewLogo from "./the-view-logo.svg";

interface MaintenanceProps {
  showMaintenance: boolean;
}

const Maintenance: FC<MaintenanceProps> = ({ showMaintenance }) => {
  if (!showMaintenance) {
    return <Redirect to={routes.HOME} />;
  }

  return (
    <Container>
      <TheViewLogoImage src={TheViewLogo} />

      <Title>THE VIEW is temporarily offline for maintenance</Title>
    </Container>
  );
};

export default Maintenance;
