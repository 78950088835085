import { useEffect, useState } from "react";
import Api from "../Api";

export const useFeatureEnableForUser = (featureFlagName: string) => {
  const [featureFlag, setFeatureFlag] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    isFeatureFlagEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFeatureFlagEnabled = async () => {
    const featureFlagReceived = await Api.getFeatureFlag(featureFlagName);

    if (featureFlagReceived?.data?.isFeatureVisibleByUser)
      setFeatureFlag(featureFlagReceived?.data?.isFeatureVisibleByUser);
    setLoaded(true);
  };

  return {
    isFeatureEnabled: featureFlag,
    isFeatureFlagEnabled,
    isFeatureFlagLoaded: loaded,
  };
};
