import React, { Fragment, useContext } from "react";

import { ThemeContext } from "styled-components";

import { useAppState } from "AppContext";

import {
  BorderDiv,
  CardBody,
  CardFooter,
  CardFooterLine,
  CardHeader,
  CardHeaderContent,
  CardHeaderEmptyContent,
  CardHeaderLine,
  Container,
  FooterBottom,
  FooterCaption,
  FooterContainer,
  FooterTitle,
  FooterWrapper,
  HeaderCaptionLeft,
  HeaderCaptionRight,
  HeaderMintedStatusText,
  HeaderMintingStatus,
  HeaderTitleLeft,
  HeaderTitleRight,
} from "./CardMembership.styles";
import LoadingContentIcon from "./LoadingContentIcon";

type CardContent = {
  header: {
    captionLeft: string;
    captionRight: string;
    titleLeft: string | undefined;
    titleRight: string;
    minting: any;
    mintingProgressCaption: string;
    mintingCompleteCaption: string;
  };
  footer: Array<{
    caption: string;
    title: string;
  }>;
};
export interface SfCardMembershipProps {
  type: "initial" | "allocated" | "empty";
  cardContent: CardContent;
  width?: string;
}

export const CardMembership: React.FC<SfCardMembershipProps> = ({
  type = "initial",
  cardContent,
  children,
  width = "282px",
}) => {
  const theme = useContext(ThemeContext);
  const { language } = useAppState();

  return (
    <Container width={width}>
      <CardHeaderLine />
      <CardHeader>
        {type === "empty" ? (
          <CardHeaderEmptyContent>
            <LoadingContentIcon color={theme.colors.textColors.textGrey} />
          </CardHeaderEmptyContent>
        ) : (
          <CardHeaderContent>
            <HeaderCaptionLeft>
              {cardContent.header.captionLeft}
            </HeaderCaptionLeft>
            <HeaderCaptionRight>
              {cardContent.header.captionRight}
            </HeaderCaptionRight>
            <HeaderTitleLeft>{cardContent.header.titleLeft}</HeaderTitleLeft>
            <HeaderTitleRight>{cardContent.header.titleRight}</HeaderTitleRight>
            {cardContent.header.minting?.requested && (
              <HeaderMintingStatus>
                <HeaderMintedStatusText>
                  {cardContent.header.minting?.mintedAt
                    ? cardContent.header.mintingCompleteCaption
                    : cardContent.header.mintingProgressCaption}
                </HeaderMintedStatusText>
              </HeaderMintingStatus>
            )}
          </CardHeaderContent>
        )}
      </CardHeader>
      <CardBody>{children}</CardBody>
      {cardContent && cardContent.footer ? (
        <FooterBottom>
          <CardFooterLine />
          <CardFooter>
            {cardContent.footer.map(
              (
                footerItem: { caption: string; title: string },
                index: number
              ) => {
                return (
                  <Fragment key={index}>
                    <FooterContainer>
                      <FooterWrapper>
                        <FooterCaption>
                          {footerItem.caption.toUpperCase()}
                        </FooterCaption>
                        <FooterTitle lang={language}>
                          {footerItem.title}
                        </FooterTitle>
                      </FooterWrapper>
                    </FooterContainer>
                    <BorderDiv />
                  </Fragment>
                );
              }
            )}
          </CardFooter>
        </FooterBottom>
      ) : null}
    </Container>
  );
};
