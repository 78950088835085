import { useCallback } from "react";

import { CellInputField } from "./UnitCell.styles";

interface InputFieldProps {
  amount: number | string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  list?: string;
}

export const InputField = (props: InputFieldProps) => {
  const { amount, onChange, placeholder = "0", list } = props;

  const handleChange = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange]
  );
  return (
    <CellInputField
      value={amount === 0 ? "" : amount}
      onChange={handleChange}
      placeholder={placeholder}
      list={list}
    />
  );
};

export default InputField;
