import { useTranslation } from "react-i18next";

import BannerButton from "components/BannerButton/BannerButton";
import { FormsContainer } from "pages/login/PublicTemplate.styles";

import routes from "config/routes";

import Content from "../Components/Common/Content";
import * as S from "../styles/Shared.styles";

const Landing = () => {
  const { t } = useTranslation();

  return (
    <FormsContainer>
      <Content
        title={t("Login")}
        description={t("Welcome back! Let’s access your account.")}
      />
      <S.LandingLogin>
        <BannerButton
          labelText={t("Login")}
          redirectUrl={routes.LOGIN}
          $twoTone
        />
      </S.LandingLogin>
      <S.ButtonText as="a" href={routes.TEMPORARY}>
        {t("Request a temporary password")}
      </S.ButtonText>
    </FormsContainer>
  );
};

export default Landing;
