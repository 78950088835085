import IPackageData from "interface/IPackageData";
import { getUnits } from "./getFounderAndGlobalPoolShares";
import i18n from "i18n";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import ILocationData from "interface/ILocationData";

export const calculateShares = (lot: IPackageData, location: ILocationData) => {
  const multiplier = location.offered_perks?.continent?.multiplier || 2;

  return location.type === "continent"
    ? (multiplier * lot.value) / location.value
    : lot.value / location.value;
};

export const calculateLocationShares = (
  packages: any
): { [name: string]: any } => {
  if (!packages) return {};

  const locationShares: { [name: string]: any } = {};
  let countryCode;
  for (let userPackage of packages) {
    if (userPackage.location) {
      countryCode = userPackage.location._id;
      if (!locationShares.hasOwnProperty(countryCode))
        locationShares[countryCode] = 0;
      locationShares[countryCode] += userPackage.shares;
    }
  }
  return locationShares;
};

export const calculateUnits = (
  membershipPackage: IPackageData,
  multiplier: number = 1
) => {
  const units = getUnits(membershipPackage.value);

  return units * multiplier;
};

export const getRegionUnits = (
  countryValue: number,
  packageValue: number,
  countryType: string
) => {
  const reginUnits = countryValue / packageValue;

  return countryType === "continent" ? 2 * reginUnits : reginUnits;
};

export const getUnitsString = (
  pack: any,
  location: any,
  unitTypes: Array<any> = ["global", "universal", "continent"]
) => {
  const isFounder = ["s", "m", "l", "xl"].includes(pack.size.toLowerCase());
  const textParts = [];

  for (const unitType of unitTypes) {
    let multiplier = 0;

    if (isFounder && location.offered_perks?.rules?.includeFounders >= 0) {
      multiplier = location.offered_perks?.rules?.includeFounders;
    } else if (location.offered_perks && location.offered_perks[unitType]) {
      multiplier = location.offered_perks[unitType]?.multiplier || 0;
    }
    const units = calculateUnits(pack, multiplier);

    textParts.push(
      units + i18n.t(` units to the ${capitalizeFirstLetter(unitType)} Pool`)
    );

    if (pack.offered_perks.founders)
      textParts.push(
        pack?.offered_perks?.founders?.shareData?.shares +
          i18n.t(" units to the Founders Circle")
      );
  }

  return textParts.join(" and ");
};
