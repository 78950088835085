import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useUserApi from "api/useUserApi";
import i18n from "i18n";
import { ILanguage } from "interface/ILanguage";
import { sStorage } from "util/sStorage";

import { useAppDispatch, useAppState } from "AppContext";

import { availableLanguages, defaultLang } from "config/availableLanguages";

import { ReactComponent as Chevron } from "assets/icons/chevron.svg";

import {
  Dropdown,
  DropdownBody,
  DropdownBodyContent,
  DropdownBox,
  DropdownItem,
  DropdownLabel,
  InnerMenuLabel,
} from "./LanguageDropdown.styles";

interface LanguageDropdownProps {
  setIsLanguageOpen?: (isLanguageOpen: boolean) => void;
  isPublicPage?: boolean;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  setIsLanguageOpen = () => {},
  isPublicPage = false,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const appDispatch: Function = useAppDispatch();
  const { language } = useAppState();
  const storedLanguage = localStorage.getItem("language");

  const storedLangItem = availableLanguages.find(
    (lang: ILanguage) => lang.code === storedLanguage
  );
  const stateLangItem = availableLanguages.find((item: ILanguage) => {
    return item.code === language;
  });

  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    storedLangItem?.display
  );

  const { updateUserLanguage, userProfile } = useUserApi(
    !isPublicPage ? true : false
  );

  const userProfileLanguage =
    userProfile &&
    availableLanguages.find((item: ILanguage) => {
      return item.code === userProfile.language;
    });

  const OutsideSelectOption = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: { target: any }) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
          setIsLanguageOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  OutsideSelectOption(wrapperRef);

  useEffect(() => {
    if (userProfileLanguage) {
      sStorage.set("lng", userProfileLanguage.code);
      localStorage.setItem("language", userProfileLanguage.code);
      i18n.changeLanguage(userProfileLanguage.code);
      setSelectedItem(userProfileLanguage.display);
    }
    sStorage.set("lng", language);
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
    setSelectedItem(stateLangItem?.display);
  }, [userProfileLanguage, language]);

  useEffect(() => {
    if (!storedLangItem) return;
    return setSelectedItem(storedLangItem.display);
  }, [storedLangItem]);

  const checkValue = (value: string) => {
    setSelectedItem(value);
    setOpen(false);
    setIsLanguageOpen(false);
  };
  const changeLanguage = (item: ILanguage) => {
    sStorage.set("lng", item.code);
    localStorage.setItem("language", item.code);
    i18n.changeLanguage(item.code);
    appDispatch({
      type: "lng",
      data: item.code,
    });

    if (!isPublicPage) updateUserLanguage(item.code);
  };

  return (
    <Dropdown
      ref={wrapperRef}
      isOpen={isOpen}
      onClick={() => {
        setOpen(!isOpen);
        setIsLanguageOpen(!isOpen);
      }}
    >
      {!isPublicPage && <DropdownLabel>{t("Language")}:</DropdownLabel>}
      <DropdownBox isOpen={isOpen}>
        <DropdownItem>{selectedItem ?? defaultLang.display}</DropdownItem>
        <Chevron />
      </DropdownBox>
      <DropdownBody>
        <InnerMenuLabel>
          <Chevron />
          {t("Language")}
        </InnerMenuLabel>

        {availableLanguages.map((item: any, index: number) => (
          <DropdownBodyContent
            key={index}
            onClick={() => {
              checkValue(item.language);
              changeLanguage(item);
            }}
            isSelected={
              selectedItem === item.language || selectedItem === item.display
            }
            index={index}
          >
            {" "}
            <span>{item.display}</span>
          </DropdownBodyContent>
        ))}
      </DropdownBody>
    </Dropdown>
  );
};

export default LanguageDropdown;
