import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import * as S from "../../styles/Shared.styles";

interface FeedbackProps {
  status?: string;
  message: string;
  icon: ReactNode;
}

const Feedback = ({ ...props }: FeedbackProps) => {
  const { t } = useTranslation();

  const { message, icon } = props;
  return (
    <S.FeedbackContainer>
      {icon && icon}
      <S.FeedbackMessage dangerouslySetInnerHTML={{ __html: t(message) }} />
    </S.FeedbackContainer>
  );
};

export default Feedback;
