import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import BannerButton from "components/BannerButton/BannerButton";
import { getMembershipName } from "util/getMembershipName";
import { IUnitMintable } from "util/IMinting";

import MintingListItem, { MintingListProps } from "./MintingListItem";
import * as S from "./SharedStyles.styles";

interface MintingProps {
  logo?: string;
  dark_logo?: string;
  region?: string;
  units_mintable?: IUnitMintable[];
  location_id?: string;
  onSubmit: Function;
  handleModalClose: () => void;
}

const Minting = ({
  logo,
  dark_logo,
  region,
  units_mintable,
  onSubmit,
  location_id,
  handleModalClose,
}: MintingProps) => {
  const { t } = useTranslation();

  const getMintingListItems = (unitsMintable: IUnitMintable[]) => {
    return unitsMintable?.map((item: IUnitMintable) => {
      const { name_one, name_two, available, total, value } = item;
      const unitText = total === 1 ? t("Membership") : t("Memberships");
      const isViewUltraPlus = name_one.size === "VIEW_ULTRA_PLUS";

      const title = `${getMembershipName(name_one) || ""}${
        name_two && !isViewUltraPlus ? " / " : ""
      }${(!isViewUltraPlus && getMembershipName(name_two)) || ""}`;

      const subTitle = `(${total} ${unitText}, ${available} ${t("Available")})`;

      return { title, subTitle, amount: 0, available, value };
    });
  };

  const [mintingState, setMintingState] = useState<MintingListProps[]>(
    getMintingListItems(units_mintable || [])
  );

  const isDisabled = useMemo(() => {
    const isAnyItemChanged = mintingState.some(
      (item) =>
        item.amount !== 0 && Number(item.amount) <= Number(item.available)
    );

    return !isAnyItemChanged;
  }, [mintingState]);

  const onListItemChange = (index: number, value: number) => {
    const newMintingState = [...mintingState];
    newMintingState[index].amount = Number(value);
    setMintingState(newMintingState);
  };

  const handleSubmit = () => {
    const filterJustChangedItems = mintingState.filter(
      (item) =>
        item.amount !== 0 && Number(item.amount) <= Number(item.available)
    );

    const stepData = {
      step: "Minting",
      location_id: location_id,
      requestedAllocations: filterJustChangedItems.map((item, index) => {
        return {
          unit_type: Number(item.value),
          count: item.amount,
        };
      }),
    };
    onSubmit(stepData);
  };

  return (
    <S.MintWrapper>
      <S.ModalHeader>{t("Minting Units")}</S.ModalHeader>
      <S.MintLocationDetails>
        <S.MintLocationImage
          image={logo}
          darkImage={dark_logo ? dark_logo : logo}
        />
        <S.MintLocationName>{t(region || "")}</S.MintLocationName>
      </S.MintLocationDetails>
      <S.MintingScrollWrapper>
        <S.MintingListWrapper>
          {mintingState.map((item, index) => {
            const isJustOneOrLastItem =
              mintingState.length === 1 || index === mintingState.length - 1;
            const mintinData = {
              title: item.title,
              subTitle: item.subTitle,
              available: item.available,
              amount: item.amount,
              value: item.value,
              key: `minting-list-item-${index}`,
              isJustOneOrLastItem,
              onChange: (value: number) => onListItemChange(index, value),
            };

            return <MintingListItem {...mintinData} />;
          })}
        </S.MintingListWrapper>
      </S.MintingScrollWrapper>

      <S.BannerGroup>
        <BannerButton
          labelText={t("Cancel")}
          onClick={handleModalClose}
          secondary={true}
        />
        <BannerButton
          labelText={t("Continue")}
          onClick={handleSubmit}
          disabled={isDisabled}
          $twoTone
        />
      </S.BannerGroup>
    </S.MintWrapper>
  );
};

export default Minting;
