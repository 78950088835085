import { Redirect, Route, Switch } from "react-router-dom";

import ForgotPasswordComponent from "components/Login/Components/ForgotPasswordComponent";
import LoginComponent from "components/Login/Components/LoginComponent";
import SetupPasswordComponent from "components/Login/Components/SetupPasswordComponent";
import TemporaryPasswordComponent from "components/Login/Components/TemporaryPasswordComponent";
import Landing from "components/Login/Landing/Landing";

import routes from "config/routes";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path={routes.LANDING}>
        <Landing />
      </Route>
      <Route path={routes.LOGIN}>
        <LoginComponent />
      </Route>
      <Route path={routes.FORGOTPASSWORD}>
        <ForgotPasswordComponent />
      </Route>
      <Route path={routes.TEMPORARY}>
        <TemporaryPasswordComponent />
      </Route>
      <Route path={routes.SETUPPASSWORD}>
        <SetupPasswordComponent />
      </Route>
      <Route path={routes.RESETPASSWORD}>
        <SetupPasswordComponent />
      </Route>

      {/* <Route path={routes.LOGIN_TEST}>
        <LoginTestPage />
      </Route> */}
      {/* <Route
        component={() => {
          window.location.href = process.env.REACT_APP_RETURN_URL || "";
          return null;
        }}
      /> */}
      <Route>
        <Redirect to={routes.LANDING} />
      </Route>
    </Switch>
  );
};

export default PublicRoutes;
