import { LocationIcon } from "components/LocationIcon/LocationIcon";
import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export interface CheckboxInputProps {
  error?: any;
  isLabelHasHtml?: boolean;
}

const FormElementsDefaultHeightStyles = css`
  height: 40px;
`;

export const flexBox = css`
  display: flex;
`;

export const fontStyle = (
  weight?: string,
  size?: string,
  lineHeight?: string
) => {
  return css`
    font-style: normal;
    font-weight: ${weight ? weight : "400"};
    font-size: ${size ? size : "16px"};
    line-height: ${lineHeight ? lineHeight : "24px"};
  `;
};

const alignItemsJustifyContentCenter = css`
  ${flexBox}
  align-items: center;
  justify-content: center;
`;

export const FormElementsDefaultHeight = FormElementsDefaultHeightStyles;

export const MintWrapper = styled.div`
  padding: 60px 32px 0;
  ${({ theme }) => {
    const { direction } = theme;
    return css`
      direction: ${direction};
    `;
  }}
`;

export const ModalHeader = styled.div`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      margin-bottom: 22px;
      color: ${colors.brandColors.brandSecondary};

      ${fontStyle("", "28px", "40px")};
    `;
  }}
`;

export const MintLocationDetails = styled.div`
  ${flexBox}
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
`;

export const MintLocationImage = styled(LocationIcon)`
  width: 40px;
  height: 50px;
  margin-inline-end: 20px;
`;

export const MintLocationName = styled.div`
  ${fontStyle("400", "24px", "48px")};
`;

/*Minting.tsx*/
export const MintingListWrapper = styled.ul`
  padding: 0;
  margin: 0;
`;

export const MintingList = styled.li<{ isJustOneOrLastItem?: boolean }>`
  ${({ theme, isJustOneOrLastItem }) => {
    const { colors } = theme;
    return css`
      width: 100%;
      ${flexBox};
      align-items: center;
      padding: 20px 0;
      border-bottom: ${isJustOneOrLastItem
        ? ""
        : `0.5px solid ${colors.textColors.textGrey}`};
      justify-content: space-between;
    `;
  }}
`;

export const MintingContainer = styled.div<{ isJustOneOrLastItem?: boolean }>`
  ${flexBox};
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 20px 0;
  button {
    margin-bottom: 0;
  }
`;

export const MintingListItemName = styled.div`
  ${fontStyle("300", "16px", "24px")};
`;

export const MintingListInputGroup = styled.div`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
     ${flexBox}
  align-items: center;
  gap: 10px;
  input {
    border-radius: 12px;
    border: 0;
    padding: 5px;
    width: 90px;
    ${fontStyle("600", "24px", "24px")};
    ${FormElementsDefaultHeight};
    :focus-visible {
      outline: none;
      border: 0;
    }
    ::placeholder {
      color: ${({ theme }) =>
        theme.name === "TheiaThemeLight"
          ? colors.textColors.textBlack
          : colors.textColors.textLightGrey};
    }
    `;
  }}
`;

export const MintingListInputButton = styled.button<{ disabled: boolean }>`
  ${({ theme, disabled }) => {
    const { colors } = theme;
    return css`
      ${alignItemsJustifyContentCenter}
      flex-direction: row;
      padding: 8px;
      gap: 10px;
      background: ${colors.surfaces.surfaceSecondary};
      border: 1px solid
        ${disabled ? "#c8c9cc " : colors.brandColors.brandPrimary};
      border-radius: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 10px;
      ${fontStyle("600", "20px", "24px")};

      cursor: ${!disabled ? "pointer" : "not-allowed"};
      width: 40px;
      ${FormElementsDefaultHeight}
    `;
  }}
`;

export const BannerGroup = styled.div<{
  isMintingStep?: boolean;
  isJustOneButton?: boolean;
}>`
  width: 100%;
  display: flex;
  gap: 40px;
  ${({ isMintingStep }) =>
    isMintingStep ? "padding-bottom: 32px;" : "padding: 32px 0 32px 0;"};
  margin: 0 auto;
  ${({ isJustOneButton }) =>
    isJustOneButton
      ? "justify-content: center;button{width:50%;margin-top:0}"
      : "  justify-content: space-between;"};
`;

/* Wallet.tsx */
export const FormWrapper = styled.div<CheckboxInputProps>`
  ${({ theme, error }) => {
    const { colors } = theme;
    return css`
      margin-bottom: 10px;
      text-align: start;
      position: relative;
      input {
        ${fontStyle("400", "14px", "20px")};
        border: 1px solid
          ${error
            ? colors.helpers.negativePrimary
            : colors.brandColors.brandPrimary};
        border-radius: 14px;
        padding: 8px;
        height: 40px;
      }
    `;
  }}
`;
export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const FormLabel = styled.div`
  width: 100%;
  ${fontStyle("700", "14px", "20px")};
  margin-bottom: 6px;
  text-transform: uppercase;
`;

export const FormError = styled.span`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      font-style: normal;
      ${fontStyle("400", "16px", "12px")};
      color: ${colors.helpers.negativePrimary};
      ${flexBox}
      align-items: center;
      padding: 8px;
    `;
  }}
`;
export const CheckboxDisplay = styled.div<{
  language?: any;
  isLabelHasHtml?: boolean;
}>`
  ${({ theme, language, isLabelHasHtml }) => {
    const { colors } = theme;

    const commonStyles = css`
      text-align: start;
      color: ${colors.textColors.textGrey};
      ${fontStyle("500", "14px", "18px")};
      svg {
        ${alignItemsJustifyContentCenter};
        position: absolute;
        left: 2px;
        right: 2px;
      }
    `;

    if (language === "ru") {
      return css`
        width: 80%;
        ${commonStyles};
      `;
    }

    if (isLabelHasHtml) {
      return css`
        display: inline-block;
        span {
          color: ${colors.brandColors.brandSecondary};
        }
        ${commonStyles};
      `;
    }

    return css`
      ${flexBox};
      gap: 5px;
      align-items: center;
      ${commonStyles};
    `;
  }}
`;

export const CheckboxInput = styled.input<CheckboxInputProps>`
  ${({ theme, isLabelHasHtml }) => {
    const { colors } = theme;
    return css`
      display: none;
      & + ${CheckboxDisplay} {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        border-radius: 2px;
        box-sizing: border-box;
        background: ${colors.textColors.textGrey};
      }

      &:hover + ${CheckboxDisplay} {
        border-color: #00b3ee; // color is not in theia design
      }

      &:checked + ${CheckboxDisplay} {
        background-size: cover;
        background: ${colors.brandColors.brandPrimaryVariant};
      }
    `;
  }}
`;

export const CheckboxLabel = styled.label`
  ${flexBox}
  gap: 8px;
  align-items: center;
  margin: 0 0 10px 0px;
  position: relative;
  cursor: pointer;
`;

export const InformationTextWrapper = styled.div`
  padding-top: 30px;
`;
export const InformationText = styled.span`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      ${fontStyle("400", "14px", "20px")};
      color: ${colors.textColors.textGrey};
    `;
  }}
`;
export const PrivacyPolicyLink = styled.a`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      ${fontStyle("400", "14px", "20px")};
      color: ${colors.textColors.textGrey};
      padding-inline-start: 5px;
      @media ${breakpoints.tabletSmall} {
        padding-inline-start: 0;
      }
    `;
  }}
`;

export const CheckboxPrivacyWrapper = styled.div`
  ${flexBox};
  flex-direction: row;
  @media ${breakpoints.tabletSmall} {
    flex-direction: column;
  }
`;

export const Datalist = styled.datalist``;

/*Walletcheck.tsx*/
export const ApiErrorMessageWrapper = styled.div`
  ${alignItemsJustifyContentCenter}
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const ApiErrorMessage = styled.div`
  ${alignItemsJustifyContentCenter}
  flex-direction: column;
  gap: 20px;
`;

export const ApiErrorMessageTitle = styled.span`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      color: ${colors.brandColors.brandSecondary};
      ${fontStyle("500", "28px", "40px")};
      letter-spacing: -0.4375px;
      text-align: center;
    `;
  }}
`;

export const ApiErrorMessageDescription = styled.p`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      text-align: center;

      ${fontStyle("600", "20px", "28px")};
      span {
        color: ${colors.brandColors.brandSecondary};
        line-break: anywhere;
      }
    `;
  }}
`;

export const MintDetailGroup = styled.div`
  margin-bottom: 20px;
  margin: 15px 0;
  padding: 8px 0;
  border-bottom: 1px solid #c8c9cc;
  ${flexBox};
  flex-direction: column;
  gap: 5px;
`;

export const MintDetailTitle = styled.span<{ walletID?: boolean }>`
  ${({ theme, walletID = false }) => {
    const { colors } = theme;
    return css`
      color: ${colors.brandColors.brandSecondary};
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      gap: 10px;
      ${fontStyle("600", "16px", "24px")};
      ${walletID && ` flex-direction: column;align-items: flex-start;`}
    `;
  }}
`;

export const MintingDetailLabel = styled.span`
  ${fontStyle("600", "16px", "24px")};
  text-transform: lowercase;
  ${flexBox};
  align-items: center;
  gap: 10px;
  b {
    ${fontStyle("600", "20px", "30px")};
  }
`;

export const MintingDetailGroupSet = styled.div`
  ${flexBox};
  justify-content: space-between;
  gap: 10px;
`;

export const MintingDetailItem = styled.div``;

export const MintingDetailLabelDate = styled.span`
  ${fontStyle("600", "14px", "24px")};
  width: 100%;
  display: block;
`;

export const MintingDetailLabelStatus = styled.span<{ status: string }>`
  ${({ theme, status }) => {
    const { colors } = theme;
    let color;

    switch (status) {
      case "minting":
        color = colors.brandColors.brandPrimary;
        break;
      case "progress":
        color = colors.helpers.warningSecondary;
        break;
      case "unable":
        color = "#989898";
        break;
      case "notified":
        color = colors.helpers.positiveSecondary;
        break;
      default:
        color = "inherit";
        break;
    }
    return css`
      ${fontStyle("500", "14px", "24px")};
      text-transform: uppercase;
      ${flexBox};
      align-items: center;
      gap: 10px;
      color: ${color};
    `;
  }}
`;

export const DateItemsTable = styled.table`
  thead {
    td {
      ${({ theme }) => {
        const { colors } = theme;
        return css`
          color: ${colors.brandColors.brandSecondary};
          letter-spacing: 1px;
        `;
      }}
    }
  }
`;
export const DateItemsTableTr = styled.tr``;
export const DateItemsTableTd = styled.td`
  ${fontStyle("600", "16px", "24px")};
`;

export const MintingScrollWrapper = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-height: 400px;
`;
