const Notified = ({ ...props }) => {
  const {
    width = 16,
    height = 16,
    fill = "#DBF6EC",
    viewBox = "0 0 16 16",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={fill} />
      <rect x="3" y="7" width="2" height="2" rx="1" fill="#141414" />
      <rect x="7" y="7" width="2" height="2" rx="1" fill="#141414" />
      <rect x="11" y="7" width="2" height="2" rx="1" fill="#141414" />
    </svg>
  );
};
export default Notified;
