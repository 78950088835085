import { Button } from "@successfactory/sf-ui-kit";
import styled from "styled-components";

interface ButtonStylesProps {
  theme: any;
  $twoTone?: boolean;
  disabled?: boolean;
  $secondary?: boolean;
}

const getBackgroundColor = (p: ButtonStylesProps) => {
  const twoToneColor = p.$twoTone
    ? p.theme.colors.brandColors.brandPrimaryVariant
    : null;
  const secondaryColor = p.$secondary
    ? p.theme.colors.textColors.textGrey
    : null;
  return twoToneColor || secondaryColor || "transparent";
};

export const ButtonStyles = styled(Button)<ButtonStylesProps>`
  border: ${({ $twoTone, theme }) =>
    $twoTone
      ? `none`
      : `1px solid ${theme.colors.brandColors.brandVariantDark}`};
  background-color: ${getBackgroundColor};
  font-weight: ${({ $twoTone }) => ($twoTone ? 700 : 600)};
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 17px;
  gap: 8px;
  margin-top: 30px;
  height: 40px;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.textColors.textWhite};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    box-shadow: ${({ $twoTone }) =>
      $twoTone ? `inset 0px -2px 0px rgba(0, 0, 0, 0.25)` : `none`};
    border-color: ${({ $twoTone, theme }) =>
      $twoTone
        ? `${theme.colors.brandColors.brandPrimary}`
        : `${theme.colors.brandColors.brandVariantDark}`};
  }
  &:active {
    border-width: ${({ $twoTone }) => ($twoTone ? `3px` : `0`)};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #c8c9cc;
    color: ${({ theme }) => theme.colors.textColors.textGrey};
    box-shadow: inset 0px -2px 0px #989898;
  }
`;
