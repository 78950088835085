import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import BannerButton from "components/BannerButton/BannerButton";
import { emailValidateSchema as schema } from "components/Login/schemas/login.schema";
import * as S from "components/Login/styles/Shared.styles";
import { FormGroup } from "components/MintingComponents/SharedStyles.styles";
import FormCheckbox from "components/MintingComponents/WalletFormCheckbox";

import FormInput from "../Components/Common/FormInput";

interface TemporaryPasswordProps {
  onSubmit: (data: any) => void;
}

const TemporaryPasswordForm = ({ ...props }: TemporaryPasswordProps) => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { onSubmit } = props;
  const { handleSubmit, formState } = methods;

  const [checkboxes, setCheckboxes] = useState<{
    termsOfUse: boolean;
    privacyPolicy: boolean;
    cookiePolicy: boolean;
    disclaimer: boolean;
  }>({
    termsOfUse: false,
    privacyPolicy: false,
    cookiePolicy: false,
    disclaimer: false,
  });

  const handleCheckboxChange = (
    checkboxName: keyof typeof checkboxes,
    checked: boolean
  ) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: checked,
    }));
  };

  const commonTextForCheckbox = t("I agree to the");

  const checkboxProps = {
    termsOfUse: {
      label: commonTextForCheckbox,
      checked: checkboxes.termsOfUse,
      onChange: (checked: boolean) =>
        handleCheckboxChange("termsOfUse", checked),
      isTermsOfUse: true,
      termsOfUseUrl: "/assets/legal/The_View_Terms_of_Use.pdf",
    },
    privacyPolicy: {
      label: commonTextForCheckbox,
      checked: checkboxes.privacyPolicy,
      onChange: (checked: boolean) =>
        handleCheckboxChange("privacyPolicy", checked),
      isPrivacyPolicy: true,
      privacyPolicyUrl: "/assets/legal/The_View_Privacy_Policy.pdf",
    },
    cookiePolicy: {
      label: commonTextForCheckbox,
      checked: checkboxes.cookiePolicy,
      onChange: (checked: boolean) =>
        handleCheckboxChange("cookiePolicy", checked),
      isCookiePolicy: true,
      cookiePolicyUrl: "/assets/legal/Cookie_Policy_The_View.pdf",
    },
    disclaimer: {
      label: commonTextForCheckbox,
      checked: checkboxes.disclaimer,
      onChange: (checked: boolean) =>
        handleCheckboxChange("disclaimer", checked),
      isDisclaimer: true,
      disclaimerUrl: "/assets/legal/Disclaimer_The_View.pdf",
    },
  };

  const isDisabled = useMemo(() => {
    return !Object.values(checkboxes).every((isChecked) => isChecked);
  }, [checkboxes]);

  return (
    <FormProvider {...methods}>
      <S.ButtonGroup>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormInput
              {...methods.register("email", { required: true })}
              name="email"
              type="email"
              title={t("Email Address*")}
              placeHolder={t("Please enter your email address")}
            />

            <FormInput
              {...methods.register("confirmEmail", { required: true })}
              name="confirmEmail"
              type="confirmEmail"
              title={t("Repeat Email Address*")}
              placeHolder={t("Please re-enter your email address")}
            />

            <S.ContentAlert>
              <S.ContentAlertText>
                {t(
                  "There has been a change in the contracting entity for our services. As of 28 November 2023, The View Meta Technologies LLC has taken over all responsibilities previously held by The View B.V."
                )}
              </S.ContentAlertText>
              <S.ContentAlertText>
                {t(
                  "Please review the updated Terms of Use for any modifications related to this change. By continuing, you acknowledge and agree to the updated terms."
                )}
              </S.ContentAlertText>
            </S.ContentAlert>

            <S.CheckboxWrap>
              {Object.entries(checkboxProps).map(([key, props]) => (
                <FormCheckbox key={key} {...props} />
              ))}
            </S.CheckboxWrap>
          </FormGroup>

          <BannerButton
            labelText={t("Request Password")}
            disabled={
              !formState.isValid || formState.isSubmitting || isDisabled
            }
            $twoTone
          />
        </S.Form>
      </S.ButtonGroup>
    </FormProvider>
  );
};

export default TemporaryPasswordForm;
