import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const VotedRegionWrapper = styled.div`
  margin-bottom: 33px;
  ${(props) =>
    props.theme.direction === "rtl"
      ? " margin-left: 26px"
      : " margin-right: 26px"};
  @media ${breakpoints.mobileSmall} {
    :nth-child(odd) {
      ${(props) =>
        props.theme.direction === "rtl"
          ? " margin-left: 0"
          : " margin-right: 0"};
    }
  }

  @media ${breakpoints.mobile} {
    :nth-child(even) {
      ${(props) =>
        props.theme.direction === "rtl"
          ? " margin-left: 0"
          : " margin-right: 0"};
    }
    ${(props) =>
      props.theme.direction === "rtl"
        ? " margin-left: 18px"
        : " margin-right: 18px"};
    display: flex;
    justify-content: center;
    div {
      width: 154px;
      ${(props) =>
        props.theme.direction === "rtl"
          ? " margin-left: auto"
          : " margin-right: auto"};
    }
  }
`;

export const FingerprintContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    height: 80%;
    margin-top: 5%;
  }
`;

export const Fingerprint = styled.div`
  height: 150px;
  width: 100%;
  img {
    max-width: 130px;
  }
`;
