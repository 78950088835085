import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { SfTabsMenu } from "@successfactory/sf-libraries";
import useStatsApi from "api/useStatsApi";
import useViewCreditApi from "api/useViewCreditApi";
import Loader from "components/Loader/Loader";
import poolBoxItems from "components/PoolBoxes/PoolBoxItems";
import { DashboardTable } from "components/Table/DashboardTable";
import { IPoolCard } from "interface/IPool";
import { LocationTabs, LocationType } from "interface/IpoolsName";
import { TabsMenuWrapper } from "pages/pools/PoolsPageDashboard.styles";
import { Grid, GridContainer } from "styles/Grid.styles";

import { useAppState } from "AppContext";

import { HomeMetaTags } from "config/metaTags";
import routes from "config/routes";

const HomePageBanner = React.lazy(
  () => import("../../components/Banners/HomePageBanner")
);
const Boxey = React.lazy(() => import("../../components/PoolBoxes/Boxey"));
const PoolBox = React.lazy(() => import("../../components/PoolBoxes/PoolBox"));
const ViewCredit = React.lazy(() => import("components/ViewCredit/ViewCredit"));

// TODO: remove showMaintenance because it's temporary
interface HomePageProps {
  showMaintenance: boolean;
}

export const HomePage = ({ showMaintenance }: HomePageProps) => {
  const { t } = useTranslation();
  const { isDirection } = useAppState();

  const { creditData } = useViewCreditApi(true);

  const { stats, getStatsStatus } = useStatsApi({
    init: ["getStats"],
  });

  if (showMaintenance) {
    return <Redirect to={routes.MAINTENANCE} />;
  }

  const isLoading = getStatsStatus === "loading";

  if (isLoading) return <Loader />;

  return (
    <>
      <HomeMetaTags />
      <GridContainer>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12"></Grid>
        <Grid
          smCol="1/4"
          mdCol="1/8"
          lgCol="1/9"
          smRow="2/2"
          mdRow="2/2"
          lgRow="2/2"
        >
          <Suspense fallback={<Loader />}>
            <HomePageBanner />
          </Suspense>
        </Grid>
        <Grid
          smCol="1/4"
          mdCol="1/8"
          lgCol="10/12"
          smRow="3/3"
          mdRow="3/3"
          lgRow="2/2"
        >
          <>
            <ViewCredit creditData={creditData} />
          </>
        </Grid>
        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
          <Suspense fallback={<Loader />}>
            <PoolBox controls>
              {stats &&
                poolBoxItems.map((pool: IPoolCard) => {
                  return (
                    <Boxey
                      key={pool.key}
                      pool={pool}
                      type="home"
                      poolsName={pool.key}
                      stats={stats}
                    />
                  );
                })}
            </PoolBox>
          </Suspense>
        </Grid>

        <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
          <TabsMenuWrapper page="dashboard">
            <SfTabsMenu
              items={[
                {
                  label: t("Regions"),
                  content: (
                    <DashboardTable
                      tabsName={LocationTabs.REGIONS}
                      tabsType={LocationType.REGION}
                    />
                  ),
                },
                {
                  label: t("Cities"),
                  content: (
                    <DashboardTable
                      tabsName={LocationTabs.CITIES}
                      tabsType={LocationType.CITY}
                    />
                  ),
                },
                {
                  label: t("Verticals"),
                  content: (
                    <DashboardTable
                      tabsName={LocationTabs.VERTICALS}
                      tabsType={LocationType.VERTICAL}
                    />
                  ),
                },
                {
                  label: t("World Areas"),
                  content: (
                    <DashboardTable
                      tabsName={LocationTabs.CONTINENTS}
                      tabsType={LocationType.CONTINENT}
                    />
                  ),
                },
              ]}
              design="Round"
              align="Start"
              withDropDown={true}
              borderRadius="20px"
              padding="10px 20px"
              dir={isDirection}
            />
          </TabsMenuWrapper>
        </Grid>
      </GridContainer>
    </>
  );
};
