import { breakpointMax, breakpointMin } from "@successfactory/sf-libraries";
import styled from "styled-components";

import { getFontStyle } from "styles/mixin/getFontStyles";

interface TableRowColumnTextProps {
  changed?: boolean;
}
interface UnitsDisplayProps {
  soldOut?: boolean;
}
interface RefreshContainerProps {
  changed?: boolean;
}

export const TableContainer = styled.div`
  margin-top: -20px;
  ${breakpointMin.xl} {
    margin-top: 80px;
  }
`;

export const TableWrapper = styled.div<{ tableName?: string }>`
  position: relative;
  ${({ tableName }) => (tableName === "dashboard" ? "" : "padding-top: 30px;")}
  .sort-container {
    ${({ tableName }) => (tableName === "dashboard" ? "display: none;" : "")}
  }
  /*this needed to be updated after react table dom issue changes*/
  tbody:first-child {
    ${({ tableName }) =>
      tableName !== "dashboard" &&
      `
      display: block;
      tr {
        width: 100%;
      }
      tr, td {
        border-color: transparent;
      }
      ${breakpointMin.xxl} {
        display: none;
      }
    `}
  }

  th {
    text-transform: uppercase;
    ${({ theme }) => getFontStyle("captionCaps", theme)};
    background: ${({ theme }) => theme.colors.brandColors.brandTableHeadTone};
    padding: 10px 6px !important;
  }
  tbody {
    ${breakpointMin.xxl} {
      tr:nth-child(even) {
        background: ${({ theme }) =>
          theme.colors.brandColors.brandTableHeadTone};
      }
    }
  }
  ${(p: any) => {
    if (p.tableName === "incomeCalculator")
      return `

     div {
      
          thead tr th:last-child{
          svg{
          display: none;
          }
        }
      }
      tbody tr {
          height: 80px;
        }
    ${breakpointMax.xxl} {
     div {
       table {
        background: ${(props: any) =>
          props.theme.colors.brandColors.brandPrimary};
        thead tr {
          justify-content: unset;  
          border: solid 1px ${(props: any) =>
            props.theme.colors.surfaces.surfaceVariant};
        }
        tbody{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
        }
         tr {
          width: calc(50% - 10px);
          margin:5px;
          height:auto;
          padding: 0px 0px 16px 0px;
          justify-content: space-between;
        }
         td {
          :nth-child(1) {
            border: none;
            border-bottom: 1px solid grey;
            order:3;
            padding:0;
          }

          :nth-child(2) {
            margin: 16px 0 19px 16px;
            width: 100px;
            font-weight: 600;
            width: 50%;
            order: 0;
            div{
              padding-bottom:unset;
            }
          }
          :nth-child(3) {
            order: 4;
            width:50%;
            padding:0;
            ${
              p.theme.direction === "rtl"
                ? "margin:19px 16px 0 0px;"
                : "margin:19px 16px 0 16px;"
            }
            padding-bottom:unset;
          }
          :nth-child(4) {
            order: 5;
            margin: 19px 0px 0px 0px;
            width:35%;
            padding:0;
            

          }
          :nth-child(5) {
            margin:19px 0px 0px 16px;
            ${p.theme.direction === "rtl" ? "order: 9;" : "order: 8;"}
          }
          :nth-child(6) {
            margin: 15px 24px 0px 24px;
            ${p.theme.direction === "rtl" ? "order: 8;" : "order: 9;"}
          }
          :nth-child(7) {
            order:1;
      
             ${
               p.theme.direction === "rtl"
                 ? "margin:5px 0 0 16px;"
                 : "margin: 5px 16px 0 0;"
             }
             padding:0
          }
          :nth-of-type(2):before {
            display: none;
            
          }
        }
        th:first-child {
          display: none;
        }

      
   

     ${breakpointMax.xl} {
      table thead tr {
        width: unset;
      }
          tbody{
            flex-direction: column;
          }
            tr{
              width:100%;
            }
    }

    ${breakpointMax.xl} {
      
      div {
        table {
          margin-top: 46px;
          thead {
            height: 40px;
          }
          tbody tr {
            height: 80px;
          }
          
        }
      }
    }
      `;
  }}

  ${breakpointMax.xl} {
    .sort-container {
      ${({ tableName }) =>
        tableName !== "incomeCalculator" ? "margin-bottom: -20px;" : ""}
    }
    div {
      table {
        thead tr {
          justify-content: unset;
          border: solid 1px
            ${({ theme }) => theme.colors.brandColors.brandSecondary};
        }
        tr {
          justify-content: space-between;
        }
        td {
          :nth-child(1) {
            width: 30%;
            order: 2;
            padding-right: 0;
          }
          :nth-child(2) {
            border: none;
            border-bottom: 1px solid grey;
            padding-left: 24px;
            margin: auto;
            width: 100%;
            font-weight: 600;
            width: 70%;
            order: 1;
            padding-right: 0;
          }
          :nth-child(3) {
            order: 3;
          }
          :nth-child(4) {
            order: 4;
            ${({ theme }) =>
              theme.direction === "rtl"
                ? "margin-left: 16px;"
                : "padding-right: 24px;"}
          }
          :first-child {
            height: 23.1%;
          }
          :nth-of-type(2):before {
            display: none;
          }
        }
        th:first-child {
          display: none;
        }
      }
    }
  }
`;

export const TableRowCountry = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => getFontStyle("bodyS", theme)};

  img {
    width: 32px;
    ${({ theme }) =>
      theme.direction === "ltr"
        ? `margin-right: 20px;`
        : `margin-left: 20px; margin-right: 10px;`}
    ${breakpointMax.xl} {
      ${({ theme }) =>
        theme.direction === "ltr"
          ? `margin-right: 10px;`
          : `margin-left: 10px; margin-right: 16px;`}
    }
  }
`;

export const LocationText = styled.div`
  /* width: 120px; */
`;

export const LocationIconContainer = styled.div``;

export const TableRowPromo = styled.div`
  /* ${breakpointMin.xl} {
    width: 80px;
  } */
`;

export const TableRowUnits = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${breakpointMin.xl} {
    /* width: 170px; */
  }
`;

export const TableRowColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${breakpointMin.xl} {
    /* width: 170px; */
  }
`;

export const TableRowColumnText = styled.div<TableRowColumnTextProps>`
  color: ${({ changed }) =>
    changed
      ? ({ theme }) => theme.colors.brandColors.brandPrimary
      : ({ theme }) => theme.colors.brandColors.brandSecondary};
  ${({ theme }) => getFontStyle("bodyS", theme)}
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    cursor: pointer;

    fill: ${(p: any) => p.theme.colors.textColors.textVariant};
  }
`;

export const EuroSign = styled.span<TableRowColumnTextProps>`
  ${({ theme }) =>
    theme.direction === "rtl"
      ? `display: flex; flex-direction: row-reverse; direction: ltr;`
      : ""}
  color: ${({ changed }) =>
    changed
      ? ({ theme }) => theme.colors.brandColors.brandPrimary
      : ({ theme }) => theme.colors.brandColors.brandSecondary};
`;

export const TableRowColumnSubtext = styled.div<{ upperCase?: boolean }>`
  font-weight: 500;
  ${({ theme }) => getFontStyle("caption", theme)}
  color: ${({ theme }) => theme.colors.textColors.textVariant};
  ${breakpointMax.xl} {
    white-space: nowrap;
    overflow: hidden;
  }
  text-transform: ${({ upperCase }) => (upperCase ? `uppercase` : "none")};
`;

export const UnitsDisplay = styled.span<UnitsDisplayProps>`
  text-decoration: ${({ soldOut }) => (soldOut ? "line-through" : "none")};
  color: ${({ theme, soldOut }) =>
    soldOut
      ? theme.colors.textColors.textGrey
      : theme.colors.brandColors.brandSecondary};
`;

export const SoldOut = styled.span`
  color: ${({ theme }) => theme.colors.textColors.textGrey};
`;

export const RefreshContainer = styled.div<RefreshContainerProps>`
  #HoverStroke2 {
    stroke: ${({ changed }) =>
      changed ? (p: any) => "#fff" : (p: any) => null};
  }
`;

export const CoinImage = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  ${breakpointMin.xl} {
    margin-bottom: 0px;
  }
`;

export const SortLabel = styled.span`
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 30px;
  ${({ theme }) =>
    theme.direction === "rtl" ? "margin-right: 4px;" : "margin-left: 4px;"}
  ${breakpointMin.md} {
    ${({ theme }) =>
      theme.direction === "rtl" ? "margin-right: 0;" : "margin-left: 0;"}
  }
`;

export const LocationTableMobile = styled.div`
  margin-top: 10px;
  display: block;
  ${breakpointMin.xl} {
    display: none;
  }
`;

export const EmptyTable = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RowWrapperDashboard = styled.tr`
  display: flex;
`;

export const CustomTableButton = styled.div`
  button {
    width: auto;
    padding: 2px 8px;
    height: 20px;
    gap: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    background-color: ${({ theme }) => theme.colors.surfaces.buttonSecondary};
    color: ${({ theme }) => theme.colors.textColors.textBlack};
    margin: 0;
  }
`;
