import { useTranslation } from "react-i18next";

import { SfToggleSwitcher } from "@successfactory/sf-libraries";
import { linkData } from "components/Login/data/FooterLinkData";
import LanguageDropdown from "components/TopHeader/LanguageDropdown";
import { ToggleWrapper } from "components/TopHeader/TopHeader.styles";
import * as S from "pages/login/PublicTemplate.styles";
import { TheiaThemeDark, TheiaThemeLight } from "styles/themes/Theia";
import sStorage from "util/sStorage";

import { useAppDispatch } from "AppContext";

interface AppAuthProps {
  children?: any;
}
export const PublicTemplate = ({ children }: AppAuthProps) => {
  const { t } = useTranslation();

  const appDispatch: Function = useAppDispatch();

  const toggle = (checked: boolean) => {
    checked
      ? sStorage.set("theme", "TheiaThemeLight")
      : sStorage.set("theme", "TheiaThemeDark");
    appDispatch({
      type: "theme",
      data: { theme: checked ? TheiaThemeLight : TheiaThemeDark },
    });
  };

  return (
    <S.LoginFormsLayoutContainer>
      <S.LoginFormsHeader>
        <S.LoginFormsLogo
          src={`/assets/re-branding/logos/new-view-logo-${
            sStorage.get("theme") === "TheiaThemeLight" ? "black" : "white"
          }.svg`}
          alt="viewlogo"
        />
        <S.LoginFormsLanguage>
          <ToggleWrapper>
            <SfToggleSwitcher
              onChange={toggle}
              checked={sStorage.get("theme") === "TheiaThemeLight"}
            />
          </ToggleWrapper>
          <LanguageDropdown isPublicPage />
        </S.LoginFormsLanguage>
      </S.LoginFormsHeader>

      {children}

      <S.LoginFooterContainer>
        {linkData.map((data, yearIndex) => (
          <S.LoginFooterUl key={yearIndex}>
            <S.LoginFooterText>&copy; {data.year}</S.LoginFooterText>
            {data.links.map((link, linkIndex) => (
              <S.LoginFooterLi key={linkIndex}>
                <S.LoginFooterLink href={link.url} target="_blank">
                  {t(link.text)}
                </S.LoginFooterLink>
              </S.LoginFooterLi>
            ))}
          </S.LoginFooterUl>
        ))}
      </S.LoginFooterContainer>
    </S.LoginFormsLayoutContainer>
  );
};
