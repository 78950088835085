import { z } from "zod";

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-+<>?@[\].^_`{|}~])[A-Za-z\d!@#$%^&*()\-+<>?@[\].^_`{|}~]+$/;

const emailValidator = z.string().refine((email) => emailRegex.test(email), {
  message: "Enter a valid Email Address",
});

const passwordValidator = z
  .string()
  .min(8, { message: "Password must be at least 8 characters long" })
  .max(20)
  .refine((password) => passwordRegex.test(password), {
    message: "Please enter a valid Password",
  });

export const loginSchema = z.object({
  email: emailValidator,
  password: passwordValidator,
});

export const forgotPasswordSchema = z.object({
  email: emailValidator,
});

export const setupPasswordSchema = z
  .object({
    password: passwordValidator,
    confirmPassword: passwordValidator,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Password and Repeat Password do not match",
    path: ["confirmPassword"],
  })
  .superRefine((data, ctx) => {
    const passwordStrength = {
      hasMinCharacters: data.password.length >= 8,
      hasMaxCharacters: data.password.length > 0 && data.password.length <= 20,
      hasUppercase: /[A-Z]/.test(data.password),
      hasLowercase: /[a-z]/.test(data.password),
      hasNumber: /\d/.test(data.password),
      hasSpecialChar: /[!@#$%^&*()\-+<>?@[\].^_`{|}~]/.test(data.password),
    };

    if (!passwordStrength.hasMinCharacters) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min 8 characters",
        path: ["password.hasMinCharacters"],
      });
    }

    if (!passwordStrength.hasMaxCharacters) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Max 20 characters",
        path: ["password.hasMaxCharacters"],
      });
    }

    if (!passwordStrength.hasUppercase) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min 1 uppercase letter (A-Z)",
        path: ["password.hasUppercase"],
      });
    }

    if (!passwordStrength.hasLowercase) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min 1 lowercase letter (a-z)",
        path: ["password.hasLowercase"],
      });
    }

    if (!passwordStrength.hasNumber) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min 1 number (0-9)",
        path: ["password.hasNumber"],
      });
    }

    if (!passwordStrength.hasSpecialChar) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min 1 special character",
        path: ["password.hasSpecialChar"],
      });
    }
  });

export const emailValidateSchema = z
  .object({
    email: emailValidator,
    confirmEmail: emailValidator,
  })
  .refine((data) => data.email === data.confirmEmail, {
    message: "Email and Repeat Email do not match",
    path: ["confirmEmail"],
  });
