import { ReactNode } from "react";

import i18n from "i18n";

import routes from "config/routes";

import { ReactComponent as Academy } from "assets/dashboard/sidebar-icons/academy.svg";
import { ReactComponent as Home } from "assets/dashboard/sidebar-icons/home.svg";
// import { ReactComponent as IncomeCalculator } from "assets/dashboard/sidebar-icons/incomecalculator.svg";
import { ReactComponent as Memberships } from "assets/dashboard/sidebar-icons/membership.svg";
import { ReactComponent as Pools } from "assets/dashboard/sidebar-icons/pools.svg";
import { ReactComponent as Profile } from "assets/dashboard/sidebar-icons/profilesettings.svg";
import { ReactComponent as Videos } from "assets/dashboard/sidebar-icons/videospage.svg";

export interface ISidebarLinkItem {
  label: string;
  icon: ReactNode;
  linkTo: string;
  className?: string;
}

const SidebarLinkItems: Array<ISidebarLinkItem> = [
  {
    icon: <Home />,
    label: i18n.t("Home"),
    linkTo: routes.HOME,
  },
  {
    icon: <Memberships />,
    label: i18n.t("My Memberships"),
    linkTo: routes.MYPACKAGES,
  },
  {
    icon: <Pools />,
    label: i18n.t("Pools"),
    linkTo: routes.POOLS_UNIVERSAL,
  },
  // {
  //   icon: <IncomeCalculator />,
  //   label: i18n.t("Income Calculator"),
  //   linkTo: routes.INCOMECALCULATOR,
  // },
  {
    icon: <Profile />,
    label: i18n.t("Account Center"),
    linkTo: routes.ACCOUNTCENTER,
  },
  {
    icon: <Pools />,
    label: i18n.t("The View Credit Ledger"),
    linkTo: routes.CREDITLEDGER,
  },
  {
    icon: <Videos />,
    label: i18n.t("The View App Videos"),
    linkTo: routes.VIDEOS,
  },
  {
    icon: <Academy />,
    label: i18n.t("The View Academy"),
    linkTo: routes.ACADEMY,
  },
];

export default SidebarLinkItems;
