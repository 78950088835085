import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const MyProfileBlock = styled.div`
  max-width: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${breakpoints.desktop} {
    :first-child {
      ${({ theme }) =>
        theme.direction === "rtl"
          ? " padding-right: 3%; "
          : " padding-right: 1.5%;"};
    }
    :last-child {
      ${({ theme }) =>
        theme.direction === "rtl"
          ? " padding-right: 1.5%; padding-left: 3%;"
          : " padding-right: 3%;padding-left: 1.5%"};
    }
  }
  @media ${breakpoints.tablet} {
    width: 90%;
    margin-bottom: 5%;
  }
  @media ${breakpoints.mobile} {
    width: 100%;
    align-items: center;
    padding: 0;
  }
`;

export const MyProfileContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${breakpoints.tablet} {
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const AccountCenterBox = styled.div`
  min-height: 340px;
  padding: 0 20px 48px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  border-radius: 20px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.surfaces.surfaceVariant};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px -2px 3px #6baabf;
  @media ${breakpoints.tablet} {
    margin-bottom: 5%;
  }
  @media ${breakpoints.mobile} {
    width: 90%;
    .text {
      font-size: 14px;
    }
  }
`;

export const AccountCenterTitle = styled.h3`
  width: 100%;
  display: flex;
`;

export const AccountCenterIcon = styled.div`
  ${({ theme }) =>
    theme.direction === "rtl" ? " margin-left: 8px" : " margin-right: 8px"};
  svg {
    #briefcase-thick {
      stroke: ${({ theme }) => theme.colors.brandColors.brandPrimary};
    }
  }
`;
export const Description = styled.div`
  ${({ theme }) =>
    theme.direction === "rtl" ? " text-align: right" : " text-align: left"};
  width: 100%;
  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const AccountCenterItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.brandColors.brandPrimary};
  margin: 2% 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export const ItemContainer = styled.div`
  margin-top: 5%;
  width: 100%;
`;
const hoverState = css`
  color: ${({ theme }) => theme.colors.brandColors.brandSecondary};
`;

export const Download = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    ${hoverState};
    svg {
      #download-icon {
        stroke: ${({ theme }) => theme.colors.brandColors.brandSecondary};
      }
    }
  }
  svg {
    #download-icon {
      stroke: ${({ theme }) => theme.colors.brandColors.brandPrimary};
    }
  }
`;

export const ItemTitle = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.brandColors.brandPrimary};
  :hover {
    ${hoverState};
  }
`;
