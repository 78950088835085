import React from "react";
import {
  CardBody,
  CardBodyItem,
  CardBodyItemWrapper,
  CardItemTitle,
  CardItemValue,
  SfCardOneItem,
} from "./InfinityPoolsPage.styles";
import { useTranslation } from "react-i18next";
import { SfCardMembership } from "components/MyPackages/CardGroups/SfCardMembership/SfCardMembership";
interface InfinityPoolsCardProps {
  infinityPoolsData: Array<any>;
}
interface infinityPoolPackage {
  package: number;
  package_owned: number;
  package_sold: number;
  shared: number;
  you_own: string;
}
const InfinityPoolsCard: React.FC<InfinityPoolsCardProps> = (
  infinityPoolsData
) => {
  const { t } = useTranslation();
  const mediaMobile = window.matchMedia("(max-width: 414px)");
  const sortedPackage = infinityPoolsData.infinityPoolsData.sort(
    (a: infinityPoolPackage, b: infinityPoolPackage) => {
      return b.package - a.package;
    }
  );

  return (
    <>
      {sortedPackage.map((item: infinityPoolPackage, index: number) => (
        <SfCardOneItem key={index}>
          <SfCardMembership
            cardContent={{
              header: {
                captionLeft: t("Packages"),
                titleLeft:
                  "€ " +
                  (item.package === 1000
                    ? `${item.package} / 2000`
                    : item.package),
              },
            }}
            type="initial"
            width={
              mediaMobile.matches
                ? "342px"
                : mediaMobile.matches
                ? "292px"
                : undefined
            }
            height="292px"
          >
            <CardBody>
              <CardBodyItemWrapper>
                <CardBodyItem>
                  <CardItemTitle> {t("Packages Owned")}</CardItemTitle>
                  <CardItemValue>{item.package_owned}</CardItemValue>
                </CardBodyItem>

                <CardBodyItem>
                  <CardItemTitle>{t("Packages Sold")}</CardItemTitle>
                  <CardItemValue>{item.package_sold}</CardItemValue>
                </CardBodyItem>
              </CardBodyItemWrapper>
              <CardBodyItemWrapper>
                <CardBodyItem>
                  <CardItemTitle>{t("Shared %")}</CardItemTitle>
                  <CardItemValue>{item.shared} %</CardItemValue>
                </CardBodyItem>

                <CardBodyItem>
                  <CardItemTitle>{t("You Own")}</CardItemTitle>
                  <CardItemValue className="you-own">
                    {parseFloat(item.you_own).toFixed(5)} %
                  </CardItemValue>
                </CardBodyItem>
              </CardBodyItemWrapper>
            </CardBody>
          </SfCardMembership>
        </SfCardOneItem>
      ))}
    </>
  );
};

export default InfinityPoolsCard;
