import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { SfTabsMenu } from "@successfactory/sf-libraries";
import useStatsApi from "api/useStatsApi";
import Loader from "components/Loader/Loader";
import { PoolsName } from "interface/IpoolsName";
import { Grid, GridContainer } from "styles/Grid.styles";

import { useAppState } from "AppContext";

import routes from "config/routes";

import GenericPoolsPage from "./GenericPools/GenericPoolsPage";
import InfinityPoolsPage from "./InfinityPool/InfinityPoolPage";
import { TabsMenuWrapper } from "./PoolsPageDashboard.styles";

import FounderCircle from "../../assets/pool-tabs-icons/founders-circle.png";
import GlobalPool from "../../assets/pool-tabs-icons/global-pool.png";
import InfinityPool from "../../assets/pool-tabs-icons/infinity-pool.png";
import UniversalPool from "../../assets/pool-tabs-icons/universal-pool.png";

interface PoolsPageProps {
  tab: number;
}
const PoolsPageDashboard: FC<PoolsPageProps> = ({ tab }) => {
  const { t } = useTranslation();
  const { isDirection } = useAppState();
  const [currentTab, setCurrentTab] = useState(tab);
  const history = useHistory();

  const { stats, getStatsStatus } = useStatsApi({
    init: ["getStats"],
  });

  const isLoading = getStatsStatus === "loading";

  if (isLoading) return <Loader />;

  const items = [
    {
      icon: <img src={UniversalPool} alt="universal-pool-icon" />,
      label: t("Universal Pool"),
      content: (
        <GenericPoolsPage
          poolsName={PoolsName.UNIVERSAL}
          description={t(
            "Please find an overview of all your Universal Pool units below"
          )}
          stats={stats}
        />
      ),
    },
    {
      icon: <img src={GlobalPool} alt="universal-pool-icon" />,
      label: t("Global Pool"),
      content: (
        <GenericPoolsPage
          poolsName={PoolsName.GLOBAL}
          description={t(
            "Please find an overview of all your Global Pool units below"
          )}
          stats={stats}
        />
      ),
    },
    {
      icon: <img src={FounderCircle} alt="founders-circle-icon" />,
      label: t("Founders Circle"),
      content: (
        <GenericPoolsPage
          poolsName={PoolsName.FOUNDERS}
          description={t(
            "Please find an overview of all your Founders Circle Pool units below"
          )}
          stats={stats}
        />
      ),
    },
    {
      icon: <img src={InfinityPool} alt="infinity-pool-icon" />,
      label: t("Infinity Pool"),
      content: <InfinityPoolsPage />,
    },
  ];

  const onTabsChange = (e: any) => {
    switch (e) {
      case 0:
        history.replace(routes.POOLS_UNIVERSAL);
        break;
      case 1:
        history.replace(routes.POOLS_GLOBAL);

        break;
      case 2:
        history.replace(routes.POOLS_FOUNDERS);

        break;
      default:
        history.replace(routes.POOLS_INFINITY);
    }
    setCurrentTab(e);
  };

  const PageView = () => {
    return (
      <>
        <GridContainer>
          <Grid smCol="1/4" mdCol="1/8" lgCol="1/12"></Grid>
          <Grid smCol="1/4" mdCol="1/8" lgCol="1/12">
            <TabsMenuWrapper>
              <SfTabsMenu
                items={items.map((data) => {
                  return {
                    icon: data.icon,
                    label: data.label,
                    content: data.content,
                  };
                })}
                onChange={(e: any) => onTabsChange(e)}
                withDropDown={true}
                design="Round"
                align="Start"
                borderRadius="20px"
                padding="4px 20px"
                dir={isDirection}
                currentTab={currentTab}
              />
            </TabsMenuWrapper>
          </Grid>
        </GridContainer>
      </>
    );
  };
  return <>{PageView()}</>;
};

export default PoolsPageDashboard;
