import { useState } from "react";

import axios from "axios";
import IPackageData from "interface/IPackageData";
import IUser from "interface/IUser";
import sStorage from "util/sStorage";

import { LoaderStates } from "config/states";

export const baseUrl = process.env.REACT_APP_API_URL;

export const getHeader = () => {
  const token = sStorage.get("token");
  return {
    headers: {
      Accept: "application/json",
      // 'Accept-Encoding': 'gzip',
      Authorization: "bearer " + token,
    },
  };
};
const useApi = () => {
  const [getUserStatus, setGetUserStatus] = useState(LoaderStates.IDLE);
  const [userPackages, setUserPackages] = useState<Array<IPackageData>>();
  const [userProfile, setUserProfile] = useState<IUser>();

  const getUser = async () => {
    if (getUserStatus === LoaderStates.LOADING) return;
    setGetUserStatus(LoaderStates.LOADING);
    try {
      const result = await axios.get(`${baseUrl}/user`, getHeader());
      const userObject = result.data.data;
      setUserPackages(userObject.packages);
      setUserProfile(userObject.user);
      setGetUserStatus(LoaderStates.SUCCESS);
    } catch (error: any) {
      console.log("userEffect -> error: ", error);
      setGetUserStatus(LoaderStates.ERROR);
    }
  };
  return { userPackages, userProfile, getUserStatus, getUser };
};

export const useAxiosGet = (
  endpoint: string
): [(params?: string[] | string) => Promise<boolean>, any, string] => {
  const [status, setStatus] =
    useState<"idle" | "loading" | "success" | "error" | "serverErrors">("idle");
  const [response, setResponse] = useState({});

  const get = async (params?: string[] | string) => {
    if (status === "loading") return false;
    setStatus("loading");
    try {
      const endpointBaseUrl = `${baseUrl}${endpoint}`;
      const endpointUrl =
        params && typeof params === "string"
          ? `${endpointBaseUrl}/${params}`
          : endpointBaseUrl;
      const result = await axios.get(endpointUrl, getHeader());

      if (result.data.success) {
        setResponse(result);
        setStatus("success");
        return true;
      } else {
        setResponse(result);
        setStatus("error");
      }
    } catch (error: any) {
      console.log("🚀 ~ file: useApi.ts ~ line 23 ~ get ~ error", error);
      setResponse(error.response);
      setStatus("serverErrors");
    }
    return false;
  };

  return [get, response, status];
};

export const useAxiosPost = (
  endpoint: string
): [
  (data: any, params?: string[] | string) => Promise<boolean>,
  any,
  string
] => {
  const [status, setStatus] =
    useState<"idle" | "loading" | "success" | "error" | "serverErrors">("idle");
  const [response, setResponse] = useState({});

  const post = async (data: any = {}, params?: string[] | string) => {
    if (status === "loading") return false;
    setStatus("loading");
    try {
      const endpointBaseUrl = `${baseUrl}${endpoint}`;
      const endpointUrl =
        params && typeof params === "string"
          ? `${endpointBaseUrl}/${params}`
          : endpointBaseUrl;

      const result = await axios.post(endpointUrl, data, getHeader());
      if (result.data.success) {
        setResponse(result);
        setStatus("success");
        return true;
      } else {
        setResponse(result);
        setStatus("error");
      }
    } catch (error: any) {
      console.log("🚀 ~ file: useApi.ts ~ line 23 ~ get ~ error", error);
      setResponse(error.response);
      setStatus("serverErrors");
    }
    return false;
  };

  return [post, response, status];
};

export const useApiHelpers = () => {
  return {
    shouldInitApi: (subject: string, init: string[] | string, status: string) =>
      (typeof init === "string" ? subject === init : init.includes(subject)) &&
      status === "idle",
  };
};

export interface useApiOptionsProps {
  id?: string;
  refresh?: boolean;
  init?: string[] | string;
}

export default useApi;
