import axios from "axios";
import { useState } from "react";

import { LoaderStates } from "config/states";
import { baseUrl, getHeader } from "./useApi";
import { ISelectedLocationData } from "interface/ILocationData";

const useSelectedLocationsApi = (
  directStart: boolean = false,
  apiUrl: string,
  selectedLocationId: string
) => {
  const [getSelectedLocationsStatus, setGetSelectedLocationsStatus] = useState(
    LoaderStates.IDLE
  );
  const [selectedLocations, setSelectedLocations] =
    useState<ISelectedLocationData>();

  const getSelectedLocations = async (selectedLocationId: string) => {
    if (getSelectedLocationsStatus === LoaderStates.LOADING) return;
    setGetSelectedLocationsStatus(LoaderStates.LOADING);
    try {
      const result = await axios.get(
        `${baseUrl}/${apiUrl}/${selectedLocationId}`,
        getHeader()
      );
      setSelectedLocations(result.data.data);
      setGetSelectedLocationsStatus(LoaderStates.SUCCESS);
    } catch (error) {
      console.log("userEffect -> error: ", error);
      setGetSelectedLocationsStatus(LoaderStates.ERROR);
    }
  };
  if (directStart && getSelectedLocationsStatus === LoaderStates.IDLE)
    getSelectedLocations(selectedLocationId);
  return {
    selectedLocations,
    getSelectedLocationsStatus,
    getSelectedLocations,
  };
};

export default useSelectedLocationsApi;
