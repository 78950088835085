import { fontStyle } from "components/MintingComponents/SharedStyles.styles";
import styled, { css } from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

interface ModalHeaderProps {
  isError?: boolean;
}

export const ModalWrapper = styled.div`
  ${({ theme }) => {
    const { direction } = theme;
    return css`
      padding: 30px 32px;
      direction: ${direction};
      button {
        margin-top: 0;
      }
      @media ${breakpoints.mobile} {
        padding: 20px;
      }
    `;
  }}
`;

export const ModalContentWrapper = styled.div`
  text-align: start;
  label {
    margin-top: 20px;
  }
`;

export const ModalHeader = styled.div<ModalHeaderProps>`
  ${({ theme, isError }) => {
    const { colors } = theme;
    const textColor = isError
      ? colors.textColors.textLightGrey
      : colors.brandColors.brandSecondary;

    return css`
      margin-bottom: 22px;
      color: ${textColor};
      text-align: center;
      ${fontStyle("", "28px", "40px")};

      @media ${breakpoints.mobile} {
        ${fontStyle("", "22px", "34px")};
      }
    `;
  }}
`;
export const ModalContentParagraph = styled.p`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      ${fontStyle("300", "16px", "21px")};
      margin: 10px 0;
      span,
      a {
        ${fontStyle("700", "16px", "21px")};
        color: ${colors.brandColors.brandSecondary};
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    `;
  }}
`;

export const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const FormErrors = styled.p`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      ${fontStyle("400", "12px", "16px")};
      color: ${colors.helpers.negativePrimary};
      display: flex;
      align-items: center;
    `;
  }}
`;

export const ModalContent = styled.p`
  ${fontStyle("600", "20px", "28px")};
  text-align: center;
`;
