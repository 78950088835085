import { fontStyle } from "components/MintingComponents/SharedStyles.styles";
import styled, { css } from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const CheckboxWrap = styled.div`
  margin-top: 20px;
  div {
    flex-direction: unset;
    gap: 5px;
  }
  a {
    padding-inline-start: 0px;
    color: ${({ theme }) => theme.colors.surfaces.buttonPrimary};
  }
`;
export const ContentTitle = styled.div`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      color: ${colors.textColors.textVariant};
      ${fontStyle("600", "30px", "40px")};
    `;
  }}
`;

export const ContentDescription = styled.p`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      color: ${colors.textColors.textVariant};
      ${fontStyle("400", "16px", "24px")};
      margin: 0 auto;
    `;
  }}
`;

export const ContentAlert = styled.div`
  background: rgba(0, 0, 0, 0.25);
  padding: 12px;
  border-radius: 12px;
`;

export const ContentAlertText = styled.p`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      color: ${colors.textColors.textVariant};
      ${fontStyle("400", "12px", "140%")};
      margin: 0 auto 6px auto;
    `;
  }}
`;

export const ButtonGroup = styled.div`
  width: 100%;
  padding: 32px 0 32px 0;
  margin: 0 auto;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const ButtonText = styled.button`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      color: ${colors.surfaces.buttonSecondary};
      ${fontStyle("600", "14px", "normal")};
      background-color: transparent;
      border: 0;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    `;
  }}
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    margin: 0 auto;
    width: 70%;
  }
`;
export const FormInput = styled.input`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      background: ${colors.brandColors.backgroundTone};
      color: ${colors.textColors.textVariant};
      outline: none;
      width: 100%;
      border: none;
      text-align: start;
      padding: 10px;
    `;
  }}
`;

export const PasswordIcon = styled.i`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      color: ${colors.textColors.textVariant};
      position: absolute;
      display: flex;
      ${({ theme }) =>
        theme.direction === "rtl" ? "left: 5px;" : "right: 5px;"};
      align-items: end;
      justify-content: end;
      top: 34px;
      cursor: pointer;
    `;
  }}
`;

export const FeedbackContainer = styled.div<{ status?: string }>`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      background-color: ${colors.helpers.negativeSecondary};
      border: 1px solid ${colors.helpers.negativePrimary};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: start;
      border-radius: 20px;
      width: 100%;
      padding: 0 20px;
      gap: 10px;
      svg {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
      }
      a {
        color: ${colors.surfaces.buttonSecondary};
        ${fontStyle("600", "14px", "normal")};
        background-color: transparent;
        border: 0;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    `;
  }}
`;

export const FeedbackMessage = styled.p`
  ${fontStyle("400", "14px", "21px")};
  color: #000;
`;

export const PasswordChecklistWrapper = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 20px;
`;

export const PasswordChecklistTitle = styled.p`
  ${fontStyle("600", "14px", "20px")};
`;

export const PasswordChecklistUl = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const PasswordChecklistLi = styled.li`
  ${({ theme }) => {
    const { colors } = theme;
    return css`
      ${fontStyle("400", "14px", "20px")};
      align-items: center;
      display: flex;
      padding-bottom: 7px;
      span {
        ${fontStyle("900", "17px", "20px")};
        color: ${colors.textColors.textGrey};
        padding-inline-end: 10px;
        &.green {
          color: ${colors.helpers.positivePrimary};
        }
      }
    `;
  }}
`;

export const LandingLogin = styled.div`
  width: 70%;
`;
