import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { SfModal, useSfModal } from "@successfactory/sf-libraries";
import { useAxiosPatch } from "api/useApiHook";
import BannerButton from "components/BannerButton/BannerButton";
import Loader from "components/Loader/Loader";
import MintingListItem from "components/MintingComponents/MintingListItem";
import * as St from "components/MintingComponents/SharedStyles.styles";
import { validateWallet } from "components/MintingComponents/Wallet";
import WalletFormCheckbox from "components/MintingComponents/WalletFormCheckbox";
import InputField from "components/Table/Cell/InputField";
import { IUserLands } from "interface/IUser";

import { modalContents } from "../data/modalScenarios";
import * as S from "../styles/CreditsCommon.styles";
import { ModalType } from "../types/modalScenarioType";

export interface ModalGenericProps {
  modalType: ModalType | string;
  userLands: IUserLands | any;
  setShown?: (value: boolean) => void;
}

const CreditModal = ({ modalType, userLands }: ModalGenericProps) => {
  const { t } = useTranslation();
  const [modalProps, setShown] = useSfModal("theia");

  const isPurchaseTwo = modalType === "CONFIRMATION_PHASE_2";

  const [plotsValue, setPlotsValue] = useState<number>(0);
  const [walletData, setWalletData] = useState<string>("");

  const [isValidWalletData, setValidWalletData] =
    useState<boolean | null>(null);

  const [patchLandRequest, patchLandResponse, patchLandStatus] =
    useAxiosPatch("/nfts/land-request");

  useEffect(() => {
    setShown(true);
  });

  const modalContent = useMemo(() => {
    const contentType =
      patchLandStatus === "success" || patchLandStatus === "serverErrors"
        ? t(patchLandResponse?.data?.data)
        : t(modalType);
    return (
      modalContents.find((content) => content.type === contentType) || {
        title: "",
        text: null,
        ctas: [],
        checkboxes: [],
      }
    );
  }, [patchLandStatus, patchLandResponse, t, modalType]);

  const [checkboxStates, setCheckboxStates] = useState<boolean[]>(
    modalContent.checkboxes
      ? modalContent.checkboxes.map((checkbox) => checkbox.checked)
      : []
  );

  const handleCheckboxChange = (index: number) => {
    const newCheckboxStates = [...checkboxStates];
    newCheckboxStates[index] = !newCheckboxStates[index];
    setCheckboxStates(newCheckboxStates);
  };

  const isDisabled: boolean =
    modalContent.checkboxes?.some(
      (checkbox, index) => checkbox.isRequired && !checkboxStates?.[index]
    ) || false;

  const replacePlaceholders = (text: string): string => {
    let replacedText = t(text)
      .replace(/%plotsRequested%/g, String(userLands.plotsRequested || 0))
      .replace(/%plotsAssigned%/g, String(plotsValue || 0))
      .replace(/%requiredCredits%/g, String(userLands.requiredCredits || 0))
      .replace(/%currentCredits%/g, String(userLands.currentCredits || 0));

    if (isPurchaseTwo) {
      replacedText = replacedText.replace(
        /%maxPurchases%/g,
        String(userLands.maxPurchases || 0)
      );
    }

    return replacedText;
  };

  const isInvalidWalletData: boolean =
    isPurchaseTwo && (walletData.length < 10 || !isValidWalletData);

  const finalIsDisabled: boolean = isDisabled || isInvalidWalletData;

  const handleDynamicAction = useCallback(
    (label: string) => {
      const landRequestId = userLands?._id;
      let userResponse: string;

      switch (label) {
        case "modal.confirm":
          userResponse = "CONFIRM";
          break;
        case "modal.reject":
          userResponse = "REJECT";
          break;
        case "modal.continue":
          userResponse = "CONTINUE";
          break;
        default:
          userResponse = "CLOSE";
          break;
      }

      const patchData = {
        landRequestId,
        userResponse,
        ...(isPurchaseTwo
          ? { plotsRequested: plotsValue, walletId: walletData }
          : {}),
      };

      patchLandRequest(patchData);

      if (userResponse === "CLOSE") {
        window.location.reload();
      }
    },
    [userLands?._id, isPurchaseTwo, patchLandRequest, plotsValue, walletData]
  );

  const isJustOneButton = modalContent.ctas?.length === 1;
  const isLoading = patchLandStatus === "loading";
  const isStarter = patchLandStatus === "idle" && isPurchaseTwo;

  const replacedModalContent = modalContent.text
    ? modalContent.text.map((textNode) => replacePlaceholders(textNode))
    : null;

  const modalCurrentProps = {
    showCrossmark: false,
    closeOnOutsideClick: true,
    ...modalProps,
  };

  const MemoizedBannerButton = memo(BannerButton);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;

    setWalletData(value);

    if (value.length >= 10) {
      setValidWalletData(validateWallet(value));
    } else {
      setValidWalletData(null);
    }
  };

  const onChangePlots = (value: number) => {
    setPlotsValue(value);
  };

  const purchaseTwoProps = {
    title: t("Please select how many plots you want to purchase:"),
    subTitle: "",
    amount: plotsValue,
    value: plotsValue,
    isJustOneOrLastItem: true,
    available: userLands.maxPurchases,
    onChange: (value: number) => onChangePlots(value),
  };

  return (
    <SfModal {...modalCurrentProps}>
      <S.ModalWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <S.ModalHeader>{t(modalContent.title)}</S.ModalHeader>

            {replacedModalContent && (
              <S.ModalContentWrapper>
                {replacedModalContent.map((textNode, index) => (
                  <S.ModalContentParagraph
                    key={index}
                    dangerouslySetInnerHTML={{ __html: t(textNode) }}
                  />
                ))}

                {isStarter && (
                  <>
                    <MintingListItem {...purchaseTwoProps} />
                    <St.FormWrapper error={isValidWalletData === false}>
                      <S.ModalContentParagraph>
                        <InputField
                          amount={walletData}
                          onChange={handleInputChange}
                          placeholder={t("Enter your wallet address")}
                        />
                        {isValidWalletData === false && (
                          <S.FormErrors>
                            {t("Please enter a valid WalletID")}
                          </S.FormErrors>
                        )}
                      </S.ModalContentParagraph>
                    </St.FormWrapper>
                  </>
                )}

                {modalContent.checkboxes &&
                  modalContent.checkboxes.map((checkbox, index) => {
                    const renderLabel = replacePlaceholders(checkbox.label);
                    return (
                      <WalletFormCheckbox
                        key={`credit-modal-checkbox-${index}`}
                        label={renderLabel}
                        checked={checkboxStates[index]}
                        onChange={() => handleCheckboxChange(index)}
                        isLabelHasHtml
                      />
                    );
                  })}
              </S.ModalContentWrapper>
            )}

            <St.BannerGroup isJustOneButton={isJustOneButton}>
              {modalContent.ctas &&
                modalContent.ctas.map((cta, index) => {
                  const ctaLabel = t(`${cta}`);
                  const isCloseButton = cta === "modal.close";
                  const ctaProps = {
                    labelText: ctaLabel,
                    $twoTone: true,
                    onClick: () => {
                      handleDynamicAction(cta as string);
                      if (isCloseButton) setShown(false);
                    },
                    disabled: finalIsDisabled,
                    key: `credit-modal-cta-${index}`,
                  };

                  return <MemoizedBannerButton {...ctaProps} />;
                })}
            </St.BannerGroup>
          </>
        )}
      </S.ModalWrapper>
    </SfModal>
  );
};

export default CreditModal;
