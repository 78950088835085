import React from "react";
import IRegionVoteData from "interface/IRegionVoteData";
import { CardRegion } from "./CardRegion";
import {
  FingerprintContainer,
  VotedRegionWrapper,
} from "./VotedRegions.styles";
import { Description, RegionVote } from "./RegionSelect.styles";
import { useTranslation } from "react-i18next";
interface VotedRegionsProps {
  regions: Array<IRegionVoteData>;
}

const VotedRegions: React.FC<VotedRegionsProps> = ({ regions }) => {
  const { t } = useTranslation();
  const displayVotedRegions = () => {
    if (!regions) return null;
    if (regions.length === 0 || regions === undefined) return;
    return regions.map((region: IRegionVoteData, key: number) => {
      const map = `/assets/country-maps/${region.code}.svg`;
      return (
        <VotedRegionWrapper key={key}>
          <CardRegion
            region={region.region}
            type={"answered"}
            activeCard={true}
          >
            <FingerprintContainer>
              <img src={map} alt={t("country")} />
            </FingerprintContainer>
          </CardRegion>
        </VotedRegionWrapper>
      );
    });
  };

  return (
    <>
      <Description>
        {t("Thank you for your response!")}
        <div>
          {t(`“What are the next 5 regions that we should add?” You answered:`)}
        </div>
      </Description>
      <RegionVote>{displayVotedRegions()}</RegionVote>
    </>
  );
};

export default VotedRegions;
