import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { LocationIcon } from "components/LocationIcon/LocationIcon";
import IPackageData from "interface/IPackageData";
import moment from "moment-timezone";
import { getMembershipName } from "util/getMembershipName";

import { useAppState } from "AppContext";

import { CardMembership } from "./CardMembership";
import { getCardFooter } from "./getCardFooter";
import * as S from "./MembershipCards.styles";

interface AllocatedCardProps {
  card: IPackageData;
}

const AllocatedCard: React.FC<AllocatedCardProps> = ({ card }) => {
  const { t } = useTranslation();
  const { isDirection } = useAppState();

  const perks = card.perks ? card.perks : card.offered_perks;

  const allocatedCardContent = {
    header: {
      captionLeft: t("Membership"),
      captionRight: new Date(card.purchasedAt).toLocaleDateString("de-CH"),
      titleLeft: getMembershipName(card),
      titleRight: `${card.value} €`,
      minting: card.minting || null,
      mintingProgressCaption: t("Minting In Progress"),
      mintingCompleteCaption: t("Minted"),
    },

    footer: getCardFooter(perks),
  };

  const TranslatedUnitOrUnits = (isUnit: boolean) => {
    const isUnitOrUnits = useMemo(() => {
      return isUnit ? t("unit ") : t("units ");
    }, [isUnit]);

    return isUnitOrUnits;
  };

  const mediaMobile = window.matchMedia("(max-width: 414px)");
  const mediaMobileSmall = window.matchMedia("(max-width: 320px)");

  const allocatedDate = useMemo(
    () =>
      moment(card.allocated_at)
        .tz("Europe/Amsterdam")
        .format("DD.MM.YYYY HH:mm:ss, z"),
    [card.allocated_at]
  );

  const cardContinentShares = card.continent?.shares || 0;
  return (
    <S.CardWrapper>
      <CardMembership
        type="allocated"
        cardContent={allocatedCardContent}
        width={
          mediaMobileSmall.matches
            ? "295px"
            : mediaMobile.matches
            ? "295px"
            : undefined
        }
      >
        <S.CardContent
          direction={isDirection}
          className={isDirection ? "direction" : ""}
          locationType={card.location?.type}
          continentShares={cardContinentShares}
        >
          <S.AllocatedCaption>
            {card.shares} {TranslatedUnitOrUnits(card.shares === 1)}{" "}
            {t("allocated to")}
          </S.AllocatedCaption>
          <S.AllocatedCountry>{t(card.location!.region)}</S.AllocatedCountry>
          <LocationIcon
            image={card.location!.map || card.location!.logo}
            darkImage={card.location!.dark_logo}
            fitLogo={false}
            type={card.location!.type}
            updatedAt={card.location!.updated_at}
          />
          {card.location?.type === "region" ? (
            <>
              <S.AllocatedCaption>
                {cardContinentShares}
                {TranslatedUnitOrUnits(cardContinentShares === 1)}
                {t("allocated to")}
                <S.AllocatedRegion>
                  {t(card?.location?.continent)}
                </S.AllocatedRegion>
                {card.continent.shares === 0 ? (
                  <div> ({t(`${t("Sold Out")}`)})</div>
                ) : (
                  ""
                )}
              </S.AllocatedCaption>
            </>
          ) : null}
          <S.AllocatedDate>
            {t("Allocated on ")}
            {allocatedDate}
          </S.AllocatedDate>
        </S.CardContent>
      </CardMembership>
    </S.CardWrapper>
  );
};

export default AllocatedCard;
