import i18n from "i18next";

export interface VideoDataProps {
  title: string;
  description: string;
  videoUrl: string;
  ids?: Record<string, string>;
}

export interface TheViewAppInfoProps {
  description: string;
  lang: string;
  data: {
    image: Record<string, string>;
    url: string | undefined;
  }[];
}

export const VIDEO_DATA: VideoDataProps[] = [
  {
    title: i18n.t("Getting started"),
    description: i18n.t(
      "A step-by-step guide to setting up your Meta Account and Oculus headset using the Oculus app. In this same video we will also instruct you on how you can download and access The View app through the Oculus store within your headset."
    ),
    videoUrl: `https://player.vimeo.com/video/[id]`,
    ids: {
      ar: "859723788",
      en: "859721824",
      de: "859722595",
      ru: "859724516",
      es: "859725205",
    },
  },
  {
    title: i18n.t("How to open an account and join The View"),
    description: i18n.t(
      "A step-by-step guide on how to open a The View account, link it to your Meta account, and log into The View for the first time."
    ),
    videoUrl: `https://player.vimeo.com/video/[id]`,
    ids: {
      ar: "859303686",
      en: "859302359",
      de: "859304852",
      ru: "859305677",
      es: "859306671",
    },
  },
];

export const APP_INFO_DATA: TheViewAppInfoProps[] = [
  {
    description: i18n.t(
      "Click on these links from your Smartphone or search for <span>'Oculus App'</span> in the Apple App Store or Google Play Store, and follow the steps outlined in the explainer video."
    ),
    lang: "",
    data: [
      {
        //TODO: replace this logic if the images needs to change, now everything is same

        image: {
          ar: "/assets/icons/meta.svg",
          de: "/assets/icons/meta.svg",
          en: "/assets/icons/meta.svg",
          es: "/assets/icons/meta.svg",
          ru: "/assets/icons/meta.svg",
        },
        url: undefined,
      },
      {
        image: {
          ar: "/assets/icons/app-store-ar.svg",
          de: "/assets/icons/app-store-de.svg",
          en: "/assets/icons/app-store.svg",
          es: "/assets/icons/app-store-es.svg",
          ru: "/assets/icons/app-store-ru.svg",
        },
        url: "https://apps.apple.com/fr/app/meta-quest/id1366478176",
      },
      {
        image: {
          ar: "/assets/icons/google-play.svg",
          de: "/assets/icons/google-play.svg",
          en: "/assets/icons/google-play.svg",
          es: "/assets/icons/google-play.svg",
          ru: "/assets/icons/google-play.svg",
        },
        url: "https://play.google.com/store/apps/details?id=com.oculus.twilight",
      },
    ],
  },
  // TODO: change links to google play and app store for view app
  {
    description: i18n.t(
      "Click on these links to download <span>'The View App'</span> in the Apple App Store or Google Play Store."
    ),
    lang: "",
    data: [
      {
        image: {
          ar: "/assets/icons/view.svg",
          de: "/assets/icons/view.svg",
          en: "/assets/icons/view.svg",
          es: "/assets/icons/view.svg",
          ru: "/assets/icons/view.svg",
        },
        url: undefined,
      },
      {
        image: {
          ar: "/assets/icons/app-store.svg",
          de: "/assets/icons/app-store-de.svg",
          en: "/assets/icons/app-store.svg",
          es: "/assets/icons/app-store.svg",
          ru: "/assets/icons/app-store-ru.svg",
        },
        url: "https://apps.apple.com/fr/app/meta-quest/id1366478176?l=en",
      },
      {
        image: {
          ar: "/assets/icons/google-play.svg",
          de: "/assets/icons/google-play.svg",
          en: "/assets/icons/google-play.svg",
          es: "/assets/icons/google-play.svg",
          ru: "/assets/icons/google-play.svg",
        },
        url: "https://play.google.com/store/apps/details?id=com.oculus.twilight",
      },
    ],
  },
];

export const VideosPageData = {
  videoData: VIDEO_DATA,
  appInfoData: APP_INFO_DATA,
};

export default VideosPageData;
