import countryData from "./countryData.json";
import i18n from "i18n";

export const getCountryName = (userProfile: any) => {
  const getCountry = (shortcode: any) => {
    return shortcode.countryShortCode === userProfile?.country;
  };
  const countryObject = countryData.find(getCountry);
  if (countryObject?.countryName === undefined) return;
  return i18n.t(countryObject.countryName);
};
