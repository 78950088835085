import Loader from "components/Loader/Loader";

import FeedbackDialog from "./FeedbackDialog";
import * as S from "./SharedStyles.styles";

interface FeedbackDialogSwitchProps {
  config: {
    [key: string]: any;
  };
  status: string;
}

const FeedbackDialogSwitch = ({
  config,
  status,
}: FeedbackDialogSwitchProps) => {
  const props = config[status];

  return status === "loading" ? (
    <S.ApiErrorMessageWrapper>
      <Loader />
    </S.ApiErrorMessageWrapper>
  ) : props ? (
    <FeedbackDialog {...props} />
  ) : null;
};

export default FeedbackDialogSwitch;
