import * as S from "./CustomCard.styles";

interface ViewCreditProps {
  children: React.ReactNode;
}

const ViewCredit = ({ children }: ViewCreditProps) => (
  <S.ViewCreditParentContainer>
    <S.ViewCreditContainer>{children}</S.ViewCreditContainer>
  </S.ViewCreditParentContainer>
);

export default ViewCredit;
