import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SfTableGlobalSearch } from "@successfactory/sf-libraries";
import { useAxiosGet } from "api/useApi";
import Loader from "components/Loader/Loader";
import * as St from "components/TheiaTable/TheiaTable.styles";
import { ICredit } from "interface/ICreditStats";
import moment from "moment";

import CreditCard from "./CreditCard";
import * as S from "./style/CreditTable.styles";
import { filterAndSortData } from "./util/sorting";

import { ReactComponent as InfoCircle } from "../../../assets/table/info-circle.svg";
import { ReactComponent as TriangleDown } from "../../../assets/table/triangle-down.svg";
import { ReactComponent as TriangleUp } from "../../../assets/table/triangle-up.svg";

export interface Column {
  header: string;
  accessor: string;
  cell?: ({ value }: { value: string }) => string;
}

export interface Sorting {
  column: string;
  order: "asc" | "desc";
}

export interface DataItem {
  [key: string]: any;
}

const HeaderCellSorting = ({
  column,
  sorting,
  handleSorting,
}: {
  column: Column;
  sorting: Sorting;
  handleSorting: (columnAccessor: string) => void;
}) => {
  const isAsc = sorting.column === column.accessor && sorting.order === "asc";
  const isDesc = sorting.column === column.accessor && sorting.order === "desc";

  const handleClick = () => {
    handleSorting(column.accessor);
  };

  return (
    <S.ShortIconWrapper onClick={handleClick}>
      {column.header}
      <S.SortingIcon isDesc={isDesc} isActive={isAsc || isDesc}>
        <TriangleUp />
        <TriangleDown />
      </S.SortingIcon>
    </S.ShortIconWrapper>
  );
};

const filterAndSearchNoResult = (
  sortedData: DataItem[],
  t: (key: string) => string
) => {
  if (sortedData.length === 0) {
    return (
      <St.Table hasIcon={false}>
        <tbody>
          <S.TableRow>
            <S.TableNoResultTd colSpan={4}>
              <InfoCircle />
              {t("No results found")}
            </S.TableNoResultTd>
          </S.TableRow>
        </tbody>
      </St.Table>
    );
  }
};

const Content = ({
  columns,
  data,
  sorting,
}: {
  columns: Column[];
  data: DataItem[];
  sorting: Sorting;
}) => {
  const { t } = useTranslation();
  const sortedData = filterAndSortData(data, columns, sorting);

  filterAndSearchNoResult(sortedData, t);

  return (
    <tbody>
      {sortedData.map((item, index) => (
        <S.TableRow key={index}>
          {columns.map((column) => (
            <S.TableTd key={column.header}>
              {column.cell
                ? column.cell({ value: t(item[column.accessor]) })
                : column.accessor === "packageType"
                ? item[column.accessor]
                : t(item[column.accessor])}
            </S.TableTd>
          ))}
        </S.TableRow>
      ))}
    </tbody>
  );
};

const CreditTable = ({ creditStats = {} as ICredit }) => {
  const { t } = useTranslation();

  const { total, limit, history } = creditStats;

  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState(history || []);
  const [page, setPage] = useState<number>(1);
  const [sorting, setSorting] = useState<Sorting>({
    column: "",
    order: "desc",
  });

  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const [getCreditHistory, creditHistoryResponse, creditHistoryStatus] =
    useAxiosGet(`/credit-history`);

  const isLoading = creditHistoryStatus === "loading";
  const serverErrors = creditHistoryStatus === "serverErrors";
  const isDataExist = data.length === 0;
  const noResult = isDataExist || isLoading || serverErrors;

  const totalRecords = total <= limit;

  const columns: Column[] = [
    {
      header: t("Membership"),
      accessor: "packageType",
    },
    { header: t("The View Credits/USDT"), accessor: "value" },
    {
      header: t("Date/Time"),
      accessor: "createdAt",
      cell: ({ value }: { value: string }) =>
        moment(value).tz("Europe/Amsterdam").format("DD.MM.YYYY HH:mm:ss, z"),
    },
    { header: t("Description"), accessor: "description" },
  ];

  const handleSortChange = (columnAccessor: string) => {
    setSorting((prevSorting) => ({
      column: columnAccessor,
      order:
        prevSorting.column === columnAccessor
          ? prevSorting.order === "asc"
            ? "desc"
            : "asc"
          : "asc",
    }));
  };

  const handleSearchChange = useCallback(
    (e: string) => {
      setSearch(e);
      setHasMoreData(true);
      if (search.length === 0) {
        setData(history || []);
        return;
      } else {
        const newPage = 1;
        setPage(newPage);
        setData([]);
        getCreditHistory(
          `?filters=${e}&page=${newPage}&limit=${limit}&sortBy=createdAt&sortOrder=-1`
        );
      }
    },
    [search.length, history, getCreditHistory, limit]
  );

  const searchInputRender = () => {
    const searchPlaceHolder = t("Search by Membership/Credits/Description");
    return (
      <St.SearchContainer page="credit">
        <SfTableGlobalSearch
          onChange={(e: string) => handleSearchChange(e)}
          placeholder={searchPlaceHolder}
          value={search}
        ></SfTableGlobalSearch>
      </St.SearchContainer>
    );
  };

  const handleLoadMore = () => {
    if (isLoading || !hasMoreData) return;
    setPage(page + 1);
    getCreditHistory(
      `?filters=${search}&page=${
        page + 1
      }&limit=${limit}&sortBy=createdAt&sortOrder=-1`
    );
  };

  useEffect(() => {
    if (creditHistoryStatus !== "success") return;
    const creditHistoryData = creditHistoryResponse?.data?.data || [];

    const newPage = creditHistoryData ? creditHistoryData?.page : 0;

    const finalPage = Math.ceil(creditHistoryData.total / limit);

    if (newPage === finalPage) {
      setHasMoreData(false);
    }

    const canAdd = (newPage - 1) * limit === data.length;

    if (creditHistoryStatus === "success" && canAdd) {
      setData(
        data.length > 0
          ? data.concat(creditHistoryData.history)
          : creditHistoryData.history
      );
    }
  }, [creditHistoryStatus, creditHistoryResponse, data, limit]);

  const commonProps = {
    columns,
    data,
    sorting,
  };

  const isDisabled = useCallback(() => {
    return !hasMoreData || isLoading || totalRecords || noResult;
  }, [hasMoreData, isLoading, totalRecords, noResult]);

  return (
    <S.TableWrapper>
      <S.TableFormElementGroup>
        <S.TableFormElementWrapper>
          <S.FormLabel>{t("Sort by")} </S.FormLabel>
          <S.CreditTableSelectWrapper>
            <S.CreditTableSelect
              value={sorting.column}
              onChange={(e) => handleSortChange(e.target.value)}
            >
              <option value="">{t("Please Select")}</option>
              {columns.map((column) => {
                return (
                  <option key={column.accessor} value={column.accessor}>
                    {t(column.header)}
                  </option>
                );
              })}
            </S.CreditTableSelect>
          </S.CreditTableSelectWrapper>
        </S.TableFormElementWrapper>
        <S.TableFormElementWrapper>
          {searchInputRender()}
        </S.TableFormElementWrapper>
      </S.TableFormElementGroup>

      <S.CreditTableContainer>
        <St.Table hasIcon={false}>
          <thead>
            <S.TableRow>
              {columns.map((column) => {
                return (
                  <HeaderCellSorting
                    key={column.header}
                    column={column}
                    sorting={sorting}
                    handleSorting={(columnAccessor: string) =>
                      handleSortChange(columnAccessor)
                    }
                  />
                );
              })}
            </S.TableRow>
          </thead>
          <Content {...commonProps} />
        </St.Table>
      </S.CreditTableContainer>
      <S.CreditCardContainer>
        <CreditCard {...commonProps} />
      </S.CreditCardContainer>

      {filterAndSearchNoResult(filterAndSortData(data, columns, sorting), t)}

      <St.LoadMoreContainer>
        {isLoading && <Loader />}
        <St.LoadMoreButton
          onClick={handleLoadMore}
          isDisabled={isDisabled()}
          disabled={isDisabled()}
        >
          {t("Load more")}
        </St.LoadMoreButton>
      </St.LoadMoreContainer>
    </S.TableWrapper>
  );
};

export default CreditTable;
