import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "translations/en/translation.json";
import es from "translations/es/translation.json";
import ru from "translations/ru/translation.json";
import ar from "translations/ar/translation.json";
import de from "translations/de/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      ru: {
        translation: ru,
      },
      ar: {
        translation: ar,
      },
      de: {
        translation: de,
      },
    },
    lng: "en",
    fallbackLng: "en",
    debug: false,
    nsSeparator: "|",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
