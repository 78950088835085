import { FC, useCallback, useState } from "react";
import { SfModal } from "@successfactory/sf-libraries";

import Video from "./Video";
import VideoModalPlayer from "./VideoModalPlayer";
import { VideoDataProps } from "./VideosPageData";

import * as S from "./Videos.styles";

export interface VideosProps {
  videos: VideoDataProps[];
}

const Videos: FC<VideosProps> = ({ videos }) => {
  const [selectedVideo, setSelectedVideo] =
    useState<VideoDataProps | null>(null);

  const handleVideoClick = useCallback((video: VideoDataProps) => {
    setSelectedVideo(video);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedVideo(null);
  }, []);

  return (
    <S.VideosContainer>
      {videos.map((video: VideoDataProps, index: number) => (
        <Video
          key={index}
          onVideoClick={() => handleVideoClick(video)}
          {...(video as VideoDataProps)}
        />
      ))}

      {selectedVideo && (
        <SfModal
          maxWidth="720px"
          onClose={handleCloseModal}
          shown={true}
          crossmarkColor="white"
          borderSize="0px"
          borderColor="transparent"
          backgroundColor="#292C34"
          borderRadius="8px"
        >
          <VideoModalPlayer selectedVideo={selectedVideo as VideoDataProps} />
        </SfModal>
      )}
    </S.VideosContainer>
  );
};

export default Videos;
