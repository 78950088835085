import { forwardRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import * as St from "components/MintingComponents/SharedStyles.styles";

import * as S from "../../styles/Shared.styles";

interface FormInputProps {
  title: string;
  name: string;
  placeHolder?: string;
  type?: string;
  disabled?: boolean;
  value?: string;
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ ...props }: FormInputProps, ref) => {
    const { t } = useTranslation();

    const {
      register,
      formState: { errors },
    } = useFormContext();

    const { title, name, placeHolder, type, disabled, value } = props;
    const isPasswordField = type === "password" ?? false;

    const [passwordType, setPasswordType] = useState("password");
    const [passwordIcon, setPasswordIcon] = useState(
      "/assets/icons/eye-show.svg"
    );
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evt: any) => {
      setPasswordInput(evt.target.value);
    };

    const togglePassword = () => {
      if (passwordType === "password") {
        setPasswordType("text");
        setPasswordIcon("/assets/icons/eye-hide.svg");
        return;
      }
      setPasswordType("password");
      setPasswordIcon("/assets/icons/eye-show.svg");
    };

    const isEmailInput = name === "email" || name === "confirmEmail";

    return (
      <St.FormWrapper error={!!errors[name]}>
        <St.FormLabel>{t(title)}</St.FormLabel>
        <S.FormInput
          {...register(name, { required: true })}
          ref={ref}
          name={name}
          type={isPasswordField ? passwordType : type}
          placeholder={placeHolder}
          disabled={disabled}
          value={value ?? value}
          maxLength={isEmailInput ? 50 : 20}
        />
        {isPasswordField && (
          <S.PasswordIcon onClick={togglePassword}>
            <img src={passwordIcon} alt="Reveal Password" />
          </S.PasswordIcon>
        )}
        {errors[name]?.message && (
          <St.FormError>{t(String(errors[name]?.message))}</St.FormError>
        )}
      </St.FormWrapper>
    );
  }
);

export default FormInput;
