import React, { FC, Suspense } from "react";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader/Loader";
import poolBoxItems from "components/PoolBoxes/PoolBoxItems";
import CreditTable from "components/Table/CreditTable/CreditTable";
import { ICredit } from "interface/ICreditStats";
import { IPoolCard } from "interface/IPool";
import { PoolsName } from "interface/IpoolsName";
import { IStats } from "interface/IStats";

import {
  GenericPoolCardSection,
  GenericPoolSectionContainer,
} from "../InfinityPool/PoolsPage.styles";

const TheiaTable = React.lazy(() => import("components/TheiaTable/TheiaTable"));
const Boxey = React.lazy(() => import("components/PoolBoxes/Boxey"));

interface GenericPoolsProps {
  poolsName: PoolsName;
  description?: string;
  stats: IStats;
  creditStats?: ICredit;
}
export const getPoolsCard = (poolsName: string, stats: IStats) => {
  const filterData = poolBoxItems.filter((item: IPoolCard) =>
    item.key.includes(poolsName)
  );

  return filterData.map((item: IPoolCard) => {
    return (
      <Boxey
        key={item.key}
        pool={item}
        type="pools-page"
        poolsName={poolsName}
        stats={stats}
      />
    );
  });
};

const GenericPoolsPage: FC<GenericPoolsProps> = ({
  poolsName,
  description,
  stats,
  creditStats,
}) => {
  const { t } = useTranslation();

  const pageView = () => {
    return (
      <GenericPoolSectionContainer>
        {t(description as string)}
        <GenericPoolCardSection>
          {getPoolsCard(poolsName, stats)}
        </GenericPoolCardSection>
        <div className="col-l-1-12 col-m-1-8 col-s-1-4 row-l-2-3 row-m-3-3 row-s-3-3">
          <Suspense fallback={<Loader />}>
            {poolsName === "credit" ? (
              <CreditTable creditStats={creditStats} />
            ) : (
              <TheiaTable poolsName={poolsName} />
            )}
          </Suspense>
        </div>
      </GenericPoolSectionContainer>
    );
  };
  return <>{pageView()}</>;
};

export default GenericPoolsPage;
