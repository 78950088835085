import React from "react";
import { Helmet } from "react-helmet-async";

const HomeMetaTags = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {/* Open Graph */}
      <meta property="og:url" content="%PUBLIC_URL%/home" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="The View — A ground-breaking virtual reality platform!"
      />
      <meta
        property="og:description"
        content="Discover the most innovative technology and enter a new reality without limits!"
      />
      <meta property="og:image" content="%PUBLIC_URL%/join-the-view.png" />

      {/* Twitter */}
      <meta
        name="twitter:title"
        content="The View — A ground-breaking virtual reality platform!"
      />
      <meta
        name="twitter:description"
        content="Discover the most innovative technology and enter a new reality without limits!"
      />
      <meta name="twitter:image" content="%PUBLIC_URL%/join-the-view.png" />
      <meta name="twitter:card" content="summary_large_image" />

      <title>The View — A ground-breaking virtual reality platform!</title>
      <link rel="canonical" href="%PUBLIC_URL%home" />
    </Helmet>
  );
};

const CreatorsCornerMetaTags = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:url" content="https://app.jointheview.com/viewzone" />

      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="The View — Stay updated about the new regions!"
      />
      <meta
        property="og:description"
        content="Allocate your units and stay updated about new ones!"
      />
      <meta property="og:image" content="%PUBLIC_URL%/join-the-view.png" />

      {/* Twitter */}
      <meta
        name="twitter:title"
        content="The View — Stay updated about the new regions!"
      />
      <meta
        name="twitter:description"
        content="Allocate your units and stay updated about new ones!"
      />
      <meta name="twitter:image" content="%PUBLIC_URL%/join-the-view.png" />
      <meta name="twitter:card" content="summary_large_image" />

      <title>The View — Stay updated about the new regions!</title>
      <link rel="canonical" href="http://app.jointheview.com/viewzone" />
    </Helmet>
  );
};

const MyPackagesMetaTags = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />

      {/* Open Graph */}
      <meta
        property="og:url"
        content="https://app.jointheview.com/my-memberships"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="The View — See all of your memberships!"
      />
      <meta
        property="og:description"
        content="Access the memberships and allocate new ones!"
      />
      <meta property="og:image" content="%PUBLIC_URL%/join-the-view.png" />

      {/* Twitter */}
      <meta
        name="twitter:title"
        content="The View — See all of your memberships!"
      />
      <meta
        name="twitter:description"
        content="Access the memberships and allocate new ones!"
      />
      <meta name="twitter:image" content="%PUBLIC_URL%/join-the-view.png" />
      <meta name="twitter:card" content="summary_large_image" />

      <title>The View — See all of your memberships!</title>
      <link rel="canonical" href="http://app.jointheview.com/my-memberships" />
    </Helmet>
  );
};

export { HomeMetaTags, CreatorsCornerMetaTags, MyPackagesMetaTags };
