import TopHeader from "components/TopHeader/TopHeader";
import UserRoutes from "routes/UserRoutes";

import { useAppState } from "AppContext";

import { ContentWrapper } from "./PageContent.styles";

// TODO: remove showMaintenance because it's temporary
interface PageContentProps {
  showMaintenance: boolean;
}

const PageContent = ({ showMaintenance }: PageContentProps) => {
  const { isSidebarShown, isDirection } = useAppState();

  return (
    <ContentWrapper sidebar={isSidebarShown} direction={isDirection}>
      {!showMaintenance && <TopHeader />}
      <UserRoutes showMaintenance={showMaintenance} />
    </ContentWrapper>
  );
};

export default PageContent;
