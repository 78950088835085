import styled from "styled-components";
import { breakpoints } from "styles/themes/breakPoint";

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 46px;
  @media ${breakpoints.mobile} {
    justify-content: center;
  }
`;

export const EmptyCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 80px 30px auto 20px;
  p {
    font-weight: 300;
    font-style: italic;
  }
`;
